import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useStateReducer } from "../../hooks";
import { NavigableComponent } from "../../interfaces";
import { siteTemplatesService } from "../../services";
import { FaHandPointer } from "react-icons/fa";
import { getQueryParameter } from "../../utilities";
import { routes } from "../../_config";
import { LoadingIndicator, Scrollable } from "../../components";
import ViewSiteTemplate from "./components/ViewSiteTemplate";
import EditSiteTemplate from "./components/EditSiteTemplate";
import { SiteTemplateFull } from "../../interfaces";

interface SiteTemplatesItemPageProps {
  index?: boolean;
  onLoad: () => void;
}
interface State {
  siteTemplate?: SiteTemplateFull | null;
  isSaveDisabled?: boolean;
  loading?: boolean;
}

export default function SiteTemplatesItemPage({
  setBreadcrumbs,
  setToolbarConfig,
  index,
  onLoad,
}: NavigableComponent & SiteTemplatesItemPageProps) {
  const { settingId } = useParams();
  const [state, setState] = useStateReducer<State>({ isSaveDisabled: true });
  const { siteTemplate } = state;
  const loading = siteTemplate ? false : true;
  const location = useLocation();
  const mode = getQueryParameter(location.search, "mode");

  useEffect(() => {
    const load = async () => {
      const _siteTemplate =
        mode === "edit"
          ? await siteTemplatesService.getSingle({
              id: settingId ?? "",
            })
          : await siteTemplatesService.getCurrent({
              id: settingId ?? "",
            });

      setState({ siteTemplate: _siteTemplate, loading: false });

      onLoad();

      setBreadcrumbs(
        index
          ? [
              { text: "Settings", to: routes.settings.go() },
              { text: "Site Templates" },
            ]
          : [
              { text: "Settings", to: routes.settings.go() },
              { text: "Site Templates", to: routes.siteTemplates.raw },
              { text: _siteTemplate.name },
            ]
      );
    };

    setState({ siteTemplate: null, isSaveDisabled: true, loading: true });
    load();
  }, [settingId, mode, setBreadcrumbs, onLoad, index, setState]);

  let content = <></>;

  if (index)
    content = (
      <>
        <FaHandPointer /> Select a site template on the left.
      </>
    );
  else if (!siteTemplate || loading) content = <LoadingIndicator />;
  else
    content = (
      <Scrollable>
        {mode === "edit" || mode === "add" ? (
          <EditSiteTemplate
            setToolbarConfig={setToolbarConfig}
            setBreadcrumbs={setBreadcrumbs}
            siteTemplate={siteTemplate}
            onLoad={onLoad}
          />
        ) : (
          <ViewSiteTemplate
            setToolbarConfig={setToolbarConfig}
            setBreadcrumbs={setBreadcrumbs}
            siteTemplate={siteTemplate}
          />
        )}
      </Scrollable>
    );

  return content;
}

import { Selection } from "d3";

export default function addTernaryGrid(
  svg: Selection<SVGSVGElement, unknown, null, undefined>,
  baseX: number,
  baseY: number,
  baseLength: number,
  equiHeight: number,
  gridCount: number,
  gridValueIncrements: "All" | "Even" | "Odd",
  gridValueFormat: "Fraction" | "Percentage",
  direction: "clockwise" | "counter-clockwise",
  rotation?: "270degrees",
  cAxisAdjust?: number
) {
  const ternaryY = baseY - equiHeight;
  const gridLines: { x1: number; x2: number; y1: number; y2: number }[] = [];
  const gridLabels: {
    x: number;
    y: number;
    label: string;
    align: "start" | "end" | "middle";
  }[] = [];
  const baseInc = baseLength / gridCount;
  const halfInc = baseLength / (gridCount * 2);
  const heightInc = equiHeight / gridCount;
  const rotated = rotation === "270degrees";

  const formatAxisValue = (labelValue: number) =>
    gridValueFormat === "Fraction"
      ? labelValue.toFixed(1)
      : `${(labelValue * 100).toFixed(0)}%`;

  for (let i = 1; i < gridCount; i++) {
    gridLines.push({
      x1: !rotated ? baseX + baseInc * i : baseX,
      x2: !rotated
        ? baseX + baseLength / 2 + halfInc * i
        : baseX - equiHeight + heightInc * i,
      y1: !rotated ? baseY : baseY + baseInc * i,
      y2: !rotated
        ? ternaryY + heightInc * i
        : baseY + baseLength / 2 + halfInc * i,
    });

    gridLines.push({
      x1: !rotated ? baseX + baseInc * i : baseX,
      x2: !rotated ? baseX + halfInc * i : baseX - heightInc * i,
      y1: !rotated ? baseY : baseY + baseInc * i,
      y2: !rotated ? baseY - heightInc * i : baseY + halfInc * i,
    });

    gridLines.push({
      x1: !rotated ? baseX + halfInc * i : baseX - heightInc * i,
      x2: !rotated ? baseX + baseLength - halfInc * i : baseX - heightInc * i,
      y1: !rotated ? baseY - heightInc * i : baseY + halfInc * i,
      y2: !rotated ? baseY - heightInc * i : baseY + baseLength - halfInc * i,
    });

    if (gridValueIncrements === "Even" && i % 2 === 1) continue;
    if (gridValueIncrements === "Odd" && i % 2 === 0) continue;

    const labelValue =
      direction === "clockwise"
        ? (baseInc * i) / baseLength
        : 1 - (baseInc * i) / baseLength;
    const labelValueInverse =
      direction === "clockwise"
        ? 1 - (baseInc * i) / baseLength
        : (baseInc * i) / baseLength;

    gridLabels.push({
      x: !rotated ? baseX + baseInc * i : baseX + 5 + (cAxisAdjust ?? 0),
      y: !rotated
        ? baseY + 17 + (cAxisAdjust ?? 0)
        : baseY + baseLength - baseInc * i,
      label: formatAxisValue(labelValueInverse),
      align: !rotated ? "middle" : "start",
    });
    gridLabels.push({
      x: !rotated
        ? baseX + baseLength / 2 + halfInc * i + 5
        : baseX - equiHeight + heightInc * i,
      y: !rotated
        ? ternaryY + heightInc * i
        : baseY + baseLength / 2 - halfInc * i - 5,
      label: formatAxisValue(labelValue),
      align: !rotated ? "start" : "end",
    });
    gridLabels.push({
      x: !rotated ? baseX + halfInc * i - 5 : baseX - heightInc * i,
      y: !rotated
        ? baseY - heightInc * i
        : baseY + baseLength - halfInc * i + 15,
      label: formatAxisValue(labelValue),
      align: "end",
    });
  }

  const gridGrouping = svg.select(".plot-area").append("g");

  gridGrouping
    .selectAll("line")
    .data(gridLines)
    .enter()
    .append("line")
    .attr("x1", (g) => g.x1)
    .attr("x2", (g) => g.x2)
    .attr("y1", (g) => g.y1)
    .attr("y2", (g) => g.y2)
    .attr("stroke", "#aaaaaa")
    .attr("stroke-width", 1)
    .attr("stroke-dasharray", "5,5");

  gridGrouping
    .selectAll("text")
    .data(gridLabels)
    .enter()
    .append("text")
    .attr("x", (g) => g.x)
    .attr("y", (g) => g.y)
    .attr("text-anchor", (g) => g.align)
    .style("font-size", "12px")
    .text((g) => g.label);
}

import { Selection } from "d3";
import Constants from "../Constants";
import { Band, Guide } from "../interfaces";

export default function addBandAndGuideLegend(
  svg: Selection<SVGSVGElement, unknown, null, undefined>,
  bandAndGuideLegendData: {
    bandData?: Band;
    guideData?: Guide;
    label: string;
    textWidth: number;
    rectangleSize: number;
    totalWidth: number;
    row: number;
    rowWidth: number;
  }[],
  totalHeight: number
) {
  const { seriesHeight, seriesRectangleSize, renderHeight, plotWidth } =
    Constants;
  let seriesData: {
    label: string;
    color: string;
    style?: "Solid" | "Dashed";
    x: number;
    y: number;
  }[] = [];

  const yStart = renderHeight - totalHeight;
  let currentRow = 1;
  let currentX = (plotWidth - bandAndGuideLegendData[0].rowWidth) / 2;

  seriesData = bandAndGuideLegendData.map((l, i) => {
    let x = currentX;

    if (currentRow !== l.row) {
      currentX = (plotWidth - l.rowWidth) / 2 + l.totalWidth;
      x = (plotWidth - l.rowWidth) / 2;
    } else currentX += l.totalWidth;

    currentRow = l.row;

    return {
      label: l.label,
      color: l.bandData?.color ?? l.guideData?.color ?? "#000000",
      style: l.guideData?.style,
      y: yStart + (l.row - 1) * seriesHeight,
      x,
    };
  });

  const bandGrouping = svg.select(".series").append("g");
  const bandElements = bandGrouping
    .selectAll("g")
    .data(seriesData.filter((d) => d.style === undefined))
    .enter()
    .append("g");
  bandElements
    .append("rect")
    .attr("x", (b) => b.x)
    .attr("y", (b) => b.y + (seriesHeight - seriesRectangleSize) / 2)
    .attr("height", seriesRectangleSize)
    .attr("width", seriesRectangleSize)
    .attr("stroke", "#000000")
    .attr("stroke-width", 1)
    .attr("fill", (b) => b.color);
  bandElements
    .append("text")
    .attr("x", (b) => b.x + seriesRectangleSize + 5)
    .attr("y", (b) => b.y + seriesHeight - 6)
    .style("font-size", "12px")
    .text((b) => b.label);

  const guideGrouping = svg.select(".series").append("g");
  const guideElements = guideGrouping
    .selectAll("g")
    .data(seriesData.filter((d) => d.style !== undefined))
    .enter()
    .append("g");
  guideElements
    .append("line")
    .attr("x1", (g) => g.x)
    .attr("x2", (g) => g.x + seriesRectangleSize)
    .attr("y1", (g) => g.y + seriesHeight / 2)
    .attr("y2", (g) => g.y + seriesHeight / 2)
    .attr("stroke", (s) => s.color)
    .attr("stroke-width", 4)
    .attr("stroke-dasharray", (g) => (g.style === "Dashed" ? "4,2" : ""));
  guideElements
    .append("text")
    .attr("x", (g) => g.x + seriesRectangleSize + 5)
    .attr("y", (g) => g.y + seriesHeight - 6)
    .style("font-size", "12px")
    .text((g) => g.label);
}

import React from "react";
import {
  CasingMaterials,
  ConstructionMaterials,
  LibraryCardProps,
  LithologyMaterials,
} from "../../interfaces";

export default function LibraryCard({
  item,
}: LibraryCardProps<
  CasingMaterials | ConstructionMaterials | LithologyMaterials
>) {
  const { type } = item;

  return (
    <>
      <span className="card-primary">{type || "No type specified"}</span>
    </>
  );
}

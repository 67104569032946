import React, { useCallback, useEffect, useState } from "react";
import { MeasurableSummary } from "../../../interfaces";
import { measurablesService } from "../../../services";
import {
  InstructionWrapper,
  List,
  TextField,
} from "../../../components/fields";
import { Button, Form } from "../../../components";
import { updateModalActionState } from "../../../components/modal/Modal";
import { useStateReducer } from "../../../hooks";
import "./_styles.scss";

export interface UnitConversionItemProps {
  unitFull?: string;
  unitAbbreviation?: string;
  formula?: string;
}

interface UnitConversionProps {
  id: string;
  name: string;
  handleSubmit: (
    unitFull?: string,
    unitAbbreviation?: string,
    formula?: string
  ) => void;
}

export interface State {
  unitFull?: string;
  unitAbbreviation?: string;
  formula?: string;
}

export default function UnitConversionModal({
  id,
  name,
  handleSubmit,
}: UnitConversionProps) {
  const [measurables, setMeasurables] = useState<MeasurableSummary[]>([]);
  const [state, setState] = useStateReducer<State>({});
  const handleMeasurableSearch = useCallback(
    async (search: string) => {
      const results = await measurablesService.getByPage({
        search,
        pageNo: 1,
        pageSize: 100,
      });

      setMeasurables(results.data);
    },
    [setMeasurables]
  );

  useEffect(() => {
    updateModalActionState([
      {
        disabled:
          !state.formula || !state.unitAbbreviation || !state.unitFull
            ? true
            : false,
        onClick: () =>
          handleSubmit(state.unitFull, state.unitAbbreviation, state.formula),
      },
      {},
    ]);
  }, [state.unitFull, state.unitAbbreviation, state.formula, handleSubmit]);

  return (
    <Form title="Unit Conversion">
      <InstructionWrapper text="Unit details.">
        <TextField
          className="conversion-item-flex"
          label="Unit Full"
          value={state.unitFull ?? ""}
          onChange={(e) => setState({ unitFull: e.target.value })}
        />
        <TextField
          className="conversion-item-flex"
          label="Unit Abbreviation"
          value={state.unitAbbreviation ?? ""}
          onChange={(e) => setState({ unitAbbreviation: e.target.value })}
        />
      </InstructionWrapper>
      <TextField
        className="conversion-item-style"
        label="Formula"
        value={state.formula ?? ""}
        multiline={true}
        onChange={(e) => setState({ formula: e.target.value })}
      />
      <div className="conversion-unit">
        <Button
          className="conversion-button"
          primary
          text="Current Value"
          onClick={() =>
            setState({
              formula:
                state.formula ??
                "" + ` {${id}-${name || name === "" ? "Current Value" : name}}`,
            })
          }
        />
        <Button
          className="conversion-button"
          primary
          text="Surface Elevation"
          onClick={() =>
            setState({
              formula: state.formula + ` {SurfaceElevation}`,
            })
          }
        />
        <List
          className="conversion-expand"
          label="Measurements"
          getOptionLabel={(o) => o.name}
          getOptionValue={(o) => o.id}
          options={measurables}
          onInputChange={(_, v) => handleMeasurableSearch(v)}
          onChange={(_, value) =>
            setState({
              formula: state.formula + ` {${value.id}-${value.name}}`,
            })
          }
          onFocus={() => handleMeasurableSearch("")}
        />
      </div>
    </Form>
  );
}

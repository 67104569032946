import moment from "moment";
import { ReportTemplateSummary } from "../interfaces";
import { LithologyAndConstructionReport, Report } from "../interfaces/Reports";
import { goFetch } from "../utilities/goFetch";

interface ReportsService {
  getReportData: (
    reportTemplate: ReportTemplateSummary,
    siteId: string,
    snapshotDate: Date
  ) => Promise<Report | undefined>;
}

const controller = "reports";

const service: ReportsService = {
  getReportData: async (reportTemplate, siteId, snapshotDate) => {
    let reportEndpoint = "";

    switch (reportTemplate.reportType) {
      case "LithologyAndBoreholeConstruction":
        reportEndpoint = "lithology-and-borehole-construction";
        break;
    }

    if (!reportEndpoint) throw new Error("Invalid report type specified.");

    if (!siteId) throw new Error("No site id specified.");

    const data = await goFetch(`${controller}/${reportEndpoint}`)
      .withQueryParameters({
        reportTemplateId: reportTemplate.id,
        siteId: siteId,
        snapshotDate: moment(snapshotDate).format(),
      })
      .get();

    switch (reportTemplate.reportType) {
      case "LithologyAndBoreholeConstruction":
        return data as LithologyAndConstructionReport;
    }

    return undefined;
  },
};

export default service;

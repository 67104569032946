export interface ValidationRule {
  regex?: RegExp;
  func?: Function;
  message: string;
}

export interface Validation {
  [key: string | number]: ValidationRule;
}

export interface ValidationResponse {
  [key: string | number]: string | null;
}

export function hasErrors(validationResult: ValidationResponse) {
  return (
    Object.keys(validationResult).filter((k) =>
      validationResult[k] ? true : false
    ).length > 0
  );
}

/**
 * Validates an object against the supplied rules.
 * @param {Object} rules The rules to validate against
 * @param {Object} rules.prop1 The property's validation rule
 * @param {RegExp} rules.prop1.regex The regular expression to validate the value against
 * @param {Function} rules.prop1.func A function to execute for validating the value
 * @param {string} rules.prop1.message The message to display when validation fails
 * @param {Object} obj The object to validate
 * @returns
 */
export default function validateObject(
  rules: Validation,
  obj: any | null
): ValidationResponse {
  const errors: ValidationResponse = {};

  Object.keys(rules).forEach((k: string) => {
    if (!obj) return;

    const val = obj[k];
    const { regex, func, message } = rules[k];
    let error: string | null = null;

    if (regex && !regex.test(val ?? "")) error = message;
    else if (func && !func(val)) error = message;

    errors[k] = error;
  });

  return errors;
}

import moment from "moment";
import React from "react";
import { LibraryCardProps, SiteTemplateSummary } from "../../interfaces";

export default function IncompleteLibraryCard({
  item,
}: LibraryCardProps<SiteTemplateSummary>) {
  const { name, createdOn } = item;
  return (
    <>
      <span className="card-primary">{name || "No name specified"}</span>
      <span className="card-secondary">
        Created On: {moment(createdOn).format("DD MMM YYYY, HH:mm:ss")}
      </span>
    </>
  );
}

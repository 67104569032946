import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AreaBand,
  BarConfig,
  BoxAndWhiskerConfig,
  ChartConfigType,
  ChartTemplateFull,
  ChartType,
  DurovConfig,
  Guide,
  LineConfig,
  NavigableComponent,
  PiperConfig,
  ScatterConfig,
  SchoellerConfig,
  SiteTemplateSummary,
  StiffConfig,
} from "../../../interfaces";
import { useParams, useNavigate } from "react-router-dom";
import { asyncify, hasErrors, validateObject } from "../../../utilities";
import { FaEye, FaSave, FaTimes } from "react-icons/fa";
import { routes } from "../../../_config";
import { ToolItem } from "../../../components/toolbar/Toolbar";
import { Validation } from "../../../utilities/validateObject";
import { publish, useStateReducer } from "../../../hooks";
import ReferenceSummary from "../../../interfaces/ReferenceSummary";
import { chartTemplatesService, siteTemplatesService } from "../../../services";
import {
  BarConfigUpdate,
  BoxAndWhiskerConfigUpdate,
  ChartTemplateUpdateDefinition,
  DurovConfigUpdate,
  LineConfigUpdate,
  PiperConfigUpdate,
  ScatterConfigUpdate,
  SchoellerConfigUpdate,
  StiffConfigUpdate,
} from "../../../services/chartTemplatesService";
import { hideAlert, showAlert } from "../../../components/alert/Alert";
import { AlertTypeEnum, StateTopicEnum } from "../../../enums";
import { LibrarySection } from "../../../components/library/Library";
import { Form } from "../../../components";
import {
  InstructionWrapper,
  List,
  TextField,
} from "../../../components/fields";
import ChartTypeSelector from "./ChartTypeSelector";
import BarChartConfig from "./BarChartConfig";
import { hideModal, showModal } from "../../../components/modal/Modal";
import PreviewChartModal from "./PreviewChartModal";
import { v4 as uuid } from "uuid";
import LineChartConfig from "./LineChartConfig";
import ScatterChartConfig from "./ScatterChartConfig";
import BoxAndWhiskerChartConfig from "./BoxAndWhiskerChartConfig";
import SchoellerChartConfig from "./SchoellerChartConfig";
import PiperChartConfig from "./PiperChartConfig";
import DurovChartConfig from "./DurovChartConfig";
import StiffChartConfig from "./StiffChartConfig";

interface EditChartTemplateProps {
  chartTemplate: ChartTemplateFull;
  onLoad: () => void;
}

interface State {
  id?: string;
  name?: string;
  chartType?: ChartType;
  siteTemplates?: ReferenceSummary[];
  config?: ChartConfigType;
}

export default function EditChartTemplate({
  chartTemplate,
  setToolbarConfig,
}: NavigableComponent & EditChartTemplateProps) {
  const { settingId } = useParams();
  const navigate = useNavigate();
  const [state, setState] = useStateReducer<State>({
    id: chartTemplate.id,
    name: chartTemplate.name,
    chartType: chartTemplate.chartType,
    siteTemplates: chartTemplate.siteTemplates,
    config: (() => {
      if (!chartTemplate.config) return chartTemplate.config;

      let areaBands: AreaBand[] | undefined;
      let guides: Guide[] | undefined;

      switch (chartTemplate.chartType) {
        case "Bar":
          areaBands = (chartTemplate.config as BarConfig).areaBands;
          guides = (chartTemplate.config as BarConfig).guides;
          break;
        case "BoxAndWhisker":
          areaBands = (chartTemplate.config as BoxAndWhiskerConfig).areaBands;
          guides = (chartTemplate.config as BoxAndWhiskerConfig).guides;
          break;
        case "Line":
          areaBands = (chartTemplate.config as LineConfig).areaBands;
          guides = (chartTemplate.config as LineConfig).guides;
          break;
        case "Scatter":
          areaBands = (chartTemplate.config as ScatterConfig).areaBands;
          guides = (chartTemplate.config as ScatterConfig).guides;
          break;
      }

      if (areaBands)
        areaBands.forEach((a) => {
          a.id = uuid();
        });

      if (guides)
        guides.forEach((g) => {
          g.id = uuid();
        });

      return chartTemplate.config;
    })(),
  });

  const [siteTemplates, setSiteTemplates] = useState<SiteTemplateSummary[]>([]);
  const saveState = useRef<State>(state);
  const validationRules: Validation = useMemo(
    () => ({
      name: {
        regex: /.+/,
        message: "This field is required.",
      },
      chartType: {
        func: (v: ChartType | undefined) => v && true,
        message: "This field is required.",
      },
      siteTemplates: {
        func: (v: ReferenceSummary | undefined) => v && true,
        message: "This field is required.",
      },
    }),
    []
  );
  const validation = validateObject(validationRules, state);

  const handleSave = useCallback(async () => {
    await asyncify(() => {}, 10);

    if (hasErrors(validateObject(validationRules, saveState.current))) return;

    const doUpdate = async (definition: ChartTemplateUpdateDefinition) => {
      const response = await chartTemplatesService.update(
        chartTemplate.id,
        definition
      );

      if (!response?.id) {
        showAlert({
          content: "Could not update the chart template.",
          options: {
            type: AlertTypeEnum.Error,
            actions: [{ text: "Ok" }],
          },
        });
        return false;
      }

      return true;
    };

    const state = saveState.current;
    const updateDefinition: ChartTemplateUpdateDefinition = {
      type: state.chartType ?? "Bar",
      siteTemplateIds: state.siteTemplates?.map((e) => e.id) ?? [],
      name: state.name ?? "",
    };

    switch (state.chartType) {
      case "Bar":
        updateDefinition.config = {
          ...(state.config as BarConfig),
          measurableId: (state.config as BarConfig).measurable.id,
        } as BarConfigUpdate;
        break;
      case "BoxAndWhisker":
        updateDefinition.config = {
          ...(state.config as BoxAndWhiskerConfig),
          measurableId: (state.config as BoxAndWhiskerConfig).measurable.id,
        } as BoxAndWhiskerConfigUpdate;
        break;
      case "Line":
        updateDefinition.config = {
          ...(state.config as LineConfig),
          measurableId: (state.config as LineConfig).measurable.id,
        } as LineConfigUpdate;
        break;
      case "Scatter":
        updateDefinition.config = {
          ...(state.config as ScatterConfig),
          xAxisMeasurableId: (state.config as ScatterConfig).xAxisMeasurable.id,
          yAxisMeasurableId: (state.config as ScatterConfig).yAxisMeasurable.id,
        } as ScatterConfigUpdate;
        break;
      case "Schoeller":
        updateDefinition.config = {
          ...(state.config as SchoellerConfig),
          measurableIds: (state.config as SchoellerConfig).measurables.map(
            (m) => m.id
          ),
        } as SchoellerConfigUpdate;
        break;
      case "Stiff":
        updateDefinition.config = {
          ...(state.config as StiffConfig),
          cationElementIds: (state.config as StiffConfig).cationElements.map(
            (m) => m.id
          ),
          anionElementIds: (state.config as StiffConfig).anionElements.map(
            (m) => m.id
          ),
        } as StiffConfigUpdate;
        break;
      case "Piper":
        updateDefinition.config = {
          ...(state.config as PiperConfig),
          anionsAElementIds: (state.config as PiperConfig).anionsAElements.map(
            (m) => m.id
          ),
          anionsBElementIds: (state.config as PiperConfig).anionsBElements.map(
            (m) => m.id
          ),
          anionsCElementIds: (state.config as PiperConfig).anionsCElements.map(
            (m) => m.id
          ),
          cationsAElementIds: (
            state.config as PiperConfig
          ).cationsAElements.map((m) => m.id),
          cationsBElementIds: (
            state.config as PiperConfig
          ).cationsBElements.map((m) => m.id),
          cationsCElementIds: (
            state.config as PiperConfig
          ).cationsCElements.map((m) => m.id),
        } as PiperConfigUpdate;
        break;
      case "Durov":
        updateDefinition.config = {
          ...(state.config as DurovConfig),
          anionsAElementIds: (state.config as DurovConfig).anionsAElements.map(
            (m) => m.id
          ),
          anionsBElementIds: (state.config as DurovConfig).anionsBElements.map(
            (m) => m.id
          ),
          anionsCElementIds: (state.config as DurovConfig).anionsCElements.map(
            (m) => m.id
          ),
          cationsAElementIds: (
            state.config as DurovConfig
          ).cationsAElements.map((m) => m.id),
          cationsBElementIds: (
            state.config as DurovConfig
          ).cationsBElements.map((m) => m.id),
          cationsCElementIds: (
            state.config as DurovConfig
          ).cationsCElements.map((m) => m.id),
          bottomPlotMeasurableId: (state.config as DurovConfig)
            .bottomPlotMeasurable.id,
          rightPlotMeasurableId: (state.config as DurovConfig)
            .rightPlotMeasurable.id,
        } as DurovConfigUpdate;
        break;
    }

    if (!(await doUpdate(updateDefinition))) return;

    publish<LibrarySection>(StateTopicEnum.LibrarySectionRefresh, "paged");
    navigate(routes.settingItem.go("chart-templates-library", settingId));

    showAlert({
      content: "Chart Template saved.",
      options: {
        type: AlertTypeEnum.Info,
        actions: [{ text: "Ok", primary: true }],
      },
    });
  }, [navigate, settingId, chartTemplate.id, validationRules]);

  const handlePreview = useCallback(() => {
    if (!state.siteTemplates || !state.chartType || !state.config)
      return showAlert({
        content:
          "Please first complete all configuration properties before previewing the chart.",
        options: {
          type: AlertTypeEnum.Warning,
          actions: [{ text: "Ok", onClick: hideAlert, primary: true }],
        },
      });

    showModal({
      content: (
        <PreviewChartModal chartType={state.chartType} config={state.config} />
      ),
      options: {
        title: "Preview Chart Template",
        actions: [{ text: "Close", primary: true, onClick: hideModal }],
        className: "chart-preview-modal",
      },
    });
  }, [state.siteTemplates, state.chartType, state.config]);

  const handleConfigChange = useCallback(
    (config: ChartConfigType) => setState({ config }),
    [setState]
  );

  useEffect(() => {
    let toolbarItems: (ToolItem | "|")[] = [];

    toolbarItems = [
      {
        label: "Save",
        icon: FaSave,
        onClick: handleSave,
        primary: true,
        raised: true,
      },
      "|",
      {
        label: "Cancel",
        icon: FaTimes,
        onClick: () => {
          navigate(routes.settingItem.go("chart-templates-library", settingId));
        },
      },
      {
        label: "Preview",
        icon: FaEye,
        onClick: handlePreview,
        className: "chart-preview-button",
      },
    ];

    setToolbarConfig({
      allowSearch: true,
      toolbarItems,
      searchPath: routes.descriptors.go(),
    });
  }, [handleSave, navigate, setToolbarConfig, settingId, handlePreview]);

  useEffect(() => {
    const load = async () => {
      const results = await siteTemplatesService.getByPage({
        pageNo: 1,
        pageSize: 1000,
        search: "",
      });

      setSiteTemplates(results.data);
    };
    load();
  }, []);

  useEffect(() => {
    saveState.current = state;
  }, [state]);

  return (
    <>
      <Form title="Identifying Information">
        <InstructionWrapper
          text="An identifying name for the chart template."
          error={validation["name"]}
        >
          <TextField
            label="Name"
            required
            value={state.name}
            onChange={(e) => setState({ name: e.target.value })}
            error={validation["name"] ? true : false}
          />
        </InstructionWrapper>
        <InstructionWrapper
          text="The site template(s) that this chart template is associated with."
          error={validation["siteTemplates"]}
        >
          <List
            multiple
            value={state.siteTemplates == null ? [] : state.siteTemplates}
            options={siteTemplates}
            getOptionLabel={(o: SiteTemplateSummary) => o.name}
            getOptionValue={(o: SiteTemplateSummary) => o.id}
            isOptionEqualToValue={(
              o: SiteTemplateSummary,
              v: ReferenceSummary
            ) => o.id === v.id}
            label="Site Template(s)"
            onChange={(_, v: SiteTemplateSummary[]) =>
              setState({
                siteTemplates:
                  v.length > 0
                    ? v.map((e) => ({ id: e.id, name: e.name }))
                    : [],
                chartType: undefined,
                config: undefined,
              })
            }
            onInputChange={(_, v) => {}}
            error={validation["siteTemplates"] ? true : false}
          />
        </InstructionWrapper>
        <InstructionWrapper
          text="The chart type to display."
          error={validation["chartType"]}
        >
          <ChartTypeSelector
            value={state.chartType}
            onChange={(chartType) => setState({ chartType })}
          />
        </InstructionWrapper>
      </Form>
      {state.chartType === "Bar" && state.siteTemplates && (
        <BarChartConfig
          key="bar-config"
          config={state.config as BarConfig}
          siteTemplates={state.siteTemplates}
          onChange={handleConfigChange}
        />
      )}
      {state.chartType === "Line" && state.siteTemplates && (
        <LineChartConfig
          key="line-config"
          config={state.config as LineConfig}
          siteTemplates={state.siteTemplates}
          onChange={handleConfigChange}
        />
      )}
      {state.chartType === "Scatter" && state.siteTemplates && (
        <ScatterChartConfig
          key="line-config"
          config={state.config as ScatterConfig}
          siteTemplates={state.siteTemplates}
          onChange={handleConfigChange}
        />
      )}
      {state.chartType === "BoxAndWhisker" && state.siteTemplates && (
        <BoxAndWhiskerChartConfig
          key="box-and-whisker-config"
          config={state.config as BoxAndWhiskerConfig}
          siteTemplates={state.siteTemplates}
          onChange={handleConfigChange}
        />
      )}
      {state.chartType === "Schoeller" && state.siteTemplates && (
        <SchoellerChartConfig
          key="schoeller-config"
          config={state.config as SchoellerConfig}
          siteTemplates={state.siteTemplates}
          onChange={handleConfigChange}
        />
      )}
      {state.chartType === "Piper" && state.siteTemplates && (
        <PiperChartConfig
          key="piper-config"
          config={state.config as PiperConfig}
          siteTemplates={state.siteTemplates}
          onChange={handleConfigChange}
        />
      )}
      {state.chartType === "Durov" && state.siteTemplates && (
        <DurovChartConfig
          key="durov-config"
          config={state.config as DurovConfig}
          siteTemplates={state.siteTemplates}
          onChange={handleConfigChange}
        />
      )}
      {state.chartType === "Stiff" && state.siteTemplates && (
        <StiffChartConfig
          key="stiff-config"
          config={state.config as StiffConfig}
          siteTemplates={state.siteTemplates}
          onChange={handleConfigChange}
        />
      )}
    </>
  );
}

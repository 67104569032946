import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useStateReducer } from "../../hooks";
import { NavigableComponent } from "../../interfaces";
import { usersService } from "../../services";
import { FaHandPointer } from "react-icons/fa";
import { getQueryParameter } from "../../utilities";
import { routes } from "../../_config";
import { LoadingIndicator, Scrollable } from "../../components";
import ViewUser from "./components/ViewUser";
import EditUser from "./components/EditUser";
import { UserFull } from "../../interfaces";

interface UsersItemPageProps {
  index?: boolean;
  onLoad: () => void;
}
interface State {
  user?: UserFull | null;
  loading?: boolean;
}

export default function UsersItemPage({
  setBreadcrumbs,
  setToolbarConfig,
  index,
  onLoad,
}: NavigableComponent & UsersItemPageProps) {
  const { settingId } = useParams();
  const [state, setState] = useStateReducer<State>({
    loading: true,
  });
  const { user, loading } = state;
  const location = useLocation();
  const mode = getQueryParameter(location.search, "mode");

  useEffect(() => {
    const load = async () => {
      const _user = await usersService.getSingle({
        id: settingId ?? "",
      });

      setState({ user: _user, loading: false });
      onLoad();

      setBreadcrumbs(
        index
          ? [{ text: "Settings", to: routes.settings.go() }, { text: "Users" }]
          : [
              { text: "Settings", to: routes.settings.go() },
              { text: "Users", to: routes.users.go() },
              { text: _user.name },
            ]
      );
    };

    setState({ user: null, loading: false });
    load();
  }, [settingId, mode, setState, onLoad, setBreadcrumbs, index]);

  let content = <></>;

  if (index)
    content = (
      <>
        <FaHandPointer /> Select a user on the left.
      </>
    );
  else if (!user || settingId !== user.id || loading)
    content = <LoadingIndicator />;
  else
    content = (
      <Scrollable>
        {mode === "edit" || mode === "add" ? (
          <EditUser
            setToolbarConfig={setToolbarConfig}
            setBreadcrumbs={setBreadcrumbs}
            user={user}
          />
        ) : (
          <ViewUser
            setToolbarConfig={setToolbarConfig}
            setBreadcrumbs={setBreadcrumbs}
            user={user}
          />
        )}
      </Scrollable>
    );

  return content;
}

import React, { Component, ReactNode } from "react";

export interface TabProps {
  identifier: string;
  heading: string;
  children: React.ReactNode;
  className?: string;
  onShow?: () => void;
  onHide?: () => void;
  onFirstShow?: () => void;
}

export default class Tab extends Component<TabProps> {
  identifier: string;
  heading: string;
  children: React.ReactNode;
  className?: string;
  onShow?: () => void;
  onHide?: () => void;
  onFirstShow?: () => void;

  constructor(props: TabProps) {
    super(props);
    this.identifier = props.identifier;
    this.heading = props.heading;
    this.children = props.children;
    this.className = props.className;
    this.onShow = props.onShow;
    this.onHide = props.onHide;
    this.onFirstShow = props.onFirstShow;
  }

  render(): ReactNode {
    return <></>;
  }
}

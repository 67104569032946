import {
  CrudResponse,
  GetSingleArgs,
  MeasurableFull,
  MeasurableSummary,
  PageAndSearchArgs,
} from "../interfaces";
import CrudService from "../interfaces/CrudService";
import { UnitConversion } from "../interfaces/Measurable";
import { goFetch } from "../utilities/goFetch";

interface UpdateDefinition {
  name: string;
  unitFull?: string;
  unitAbbreviation?: string;
  minValue?: number;
  maxValue?: number;
  allowRangeSelection?: boolean;
  displayFormat?: string;
  unitConversions?: UnitConversion[];
  date?: Date;
}

interface MeasurablesService {
  create: () => Promise<CrudResponse>;
  update: (id: string, definition: UpdateDefinition) => Promise<MeasurableFull>;
  delete: (id: string) => Promise<CrudResponse>;
}

const controller = "measurables";

const service: CrudService<MeasurableFull, MeasurableSummary> &
  MeasurablesService = {
  getByPage: async ({ search, pageNo, pageSize }: PageAndSearchArgs) => {
    const response = await goFetch(`${controller}/page/${pageNo}`)
      .withQueryParameters({
        pageSize: pageSize || 50,
        search: search,
      })
      .get();

    return {
      pageNo: response?.pageNo ?? 0,
      pageSize: response?.pageSize ?? 0,
      pageCount: response?.pageCount ?? 0,
      itemCount: response?.itemCount ?? 0,
      data: response?.data ?? [],
    };
  },

  getSingle: async ({ id }: GetSingleArgs) =>
    await goFetch(`${controller}/${id}`).get(),

  create: async () => await goFetch(controller).post(),

  update: async (id, definition) =>
    await goFetch(`${controller}/${id}`).withBody(definition).patch(),

  delete: async (id) => await goFetch(`${controller}/${id}`).delete(),
};

export default service;

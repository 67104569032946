import React, { useEffect, useMemo, useState } from "react";
import ReferenceSummary from "../../../interfaces/ReferenceSummary";
import {
  CoordinateDatum,
  CoordinateReferenceSystem,
  LithologyAndBoreholeConstructionConfig as LConfig,
} from "../../../interfaces";
import getOrderedMeasurables, {
  OrderedMeasurable,
} from "../../charttemplates-page/utilities/getOrderedMeasurables";
import { useStateReducer } from "../../../hooks";
import validateObject, {
  Validation,
  ValidationRule,
} from "../../../utilities/validateObject";
import getOrderedDescriptors, {
  OrderedDescriptor,
} from "../../charttemplates-page/utilities/getOrderedDescriptors";
import { Form } from "../../../components";
import {
  InstructionWrapper,
  List,
  RadioButtons,
  TextField,
} from "../../../components/fields";
import coordinateReferenceSystemToOptions from "../utilities/coordinateReferenceSystemToOptions copy";
import datumToOptions from "../utilities/datumToOptions";

interface LithologyAndBoreholeConstructionConfigProps {
  siteTemplates: ReferenceSummary[];
  config?: LConfig;
  onChange?: (config: LConfig) => void;
  readOnly?: boolean;
}

const crsOptions = coordinateReferenceSystemToOptions();
const datumOptions = datumToOptions();

interface State {
  collarHeightField?: ReferenceSummary;
  coordinates_ellipsoid?: CoordinateReferenceSystem;
  coordinates_datum?: CoordinateDatum;
  lithology_depthFromField?: ReferenceSummary;
  lithology_depthToField?: ReferenceSummary;
  lithology_typeField?: ReferenceSummary;
  lithology_attributeFields?: ReferenceSummary[];
  penetrationRate_depthFromField?: ReferenceSummary;
  penetrationRate_depthToField?: ReferenceSummary;
  penetrationRate_valueField?: ReferenceSummary;
  construction_depthFromField?: ReferenceSummary;
  construction_depthToField?: ReferenceSummary;
  construction_innerRadiusField?: ReferenceSummary;
  construction_outerRadiusField?: ReferenceSummary;
  construction_typeField?: ReferenceSummary;
  casing_depthFromField?: ReferenceSummary;
  casing_depthToField?: ReferenceSummary;
  casing_radiusField?: ReferenceSummary;
  casing_typeField?: ReferenceSummary;
  electricalConductivity_depthField?: ReferenceSummary;
  electricalConductivity_valueField?: ReferenceSummary;
  waterLevelField?: ReferenceSummary;
  waterStrikeTopDepthField?: ReferenceSummary;
  waterStrikeTopValueField?: ReferenceSummary;
  waterStrikeBottomDepthField?: ReferenceSummary;
  waterStrikeBottomValueField?: ReferenceSummary;
}

export default function LithologyAndBoreholeConstructionConfig({
  siteTemplates,
  config,
  onChange,
  readOnly,
}: LithologyAndBoreholeConstructionConfigProps) {
  const [measurables, setMeasurables] = useState<OrderedMeasurable[]>();
  const [descriptors, setDescriptors] = useState<OrderedDescriptor[]>();
  const [state, setState] = useStateReducer<State>({
    collarHeightField: config?.siteDetails.collarHeightField,
    coordinates_ellipsoid:
      config?.siteDetails.coordinates.ellipsoid ?? "WebMercator",
    coordinates_datum:
      config?.siteDetails.coordinates.datum ?? "Hartebeesthoek94",
    lithology_depthFromField: config?.lithology.depthFromField,
    lithology_depthToField: config?.lithology.depthToField,
    lithology_typeField: config?.lithology.typeField,
    lithology_attributeFields: config?.lithology.attributeFields,
    penetrationRate_depthFromField: config?.penetrationRate.depthFromField,
    penetrationRate_depthToField: config?.penetrationRate.depthToField,
    penetrationRate_valueField: config?.penetrationRate.valueField,
    construction_depthFromField: config?.construction.depthFromField,
    construction_depthToField: config?.construction.depthToField,
    construction_innerRadiusField: config?.construction.innerRadiusField,
    construction_outerRadiusField: config?.construction.outerRadiusField,
    construction_typeField: config?.construction.typeField,
    casing_depthFromField: config?.casing.depthFromField,
    casing_depthToField: config?.casing.depthToField,
    casing_radiusField: config?.casing.radiusField,
    casing_typeField: config?.casing.typeField,
    electricalConductivity_depthField:
      config?.electricalConductivity.depthField,
    electricalConductivity_valueField:
      config?.electricalConductivity.valueField,
    waterLevelField: config?.water.waterLevelField,
    waterStrikeTopDepthField: config?.water.waterStrikeTopDepthField,
    waterStrikeTopValueField: config?.water.waterStrikeTopValueField,
    waterStrikeBottomDepthField: config?.water.waterStrikeBottomDepthField,
    waterStrikeBottomValueField: config?.water.waterStrikeBottomValueField,
  });
  const fieldValidationRule: ValidationRule = useMemo(
    () => ({
      func: (field: ReferenceSummary) => field?.id && field?.id !== "-1",
      message: "This field is required.",
    }),
    []
  );
  const validationRules: Validation = useMemo(
    () => ({
      collarHeightField: fieldValidationRule,
      lithology_depthFromField: fieldValidationRule,
      lithology_depthToField: fieldValidationRule,
      lithology_typeField: fieldValidationRule,
      lithology_attributeFields: {
        func: (fields: ReferenceSummary[]) => fields?.length > 0,
        message: "This field is required.",
      },
      penetrationRate_depthFromField: fieldValidationRule,
      penetrationRate_depthToField: fieldValidationRule,
      penetrationRate_valueField: fieldValidationRule,
      construction_depthFromField: fieldValidationRule,
      construction_depthToField: fieldValidationRule,
      construction_innerRadiusField: fieldValidationRule,
      construction_outerRadiusField: fieldValidationRule,
      construction_typeField: fieldValidationRule,
      casing_depthFromField: fieldValidationRule,
      casing_depthToField: fieldValidationRule,
      casing_radiusField: fieldValidationRule,
      casing_typeField: fieldValidationRule,
      electricalConductivity_depthField: fieldValidationRule,
      electricalConductivity_valueField: fieldValidationRule,
      waterLevelField: fieldValidationRule,
      waterStrikeTopDepthField: fieldValidationRule,
      waterStrikeTopValueField: fieldValidationRule,
      waterStrikeBottomDepthField: fieldValidationRule,
      waterStrikeBottomValueField: fieldValidationRule,
    }),
    [fieldValidationRule]
  );
  const unselectedField: ReferenceSummary = useMemo(
    () => ({ id: "-1", name: "" }),
    []
  );
  const validation = validateObject(validationRules, state);

  useEffect(() => {
    if (readOnly) return;

    const load = async () => {
      setMeasurables(
        await getOrderedMeasurables(siteTemplates.map((e) => e.id))
      );
      setDescriptors(
        await getOrderedDescriptors(siteTemplates.map((e) => e.id))
      );
    };

    load();
  }, [siteTemplates, readOnly, setMeasurables, setDescriptors]);

  useEffect(() => {
    if (readOnly) return;

    onChange?.({
      siteDetails: {
        collarHeightField: state.collarHeightField ?? unselectedField,
        coordinates: {
          ellipsoid: state.coordinates_ellipsoid ?? "WebMercator",
          datum: state.coordinates_datum ?? "Hartebeesthoek94",
        },
      },
      lithology: {
        depthFromField: state.lithology_depthFromField ?? unselectedField,
        depthToField: state.lithology_depthToField ?? unselectedField,
        typeField: state.lithology_typeField ?? unselectedField,
        attributeFields: state.lithology_attributeFields ?? [],
      },
      construction: {
        depthFromField: state.construction_depthFromField ?? unselectedField,
        depthToField: state.construction_depthToField ?? unselectedField,
        innerRadiusField:
          state.construction_innerRadiusField ?? unselectedField,
        outerRadiusField:
          state.construction_outerRadiusField ?? unselectedField,
        typeField: state.construction_typeField ?? unselectedField,
      },
      casing: {
        depthFromField: state.casing_depthFromField ?? unselectedField,
        depthToField: state.casing_depthToField ?? unselectedField,
        radiusField: state.casing_radiusField ?? unselectedField,
        typeField: state.casing_typeField ?? unselectedField,
      },
      electricalConductivity: {
        depthField: state.electricalConductivity_depthField ?? unselectedField,
        valueField: state.electricalConductivity_valueField ?? unselectedField,
      },
      penetrationRate: {
        depthFromField: state.penetrationRate_depthFromField ?? unselectedField,
        depthToField: state.penetrationRate_depthToField ?? unselectedField,
        valueField: state.penetrationRate_valueField ?? unselectedField,
      },
      water: {
        waterLevelField: state.waterLevelField ?? unselectedField,
        waterStrikeTopDepthField:
          state.waterStrikeTopDepthField ?? unselectedField,
        waterStrikeTopValueField:
          state.waterStrikeTopValueField ?? unselectedField,
        waterStrikeBottomDepthField:
          state.waterStrikeBottomDepthField ?? unselectedField,
        waterStrikeBottomValueField:
          state.waterStrikeBottomValueField ?? unselectedField,
      },
    });
  }, [unselectedField, state, readOnly, onChange]);

  return (
    <>
      <Form title="Site Details Configuration">
        <InstructionWrapper
          text="The measurable that is used to display site details on the report."
          error={validation["collarHeightField"]}
        >
          <ListField
            label="Collar Height Field"
            readOnly={readOnly ?? false}
            value={state.collarHeightField}
            options={measurables ?? []}
            onChange={(f) => setState({ collarHeightField: f })}
            error={validation["collarHeightField"]}
          />
        </InstructionWrapper>
        <InstructionWrapper text="The coordinate reference system that is displayed on the report.">
          <RadioButtons
            horizontal
            label="Coordinate Ellipsoid"
            items={crsOptions}
            value={state.coordinates_ellipsoid}
            onChange={
              readOnly
                ? undefined
                : (_, coordinates_ellipsoid: CoordinateReferenceSystem) =>
                    setState({ coordinates_ellipsoid })
            }
            readOnly={readOnly}
          />
        </InstructionWrapper>
        <InstructionWrapper text="The coordinate datum that is displayed on the report.">
          <RadioButtons
            horizontal
            label="Coordinate Datum"
            items={datumOptions}
            value={state.coordinates_datum}
            onChange={
              readOnly
                ? undefined
                : (_, coordinates_datum: CoordinateDatum) =>
                    setState({ coordinates_datum })
            }
            readOnly={readOnly}
          />
        </InstructionWrapper>
      </Form>
      <Form title="Lithology Configuration">
        <InstructionWrapper
          text="The measurables used for the depth of the lithology."
          error={
            validation["lithology_depthFromField"] ||
            validation["lithology_depthToField"]
          }
          row
        >
          <ListField
            label="Depth From"
            readOnly={readOnly ?? false}
            value={state.lithology_depthFromField}
            options={measurables ?? []}
            onChange={(f) => setState({ lithology_depthFromField: f })}
            error={validation["lithology_depthFromField"]}
          />
          <ListField
            label="Depth To"
            readOnly={readOnly ?? false}
            value={state.lithology_depthToField}
            options={measurables ?? []}
            onChange={(f) => setState({ lithology_depthToField: f })}
            error={validation["lithology_depthToField"]}
          />
        </InstructionWrapper>
        <InstructionWrapper
          text="The descriptors used for describing the lithology."
          error={
            validation["lithology_typeField"] ||
            validation["lithology_depthToField"]
          }
          row
        >
          <ListField
            label="Type"
            readOnly={readOnly ?? false}
            value={state.lithology_typeField}
            options={descriptors ?? []}
            onChange={(f) =>
              setState({ lithology_typeField: f as ReferenceSummary })
            }
            error={validation["lithology_typeField"]}
          />
          <MultiListField
            label="Attributes"
            readOnly={readOnly ?? false}
            value={state.lithology_attributeFields ?? []}
            options={descriptors ?? []}
            onChange={(f) => setState({ lithology_attributeFields: f })}
            error={validation["lithology_attributeFields"]}
          />
        </InstructionWrapper>
      </Form>
      <Form title="Penetration Rate Configuration">
        <InstructionWrapper
          text="The measurables used for the depth of the penetration rate."
          error={
            validation["penetrationRate_depthFromField"] ||
            validation["penetrationRate_depthToField"]
          }
          row
        >
          <ListField
            label="Depth From"
            readOnly={readOnly ?? false}
            value={state.penetrationRate_depthFromField}
            options={measurables ?? []}
            onChange={(f) => setState({ penetrationRate_depthFromField: f })}
            error={validation["penetrationRate_depthFromField"]}
          />
          <ListField
            label="Depth To"
            readOnly={readOnly ?? false}
            value={state.penetrationRate_depthToField}
            options={measurables ?? []}
            onChange={(f) => setState({ penetrationRate_depthToField: f })}
            error={validation["penetrationRate_depthToField"]}
          />
        </InstructionWrapper>
        <InstructionWrapper
          text="The measurable used for the value of the penetration rate."
          error={validation["penetrationRate_valueField"]}
          row
        >
          <ListField
            label="Value"
            readOnly={readOnly ?? false}
            value={state.penetrationRate_valueField}
            options={measurables ?? []}
            onChange={(f) => setState({ penetrationRate_valueField: f })}
            error={validation["penetrationRate_valueField"]}
          />
          <div />
        </InstructionWrapper>
      </Form>
      <Form title="Construction Configuration">
        <InstructionWrapper
          text="The measurables used for the depth of the construction."
          error={
            validation["construction_depthFromField"] ||
            validation["construction_depthToField"]
          }
          row
        >
          <ListField
            label="Depth From"
            readOnly={readOnly ?? false}
            value={state.construction_depthFromField}
            options={measurables ?? []}
            onChange={(f) => setState({ construction_depthFromField: f })}
            error={validation["construction_depthFromField"]}
          />
          <ListField
            label="Depth To"
            readOnly={readOnly ?? false}
            value={state.construction_depthToField}
            options={measurables ?? []}
            onChange={(f) => setState({ construction_depthToField: f })}
            error={validation["construction_depthToField"]}
          />
        </InstructionWrapper>
        <InstructionWrapper
          text="The measurables used for the radius of the construction."
          error={
            validation["construction_innerRadiusField"] ||
            validation["construction_outerRadiusField"]
          }
          row
        >
          <ListField
            label="Inner Radius"
            readOnly={readOnly ?? false}
            value={state.construction_innerRadiusField}
            options={measurables ?? []}
            onChange={(f) => setState({ construction_innerRadiusField: f })}
            error={validation["construction_innerRadiusField"]}
          />
          <ListField
            label="Outer Radius"
            readOnly={readOnly ?? false}
            value={state.construction_outerRadiusField}
            options={measurables ?? []}
            onChange={(f) => setState({ construction_outerRadiusField: f })}
            error={validation["construction_outerRadiusField"]}
          />
        </InstructionWrapper>
        <InstructionWrapper
          text="The descriptor used for the material of construction."
          error={validation["construction_typeField"]}
          row
        >
          <ListField
            label="Material"
            readOnly={readOnly ?? false}
            value={state.construction_typeField}
            options={descriptors ?? []}
            onChange={(f) => setState({ construction_typeField: f })}
            error={validation["construction_typeField"]}
          />
          <div />
        </InstructionWrapper>
      </Form>
      <Form title="Casing Configuration">
        <InstructionWrapper
          text="The measurables used for the depth of the casing."
          error={
            validation["casing_depthFromField"] ||
            validation["casing_depthToField"]
          }
          row
        >
          <ListField
            label="Depth From"
            readOnly={readOnly ?? false}
            value={state.casing_depthFromField}
            options={measurables ?? []}
            onChange={(f) => setState({ casing_depthFromField: f })}
            error={validation["casing_depthFromField"]}
          />
          <ListField
            label="Depth To"
            readOnly={readOnly ?? false}
            value={state.casing_depthToField}
            options={measurables ?? []}
            onChange={(f) => setState({ casing_depthToField: f })}
            error={validation["casing_depthToField"]}
          />
        </InstructionWrapper>
        <InstructionWrapper
          text="The measurable used for the radius of the casing."
          error={validation["casing_radiusField"]}
          row
        >
          <ListField
            label="Radius"
            readOnly={readOnly ?? false}
            value={state.casing_radiusField}
            options={measurables ?? []}
            onChange={(f) => setState({ casing_radiusField: f })}
            error={validation["casing_radiusField"]}
          />
          <div />
        </InstructionWrapper>
        <InstructionWrapper
          text="The descriptor used for the material of casing."
          error={validation["casing_typeField"]}
          row
        >
          <ListField
            label="Material"
            readOnly={readOnly ?? false}
            value={state.casing_typeField}
            options={descriptors ?? []}
            onChange={(f) => setState({ casing_typeField: f })}
            error={validation["casing_typeField"]}
          />
          <div />
        </InstructionWrapper>
      </Form>
      <Form title="Electrical Conductivity Configuration">
        <InstructionWrapper
          text="The measurables used for the depth and value of the electrical conductivity."
          error={
            validation["electricalConductivity_depthField"] ||
            validation["electricalConductivity_valueField"]
          }
          row
        >
          <ListField
            label="Depth"
            readOnly={readOnly ?? false}
            value={state.electricalConductivity_depthField}
            options={measurables ?? []}
            onChange={(f) => setState({ electricalConductivity_depthField: f })}
            error={validation["electricalConductivity_depthField"]}
          />
          <ListField
            label="Value"
            readOnly={readOnly ?? false}
            value={state.electricalConductivity_valueField}
            options={measurables ?? []}
            onChange={(f) => setState({ electricalConductivity_valueField: f })}
            error={validation["electricalConductivity_valueField"]}
          />
        </InstructionWrapper>
      </Form>
      <Form title="Water Measurements Configuration">
        <InstructionWrapper
          text="The measurables used for the water strike (top)."
          error={
            validation["waterStrikeTopDepthField"] ||
            validation["waterStrikeTopValueField"]
          }
          row
        >
          <ListField
            label="Depth"
            readOnly={readOnly ?? false}
            value={state.waterStrikeTopDepthField}
            options={measurables ?? []}
            onChange={(f) => setState({ waterStrikeTopDepthField: f })}
            error={validation["waterStrikeTopDepthField"]}
          />
          <ListField
            label="Value"
            readOnly={readOnly ?? false}
            value={state.waterStrikeTopValueField}
            options={measurables ?? []}
            onChange={(f) => setState({ waterStrikeTopValueField: f })}
            error={validation["waterStrikeTopValueField"]}
          />
        </InstructionWrapper>
        <InstructionWrapper
          text="The measurables used for the water strike (bottom)."
          error={
            validation["waterStrikeBottomDepthField"] ||
            validation["waterStrikeBottomValueField"]
          }
          row
        >
          <ListField
            label="Depth"
            readOnly={readOnly ?? false}
            value={state.waterStrikeBottomDepthField}
            options={measurables ?? []}
            onChange={(f) => setState({ waterStrikeBottomDepthField: f })}
            error={validation["waterStrikeBottomDepthField"]}
          />
          <ListField
            label="Value"
            readOnly={readOnly ?? false}
            value={state.waterStrikeBottomValueField}
            options={measurables ?? []}
            onChange={(f) => setState({ waterStrikeBottomValueField: f })}
            error={validation["waterStrikeBottomValueField"]}
          />
        </InstructionWrapper>
        <InstructionWrapper
          text="The measurable used for the water level."
          error={validation["waterLevelField"]}
          row
        >
          <ListField
            label="Water Level"
            readOnly={readOnly ?? false}
            value={state.waterLevelField}
            options={measurables ?? []}
            onChange={(f) => setState({ waterLevelField: f })}
            error={validation["waterLevelField"]}
          />
          <div />
        </InstructionWrapper>
      </Form>
    </>
  );
}

const ListField = ({
  label,
  readOnly,
  value,
  options,
  onChange,
  error,
}: {
  label: string;
  readOnly: boolean;
  value?: ReferenceSummary;
  options: ReferenceSummary[];
  onChange: (field: ReferenceSummary) => void;
  error: string | null;
}) => {
  return readOnly ? (
    <TextField label={label} value={value?.name} readOnly />
  ) : (
    <List
      label={label}
      options={options}
      groupBy={(o) => o.group}
      getOptionLabel={(o) => o.name}
      getOptionValue={(o) => o.id}
      isOptionEqualToValue={(o, v) => o.id === v.id}
      value={value}
      onChange={(_, field: ReferenceSummary) => onChange(field)}
      error={error ? true : false}
    />
  );
};

const MultiListField = ({
  label,
  readOnly,
  value,
  options,
  onChange,
  error,
}: {
  label: string;
  readOnly: boolean;
  value?: ReferenceSummary[];
  options: ReferenceSummary[];
  onChange: (field: ReferenceSummary[]) => void;
  error: string | null;
}) => {
  return (
    <List
      label={label}
      options={options}
      groupBy={(o) => o.group}
      getOptionLabel={(m) => m.name}
      getOptionValue={(m) => m.id}
      isOptionEqualToValue={(o, v) => o.id === v.id}
      value={value}
      onChange={
        readOnly ? undefined : (_, field: ReferenceSummary[]) => onChange(field)
      }
      error={error ? true : false}
      multiple
      readOnly={readOnly}
    />
  );
};

import {
  CoordinateAccuracyType,
  CoordinateMethodType,
  CoordinateReferencePoint,
  CrudResponse,
  GetSingleArgs,
  PageAndSearchArgs,
  PagedData,
  SiteFull,
  SiteMeasurements,
  SiteSummary,
  TopographyTypes,
  VersionableService,
} from "../interfaces";
import { goFetch } from "../utilities/goFetch";

export type SiteUpdateAction =
  | "UpdateDetails"
  | "UpdateReferences"
  | "UpdateLocation"
  | "UpdateCoordinates"
  | "UpdateMeta";

export interface UpdateSiteCoordinates {
  latitude: number;
  longitude: number;
  accuracy?: CoordinateAccuracyType;
  method?: CoordinateMethodType;
  surfaceElevation?: number;
  crsTypes: CoordinateReferencePoint[];
}

export interface UpdateSiteDetails {
  identifier?: string;
  description?: string;
  siteTemplateId?: string;
  monitoringGroupId?: string;
  tags: string[];
}

export interface UpdateSiteLocation {
  farmName?: string;
  farmNumber?: string;
  portionNumber?: string;
  erfNumber?: string;
  topographySetting?: TopographyTypes;
}

export interface UpdateSiteMeta {
  lpisgCode?: string;
  reportingInstitute?: string;
  source?: string;
}

export interface UpdateSiteReferences {
  referenceNumbers: string[];
}

interface SitePagingArgs extends PageAndSearchArgs {
  siteTemplates?: string[];
  monitoringGroups?: string[];
  databases?: string[];
  tags?: string[];
}

interface SitesService {
  getByPage: (args: SitePagingArgs) => Promise<PagedData<SiteSummary>>;
  getSiteByPage: (
    site: string,
    args: SitePagingArgs
  ) => Promise<PagedData<SiteSummary>>;
  getByFormPage: (
    formId: string,
    args: SitePagingArgs
  ) => Promise<PagedData<SiteSummary>>;
  getSingle: (args: GetSingleArgs) => Promise<SiteFull>;
  getMeasurements: (args: { id: string }) => Promise<SiteMeasurements>;
  searchTags: (search: string) => Promise<string[]>;
  searchDatabases: (search: string) => Promise<string[]>;
  searchArea: (args: {
    area: { longitude: number; latitude: number }[];
    siteTemplates?: string[];
    monitoringGroups?: string[];
    databases?: string[];
    tags?: string[];
  }) => Promise<SiteSummary[]>;
  create: (
    action: "CreateNew" | "VersionExisting",
    definition: {} | { familyId: string }
  ) => Promise<CrudResponse>;
  update: (
    id: string,
    action: SiteUpdateAction,
    definition:
      | UpdateSiteCoordinates
      | UpdateSiteDetails
      | UpdateSiteLocation
      | UpdateSiteMeta
      | UpdateSiteReferences
  ) => Promise<SiteFull>;
  delete: (
    id: string,
    action: "DiscardDraftVersion" | "DeleteFamily"
  ) => Promise<CrudResponse>;
  exportSites: (
    siteTemplateId: string,
    filters?: {
      monitoringGroups?: string[];
      databases?: string[];
      tags?: string[];
    }
  ) => Promise<{ [key: string]: string | number | Date | null }[]>;
  downloadSiteExports: (
    siteTemplateId: string,
    filters?: {
      monitoringGroups?: string[];
      databases?: string[];
      tags?: string[];
    }
  ) => Promise<string>;
  importSitesTemplate: (
    siteTemplateId: string
  ) => Promise<{ [key: string]: string | number | Date | null }[]>;
  downloadImportSitesTemplate: (siteTemplateId: string) => Promise<string>;
  importSites: (
    siteTemplateId: string,
    file: File
  ) => Promise<{ success: boolean; message?: string }>;
}

const controller = "sites";

const service: VersionableService<SiteFull, SiteSummary> & SitesService = {
  getByPage: async ({
    search,
    pageNo,
    pageSize,
    siteTemplates,
    monitoringGroups,
    databases,
    tags,
  }: SitePagingArgs) => {
    const response = await goFetch(`${controller}/page/${pageNo}`)
      .withQueryParameters({
        pageSize: pageSize || 50,
        search: search,
        siteTemplates,
        monitoringGroups,
        databases,
        tags,
      })
      .get();

    return {
      data: response?.data ?? [],
      pageCount: response?.pageCount ?? 0,
      pageSize: response?.pageSize ?? 0,
      itemCount: response?.itemCount ?? 0,
      pageNo: response?.pageNo ?? pageNo,
    };
  },

  getSiteByPage: async (
    site,
    {
      search,
      pageNo,
      pageSize,
      siteTemplates,
      monitoringGroups,
      databases,
      tags,
    }: SitePagingArgs
  ) => {
    const response = await goFetch(
      `${controller}/${site}/page/${pageNo}/family`
    )
      .withQueryParameters({
        pageSize: pageSize || 50,
        search: search,
        siteTemplates,
        monitoringGroups,
        databases,
        tags,
      })
      .get();

    return {
      data: response?.data ?? [],
      pageCount: response?.pageCount ?? 0,
      pageSize: response?.pageSize ?? 0,
      itemCount: response?.itemCount ?? 0,
      pageNo: response?.pageNo ?? pageNo,
    };
  },

  getByFormPage: async (
    formid,
    {
      search,
      pageNo,
      pageSize,
      siteTemplates,
      monitoringGroups,
      databases,
      tags,
    }: SitePagingArgs
  ) => {
    const response = await goFetch(`${controller}/${formid}/page/${pageNo}`)
      .withQueryParameters({
        pageSize: pageSize || 50,
        search: search,
        siteTemplates,
        monitoringGroups,
        databases,
        tags,
      })
      .get();

    return {
      data: response?.data ?? [],
      pageCount: response?.pageCount ?? 0,
      pageSize: response?.pageSize ?? 0,
      itemCount: response?.itemCount ?? 0,
      pageNo: response?.pageNo ?? pageNo,
    };
  },

  getIncomplete: async () => await goFetch(`${controller}/incomplete`).get(),

  getSingle: async ({ id }: GetSingleArgs) =>
    await goFetch(`${controller}/${id}`).get(),

  getCurrent: async ({ id }: GetSingleArgs) =>
    await goFetch(`${controller}/${id}/current`).get(),

  searchTags: async (search) =>
    await goFetch(`${controller}/tags`).withQueryParameters({ search }).get(),

  searchDatabases: async (search) =>
    await goFetch(`${controller}/databases`)
      .withQueryParameters({ search })
      .get(),

  searchArea: async (args) =>
    await goFetch(`${controller}/area`).withBody(args).post(),

  create: async (action, definition) =>
    await goFetch(controller).withBody({ action, definition }).post(),

  finalize: async (id) => await goFetch(`${controller}/${id}`).put(),

  update: async (id, action, definition) =>
    await goFetch(`${controller}/${id}`)
      .withBody({ action, definition })
      .patch(),

  delete: async (id, action) =>
    await goFetch(`${controller}/${id}`).withBody({ action }).delete(),

  getMeasurements: async (args) =>
    await goFetch(`${controller}/${args.id}/measurements`).get(),

  exportSites: async (siteTemplateId, filters) =>
    await goFetch(`${controller}/export/${siteTemplateId}`)
      .withQueryParameters(filters ?? {})
      .get(),

  downloadSiteExports: async (siteTemplateId, filters) =>
    await goFetch(`${controller}/export/${siteTemplateId}/download`)
      .withQueryParameters(filters ?? {})
      .get(),

  importSitesTemplate: async (siteTemplateId) =>
    await goFetch(`${controller}/import-template/${siteTemplateId}`).get(),

  downloadImportSitesTemplate: async (siteTemplateId) =>
    await goFetch(
      `${controller}/import-template/${siteTemplateId}/download`
    ).get(),

  importSites: async (siteTemplateId, file) => {
    const formData = new FormData();
    formData.append("file", file);

    let message: string | undefined;
    let response = await goFetch(`${controller}/import/${siteTemplateId}`)
      .withFormData(formData)
      .withCustomErrorHandler((error) => {
        message = error;
      })
      .post();

    return {
      success: response ? true : false,
      message,
    };
  },
};

export default service;

import React from "react";
import { scaleLinear, scaleLog } from "d3";
import { useD3 } from "../../hooks";
import {
  addAxisLabels,
  addBandAndGuideLegend,
  addChartBands,
  addChartGrid,
  addChartGuides,
  calculateBandAndGuideRows,
  calculatePlotHeight,
  calculateRightMargin,
  calculateRotatedLabelHeight,
  getSeriesColor,
  measureTextWidth,
} from "./utilities";
import { AxisSettings, Band, Guide, Series } from "./interfaces";
import addSeriesLegend from "./utilities/addSeriesLegend";
import Constants from "./Constants";
import Defaults from "./Defaults";

interface LineChartProps<TData> {
  data: TData[][];
  getPointValue: (point: TData) => number;
  formatPointValue: (point: TData) => string;
  getPointLabel: (point: TData) => string;
  showPointValues?: boolean;
  showGrid?: boolean;
  axis?: AxisSettings;
  guides?: Guide[];
  bands?: Band[];
  series?: Series<TData>;
  styles?: {
    gridColor?: string;
  };
  padDataWithEmpty?: boolean;
}

export default function LineChart<TData>({
  data,
  getPointValue,
  getPointLabel,
  formatPointValue,
  showPointValues,
  showGrid,
  axis,
  styles,
  guides,
  bands,
  series,
  padDataWithEmpty,
}: LineChartProps<TData>) {
  const ref = useD3(
    (svg) => {
      const {
        plotWidth,
        axisLabelHeight,
        axisLabelMarginBottom,
        axisLabelMarginTop,
      } = Constants;
      const canvas = document.createElement("canvas");
      let max: number = axis?.y?.max ?? 0;
      let maxLabelWidth: number = 0;
      let largestSeriesIndex = 0;

      data.forEach((series, seriesIndex) => {
        if (series.length > data[largestSeriesIndex].length)
          largestSeriesIndex = seriesIndex;
      });

      const labelMargin = 10;
      let min = axis?.y?.min ?? 0;
      const width = plotWidth;
      const margin = {
        top: 40,
        right: calculateRightMargin(data, guides, series),
        left: 80,
      };
      let graphData: {
        value: number;
        formattedValue: string;
        label: string;
        labelWidth: number;
        width: number;
        x: number;
        y: number;
      }[][] = [];

      const positionWidth =
        (1 /
          (data[largestSeriesIndex].length + (padDataWithEmpty ? 2 : 0) - 1)) *
        (width - margin.left - margin.right);

      graphData = data.map((series) =>
        series.map((p, i) => {
          const v = getPointValue(p);
          const label = getPointLabel(p);
          const labelWidth = measureTextWidth(label, canvas) + labelMargin;

          if (max === undefined || v > max) max = v;
          if (v < min) min = v;
          if (labelWidth > maxLabelWidth) maxLabelWidth = labelWidth;

          return {
            value: v,
            formattedValue: formatPointValue(p),
            label,
            labelWidth,
            width: positionWidth,
            x: margin.left + (i + (padDataWithEmpty ? 1 : 0)) * positionWidth,
            y: 0,
          };
        })
      );

      const xAxisLabelHeight = calculateRotatedLabelHeight(
        maxLabelWidth,
        maxLabelWidth > positionWidth ? 35 : 0
      );
      const bandAndGuideLegend = calculateBandAndGuideRows(
        guides ?? [],
        bands ?? [],
        canvas
      );
      let height = calculatePlotHeight(
        data,
        series,
        xAxisLabelHeight,
        bandAndGuideLegend.totalHeight,
        canvas
      );

      if (axis?.x?.label)
        height -= axisLabelHeight + axisLabelMarginBottom + axisLabelMarginTop;

      if (axis?.y?.scale === "Log" && min === 0) min = 0.001;

      const yAxisScale = (axis?.y?.scale === "Log" ? scaleLog() : scaleLinear())
        .domain([min, max])
        .range([height, margin.top])
        .nice();

      graphData.map((seriesData, seriesIndex) => {
        let pathDef = "";

        seriesData.forEach((p, i) => {
          const y = yAxisScale(p.value);

          p.y = y;
          pathDef += `${i === 0 ? "M" : " L"} ${p.x} ${p.y}`;
        });

        const seriesGroup = svg.select(".plot-area").append("g");

        // Data line
        seriesGroup
          .append("path")
          .attr("d", pathDef)
          .attr("fill", "transparent")
          .attr("stroke-width", 1)
          .attr(
            "stroke",
            getSeriesColor(data[seriesIndex][0], seriesIndex, series)
          );

        // Data points
        seriesGroup
          .selectAll("circle")
          .data(seriesData)
          .enter()
          .append("circle")
          .attr("cx", (p) => p.x)
          .attr("cy", (p) => p.y)
          .attr("r", 2)
          .attr("fill", (_, i) =>
            getSeriesColor(data[seriesIndex][i], seriesIndex, series)
          );

        if (showPointValues) {
          // Data labels
          const barLabels = seriesGroup
            .selectAll("text")
            .data(seriesData)
            .enter()
            .append("g");
          barLabels
            .append("rect")
            .attr("fill", "transparent")
            .attr("height", margin.top)
            .attr("width", (p) => p.width)
            .attr("x", (p) => p.x)
            .attr("y", (p) => p.y - margin.top);
          barLabels
            .append("text")
            .attr("height", margin.top)
            .attr("text-anchor", "start")
            .attr("x", (p) => p.x)
            .attr("y", (p) => p.y - 4)
            .style("font-size", "12px")
            .text((p) => p.formattedValue);
        }

        return seriesData;
      });

      // X Axis
      svg
        .select(".x-axis")
        .append("line")
        .attr("x1", margin.left)
        .attr("x2", width - margin.right)
        .attr("y1", height)
        .attr("y2", height)
        .style("stroke", axis?.x?.color ?? "#000000")
        .style("stroke-width", 1);

      const xAxisLabels = svg
        .select(".x-axis")
        .selectAll("g")
        .data(graphData[largestSeriesIndex])
        .enter()
        .append("g");
      xAxisLabels
        .append("text")
        .attr("height", margin.top)
        .attr("text-anchor", (p) =>
          maxLabelWidth > p.width ? "end" : "middle"
        )
        .attr("x", (p) => p.x + (maxLabelWidth > p.width ? 5 : 0))
        .attr("y", height + 20)
        .style("font-size", "12px")
        .style("transform", (p) =>
          maxLabelWidth > p.width ? "rotate(-35deg)" : ""
        )
        .style("transform-box", "fill-box")
        .style("transform-origin", "right center")
        .text((p) => p.label);

      // Y Axis
      const yAxisTicks: { value: number; y: number }[] = yAxisScale
        .ticks(
          axis?.y?.scale === "Linear" ? axis?.y?.tickCount ?? 10 : undefined
        )
        .map((t) => ({
          value: t,
          y: yAxisScale(t) - margin.top,
        }));

      svg
        .select(".y-axis")
        .append("line")
        .attr("x1", margin.left)
        .attr("x2", margin.left)
        .attr("y1", margin.top)
        .attr("y2", height)
        .style("stroke", axis?.y?.color ?? "#000000")
        .style("stroke-width", 1);

      svg
        .select(".y-axis")
        .selectAll("text")
        .data(yAxisTicks)
        .enter()
        .append("text")
        .attr("text-anchor", "end")
        .style("font-size", "12px")
        .attr("x", margin.left - 5)
        .attr("y", (p) => p.y + margin.top + 5)
        .text((p, i) =>
          axis?.y?.scale === "Log" && i % 9 !== 0
            ? ""
            : p.value.toFixed(axis?.y?.tickPrecision ?? 2)
        );

      // Axis Labels
      if (axis)
        addAxisLabels(
          svg,
          canvas,
          axis,
          margin,
          width,
          height,
          xAxisLabelHeight
        );

      // Bands
      if (bands?.length)
        addChartBands(
          svg,
          bands,
          margin,
          yAxisTicks[0].value,
          yAxisTicks[yAxisTicks.length - 1].value,
          width,
          height,
          axis?.y?.scale ?? "Linear"
        );

      // Guides
      if (guides?.length)
        addChartGuides(
          svg,
          guides,
          margin,
          yAxisTicks[0].value,
          yAxisTicks[yAxisTicks.length - 1].value,
          width,
          height,
          axis?.y?.scale ?? "Linear"
        );

      // Guide and Band Legend
      if (guides?.length || bands?.length)
        addBandAndGuideLegend(
          svg,
          bandAndGuideLegend.labelData,
          bandAndGuideLegend.totalHeight
        );

      // Grid
      if (showGrid) {
        addChartGrid(
          svg,
          { ...margin, right: margin.right },
          graphData[largestSeriesIndex].map((p) => ({ x: p.x - margin.left })),
          yAxisTicks.filter((_, i) => i > 0),
          height,
          width,
          styles?.gridColor
        );

        if (padDataWithEmpty)
          svg
            .select(".grid")
            .append("line")
            .attr("x1", width - margin.right)
            .attr("x2", width - margin.right)
            .attr("y1", margin.top)
            .attr("y2", height)
            .attr("stroke", styles?.gridColor ?? "#bbbbbb")
            .attr("stroke-width", 1)
            .attr("stroke-dasharray", "5,5");
      }

      // Series Legend
      if (data.length > 1)
        addSeriesLegend(
          svg,
          series,
          data,
          width,
          bandAndGuideLegend.totalHeight
        );

      canvas.remove();
    },
    [data]
  );

  return (
    <svg
      key={new Date().getTime()}
      ref={ref}
      className="h-app-chart-line"
      viewBox={`0 0 ${Constants.renderWidth} ${Constants.renderHeight}`}
      height={Constants.renderHeight}
      width="100%"
      preserveAspectRatio="xMidYMid meet"
    >
      <Defaults />
      <g className="bands" />
      <g className="grid" />
      <g className="guides" />
      <g className="plot-area" />
      <g className="x-axis" />
      <g className="y-axis" />
      <g className="series" />
    </svg>
  );
}

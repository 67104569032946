import {
  CrudResponse,
  GetSingleArgs,
  MonitoringGroupFull,
  MonitoringGroupSummary,
  PageAndSearchArgs,
  VersionableService,
} from "../interfaces";
import CrudService from "../interfaces/CrudService";
import { goFetch } from "../utilities/goFetch";

interface MonitoringGroupsService {
  create: (
    action: "CreateNew" | "VersionExisting",
    definition: {} | { familyId: string }
  ) => Promise<CrudResponse>;
  update: (
    id: string,
    action: "UpdateDetails",
    definition: {
      name: string;
      class: string;
      group: string;
      subGroup: string;
    }
  ) => Promise<MonitoringGroupFull>;
  delete: (
    id: string,
    action: "DiscardDraftVersion" | "DeleteFamily"
  ) => Promise<CrudResponse>;
}

const controller = "monitoring-groups";

const service: CrudService<MonitoringGroupFull, MonitoringGroupSummary> &
  VersionableService<MonitoringGroupFull, MonitoringGroupSummary> &
  MonitoringGroupsService = {
  getByPage: async ({ search, pageNo, pageSize }: PageAndSearchArgs) => {
    const response = await goFetch(`${controller}/page/${pageNo}`)
      .withQueryParameters({
        pageSize: pageSize || 50,
        search: search,
      })
      .get();

    return {
      pageNo: response?.pageNo ?? 0,
      pageSize: response?.pageSize ?? 0,
      pageCount: response?.pageCount ?? 0,
      itemCount: response?.itemCount ?? 0,
      data: response?.data ?? [],
    };
  },

  getIncomplete: async () => await goFetch(`${controller}/incomplete`).get(),

  getSingle: async ({ id }: GetSingleArgs) =>
    await goFetch(`${controller}/${id}`).get(),

  getCurrent: async ({ id }: GetSingleArgs) =>
    await goFetch(`${controller}/${id}/current`).get(),

  create: async (action, definition) =>
    await goFetch(controller).withBody({ action, definition }).post(),

  finalize: async (id) => await goFetch(`${controller}/${id}`).put(),

  update: async (id, action, definition) =>
    await goFetch(`${controller}/${id}`)
      .withBody({ action, definition })
      .patch(),

  delete: async (id, action) =>
    await goFetch(`${controller}/${id}`).withBody({ action }).delete(),
};

export default service;

import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuthentication, useStateReducer } from "../../hooks";
import {
  NavigableComponent,
  ConstructionMaterials,
  CasingMaterials,
  LithologyMaterials,
} from "../../interfaces";
import { materialsService } from "../../services";
import { FaArrowLeft, FaEdit, FaHandPointer } from "react-icons/fa";
import { getQueryParameter } from "../../utilities";
import { routes } from "../../_config";
import { LoadingIndicator, Scrollable } from "../../components";
import CasingItemPage from "./components/CasingItemPage";
import ConstructionItemPage from "./components/ConstructionItemPage";
import LithologyItemPage from "./components/LithologyItemPage";
import { ToolItem } from "../../components/toolbar/Toolbar";
import "./_styles.scss";

interface MaterialsItemPageProps {
  index?: boolean;
  onLoad: () => void;
}
interface State {
  materials?:
    | ConstructionMaterials
    | CasingMaterials
    | LithologyMaterials
    | null;
  isSaveDisabled?: boolean;
  loading?: boolean;
}

type PageMode = "view" | "edit";

export default function MaterialsItemPage({
  setBreadcrumbs,
  setToolbarConfig,
  index,
  onLoad,
}: NavigableComponent & MaterialsItemPageProps) {
  const auth = useAuthentication();
  const { settingId: materialsId } = useParams();
  const [state, setState] = useStateReducer<State>({ isSaveDisabled: true });
  const { materials } = state;
  const loading = materials ? false : true;
  const location = useLocation();
  const navigate = useNavigate();
  const mode: PageMode =
    getQueryParameter<PageMode>(location.search, "mode") === "edit"
      ? "edit"
      : "view";

  useEffect(() => {
    const load = async () => {
      const materials = await materialsService.getSingle({
        id: materialsId ?? "",
      });

      setState({ materials, loading: false });
      onLoad();

      setBreadcrumbs(
        index
          ? [
              { text: "Settings", to: routes.settings.go() },
              { text: "Materials" },
            ]
          : [
              { text: "Settings", to: routes.settings.go() },
              { text: "Materials", to: routes.materials.raw },
              { text: materials.type },
            ]
      );

      if (mode === "view") {
        let toolbarItems: (ToolItem | "|")[] = [
          {
            label: "Back",
            icon: FaArrowLeft,
            primary: true,
            raised: true,
            onClick: () =>
              navigate(routes.settingCategory.go("materials-library")),
          },
        ];

        if (auth?.hasPermission("ModifyMaterials")) {
          toolbarItems.push("|");
          toolbarItems.push({
            label: "Edit",
            icon: FaEdit,
            onClick: () =>
              navigate(
                `${routes.settingItem.go(
                  "materials-library",
                  materialsId
                )}?mode=edit`
              ),
          });
        }

        setToolbarConfig({
          allowSearch: true,
          toolbarItems,
          searchPath: routes.descriptors.go(),
        });
      }
    };

    setState({ materials: null, isSaveDisabled: true, loading: true });
    load();
  }, [
    materialsId,
    mode,
    index,
    setState,
    setBreadcrumbs,
    setToolbarConfig,
    onLoad,
    navigate,
  ]);

  let content = <></>;

  if (index)
    content = (
      <>
        <FaHandPointer /> Select a material type on the left.
      </>
    );
  else if (!materials || materialsId !== materials.id || loading)
    content = <LoadingIndicator />;
  else
    content = (
      <Scrollable>
        {materials.type === "Casing" ? (
          <CasingItemPage
            materials={materials}
            mode={mode}
            setBreadcrumbs={setBreadcrumbs}
            setToolbarConfig={setToolbarConfig}
          />
        ) : undefined}
        {materials.type === "Construction" ? (
          <ConstructionItemPage
            materials={materials}
            mode={mode}
            setBreadcrumbs={setBreadcrumbs}
            setToolbarConfig={setToolbarConfig}
          />
        ) : undefined}
        {materials.type === "Lithology" ? (
          <LithologyItemPage
            materials={materials}
            mode={mode}
            setBreadcrumbs={setBreadcrumbs}
            setToolbarConfig={setToolbarConfig}
          />
        ) : undefined}
      </Scrollable>
    );

  return content;
}

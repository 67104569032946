import React from "react";
import { BiLoaderAlt } from "react-icons/bi";
import "./_styles.scss";

export default function LoadingIndicator() {
  return (
    <div className="h-app-loading-indicator" key="loading-indicator">
      <BiLoaderAlt />
    </div>
  );
}

import React, { useCallback, useEffect, useMemo, useRef } from "react";
import Form from "../../../components/form/Form";
import {
  Checkbox,
  InstructionWrapper,
  List,
  TextField,
} from "../../../components/fields";
import { publish, useStateReducer } from "../../../hooks";
import {
  NavigableComponent,
  SiteTemplateFull,
  SiteTemplateSummary,
} from "../../../interfaces";
import { useNavigate, useParams } from "react-router-dom";
import { Button as MUIButton, ButtonGroup, Chip } from "@mui/material";
import { hideAlert, showAlert } from "../../../components/alert/Alert";
import { AlertTypeEnum, StateTopicEnum } from "../../../enums";
import { routes } from "../../../_config";
import {
  FaArrowDown,
  FaArrowUp,
  FaPlus,
  FaSave,
  FaTimes,
  FaTrash,
} from "react-icons/fa";
import { ToolItem } from "../../../components/toolbar/Toolbar";
import "../_styles.scss";
import { FormFull } from "../../../interfaces";
import { fieldFormsService, siteTemplatesService } from "../../../services";
import { hideModal, showModal } from "../../../components/modal/Modal";
import ReferenceSummary from "../../../interfaces/ReferenceSummary";
import { LibrarySection } from "../../../components/library/Library";
import { BiEditAlt, BiFontColor, BiRuler, BiTrash } from "react-icons/bi";
import { FormDescriptor, FormMeasurableGroup } from "../../../interfaces/Form";
import { Button, Clickable } from "../../../components";
import GroupEditor from "./GroupEditor";
import validateObject, {
  Validation,
  hasErrors,
} from "../../../utilities/validateObject";
import { asyncify, classNameBuilder, clone } from "../../../utilities";

interface EditDescriptorDataProps {
  fieldForm: FormFull;
}

interface State {
  name?: string;
  instructions?: string;
  siteTemplate?: ReferenceSummary;
  measurableGroups?: FormMeasurableGroup[];
  measurableGroupsOrder?: string[];
  siteTemplates?: SiteTemplateSummary[];
  fullSiteTemplate?: SiteTemplateFull;
  justMovedGroup?: string;
  requiresApprovalOnSubmit?: boolean;
}

export default function EditDescriptor({
  setToolbarConfig,
  fieldForm,
}: EditDescriptorDataProps & NavigableComponent) {
  const { settingId } = useParams();
  const navigate = useNavigate();
  const [state, setState] = useStateReducer<State>({
    name: fieldForm.name ?? "",
    siteTemplate: fieldForm.siteTemplate,
    measurableGroups: fieldForm.measurableGroups || [],
    measurableGroupsOrder: fieldForm.measurableGroupsOrder || [],
    instructions: fieldForm.instructions ?? "",
    requiresApprovalOnSubmit: fieldForm.requiresApprovalOnSubmit ?? false,
    siteTemplates: [],
  });
  const saveState = useRef<State>(state);
  const validationRules: Validation = useMemo(
    () => ({
      name: {
        regex: /.+/,
        message: "This field is required.",
      },
      siteTemplate: {
        func: (t: ReferenceSummary) => (t ? true : false),
        message: "This field is required.",
      },
    }),
    []
  );

  const validation = validateObject(validationRules, state);

  const sortedGroups = useMemo(() => {
    const order = state.measurableGroupsOrder ?? [];
    const groups = clone(state.measurableGroups) ?? [];
    const orderedGroups: FormMeasurableGroup[] = [];

    order.forEach((o) => {
      const group = groups.find((g) => g.id === o);

      if (group) {
        orderedGroups.push(group);
        groups.splice(groups.indexOf(group), 1);
      }
    });

    groups.forEach((g) => orderedGroups.push(g));

    return orderedGroups;
  }, [state.measurableGroups, state.measurableGroupsOrder]);

  const handleSave = useCallback(async () => {
    await asyncify(() => {}, 10);

    if (hasErrors(validateObject(validationRules, saveState.current))) return;

    const response = await fieldFormsService.finalize!(settingId!);

    if (!response.id) {
      return;
    }

    publish<LibrarySection>(StateTopicEnum.LibrarySectionRefresh, "paged");
    publish<LibrarySection>(StateTopicEnum.LibrarySectionRefresh, "incomplete");

    showAlert({
      content: "Form saved.",
      options: {
        type: AlertTypeEnum.Info,
        actions: [
          {
            text: "Ok",
            primary: true,
          },
        ],
      },
    });

    navigate(routes.settingItem.go("field-forms-library", settingId));
  }, [navigate, settingId, validationRules]);

  const handleUpdate = useCallback(() => {
    if (hasErrors(validateObject(validationRules, saveState.current))) return;

    fieldFormsService.update(fieldForm.id, "UpdateDetails", {
      siteTemplateId: state.siteTemplate?.id,
      name: state.name,
      instructions: state.instructions,
      requiresApprovalOnSubmit: state.requiresApprovalOnSubmit ?? true,
    });
  }, [
    state.name,
    state.instructions,
    fieldForm.id,
    state.requiresApprovalOnSubmit,
    state.siteTemplate?.id,
    validationRules,
  ]);

  useEffect(() => {
    if (hasErrors(validateObject(validationRules, saveState.current))) return;

    fieldFormsService.update(fieldForm.id, "UpdateDetails", {
      siteTemplateId: state.siteTemplate?.id,
      name: state.name,
      instructions: state.instructions,
      requiresApprovalOnSubmit: state.requiresApprovalOnSubmit ?? true,
    });
  }, [
    state.siteTemplate,
    state.requiresApprovalOnSubmit,
    fieldForm.id,
    state.instructions,
    state.name,
    validationRules,
  ]);

  const handleDiscard = useCallback(async () => {
    showAlert({
      content:
        "Are you sure you want to discard this draft form? This action is not reversable.",
      options: {
        type: AlertTypeEnum.Warning,
        actions: [
          {
            text: "Yes",
            primary: true,
            onClick: async () => {
              hideAlert();

              const response = await fieldFormsService.delete!(
                settingId!,
                "DiscardDraftVersion"
              );

              if (response.message) {
                navigate(routes.settingCategory.go("field-forms-library"));
                publish<LibrarySection>(
                  StateTopicEnum.LibrarySectionRefresh,
                  "incomplete"
                );
              }
            },
          },
          {
            text: "No",
          },
        ],
      },
    });
  }, [navigate, settingId]);

  useEffect(() => {
    let toolbarItems: (ToolItem | "|")[] = [];

    const load = async () => {
      const siteTemplates = await siteTemplatesService.getByPage({
        pageNo: 1,
        pageSize: 1000,
        search: "",
      });
      const fullSiteTemplate = state.siteTemplate
        ? await siteTemplatesService.getCurrent({ id: state.siteTemplate.id })
        : undefined;
      setState({ siteTemplates: siteTemplates.data, fullSiteTemplate });
    };

    load();

    toolbarItems = [
      {
        label: "Save",
        icon: FaSave,
        onClick: handleSave,
        primary: true,
        raised: true,
      },
      "|",
      {
        label: "Cancel",
        icon: FaTimes,
        onClick: () => {
          navigate(routes.settingCategory.go("field-forms-library"));
        },
      },
      {
        label: "Discard",
        icon: FaTrash,
        onClick: handleDiscard,
      },
    ];

    setToolbarConfig({
      allowSearch: true,
      toolbarItems,
      searchPath: routes.fieldForms.go(),
    });
  }, [
    handleSave,
    handleDiscard,
    navigate,
    setToolbarConfig,
    settingId,
    setState,
    state.siteTemplate,
  ]);

  useEffect(() => {
    const load = async () => {
      const fullSiteTemplate = state.siteTemplate
        ? await siteTemplatesService.getCurrent({ id: state.siteTemplate.id })
        : undefined;

      if (
        state.fullSiteTemplate?.id &&
        fullSiteTemplate?.id !== state.fullSiteTemplate?.id
      ) {
        if (state.measurableGroups?.length)
          await Promise.all(
            state.measurableGroups.map((g) =>
              fieldFormsService.update(fieldForm.id, "RemoveMeasurableGroup", {
                groupId: g.id,
              })
            )
          );

        setState({
          fullSiteTemplate,
          measurableGroups: [],
          measurableGroupsOrder: [],
        });
      } else setState({ fullSiteTemplate });
    };

    load();
  }, [
    state.siteTemplate,
    fieldForm.id,
    setState,
    state.fullSiteTemplate?.id,
    state.measurableGroups,
  ]);

  useEffect(() => {
    saveState.current = state;
  }, [state]);

  const handleEditGroup = (measurableGroup: FormMeasurableGroup) => {
    if (!state.fullSiteTemplate) return;

    showModal({
      content: (
        <GroupEditor
          form={{
            ...fieldForm,
            measurableGroups: state.measurableGroups ?? [],
          }}
          siteTemplate={state.fullSiteTemplate}
          group={measurableGroup}
          onUpdate={(measurableGroups) => setState({ measurableGroups })}
        />
      ),
      options: {
        title: "Edit Field Group",
        disableBodyScrolling: true,
        actions: [
          {
            text: "Finish",
            primary: true,
            onClick: () => {
              hideModal();
            },
          },
        ],
        disallowOutsideClick: true,
      },
    });
  };

  const handleAddGroup = async () => {
    if (!state.fullSiteTemplate) return;

    const response = await fieldFormsService.update(
      fieldForm.id,
      "AddMeasurableGroup",
      {
        name: "New Field Group",
        instructions: "",
      }
    );

    const group =
      response.measurableGroups[response.measurableGroups.length - 1];

    setState({
      measurableGroups: response.measurableGroups,
      measurableGroupsOrder: response.measurableGroupsOrder,
    });

    await asyncify(() => handleEditGroup(group), 100);
  };

  const handleDeleteGroup = async (id: string) => {
    if (!state.measurableGroupsOrder) return;

    const response = await fieldFormsService.update(
      fieldForm.id,
      "RemoveMeasurableGroup",
      {
        groupId: id,
      }
    );

    state.measurableGroupsOrder.splice(
      state.measurableGroupsOrder.indexOf(id),
      1
    );

    setState({
      measurableGroups: response.measurableGroups,
      measurableGroupsOrder: state.measurableGroupsOrder,
    });
  };

  const handleMoveGroup = async (id: string, direction: "up" | "down") => {
    const order = sortedGroups.map((g) => g.id);
    const index = order.indexOf(id);
    const newIndex = direction === "up" ? index - 1 : index + 1;
    order.splice(index, 1);
    order.splice(newIndex, 0, id);

    setState({ measurableGroupsOrder: order, justMovedGroup: id });

    await fieldFormsService.update(fieldForm.id, "UpdateMeasurableGroupOrder", {
      order,
    });
  };

  return (
    <>
      <Form title="Identifying Information">
        <InstructionWrapper
          text="The site template to which this form applies."
          error={validation["siteTemplate"]}
        >
          <List
            options={state.siteTemplates || []}
            getOptionLabel={(o: SiteTemplateSummary) => o.name}
            getOptionValue={(o: SiteTemplateSummary) => o.id}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            blurOnSelect
            readOnly={state.siteTemplates ? false : true}
            error={
              state.siteTemplates && validation["siteTemplate"] ? true : false
            }
            value={state.siteTemplates ? state.siteTemplate : undefined}
            label={state.siteTemplates ? "Site Template *" : "Loading..."}
            onChange={(_, v: SiteTemplateSummary) =>
              setState({ siteTemplate: { id: v.id, name: v.name } })
            }
          />
        </InstructionWrapper>
        <InstructionWrapper
          text="An identifying name for the form."
          error={validation["name"]}
        >
          <TextField
            label="Name"
            required
            value={state.name}
            onChange={(e) => setState({ name: e.target.value })}
            onBlur={handleUpdate}
            error={validation["name"] ? true : false}
          />
        </InstructionWrapper>
        <InstructionWrapper text="Instructions presented to users when completing the form.">
          <TextField
            label="Instructions"
            multiline
            value={state.instructions}
            onChange={(e) => setState({ instructions: e.target.value })}
            onBlur={handleUpdate}
          />
        </InstructionWrapper>
        <InstructionWrapper text="Indication of whether the form requires approval after values where captured and submitted using the mobile app.">
          <Checkbox
            label="Requires Approval"
            value={state.requiresApprovalOnSubmit ?? false}
            onChange={(e) =>
              setState({ requiresApprovalOnSubmit: e.target.checked })
            }
          />
        </InstructionWrapper>
      </Form>
      {state.siteTemplate ? (
        <Form title="Configuration">
          <InstructionWrapper>
            {sortedGroups.map((group, index) => {
              const combinedArray = [
                ...group.measurables,
                ...group.descriptors,
              ];

              return (
                <div
                  className={classNameBuilder(
                    "measurable-group-row",
                    state.justMovedGroup === group.id ? "moved" : ""
                  )}
                  key={`${group.id}-${index}`}
                >
                  <div className="measurable-group">
                    <TextField
                      value={group.name}
                      label="Field Group Name"
                      readOnly
                      endAdornment={
                        <div className="adornment-actions">
                          <Clickable
                            className="measurable-group-action-button"
                            onClick={() => handleEditGroup(group)}
                          >
                            <BiEditAlt className="edit-action" />
                          </Clickable>
                          <Clickable
                            className="measurable-group-action-button"
                            onClick={() => handleDeleteGroup(group.id)}
                          >
                            <BiTrash className="delete-action" />
                          </Clickable>
                        </div>
                      }
                    />
                    {combinedArray.length > 0 && (
                      <div className="field-chips">
                        <div className="fields">
                          {group.order?.map((o: any, index: number) => {
                            let item = combinedArray.find((c) => {
                              return c.id === o;
                            });

                            if (!item) return null;

                            const isDescriptor = (item as FormDescriptor).type
                              ? true
                              : false;

                            return (
                              <Chip
                                key={`${o}-${index}`}
                                label={item.name}
                                icon={
                                  isDescriptor ? <BiFontColor /> : <BiRuler />
                                }
                                className={
                                  isDescriptor
                                    ? "descriptor-chip"
                                    : "measurable-chip"
                                }
                              />
                            );
                          })}
                        </div>
                      </div>
                    )}
                    <div className="measureable-checkbox-items">
                      <Checkbox
                        label="Allow Multiple Capturing"
                        value={group.multiItem}
                        readOnly
                      />
                      <Checkbox
                        label="Mandatory Multiple Capturing"
                        value={group.mandatoryMultiItem}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="action-section">
                    <ButtonGroup
                      aria-label="text button group"
                      orientation="vertical"
                      size="small"
                      className="actions-button-group"
                    >
                      <MUIButton
                        className="action-button"
                        disabled={index === 0}
                        onClick={() => handleMoveGroup(group.id, "up")}
                      >
                        <div className="measurable-group-actions">
                          <FaArrowUp />
                        </div>
                      </MUIButton>
                      <MUIButton
                        className="action-button"
                        disabled={index === sortedGroups.length - 1}
                        onClick={() => handleMoveGroup(group.id, "down")}
                      >
                        <div className="measurable-group-actions">
                          <FaArrowDown />
                        </div>
                      </MUIButton>
                    </ButtonGroup>
                  </div>
                </div>
              );
            })}
            <div className="measurables-button-bar">
              <div>
                <Button
                  onClick={handleAddGroup}
                  className="add-measurable-group"
                  text="Add Group"
                  icon={FaPlus}
                  disabled={!state.siteTemplate}
                  primary
                  raised
                />
                {!state.siteTemplate ? (
                  <div className="disabled-button-helper-text">
                    Please choose a site template first
                  </div>
                ) : null}
              </div>
            </div>
          </InstructionWrapper>
        </Form>
      ) : null}
    </>
  );
}

import {
  CrudResponse,
  GetSingleArgs,
  NoteContextType,
  NoteFull,
  PageAndSearchArgs,
  PagedData,
} from "../interfaces";
import { goFetch } from "../utilities/goFetch";

interface NotesService {
  getByPage: (
    context: NoteContextType,
    contextReferenceId: string,
    pageArgs: PageAndSearchArgs
  ) => Promise<PagedData<NoteFull>>;
  getSingle: (args: GetSingleArgs) => Promise<NoteFull>;
  create: (
    context: NoteContextType,
    contextReferenceId: string,
    body: string,
    attachmentIds: string[]
  ) => Promise<NoteFull>;
}

const controller = "notes";

const service: NotesService = {
  getByPage: async (
    context: NoteContextType,
    contextReferenceId: string,
    { search, pageNo, pageSize }: PageAndSearchArgs
  ) => {
    const response = await goFetch(`${controller}/page/${pageNo}`)
      .withQueryParameters({
        context,
        referenceId: contextReferenceId,
        pageSize: pageSize || 50,
        search: search,
      })
      .get();

    return {
      pageNo: response?.pageNo ?? 0,
      pageSize: response?.pageSize ?? 0,
      pageCount: response?.pageCount ?? 0,
      itemCount: response?.itemCount ?? 0,
      data: response?.data ?? [],
    };
  },

  getSingle: async ({ id }: GetSingleArgs) =>
    await goFetch(`${controller}/${id}`).get(),

  create: async (context, contextReferenceId, body, attachmentIds) => {
    let noteId = undefined;

    try {
      const result: CrudResponse = await goFetch(controller)
        .withBody({
          action: "CreateNew",
          definition: {},
        })
        .post();

      noteId = result.id;
    } catch (e) {
      throw e;
    }

    let note: NoteFull | undefined = undefined;

    try {
      note = await goFetch(`${controller}/${noteId}`)
        .withBody({
          action: "UpdateDetails",
          definition: {
            body,
            contextType: context,
            contextReferenceId,
          },
        })
        .patch();
    } catch (e) {
      throw e;
    }

    if (attachmentIds?.length)
      for (let attachmentId of attachmentIds)
        try {
          note = await goFetch(`${controller}/${noteId}`)
            .withBody({
              action: "AddAttachment",
              definition: {
                attachmentId,
              },
            })
            .patch();
        } catch (e) {
          throw e;
        }

    try {
      await goFetch(`${controller}/${noteId}`).put();
    } catch (e) {
      throw e;
    }

    if (!note) throw Error("Could not create note.");

    return note;
  },
};

export default service;

import { GetSingleArgs, Permission, SettingConfig } from "../interfaces";
import { asyncify } from "../utilities";
import { settingsModules } from "./data";

interface SettingService {
  getUserSettings(permissions: Permission[]): Promise<SettingConfig[]>;
  getAllSettings(): Promise<SettingConfig[]>;
  getSingleSetting(args: GetSingleArgs): Promise<SettingConfig>;
}

let _CACHE: SettingConfig[] = [];

const service: SettingService = {
  async getUserSettings(permissions: Permission[]) {
    const settings = await service.getAllSettings();

    return settings.filter(
      (s) => permissions.indexOf(s.libraryPermission) >= 0
    );
  },

  async getAllSettings() {
    if (_CACHE.length) return _CACHE;

    return await asyncify(() => {
      _CACHE = settingsModules;

      return _CACHE;
    }, 0);
  },

  async getSingleSetting({ id }) {
    const found = _CACHE.filter((s) => s.id === id)[0];

    if (found) return found;

    return (await service.getAllSettings()).filter((s) => s.id === id)[0];
  },
};

export default service;

import { Selection } from "d3";
import { measureTextWidth } from ".";

const textAdjust = 6;
const labelTranslateY = 45;

export default function addDiamondProjectionAxisLabels(
  svg: Selection<SVGSVGElement, unknown, null, undefined>,
  baseX: number,
  baseY: number,
  baseLength: number,
  equiHeight: number,
  axisLabels: {
    a: string;
    b: string;
  }
) {
  const canvas = document.createElement("canvas");
  const labelAWidth = measureTextWidth(axisLabels.a, canvas, "20px");
  const labelBWidth = measureTextWidth(axisLabels.b, canvas, "20px");
  canvas.remove();

  const axisALabelGrouping = svg
    .select(".plot-area")
    .append("g")
    .style(
      "transform",
      `rotate(-60deg) translate(-5px,${3 - labelTranslateY}px)`
    )
    .style("transform-box", "fill-box")
    .style("transform-origin", "center center");
  axisALabelGrouping
    .append("text")
    .attr("x", baseX + baseLength / 4)
    .attr("y", baseY - equiHeight / 2 + textAdjust)
    .attr("text-anchor", "middle")
    .style("font-size", 20)
    .text(axisLabels.b);
  axisALabelGrouping
    .append("line")
    .attr("x1", baseX - baseLength / 4)
    .attr("x2", baseX - baseLength / 4 + (baseLength - labelBWidth) / 2 - 10)
    .attr("y1", baseY - equiHeight / 2)
    .attr("y2", baseY - equiHeight / 2)
    .attr("stroke", "#000000")
    .attr("stroke-width", 1);
  axisALabelGrouping
    .append("line")
    .attr(
      "x1",
      baseX - baseLength / 4 + (baseLength - labelBWidth) / 2 + labelBWidth + 10
    )
    .attr("x2", baseX - baseLength / 4 + baseLength - 10)
    .attr("y1", baseY - equiHeight / 2)
    .attr("y2", baseY - equiHeight / 2)
    .attr("stroke", "#000000")
    .attr("stroke-width", 1)
    .attr("marker-end", "url(#arrowheadright)");

  const axisBLabelGrouping = svg
    .select(".plot-area")
    .append("g")
    .style(
      "transform",
      `rotate(60deg) translate(3px,${-1 - labelTranslateY}px)`
    )
    .style("transform-box", "fill-box")
    .style("transform-origin", "center center");
  axisBLabelGrouping
    .append("text")
    .attr("x", baseX + (baseLength * 3) / 4)
    .attr("y", baseY - equiHeight / 2 + textAdjust)
    .attr("text-anchor", "middle")
    .style("font-size", 20)
    .text(axisLabels.a);
  axisBLabelGrouping
    .append("line")
    .attr("x1", baseX + baseLength / 4)
    .attr("x2", baseX + baseLength / 4 + (baseLength - labelAWidth) / 2 - 10)
    .attr("y1", baseY - equiHeight / 2)
    .attr("y2", baseY - equiHeight / 2)
    .attr("stroke", "#000000")
    .attr("stroke-width", 1)
    .attr("marker-start", "url(#arrowheadleft)");
  axisBLabelGrouping
    .append("line")
    .attr(
      "x1",
      baseX +
        (baseLength * 1) / 4 +
        (baseLength - labelAWidth) / 2 +
        labelAWidth +
        10
    )
    .attr("x2", baseX + (baseLength * 5) / 4)
    .attr("y1", baseY - equiHeight / 2)
    .attr("y2", baseY - equiHeight / 2)
    .attr("stroke", "#000000")
    .attr("stroke-width", 1);
}

import React, { useCallback, useState } from "react";
import { AreaBand } from "../../../interfaces";
import {
  ColorPicker,
  NumberField,
  TextField,
} from "../../../components/fields";

interface AreaBandConfigProps {
  areaBand: AreaBand;
  onUpdate?: (areaBand: AreaBand) => void;
  readOnly?: boolean;
}

export default function AreaBandConfig({
  areaBand,
  onUpdate,
  readOnly,
}: AreaBandConfigProps) {
  const [label, setLabel] = useState(areaBand?.label ?? "New Area Band");
  const [color, setColor] = useState(areaBand?.color ?? "#aa0000");
  const [min, setMin] = useState(areaBand?.min ?? 0);
  const [max, setMax] = useState(areaBand?.max ?? 0);

  const handleUpdate = useCallback(
    (label: string, color: string, min: number, max: number) => {
      onUpdate?.({ id: areaBand.id, label, color, min, max });
    },
    [areaBand, onUpdate]
  );

  return (
    <div className="chart-area-band-config">
      <TextField
        label="Label"
        value={label}
        onChange={readOnly ? undefined : (e) => setLabel(e.target.value)}
        onBlur={
          readOnly
            ? undefined
            : (e) => handleUpdate(e.target.value, color, min, max)
        }
        readOnly={readOnly}
      />
      <NumberField
        label="Min"
        value={min}
        onChange={
          readOnly
            ? undefined
            : (e) => setMin(parseFloat(e.target.value || "0"))
        }
        className="fixed-width-minmax"
        onBlur={
          readOnly
            ? undefined
            : (e) =>
                handleUpdate(
                  label,
                  color,
                  parseFloat(e.target.value || "0"),
                  max
                )
        }
        readOnly={readOnly}
      />
      <NumberField
        label="Max"
        value={max}
        onChange={
          readOnly
            ? undefined
            : (e) => setMax(parseFloat(e.target.value || "0"))
        }
        className="fixed-width-minmax"
        onBlur={
          readOnly
            ? undefined
            : (e) =>
                handleUpdate(
                  label,
                  color,
                  min,
                  parseFloat(e.target.value || "0")
                )
        }
        readOnly={readOnly}
      />
      <ColorPicker
        label="Color"
        value={color}
        className="fixed-width-color"
        onChange={
          readOnly
            ? undefined
            : (c: string) => {
                setColor(c);
                handleUpdate(label, c, min, max);
              }
        }
        readOnly={readOnly}
      />
    </div>
  );
}

import React, { useCallback, useEffect, useMemo, useRef } from "react";
import Form from "../../../components/form/Form";
import {
  InstructionWrapper,
  SearchAndChips,
  TextField,
} from "../../../components/fields";
import { publish, useStateReducer } from "../../../hooks";
import { NavigableComponent } from "../../../interfaces";
import { useNavigate, useParams } from "react-router-dom";
import { showAlert } from "../../../components/alert/Alert";
import { AlertTypeEnum, StateTopicEnum } from "../../../enums";
import { routes } from "../../../_config";
import { FaSave, FaTimes } from "react-icons/fa";
import "../_styles.scss";
import { UserFull } from "../../../interfaces";
import { rolesService, usersService } from "../../../services";
import { LibrarySection } from "../../../components/library/Library";
import { asyncify, validateObject } from "../../../utilities";
import { hasErrors, Validation } from "../../../utilities/validateObject";
import { UserUpdateActions } from "../../../services/usersService";

interface EditUserDataProps {
  user: UserFull;
}

interface State {
  credentials?: CredentialState;
  addedRoles?: string[];
  removedRoles?: string[];
}

interface CredentialState {
  updatedAccountName?: string;
  updatedPassword?: string;
}

export default function EditUser({
  setToolbarConfig,
  user,
}: EditUserDataProps & NavigableComponent) {
  const { settingId } = useParams();
  const navigate = useNavigate();
  const [state, setState] = useStateReducer<State>({});
  const [userState, setUserState] = useStateReducer<UserFull>({ ...user });
  const saveState = useRef<UserFull & CredentialState & State>({
    ...userState,
  });
  const validationRules: Validation = useMemo(
    () => ({
      name: {
        regex: /.+/,
        message: "This field is required.",
      },
      surname: {
        regex: /.+/,
        message: "This field is required.",
      },
      emailAddress: {
        regex: /^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/,
        message: "A valid email address is required.",
      },
    }),
    []
  );

  const validation = validateObject(validationRules, userState);

  const handleSave = useCallback(async () => {
    await asyncify(() => {}, 10);

    if (hasErrors(validateObject(validationRules, saveState.current))) return;

    const doUpdate = async (action: UserUpdateActions, definition: any) => {
      const response = await usersService.update?.(user.id, action, definition);

      if (!response?.id) {
        showAlert({
          content: "Could not update the user.",
          options: {
            type: AlertTypeEnum.Error,
            actions: [{ text: "Ok" }],
          },
        });
        return false;
      }

      return true;
    };

    if (
      !(await doUpdate("UpdateDetails", {
        name: saveState.current.name,
        surname: saveState.current.surname,
        emailAddress: saveState.current.emailAddress,
      }))
    )
      return;

    if (
      saveState.current.updatedAccountName &&
      !(await doUpdate("UpdateCredentials", {
        accountName: saveState.current.updatedAccountName,
        password: saveState.current.updatedPassword,
      }))
    )
      return;

    if (saveState.current.addedRoles)
      await Promise.all(
        saveState.current.addedRoles.map((r) =>
          doUpdate("AddRole", { roleId: r })
        )
      );

    if (saveState.current.removedRoles)
      await Promise.all(
        saveState.current.removedRoles.map((r) =>
          doUpdate("RemoveRole", { roleId: r })
        )
      );

    publish<LibrarySection>(StateTopicEnum.LibrarySectionRefresh, "paged");
    navigate(routes.settingItem.go("users-library", settingId));

    showAlert({
      content: "User saved.",
      options: {
        type: AlertTypeEnum.Info,
        actions: [
          {
            text: "Ok",
            primary: true,
          },
        ],
      },
    });
  }, [navigate, settingId, user.id, validationRules]);

  const handleSearchRoles = useCallback(async (search: string) => {
    const response = await rolesService.getByPage({
      pageSize: 20,
      pageNo: 1,
      search,
    });

    return response.data;
  }, []);

  useEffect(() => {
    setToolbarConfig({
      allowSearch: true,
      toolbarItems: [
        {
          label: "Save",
          icon: FaSave,
          onClick: handleSave,
          primary: true,
          raised: true,
        },
        "|",
        {
          label: "Cancel",
          icon: FaTimes,
          onClick: () =>
            navigate(routes.settingItem.go("users-library", settingId)),
        },
      ],
      searchPath: routes.users.go(),
    });
  }, [handleSave, navigate, setToolbarConfig, settingId]);

  useEffect(() => {
    saveState.current = {
      ...userState,
      addedRoles: state.addedRoles,
      removedRoles: state.removedRoles,
    };
  }, [userState, state.addedRoles, state.removedRoles]);

  return (
    <>
      <Form title="Credential Information">
        <InstructionWrapper text="The account name that the user logs onto Zamanzi with.">
          <TextField
            label="Account Name"
            value={userState.accountName}
            readOnly
            //onChange={(e: any) => handleChange("accountName", )}
          />
        </InstructionWrapper>
        <InstructionWrapper text="The password that the user logs onto Zamanzi with.">
          <TextField
            label="Password"
            value={"**********"}
            type="password"
            readOnly
            //onChange={(e: any) => handleChange("accountName", e.target.value)}
          />
        </InstructionWrapper>
      </Form>
      <Form title="Identifying Information">
        <InstructionWrapper
          text="The name and surname of the user."
          error={validation["name"] || validation["surname"]}
          row
        >
          <TextField
            label="Name"
            value={userState.name}
            onChange={(e: any) =>
              setUserState({ ...userState, name: e.target.value })
            }
            error={validation["name"] ? true : false}
          />
          <TextField
            label="Surname"
            value={userState.surname}
            onChange={(e: any) =>
              setUserState({ ...userState, surname: e.target.value })
            }
            error={validation["surname"] ? true : false}
          />
        </InstructionWrapper>
        <InstructionWrapper
          text="The email address where the user can access communication."
          error={validation["emailAddress"]}
        >
          <TextField
            label="Email Address"
            value={userState.emailAddress}
            onChange={(e: any) =>
              setUserState({ ...userState, emailAddress: e.target.value })
            }
            error={validation["emailAddress"] ? true : false}
          />
        </InstructionWrapper>
      </Form>
      <Form title="System Roles">
        <InstructionWrapper text="The system roles assigned to this user.">
          <SearchAndChips
            label="Search Roles"
            values={user.roles}
            onSearch={handleSearchRoles}
            getOptionLabel={(o) => o.name}
            getOptionValue={(o) => o.id}
            onSelect={(v) => {
              const roles = userState.roles ?? [];
              roles.push({ id: v.id, name: v.name });
              const addedRoles = state.addedRoles ?? [];
              addedRoles.push(v.id);

              setUserState({ ...userState, roles });
              setState({ addedRoles });
            }}
            onRemove={(_L, _v, v) => {
              const roles = userState.roles ?? [];
              roles.splice(
                roles.findIndex((o) => o.id === v.id),
                1
              );
              const removedRoles = state.removedRoles ?? [];
              removedRoles.push(v.id);

              setUserState({ ...userState, roles });
              setState({ removedRoles });
            }}
          />
        </InstructionWrapper>
      </Form>
    </>
  );
}

import {
  CrudResponse,
  FormFull,
  FormSummary,
  GetSingleArgs,
  PageAndSearchArgs,
  VersionableService,
} from "../interfaces";
import CrudService from "../interfaces/CrudService";
import { goFetch } from "../utilities/goFetch";

interface AddDescriptorToGroup {
  groupId: string;
  descriptorId: string;
  isMandatory: boolean;
}

interface AddMeasurableToGroup {
  groupId: string;
  measurableId: string;
  isMandatory: boolean;
}

interface AddGroup {
  name: string;
  instructions?: string;
}

interface RemoveDescriptorFromGroup {
  groupId: string;
  descriptorId: string;
}

interface RemoveMeasurableFromGroup {
  groupId: string;
  measurableId: string;
}

interface RemoveGroup {
  groupId: string;
}

interface UpdateDetails {
  siteTemplateId?: string;
  name?: string;
  instructions?: string;
  requiresApprovalOnSubmit: boolean;
  multiItem?: boolean;
  mandatoryMultiItem?: boolean;
}

interface UpdateGroup {
  groupId: string;
  name?: string;
  order?: string[];
}

interface UpdateGroupOrder {
  order: string[];
}

interface FieldFormsService {
  create: (
    action: "CreateNew" | "VersionExisting",
    definition: {} | { familyId: string }
  ) => Promise<CrudResponse>;
  update: (
    id: string,
    action:
      | "UpdateDetails"
      | "AddMeasurableGroup"
      | "AddMeasurable"
      | "AddDescriptor"
      | "RemoveMeasurableGroup"
      | "RemoveMeasurable"
      | "RemoveDescriptor"
      | "UpdateMeasurableGroup"
      | "UpdateMeasurableGroupOrder",
    definition:
      | AddDescriptorToGroup
      | AddMeasurableToGroup
      | AddGroup
      | RemoveDescriptorFromGroup
      | RemoveMeasurableFromGroup
      | RemoveGroup
      | UpdateDetails
      | UpdateGroup
      | UpdateGroupOrder
  ) => Promise<FormFull>;
  delete: (
    id: string,
    action: "DiscardDraftVersion" | "DeleteFamily"
  ) => Promise<CrudResponse>;
}

const controller = "forms";

const service: CrudService<FormFull, FormSummary> &
  VersionableService<FormFull, FormSummary> &
  FieldFormsService = {
  getByPage: async ({ search, pageNo, pageSize }: PageAndSearchArgs) => {
    const response = await goFetch(`${controller}/page/${pageNo}`)
      .withQueryParameters({
        pageSize: pageSize || 50,
        search: search,
      })
      .get();

    return {
      pageNo: response?.pageNo ?? 0,
      pageSize: response?.pageSize ?? 0,
      pageCount: response?.pageCount ?? 0,
      itemCount: response?.itemCount ?? 0,
      data: response?.data ?? [],
    };
  },

  getIncomplete: async () => await goFetch(`${controller}/incomplete`).get(),

  getSingle: async ({ id }: GetSingleArgs) =>
    await goFetch(`${controller}/${id}`).get(),

  getCurrent: async ({ id }: GetSingleArgs) =>
    await goFetch(`${controller}/${id}/current`).get(),

  create: async (action, definition) =>
    await goFetch(controller).withBody({ action, definition }).post(),

  finalize: async (id) => await goFetch(`${controller}/${id}`).put(),

  update: async (id, action, definition) =>
    await goFetch(`${controller}/${id}`)
      .withBody({ action, definition })
      .patch(),

  delete: async (id, action) =>
    await goFetch(`${controller}/${id}`).withBody({ action }).delete(),
};

export default service;

import React, { useCallback, useMemo, useState } from "react";
import { Guide } from "../../../interfaces";
import {
  ColorPicker,
  List,
  NumberField,
  TextField,
} from "../../../components/fields";

interface GuideConfigProps {
  guide: Guide;
  onUpdate?: (guide: Guide) => void;
  readOnly?: boolean;
}

export default function GuideConfig({
  guide,
  onUpdate,
  readOnly,
}: GuideConfigProps) {
  const styleOptions = useMemo(
    () => [
      { key: "Solid", value: "Solid" },
      { key: "Dashed", value: "Dashed" },
    ],
    []
  );
  const [label, setLabel] = useState(guide?.label ?? "New Guide");
  const [color, setColor] = useState(guide?.color ?? "#aa0000");
  const [style, setStyle] = useState(guide?.style ?? "dashed");
  const [value, setValue] = useState(guide?.value ?? 0);

  const handleUpdate = useCallback(
    (
      label: string,
      color: string,
      style: "Solid" | "Dashed",
      value: number
    ) => {
      onUpdate?.({ id: guide.id, label, color, style, value });
    },
    [guide.id, onUpdate]
  );

  return (
    <div className="chart-guide-config">
      <TextField
        label="Label"
        value={label}
        onChange={readOnly ? undefined : (e) => setLabel(e.target.value)}
        onBlur={
          readOnly
            ? undefined
            : (e) => handleUpdate(e.target.value, color, style, value)
        }
        readOnly={readOnly}
      />
      <NumberField
        label="Value"
        value={value}
        onChange={
          readOnly
            ? undefined
            : (e) => setValue(parseFloat(e.target.value || "0"))
        }
        className="fixed-width-minmax"
        onBlur={
          readOnly
            ? undefined
            : (e) =>
                handleUpdate(
                  label,
                  color,
                  style,
                  parseFloat(e.target.value || "0")
                )
        }
        readOnly={readOnly}
      />
      {readOnly ? (
        <TextField
          label="Style"
          value={style}
          readOnly
          className="fixed-width-style"
        />
      ) : (
        <List
          label="Style"
          options={styleOptions}
          value={style}
          getOptionLabel={(o) => o.value}
          getOptionValue={(o) => o.key}
          isOptionEqualToValue={(o, v) => o.key === v.key}
          onChange={(_, v) => {
            setStyle(v.key);
            handleUpdate(label, color, v.key, value);
          }}
          className="fixed-width-style"
        />
      )}
      <ColorPicker
        label="Color"
        value={color}
        className="fixed-width-color"
        onChange={
          readOnly
            ? undefined
            : (c: string) => {
                setColor(c);
                handleUpdate(label, c, style, value);
              }
        }
        readOnly={readOnly}
      />
    </div>
  );
}

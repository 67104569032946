import { clone } from "lodash";
import { siteTemplatesService } from "../../../services";

export interface OrderedMeasurable {
  id: string;
  name: string;
  group?: string;
}

export default async function getOrderedMeasurables(
  siteTemplateIds: string[],
  search?: string
) {
  const result = await siteTemplatesService.getAllCurrentInFamily(
    siteTemplateIds
  );
  var resultList: OrderedMeasurable[][] = [];

  result.forEach((result) => {
    const order = result.measurableGroupsOrder ?? [];
    const groups = clone(result.measurableGroups) ?? [];
    const ordered: OrderedMeasurable[] = [];
    const searchRegex = search ? new RegExp(search, "gi") : undefined;

    order.forEach((o) => {
      const group = groups.find((g) => g.id === o);

      if (!group) return;

      group.order.forEach((o) => {
        const measurable = group.measurables.find((m) => m.id === o);

        if (!measurable || (searchRegex && !searchRegex.test(measurable.name)))
          return;

        ordered.push({
          id: measurable?.id ?? "",
          name: measurable?.name ?? "",
          group: group.name,
        });
      });
      resultList.push(ordered);
    });
  });

  if (resultList === null || resultList.length === 0) return [];
  else if (resultList.length === 1) return resultList[0];
  else {
    let commonItems: OrderedMeasurable[] = [];

    for (const item of resultList[0]) {
      const existsInAll = resultList.every((list) =>
        list.some((childItem) => childItem.id === item.id)
      );

      if (
        existsInAll &&
        commonItems.filter((e) => e.id === item.id).length < 1
      ) {
        commonItems.push(item);
      }
    }
    return commonItems;
  }
}

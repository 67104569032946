import React from "react";
import { LibraryCardProps, RoleSummary } from "../../interfaces";

export default function LibraryCard({ item }: LibraryCardProps<RoleSummary>) {
  const { name } = item;

  return (
    <>
      <span className="card-primary">{name || "No name specified"}</span>
    </>
  );
}

import React from "react";
import "./_styles.scss";
import { NavLink } from "react-router-dom";

export default function AppLogo() {
  return (
    <NavLink className="h-app-logo" to="/">
      <div className="app-logo">
        <span
          className="logo-image"
          style={{
            backgroundImage: `url(${require("../../_assets/zamanzi-logo.png")})`,
          }}
        />
        <span className="logo-text">Zamanzi</span>
      </div>
    </NavLink>
  );
}

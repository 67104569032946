import moment from "moment";
import React from "react";
import { LibraryCardProps, MonitoringGroupSummary } from "../../interfaces";

export default function LibraryCard({
  item,
}: LibraryCardProps<MonitoringGroupSummary>) {
  const { name, createdOn, updatedOn, version } = item;

  return (
    <>
      <span className="card-primary">{name || "No name specified"}</span>
      <span className="card-version">v{version}</span>
      <span className="card-secondary">
        Created On: {moment(createdOn).format("DD MMM YYYY, HH:mm:ss")}
      </span>
      <span className="card-secondary">
        Updated On:{" "}
        {updatedOn
          ? moment(updatedOn).format("DD MMM YYYY, HH:mm:ss")
          : "Never"}
      </span>
    </>
  );
}

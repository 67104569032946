const Constants = {
  plotHeight: 768,
  plotBottomPadding: 80,
  plotWidth: 1024,
  renderHeight: 768,
  renderWidth: 1024,
  seriesHeight: 20,
  seriesRectangleSize: 10,
  seriesWidth: 150,
  seriesMargin: 20,
  seriesLineItemCount: 35,
  xAxisLabelHeight: 16,
  xAxisLabelMarginTop: 20,
  axisLabelHeight: 20,
  axisLabelMarginTop: 10,
  axisLabelMarginBottom: 10,
};
export default Constants;

import moment from "moment";
import React from "react";
import { FormSummary, LibraryCardProps } from "../../interfaces";

export default function LibraryCard({ item }: LibraryCardProps<FormSummary>) {
  const { name, createdOn, updatedOn, version, siteTemplate } = item;
  return (
    <>
      <span className="card-primary">{name || "No name specified"}</span>
      <span className="card-version">v{version}</span>
      <span className="card-secondary">
        Template: {siteTemplate?.name || "Not Specified"}
      </span>
      <span className="card-secondary">
        Created On: {moment(createdOn).format("DD MMM YYYY, HH:mm:ss")}
      </span>
      <span className="card-secondary">
        Updated On:{" "}
        {updatedOn
          ? moment(updatedOn).format("DD MMM YYYY, HH:mm:ss")
          : "Never"}
      </span>
    </>
  );
}

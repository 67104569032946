import React, { ReactNode, useCallback, useState } from "react";
import { IconType } from "react-icons";
import { Config } from "../../interfaces";
import { classNameBuilder } from "../../utilities";
import Button from "../button/Button";
import SearchBox from "../search-box/SearchBox";
import ToolSplitter from "./ToolSplitter";
import "./_styles.scss";
import { FaChevronDown } from "react-icons/fa";

export interface ToolbarProps {
  item?: any;
  config?: Config | null;
  toolbarItems: (ToolItem | ToolComponent | "|")[];
  allowSearch?: boolean;
  searchPath?: string;
}

export interface ToolItem {
  label: string;
  icon?: IconType;
  iconComponent?: ReactNode;
  disabled?: boolean;
  onClick?: () => void | Promise<void>;
  className?: string;
  primary?: boolean;
  raised?: boolean;
  children?: ToolItem[];
}

export interface ToolComponent {
  component: ReactNode;
  className?: string;
}

function determineIfToolItem(element: any): element is ToolItem {
  return (element as ToolItem).label ? true : false;
}

export default function Toolbar({
  toolbarItems,
  allowSearch,
  searchPath,
}: ToolbarProps) {
  if (!toolbarItems?.length && !allowSearch)
    return <div className="h-app-toolbar" />;

  return (
    <div className="h-app-toolbar">
      {allowSearch && <SearchBox path={searchPath} />}
      <ToolbarItems toolbarItems={toolbarItems} keyPrefix="root" />
    </div>
  );
}

const ToolbarItems = ({
  toolbarItems,
  keyPrefix,
}: {
  toolbarItems: (ToolItem | ToolComponent | "|")[];
  keyPrefix: string;
}) => (
  <>
    {toolbarItems.map((ti, i) => {
      const key =
        ti === "|"
          ? "splitter"
          : determineIfToolItem(ti)
          ? ti.label
          : "component";

      return (
        <ToolbarItem
          key={`${keyPrefix}-toolbar-item-${i}-${key}`}
          toolbarItem={ti}
        />
      );
    })}
  </>
);

const ToolbarItem = ({
  toolbarItem: ti,
}: {
  toolbarItem: ToolItem | ToolComponent | "|";
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpand = useCallback(
    () => setExpanded(!expanded),
    [expanded, setExpanded]
  );

  if (ti === "|") return <ToolSplitter />;

  if (determineIfToolItem(ti)) {
    const button = (
      <Button
        className={classNameBuilder(
          "h-app-tool-button",
          expanded ? "expanded" : "",
          ti.className ?? ""
        )}
        raised={ti.raised}
        primary={ti.primary}
        icon={ti.icon}
        iconComponent={ti.iconComponent}
        text={ti.label}
        onClick={ti.children?.length ? handleExpand : ti.onClick}
        disabled={ti.disabled}
        endAdornment={
          ti.children?.length ? (
            <FaChevronDown className="children-dropdown" />
          ) : undefined
        }
      />
    );
    return ti.children?.length ? (
      <div className="h-app-children-wrapper">
        {button}
        <div className="h-app-tool-children">
          <ToolbarItems toolbarItems={ti.children} keyPrefix={ti.label} />
        </div>
      </div>
    ) : (
      button
    );
  } else
    return (
      <div
        className={classNameBuilder("h-app-tool-component", ti.className ?? "")}
      >
        {(ti as ToolComponent).component}
      </div>
    );
};

import React from "react";

export default function Defaults() {
  return (
    <style>
      {`
      text {
        font-family:Roboto-Regular,Roboto;
      }
      `}
    </style>
  );
}

import { PageAndSearchArgs, PagedData } from "../interfaces";
import PolygonImportProcessSummary from "../interfaces/PolygonImportProcessSummary";
import { goFetch } from "../utilities/goFetch";
import PlacementItemSummary from "../interfaces/PlacementItemSummary";
import PlacementItemFull from "../interfaces/PlacementItemFull";

interface PolygonServiceService {
  uploadFile: (file: File) => Promise<any>;
  importProcessByPage: (
    args: PageAndSearchArgs
  ) => Promise<PagedData<PolygonImportProcessSummary>>;
  placementItemsByPage: (
    pageNo: number,
    id: string,
    search?: string,
    pageSize?: number
  ) => Promise<PagedData<PlacementItemSummary>>;
  getPlacementById: (id: string) => Promise<PlacementItemFull | null>;
  getPlacementBulkById: (ids: string[]) => Promise<PlacementItemFull[]>;
  deleteFile: (id: string) => Promise<any>;
}

const controller = "polygon";

const service: PolygonServiceService = {
  uploadFile: async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);

    return await goFetch(`${controller}/upload`).withFormData(formData).post();
  },

  importProcessByPage: async ({
    search,
    pageNo,
    pageSize,
  }: PageAndSearchArgs) => {
    const response = await goFetch(`${controller}/import/page/${pageNo}`)
      .withQueryParameters({
        pageSize: pageSize || 50,
        search: search,
      })
      .get();

    return {
      data: response?.data ?? [],
      pageCount: response?.pageCount ?? 0,
      pageSize: response?.pageSize ?? 0,
      itemCount: response?.itemCount ?? 0,
      pageNo: response?.pageNo ?? pageNo,
    };
  },

  placementItemsByPage: async (
    pageNo: number,
    id: string,
    search?: string,
    pageSize?: number
  ) => {
    const response = await goFetch(
      `${controller}/placement/${id}/page/${pageNo}`
    )
      .withQueryParameters({
        pageSize: pageSize || 50,
        search: search,
      })
      .get();

    return {
      data: response?.data ?? [],
      pageCount: response?.pageCount ?? 0,
      pageSize: response?.pageSize ?? 0,
      itemCount: response?.itemCount ?? 0,
      pageNo: response?.pageNo ?? pageNo,
    };
  },

  getPlacementById: async (id: string) => {
    let placement: PlacementItemFull | null = null;
    let page = 1;
    let run = true;
    while (run) {
      let result: PlacementItemFull = await goFetch(
        `${controller}/placement/${id}?page=${page}`
      ).get();
      if (placement == null) placement = result;
      else {
        placement.lineCoordinates = placement.lineCoordinates
          ? placement.lineCoordinates.concat(result.lineCoordinates ?? [])
          : [];
        placement.polygonCoordinates = placement.polygonCoordinates
          ? placement.polygonCoordinates.concat(result.polygonCoordinates)
          : [];
      }
      run = page < result?.pageSize ?? false;
      page++;
    }
    return placement;
  },

  getPlacementBulkById: async (ids: string[]) =>
    await goFetch(`${controller}/placement/bulk`)
      .withBody({ IdList: ids })
      .post(),

  deleteFile: async (id: string) =>
    await goFetch(`${controller}/import/delete/${id}`).post(),
};

export default service;

import React from "react";
import { ReactComponent as USGS101 } from "./patterns/101.svg";
import { ReactComponent as USGS102 } from "./patterns/102.svg";
import { ReactComponent as USGS103 } from "./patterns/103.svg";
import { ReactComponent as USGS104 } from "./patterns/104.svg";
import { ReactComponent as USGS105 } from "./patterns/105.svg";
import { ReactComponent as USGS106 } from "./patterns/106.svg";
import { ReactComponent as USGS114 } from "./patterns/114.svg";
import { ReactComponent as USGS117 } from "./patterns/117.svg";
import { ReactComponent as USGS118 } from "./patterns/118.svg";
import { ReactComponent as USGS119 } from "./patterns/119.svg";
import { ReactComponent as USGS120 } from "./patterns/120.svg";
import { ReactComponent as USGS121 } from "./patterns/121.svg";
import { ReactComponent as USGS122 } from "./patterns/122.svg";
import { ReactComponent as USGS123 } from "./patterns/123.svg";
import { ReactComponent as USGS124 } from "./patterns/124.svg";
import { ReactComponent as USGS132 } from "./patterns/132.svg";
import { ReactComponent as USGS134 } from "./patterns/134.svg";
import { ReactComponent as USGS135 } from "./patterns/135.svg";
import { ReactComponent as USGS136 } from "./patterns/136.svg";
import { ReactComponent as USGS137 } from "./patterns/137.svg";
import { ReactComponent as USGS201 } from "./patterns/201.svg";
import { ReactComponent as USGS202 } from "./patterns/202.svg";
import { ReactComponent as USGS204 } from "./patterns/204.svg";
import { ReactComponent as USGS206 } from "./patterns/206.svg";
import { ReactComponent as USGS207 } from "./patterns/207.svg";
import { ReactComponent as USGS214 } from "./patterns/214.svg";
import { ReactComponent as USGS215 } from "./patterns/215.svg";
import { ReactComponent as USGS216 } from "./patterns/216.svg";
import { ReactComponent as USGS217 } from "./patterns/217.svg";
import { ReactComponent as USGS218 } from "./patterns/218.svg";
import { ReactComponent as USGS219 } from "./patterns/219.svg";
import { ReactComponent as USGS226 } from "./patterns/226.svg";
import { ReactComponent as USGS228 } from "./patterns/228.svg";
import { ReactComponent as USGS229 } from "./patterns/229.svg";
import { ReactComponent as USGS230 } from "./patterns/230.svg";
import { ReactComponent as USGS231 } from "./patterns/231.svg";
import { ReactComponent as USGS232 } from "./patterns/232.svg";
import { ReactComponent as USGS233 } from "./patterns/233.svg";
import { ReactComponent as USGS301 } from "./patterns/301.svg";
import { ReactComponent as USGS302 } from "./patterns/302.svg";
import { ReactComponent as USGS303 } from "./patterns/303.svg";
import { ReactComponent as USGS304 } from "./patterns/304.svg";
import { ReactComponent as USGS305 } from "./patterns/305.svg";
import { ReactComponent as USGS306 } from "./patterns/306.svg";
import { ReactComponent as USGS313 } from "./patterns/313.svg";
import { ReactComponent as USGS314 } from "./patterns/314.svg";
import { ReactComponent as USGS315 } from "./patterns/315.svg";
import { ReactComponent as USGS316 } from "./patterns/316.svg";
import { ReactComponent as USGS317 } from "./patterns/317.svg";
import { ReactComponent as USGS318 } from "./patterns/318.svg";
import { ReactComponent as USGS319 } from "./patterns/319.svg";
import { ReactComponent as USGS327 } from "./patterns/327.svg";
import { ReactComponent as USGS328 } from "./patterns/328.svg";
import { ReactComponent as USGS330 } from "./patterns/330.svg";
import { ReactComponent as USGS331 } from "./patterns/331.svg";
import { ReactComponent as USGS401 } from "./patterns/401.svg";
import { ReactComponent as USGS402 } from "./patterns/402.svg";
import { ReactComponent as USGS403 } from "./patterns/403.svg";
import { ReactComponent as USGS405 } from "./patterns/405.svg";
import { ReactComponent as USGS406 } from "./patterns/406.svg";
import { ReactComponent as USGS411 } from "./patterns/411.svg";
import { ReactComponent as USGS412 } from "./patterns/412.svg";
import { ReactComponent as USGS416 } from "./patterns/416.svg";
import { ReactComponent as USGS417 } from "./patterns/417.svg";
import { ReactComponent as USGS418 } from "./patterns/418.svg";
import { ReactComponent as USGS419 } from "./patterns/419.svg";
import { ReactComponent as USGS420 } from "./patterns/420.svg";
import { ReactComponent as USGS423 } from "./patterns/423.svg";
import { ReactComponent as USGS424 } from "./patterns/424.svg";
import { ReactComponent as USGS427 } from "./patterns/427.svg";
import { ReactComponent as USGS428 } from "./patterns/428.svg";
import { ReactComponent as USGS429 } from "./patterns/429.svg";
import { ReactComponent as USGS430 } from "./patterns/430.svg";
import { ReactComponent as USGS431 } from "./patterns/431.svg";
import { ReactComponent as USGS432 } from "./patterns/432.svg";
import { ReactComponent as USGS433 } from "./patterns/433.svg";
import { ReactComponent as USGS434 } from "./patterns/434.svg";
import { ReactComponent as USGS435 } from "./patterns/435.svg";
import { ReactComponent as USGS436 } from "./patterns/436.svg";
import { ReactComponent as USGS521 } from "./patterns/521.svg";
import { ReactComponent as USGS522 } from "./patterns/522.svg";
import { ReactComponent as USGS523 } from "./patterns/523.svg";
import { ReactComponent as USGS524 } from "./patterns/524.svg";
import { ReactComponent as USGS591 } from "./patterns/591.svg";
import { ReactComponent as USGS592 } from "./patterns/592.svg";
import { ReactComponent as USGS593 } from "./patterns/593.svg";
import { ReactComponent as USGS594 } from "./patterns/594.svg";
import { ReactComponent as USGS595 } from "./patterns/595.svg";
import { ReactComponent as USGS601 } from "./patterns/601.svg";
import { ReactComponent as USGS602 } from "./patterns/602.svg";
import { ReactComponent as USGS603 } from "./patterns/603.svg";
import { ReactComponent as USGS605 } from "./patterns/605.svg";
import { ReactComponent as USGS606 } from "./patterns/606.svg";
import { ReactComponent as USGS607 } from "./patterns/607.svg";
import { ReactComponent as USGS608 } from "./patterns/608.svg";
import { ReactComponent as USGS609 } from "./patterns/609.svg";
import { ReactComponent as USGS610 } from "./patterns/610.svg";
import { ReactComponent as USGS611 } from "./patterns/611.svg";
import { ReactComponent as USGS612 } from "./patterns/612.svg";
import { ReactComponent as USGS613 } from "./patterns/613.svg";
import { ReactComponent as USGS614 } from "./patterns/614.svg";
import { ReactComponent as USGS616 } from "./patterns/616.svg";
import { ReactComponent as USGS617 } from "./patterns/617.svg";
import { ReactComponent as USGS618 } from "./patterns/618.svg";
import { ReactComponent as USGS619 } from "./patterns/619.svg";
import { ReactComponent as USGS620 } from "./patterns/620.svg";
import { ReactComponent as USGS621 } from "./patterns/621.svg";
import { ReactComponent as USGS622 } from "./patterns/622.svg";
import { ReactComponent as USGS623 } from "./patterns/623.svg";
import { ReactComponent as USGS624 } from "./patterns/624.svg";
import { ReactComponent as USGS625 } from "./patterns/625.svg";
import { ReactComponent as USGS626 } from "./patterns/626.svg";
import { ReactComponent as USGS627 } from "./patterns/627.svg";
import { ReactComponent as USGS628 } from "./patterns/628.svg";
import { ReactComponent as USGS629 } from "./patterns/629.svg";
import { ReactComponent as USGS630 } from "./patterns/630.svg";
import { ReactComponent as USGS631 } from "./patterns/631.svg";
import { ReactComponent as USGS632 } from "./patterns/632.svg";
import { ReactComponent as USGS633 } from "./patterns/633.svg";
import { ReactComponent as USGS634 } from "./patterns/634.svg";
import { ReactComponent as USGS635 } from "./patterns/635.svg";
import { ReactComponent as USGS636 } from "./patterns/636.svg";
import { ReactComponent as USGS637 } from "./patterns/637.svg";
import { ReactComponent as USGS638 } from "./patterns/638.svg";
import { ReactComponent as USGS639 } from "./patterns/639.svg";
import { ReactComponent as USGS640 } from "./patterns/640.svg";
import { ReactComponent as USGS641 } from "./patterns/641.svg";
import { ReactComponent as USGS642 } from "./patterns/642.svg";
import { ReactComponent as USGS643 } from "./patterns/643.svg";
import { ReactComponent as USGS644 } from "./patterns/644.svg";
import { ReactComponent as USGS645 } from "./patterns/645.svg";
import { ReactComponent as USGS646 } from "./patterns/646.svg";
import { ReactComponent as USGS647 } from "./patterns/647.svg";
import { ReactComponent as USGS648 } from "./patterns/648.svg";
import { ReactComponent as USGS649 } from "./patterns/649.svg";
import { ReactComponent as USGS650 } from "./patterns/650.svg";
import { ReactComponent as USGS651 } from "./patterns/651.svg";
import { ReactComponent as USGS652 } from "./patterns/652.svg";
import { ReactComponent as USGS653 } from "./patterns/653.svg";
import { ReactComponent as USGS654 } from "./patterns/654.svg";
import { ReactComponent as USGS655 } from "./patterns/655.svg";
import { ReactComponent as USGS656 } from "./patterns/656.svg";
import { ReactComponent as USGS657 } from "./patterns/657.svg";
import { ReactComponent as USGS658 } from "./patterns/658.svg";
import { ReactComponent as USGS659 } from "./patterns/659.svg";
import { ReactComponent as USGS660 } from "./patterns/660.svg";
import { ReactComponent as USGS661 } from "./patterns/661.svg";
import { ReactComponent as USGS662 } from "./patterns/662.svg";
import { ReactComponent as USGS663 } from "./patterns/663.svg";
import { ReactComponent as USGS664 } from "./patterns/664.svg";
import { ReactComponent as USGS665 } from "./patterns/665.svg";
import { ReactComponent as USGS666 } from "./patterns/666.svg";
import { ReactComponent as USGS667 } from "./patterns/667.svg";
import { ReactComponent as USGS668 } from "./patterns/668.svg";
import { ReactComponent as USGS669 } from "./patterns/669.svg";
import { ReactComponent as USGS670 } from "./patterns/670.svg";
import { ReactComponent as USGS671 } from "./patterns/671.svg";
import { ReactComponent as USGS672 } from "./patterns/672.svg";
import { ReactComponent as USGS673 } from "./patterns/673.svg";
import { ReactComponent as USGS674 } from "./patterns/674.svg";
import { ReactComponent as USGS675 } from "./patterns/675.svg";
import { ReactComponent as USGS676 } from "./patterns/676.svg";
import { ReactComponent as USGS677 } from "./patterns/677.svg";
import { ReactComponent as USGS678 } from "./patterns/678.svg";
import { ReactComponent as USGS679 } from "./patterns/679.svg";
import { ReactComponent as USGS680 } from "./patterns/680.svg";
import { ReactComponent as USGS681 } from "./patterns/681.svg";
import { ReactComponent as USGS682 } from "./patterns/682.svg";
import { ReactComponent as USGS683 } from "./patterns/683.svg";
import { ReactComponent as USGS684 } from "./patterns/684.svg";
import { ReactComponent as USGS685 } from "./patterns/685.svg";
import { ReactComponent as USGS686 } from "./patterns/686.svg";
import { ReactComponent as USGS701 } from "./patterns/701.svg";
import { ReactComponent as USGS702 } from "./patterns/702.svg";
import { ReactComponent as USGS703 } from "./patterns/703.svg";
import { ReactComponent as USGS704 } from "./patterns/704.svg";
import { ReactComponent as USGS705 } from "./patterns/705.svg";
import { ReactComponent as USGS706 } from "./patterns/706.svg";
import { ReactComponent as USGS707 } from "./patterns/707.svg";
import { ReactComponent as USGS708 } from "./patterns/708.svg";
import { ReactComponent as USGS709 } from "./patterns/709.svg";
import { ReactComponent as USGS710 } from "./patterns/710.svg";
import { ReactComponent as USGS711 } from "./patterns/711.svg";
import { ReactComponent as USGS712 } from "./patterns/712.svg";
import { ReactComponent as USGS713 } from "./patterns/713.svg";
import { ReactComponent as USGS714 } from "./patterns/714.svg";
import { ReactComponent as USGS715 } from "./patterns/715.svg";
import { ReactComponent as USGS716 } from "./patterns/716.svg";
import { ReactComponent as USGS717 } from "./patterns/717.svg";
import { ReactComponent as USGS719 } from "./patterns/719.svg";
import { ReactComponent as USGS720 } from "./patterns/720.svg";
import { ReactComponent as USGS721 } from "./patterns/721.svg";
import { ReactComponent as USGS722 } from "./patterns/722.svg";
import { ReactComponent as USGS723 } from "./patterns/723.svg";
import { ReactComponent as USGS724 } from "./patterns/724.svg";
import { ReactComponent as USGS725 } from "./patterns/725.svg";
import { ReactComponent as USGS726 } from "./patterns/726.svg";
import { ReactComponent as USGS727 } from "./patterns/727.svg";
import { ReactComponent as USGS728 } from "./patterns/728.svg";
import { ReactComponent as USGS729 } from "./patterns/729.svg";
import { ReactComponent as USGS730 } from "./patterns/730.svg";
import { ReactComponent as USGS731 } from "./patterns/731.svg";
import { ReactComponent as USGS732 } from "./patterns/732.svg";
import { ReactComponent as USGS733 } from "./patterns/733.svg";

export const AllPatternKeys = [
  "usgs-101",
  "usgs-102",
  "usgs-103",
  "usgs-104",
  "usgs-105",
  "usgs-106",
  "usgs-114",
  "usgs-117",
  "usgs-118",
  "usgs-119",
  "usgs-120",
  "usgs-121",
  "usgs-122",
  "usgs-123",
  "usgs-124",
  "usgs-132",
  "usgs-134",
  "usgs-135",
  "usgs-136",
  "usgs-137",
  "usgs-201",
  "usgs-202",
  "usgs-204",
  "usgs-206",
  "usgs-207",
  "usgs-214",
  "usgs-215",
  "usgs-216",
  "usgs-217",
  "usgs-218",
  "usgs-219",
  "usgs-226",
  "usgs-228",
  "usgs-229",
  "usgs-230",
  "usgs-231",
  "usgs-232",
  "usgs-233",
  "usgs-301",
  "usgs-302",
  "usgs-303",
  "usgs-304",
  "usgs-305",
  "usgs-306",
  "usgs-313",
  "usgs-314",
  "usgs-315",
  "usgs-316",
  "usgs-317",
  "usgs-318",
  "usgs-319",
  "usgs-327",
  "usgs-328",
  "usgs-330",
  "usgs-331",
  "usgs-401",
  "usgs-402",
  "usgs-403",
  "usgs-405",
  "usgs-406",
  "usgs-411",
  "usgs-412",
  "usgs-416",
  "usgs-417",
  "usgs-418",
  "usgs-419",
  "usgs-420",
  "usgs-423",
  "usgs-424",
  "usgs-427",
  "usgs-428",
  "usgs-429",
  "usgs-430",
  "usgs-431",
  "usgs-432",
  "usgs-433",
  "usgs-434",
  "usgs-435",
  "usgs-436",
  "usgs-521",
  "usgs-522",
  "usgs-523",
  "usgs-524",
  "usgs-591",
  "usgs-592",
  "usgs-593",
  "usgs-594",
  "usgs-595",
  "usgs-601",
  "usgs-602",
  "usgs-603",
  "usgs-605",
  "usgs-606",
  "usgs-607",
  "usgs-608",
  "usgs-609",
  "usgs-610",
  "usgs-611",
  "usgs-612",
  "usgs-613",
  "usgs-614",
  "usgs-616",
  "usgs-617",
  "usgs-618",
  "usgs-619",
  "usgs-620",
  "usgs-621",
  "usgs-622",
  "usgs-623",
  "usgs-624",
  "usgs-625",
  "usgs-626",
  "usgs-627",
  "usgs-628",
  "usgs-629",
  "usgs-630",
  "usgs-631",
  "usgs-632",
  "usgs-633",
  "usgs-634",
  "usgs-635",
  "usgs-636",
  "usgs-637",
  "usgs-638",
  "usgs-639",
  "usgs-640",
  "usgs-641",
  "usgs-642",
  "usgs-643",
  "usgs-644",
  "usgs-645",
  "usgs-646",
  "usgs-647",
  "usgs-648",
  "usgs-649",
  "usgs-650",
  "usgs-651",
  "usgs-652",
  "usgs-653",
  "usgs-654",
  "usgs-655",
  "usgs-656",
  "usgs-657",
  "usgs-658",
  "usgs-659",
  "usgs-660",
  "usgs-661",
  "usgs-662",
  "usgs-663",
  "usgs-664",
  "usgs-665",
  "usgs-666",
  "usgs-667",
  "usgs-668",
  "usgs-669",
  "usgs-670",
  "usgs-671",
  "usgs-672",
  "usgs-673",
  "usgs-674",
  "usgs-675",
  "usgs-676",
  "usgs-677",
  "usgs-678",
  "usgs-679",
  "usgs-680",
  "usgs-681",
  "usgs-682",
  "usgs-683",
  "usgs-684",
  "usgs-685",
  "usgs-686",
  "usgs-701",
  "usgs-702",
  "usgs-703",
  "usgs-704",
  "usgs-705",
  "usgs-706",
  "usgs-707",
  "usgs-708",
  "usgs-709",
  "usgs-710",
  "usgs-711",
  "usgs-712",
  "usgs-713",
  "usgs-714",
  "usgs-715",
  "usgs-716",
  "usgs-717",
  "usgs-719",
  "usgs-720",
  "usgs-721",
  "usgs-722",
  "usgs-723",
  "usgs-724",
  "usgs-725",
  "usgs-726",
  "usgs-727",
  "usgs-728",
  "usgs-729",
  "usgs-730",
  "usgs-731",
  "usgs-732",
  "usgs-733",
] as const;

type PatternKey = (typeof AllPatternKeys)[number];

const Patterns: { [key in PatternKey]: React.ReactNode } = {
  "usgs-101": <USGS101 />,
  "usgs-102": <USGS102 />,
  "usgs-103": <USGS103 />,
  "usgs-104": <USGS104 />,
  "usgs-105": <USGS105 />,
  "usgs-106": <USGS106 />,
  "usgs-114": <USGS114 />,
  "usgs-117": <USGS117 />,
  "usgs-118": <USGS118 />,
  "usgs-119": <USGS119 />,
  "usgs-120": <USGS120 />,
  "usgs-121": <USGS121 />,
  "usgs-122": <USGS122 />,
  "usgs-123": <USGS123 />,
  "usgs-124": <USGS124 />,
  "usgs-132": <USGS132 />,
  "usgs-134": <USGS134 />,
  "usgs-135": <USGS135 />,
  "usgs-136": <USGS136 />,
  "usgs-137": <USGS137 />,
  "usgs-201": <USGS201 />,
  "usgs-202": <USGS202 />,
  "usgs-204": <USGS204 />,
  "usgs-206": <USGS206 />,
  "usgs-207": <USGS207 />,
  "usgs-214": <USGS214 />,
  "usgs-215": <USGS215 />,
  "usgs-216": <USGS216 />,
  "usgs-217": <USGS217 />,
  "usgs-218": <USGS218 />,
  "usgs-219": <USGS219 />,
  "usgs-226": <USGS226 />,
  "usgs-228": <USGS228 />,
  "usgs-229": <USGS229 />,
  "usgs-230": <USGS230 />,
  "usgs-231": <USGS231 />,
  "usgs-232": <USGS232 />,
  "usgs-233": <USGS233 />,
  "usgs-301": <USGS301 />,
  "usgs-302": <USGS302 />,
  "usgs-303": <USGS303 />,
  "usgs-304": <USGS304 />,
  "usgs-305": <USGS305 />,
  "usgs-306": <USGS306 />,
  "usgs-313": <USGS313 />,
  "usgs-314": <USGS314 />,
  "usgs-315": <USGS315 />,
  "usgs-316": <USGS316 />,
  "usgs-317": <USGS317 />,
  "usgs-318": <USGS318 />,
  "usgs-319": <USGS319 />,
  "usgs-327": <USGS327 />,
  "usgs-328": <USGS328 />,
  "usgs-330": <USGS330 />,
  "usgs-331": <USGS331 />,
  "usgs-401": <USGS401 />,
  "usgs-402": <USGS402 />,
  "usgs-403": <USGS403 />,
  "usgs-405": <USGS405 />,
  "usgs-406": <USGS406 />,
  "usgs-411": <USGS411 />,
  "usgs-412": <USGS412 />,
  "usgs-416": <USGS416 />,
  "usgs-417": <USGS417 />,
  "usgs-418": <USGS418 />,
  "usgs-419": <USGS419 />,
  "usgs-420": <USGS420 />,
  "usgs-423": <USGS423 />,
  "usgs-424": <USGS424 />,
  "usgs-427": <USGS427 />,
  "usgs-428": <USGS428 />,
  "usgs-429": <USGS429 />,
  "usgs-430": <USGS430 />,
  "usgs-431": <USGS431 />,
  "usgs-432": <USGS432 />,
  "usgs-433": <USGS433 />,
  "usgs-434": <USGS434 />,
  "usgs-435": <USGS435 />,
  "usgs-436": <USGS436 />,
  "usgs-521": <USGS521 />,
  "usgs-522": <USGS522 />,
  "usgs-523": <USGS523 />,
  "usgs-524": <USGS524 />,
  "usgs-591": <USGS591 />,
  "usgs-592": <USGS592 />,
  "usgs-593": <USGS593 />,
  "usgs-594": <USGS594 />,
  "usgs-595": <USGS595 />,
  "usgs-601": <USGS601 />,
  "usgs-602": <USGS602 />,
  "usgs-603": <USGS603 />,
  "usgs-605": <USGS605 />,
  "usgs-606": <USGS606 />,
  "usgs-607": <USGS607 />,
  "usgs-608": <USGS608 />,
  "usgs-609": <USGS609 />,
  "usgs-610": <USGS610 />,
  "usgs-611": <USGS611 />,
  "usgs-612": <USGS612 />,
  "usgs-613": <USGS613 />,
  "usgs-614": <USGS614 />,
  "usgs-616": <USGS616 />,
  "usgs-617": <USGS617 />,
  "usgs-618": <USGS618 />,
  "usgs-619": <USGS619 />,
  "usgs-620": <USGS620 />,
  "usgs-621": <USGS621 />,
  "usgs-622": <USGS622 />,
  "usgs-623": <USGS623 />,
  "usgs-624": <USGS624 />,
  "usgs-625": <USGS625 />,
  "usgs-626": <USGS626 />,
  "usgs-627": <USGS627 />,
  "usgs-628": <USGS628 />,
  "usgs-629": <USGS629 />,
  "usgs-630": <USGS630 />,
  "usgs-631": <USGS631 />,
  "usgs-632": <USGS632 />,
  "usgs-633": <USGS633 />,
  "usgs-634": <USGS634 />,
  "usgs-635": <USGS635 />,
  "usgs-636": <USGS636 />,
  "usgs-637": <USGS637 />,
  "usgs-638": <USGS638 />,
  "usgs-639": <USGS639 />,
  "usgs-640": <USGS640 />,
  "usgs-641": <USGS641 />,
  "usgs-642": <USGS642 />,
  "usgs-643": <USGS643 />,
  "usgs-644": <USGS644 />,
  "usgs-645": <USGS645 />,
  "usgs-646": <USGS646 />,
  "usgs-647": <USGS647 />,
  "usgs-648": <USGS648 />,
  "usgs-649": <USGS649 />,
  "usgs-650": <USGS650 />,
  "usgs-651": <USGS651 />,
  "usgs-652": <USGS652 />,
  "usgs-653": <USGS653 />,
  "usgs-654": <USGS654 />,
  "usgs-655": <USGS655 />,
  "usgs-656": <USGS656 />,
  "usgs-657": <USGS657 />,
  "usgs-658": <USGS658 />,
  "usgs-659": <USGS659 />,
  "usgs-660": <USGS660 />,
  "usgs-661": <USGS661 />,
  "usgs-662": <USGS662 />,
  "usgs-663": <USGS663 />,
  "usgs-664": <USGS664 />,
  "usgs-665": <USGS665 />,
  "usgs-666": <USGS666 />,
  "usgs-667": <USGS667 />,
  "usgs-668": <USGS668 />,
  "usgs-669": <USGS669 />,
  "usgs-670": <USGS670 />,
  "usgs-671": <USGS671 />,
  "usgs-672": <USGS672 />,
  "usgs-673": <USGS673 />,
  "usgs-674": <USGS674 />,
  "usgs-675": <USGS675 />,
  "usgs-676": <USGS676 />,
  "usgs-677": <USGS677 />,
  "usgs-678": <USGS678 />,
  "usgs-679": <USGS679 />,
  "usgs-680": <USGS680 />,
  "usgs-681": <USGS681 />,
  "usgs-682": <USGS682 />,
  "usgs-683": <USGS683 />,
  "usgs-684": <USGS684 />,
  "usgs-685": <USGS685 />,
  "usgs-686": <USGS686 />,
  "usgs-701": <USGS701 />,
  "usgs-702": <USGS702 />,
  "usgs-703": <USGS703 />,
  "usgs-704": <USGS704 />,
  "usgs-705": <USGS705 />,
  "usgs-706": <USGS706 />,
  "usgs-707": <USGS707 />,
  "usgs-708": <USGS708 />,
  "usgs-709": <USGS709 />,
  "usgs-710": <USGS710 />,
  "usgs-711": <USGS711 />,
  "usgs-712": <USGS712 />,
  "usgs-713": <USGS713 />,
  "usgs-714": <USGS714 />,
  "usgs-715": <USGS715 />,
  "usgs-716": <USGS716 />,
  "usgs-717": <USGS717 />,
  "usgs-719": <USGS719 />,
  "usgs-720": <USGS720 />,
  "usgs-721": <USGS721 />,
  "usgs-722": <USGS722 />,
  "usgs-723": <USGS723 />,
  "usgs-724": <USGS724 />,
  "usgs-725": <USGS725 />,
  "usgs-726": <USGS726 />,
  "usgs-727": <USGS727 />,
  "usgs-728": <USGS728 />,
  "usgs-729": <USGS729 />,
  "usgs-730": <USGS730 />,
  "usgs-731": <USGS731 />,
  "usgs-732": <USGS732 />,
  "usgs-733": <USGS733 />,
};

export { PatternKey, Patterns };

import React, { useEffect } from "react";
import {
  NavigableComponent,
  ReportTemplateFull,
  LithologyAndBoreholeConstructionConfig as LConfig,
} from "../../../interfaces";
import { useParams, useNavigate } from "react-router-dom";
import { publish, useAuthentication } from "../../../hooks";
import { FaArrowLeft, FaEdit, FaTrash } from "react-icons/fa";
import { routes } from "../../../_config";
import { showAlert, hideAlert } from "../../../components/alert/Alert";
import { LibrarySection } from "../../../components/library/Library";
import { ToolItem } from "../../../components/toolbar/Toolbar";
import { AlertTypeEnum, StateTopicEnum } from "../../../enums";
import { reportTemplatesService } from "../../../services";
import { Form } from "../../../components";
import { InstructionWrapper, TextField } from "../../../components/fields";
import LithologyAndBoreholeConstructionConfig from "./LithologyAndBoreholeConstructionConfig";

interface ViewReportTemplateProps {
  reportTemplate: ReportTemplateFull;
}

export default function ViewReportTemplate({
  setToolbarConfig,
  reportTemplate,
}: NavigableComponent & ViewReportTemplateProps) {
  const auth = useAuthentication();
  const { settingId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    let toolbarItems: (ToolItem | "|")[] = [
      {
        label: "Back",
        icon: FaArrowLeft,
        primary: true,
        raised: true,
        onClick: () => navigate(routes.reportTemplates.go()),
      },
    ];

    const canEdit = auth?.hasPermission("EditReportTemplate");
    const canDelete = auth?.hasPermission("RemoveReportTemplate");

    if (canEdit || canDelete) toolbarItems.push("|");

    if (canEdit)
      toolbarItems.push({
        label: "Edit",
        icon: FaEdit,
        onClick: () =>
          navigate(
            `${routes.settingItem.go(
              "report-templates-library",
              settingId
            )}?mode=edit`
          ),
      });

    if (canDelete)
      toolbarItems.push({
        label: "Delete",
        icon: FaTrash,
        onClick: () => {
          showAlert({
            content:
              "Are you sure you want to delete this report template? This action is not reversable.",
            options: {
              type: AlertTypeEnum.Warning,
              actions: [
                {
                  text: "Yes",
                  primary: true,
                  onClick: async () => {
                    const response = await reportTemplatesService.delete?.(
                      settingId ?? ""
                    );

                    hideAlert();

                    if (response?.message) {
                      navigate(
                        routes.settingCategory.go("report-templates-library")
                      );
                      publish<LibrarySection>(
                        StateTopicEnum.LibrarySectionRefresh,
                        "paged"
                      );
                    }
                  },
                },
                { text: "No" },
              ],
            },
          });
        },
      });

    setToolbarConfig({
      allowSearch: true,
      toolbarItems,
      searchPath: routes.siteTemplates.go(),
    });
  }, [setToolbarConfig, settingId, navigate]);

  return (
    <>
      <Form title="Identifying Information">
        <InstructionWrapper text="An identifying name for the report template.">
          <TextField label="Name" value={reportTemplate.name} readOnly />
        </InstructionWrapper>
        <InstructionWrapper text="The site template(s) that this report template is associated with.">
          <TextField
            label="Site Template(s)"
            value={reportTemplate.siteTemplates?.map((e) => e.name).join(", ")}
            readOnly
          />
        </InstructionWrapper>
        <InstructionWrapper text="The report type to render."></InstructionWrapper>
      </Form>
      <LithologyAndBoreholeConstructionConfig
        key="LithologyAndBoreholeConstruction-config"
        siteTemplates={reportTemplate.siteTemplates}
        config={reportTemplate.config as LConfig}
        readOnly
      />
    </>
  );
}

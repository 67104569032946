import { GetSingleArgs } from "../interfaces";
import ReferenceSummary from "../interfaces/ReferenceSummary";
import { asyncify } from "../utilities";

interface TopographyTypesService {
  getAllTopographyTypes(): Promise<ReferenceSummary[]>;
  getSingleTopographyType(args: GetSingleArgs): Promise<ReferenceSummary>;
}

let _CACHE: ReferenceSummary[] = [
  {
    id: "NoInformation",
    name: "No Information"
  },
  {
    id: "A",
    name: "Alluvial fan"
  },
  {
    id: "B",
    name: "Dry river bed"
  },
  {
    id: "D",
    name: "Dunes"
  },
  {
    id: "E",
    name: "Ephemeral stream"
  },
  {
    id: "F",
    name: "Flat surface, plain"
  },
  {
    id: "H",
    name: "In or along sinkhole"
  },
  {
    id: "I",
    name: "Irrigated field"
  },
  {
    id: "L",
    name: "Along dam, lare or swamp"
  },
  {
    id: "M",
    name: "On mountain or hill"
  },
  {
    id: "O",
    name: "At or in opencast mine"
  },
  {
    id: "P",
    name: "Waterbody (wetland, pan or spring)"
  },
  {
    id: "R",
    name: "In or along river"
  },
  {
    id: "S",
    name: "Hillside (Slope)"
  },
  {
    id: "T",
    name: "Terrace"
  },
  {
    id: "V",
    name: "Valley"
  },
  {
    id: "W",
    name: "At or in waste disposal"
  },
  {
    id: "Z",
    name: "Riparian zone"
  },
  {
    id: "U",
    name: "Steep mountain slope"
  }
];

const service: TopographyTypesService = {
  async getAllTopographyTypes() {
    return await asyncify(() => _CACHE, 0);
  },

  async getSingleTopographyType({ id }) {
    return (await service.getAllTopographyTypes()).filter((s) => s.id === id)[0];
  },
};

export default service;

import Constants from "../Constants";
import { Guide, Series } from "../interfaces";

export default function calculateRightMargin<TData>(
  data: TData[][],
  guides?: Guide[],
  series?: Series<TData>
) {
  const { seriesLineItemCount, seriesWidth } = Constants;
  const seriesTotalWidth =
    series?.location === "Bottom"
      ? 0
      : seriesWidth * Math.ceil(data.length / seriesLineItemCount);

  return (
    20 + (guides?.length ? 20 : 0) + (data.length > 1 ? seriesTotalWidth : 0)
  );
}

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  LithologyAndBoreholeConstructionConfig as LConfig,
  NavigableComponent,
  ReportConfigType,
  ReportTemplateFull,
  ReportType,
  SiteTemplateSummary,
} from "../../../interfaces";
import ReferenceSummary from "../../../interfaces/ReferenceSummary";
import { useNavigate, useParams } from "react-router-dom";
import { publish, useStateReducer } from "../../../hooks";
import validateObject, {
  Validation,
  hasErrors,
} from "../../../utilities/validateObject";
import { asyncify } from "../../../utilities";
import { ReportTemplateUpdateDefinition } from "../../../services/reportTemplatesService";
import {
  reportTemplatesService,
  siteTemplatesService,
} from "../../../services";
import { showAlert } from "../../../components/alert/Alert";
import { AlertTypeEnum, StateTopicEnum } from "../../../enums";
import { LibrarySection } from "../../../components/library/Library";
import { routes } from "../../../_config";
import { ToolItem } from "../../../components/toolbar/Toolbar";
import { FaSave, FaTimes } from "react-icons/fa";
import { Form } from "../../../components";
import {
  InstructionWrapper,
  List,
  TextField,
} from "../../../components/fields";
import LithologyAndBoreholeConstructionConfig from "./LithologyAndBoreholeConstructionConfig";

interface EditReportTemplateProps {
  reportTemplate: ReportTemplateFull;
  onLoad: () => void;
}

interface State {
  id?: string;
  name?: string;
  reportType?: ReportType;
  siteTemplates?: ReferenceSummary[];
  config?: ReportConfigType;
}

interface ReportOption {
  key: ReportType;
  value: string;
}

export default function EditReportTemplate({
  reportTemplate,
  setToolbarConfig,
}: NavigableComponent & EditReportTemplateProps) {
  const { settingId } = useParams();
  const navigate = useNavigate();
  const [state, setState] = useStateReducer<State>({
    id: reportTemplate.id,
    name: reportTemplate.name,
    reportType: reportTemplate.reportType,
    siteTemplates: reportTemplate.siteTemplates,
    config: reportTemplate.config,
  });
  const reportTypes = useMemo<ReportOption[]>(
    () => [
      {
        key: "LithologyAndBoreholeConstruction",
        value: "Lithology and borehole construction",
      },
    ],
    []
  );
  const [siteTemplates, setSiteTemplates] = useState<SiteTemplateSummary[]>([]);
  const saveState = useRef<State>(state);
  const validationRules: Validation = useMemo(
    () => ({
      name: {
        regex: /.+/,
        message: "This field is required.",
      },
      reportType: {
        func: (v: ReportType | undefined) => v && true,
        message: "This field is required.",
      },
      siteTemplates: {
        func: (v: ReferenceSummary | undefined) => v && true,
        message: "This field is required.",
      },
    }),
    []
  );
  const validation = validateObject(validationRules, state);

  const handleSave = useCallback(async () => {
    await asyncify(() => {}, 10);

    if (hasErrors(validateObject(validationRules, saveState.current))) return;

    const doUpdate = async (definition: ReportTemplateUpdateDefinition) => {
      const response = await reportTemplatesService.update(
        reportTemplate.id,
        definition
      );

      if (!response?.id) {
        showAlert({
          content: "Could not update the report template.",
          options: {
            type: AlertTypeEnum.Error,
            actions: [{ text: "Ok", primary: true }],
          },
        });
        return false;
      }

      return true;
    };

    const state = saveState.current;
    const updateDefinition: ReportTemplateUpdateDefinition = {
      type: state.reportType ?? "LithologyAndBoreholeConstruction",
      siteTemplateIds: state.siteTemplates?.map((e) => e.id) ?? [],
      name: state.name ?? "",
    };

    switch (state.reportType) {
      case "LithologyAndBoreholeConstruction":
        updateDefinition.config = {
          siteDetails: {
            collarHeightFieldId:
              state.config?.siteDetails.collarHeightField?.id,
            coordinates: {
              ellipsoid: state.config?.siteDetails.coordinates.ellipsoid,
              datum: state.config?.siteDetails.coordinates.datum,
            },
          },
          lithology: {
            depthFromFieldId: state.config?.lithology.depthFromField?.id,
            depthToFieldId: state.config?.lithology.depthToField?.id,
            typeFieldId: state.config?.lithology.typeField?.id,
            attributeFieldIds: state.config?.lithology.attributeFields?.map(
              (f) => f.id
            ),
          },
          construction: {
            depthFromFieldId: state.config?.construction.depthFromField?.id,
            depthToFieldId: state.config?.construction.depthToField?.id,
            innerRadiusFieldId: state.config?.construction.innerRadiusField?.id,
            outerRadiusFieldId: state.config?.construction.outerRadiusField?.id,
            typeFieldId: state.config?.construction.typeField?.id,
          },
          casing: {
            depthFromFieldId: state.config?.casing.depthFromField?.id,
            depthToFieldId: state.config?.casing.depthToField?.id,
            radiusFieldId: state.config?.casing.radiusField?.id,
            typeFieldId: state.config?.casing.typeField?.id,
          },
          electricalConductivity: {
            depthFieldId: state.config?.electricalConductivity.depthField?.id,
            valueFieldId: state.config?.electricalConductivity.valueField?.id,
          },
          penetrationRate: {
            depthFromFieldId: state.config?.penetrationRate.depthFromField?.id,
            depthToFieldId: state.config?.penetrationRate.depthToField?.id,
            valueFieldId: state.config?.penetrationRate.valueField?.id,
          },
          water: {
            waterLevelFieldId: state.config?.water.waterLevelField?.id,
            waterStrikeBottomDepthFieldId:
              state.config?.water.waterStrikeBottomDepthField?.id,
            waterStrikeBottomValueFieldId:
              state.config?.water.waterStrikeBottomValueField?.id,
            waterStrikeTopDepthFieldId:
              state.config?.water.waterStrikeTopDepthField?.id,
            waterStrikeTopValueFieldId:
              state.config?.water.waterStrikeTopValueField?.id,
          },
        };
        break;
    }

    if (!(await doUpdate(updateDefinition))) return;

    publish<LibrarySection>(StateTopicEnum.LibrarySectionRefresh, "paged");
    navigate(routes.settingItem.go("report-templates-library", settingId));

    showAlert({
      content: "Report Template saved.",
      options: {
        type: AlertTypeEnum.Info,
        actions: [{ text: "Ok", primary: true }],
      },
    });
  }, [navigate, settingId, reportTemplate.id, validationRules]);

  const handleConfigChange = useCallback(
    (config: ReportConfigType) => setState({ config }),
    [setState]
  );

  useEffect(() => {
    let toolbarItems: (ToolItem | "|")[] = [];

    toolbarItems = [
      {
        label: "Save",
        icon: FaSave,
        onClick: handleSave,
        primary: true,
        raised: true,
      },
      "|",
      {
        label: "Cancel",
        icon: FaTimes,
        onClick: () => {
          navigate(
            routes.settingItem.go("report-templates-library", settingId)
          );
        },
      },
    ];

    setToolbarConfig({
      allowSearch: true,
      toolbarItems,
      searchPath: routes.descriptors.go(),
    });
  }, [handleSave, navigate, setToolbarConfig, settingId]);

  useEffect(() => {
    const load = async () => {
      const results = await siteTemplatesService.getByPage({
        pageNo: 1,
        pageSize: 1000,
        search: "",
      });

      setSiteTemplates(results.data);
    };
    load();
  }, []);

  useEffect(() => {
    saveState.current = state;
  }, [state]);

  return (
    <>
      <Form title="Identifying Information">
        <InstructionWrapper
          text="An identifying name for the report template."
          error={validation["name"]}
        >
          <TextField
            label="Name"
            required
            value={state.name}
            onChange={(e) => setState({ name: e.target.value })}
            error={validation["name"] ? true : false}
          />
        </InstructionWrapper>
        <InstructionWrapper
          text="The site template(s) that this report template is associated with."
          error={validation["siteTemplates"]}
        >
          <List
            multiple
            value={state.siteTemplates == null ? [] : state.siteTemplates}
            options={siteTemplates}
            getOptionLabel={(o: SiteTemplateSummary) => o.name}
            getOptionValue={(o: SiteTemplateSummary) => o.id}
            isOptionEqualToValue={(
              o: SiteTemplateSummary,
              v: ReferenceSummary
            ) => o.id === v.id}
            label="Site Template(s)"
            onChange={(_, v: SiteTemplateSummary[]) =>
              setState({
                siteTemplates:
                  v.length > 0
                    ? v.map((e) => ({ id: e.id, name: e.name }))
                    : [],
                reportType: undefined,
                config: undefined,
              })
            }
            error={validation["siteTemplates"] ? true : false}
          />
        </InstructionWrapper>
        <InstructionWrapper
          text="The report type to render."
          error={validation["reportType"]}
        >
          <List
            value={state.reportType}
            options={reportTypes}
            getOptionLabel={(o: ReportOption) => o.value}
            getOptionValue={(o: ReportOption) => o.key}
            isOptionEqualToValue={(o: ReportOption, v: ReportOption) =>
              o.key === v.key
            }
            label="Report Type"
            onChange={(_, v: ReportOption) =>
              setState({ reportType: v.key, config: undefined })
            }
            error={validation["reportType"] ? true : false}
          />
        </InstructionWrapper>
      </Form>
      {state.reportType === "LithologyAndBoreholeConstruction" &&
        state.siteTemplates && (
          <LithologyAndBoreholeConstructionConfig
            key="LithologyAndBoreholeConstruction-config"
            siteTemplates={state.siteTemplates}
            config={state.config as LConfig}
            onChange={handleConfigChange}
          />
        )}
    </>
  );
}

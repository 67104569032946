import { GetSingleArgs } from "../interfaces";
import ReferenceSummary from "../interfaces/ReferenceSummary";
import { asyncify } from "../utilities";

interface CoordinateAccuracyTypesService {
  getAllCoordinateAccuracyTypes(): Promise<ReferenceSummary[]>;
  getSingleCoordinateAccuracyType(args: GetSingleArgs): Promise<ReferenceSummary>;
}

let _CACHE: ReferenceSummary[] = [
  {
    id: "NoInformation",
    name: "No Information"
  },
  {
    id: "Within1Unit",
    name: "Accurate to within 1 unit (A)"
  },
  {
    id: "Within10Units",
    name: "Accurate to within 10 units (B)"
  },
  {
    id: "Witnin100Units",
    name: "Accurate to within 100 units (C)"
  },
  {
    id: "Within1kUnits",
    name: "Accurate to within 1 000 units (D)"
  },
  {
    id: "Within10kUnits",
    name: "Accurate to within 10 000 units (F)"
  }
];

const service: CoordinateAccuracyTypesService = {
  async getAllCoordinateAccuracyTypes() {
    return await asyncify(() => _CACHE, 0);
  },

  async getSingleCoordinateAccuracyType({ id }) {
    return (await service.getAllCoordinateAccuracyTypes()).filter((s) => s.id === id)[0];
  },
};

export default service;

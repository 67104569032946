import React, { useCallback } from "react";
import { UnitConversion } from "../../../interfaces/Measurable";
import { TextField } from "../../../components/fields";
import "./_styles.scss";
import { Button } from "../../../components";
import { showModal } from "../../../components/modal/Modal";
import CalculateModel from "./CalculationModal";

interface UnitConversionProps {
  conversion: UnitConversion;
  id: string;
}

export default function UnitConversionItem({
  conversion,
}: UnitConversionProps) {
  const handleAdd = useCallback(async (formula: string) => {
    showModal({
      content: <CalculateModel formula={formula} />,
      options: {
        title: "Conversion Unit Calculation",
        actions: [{ text: "Finish", primary: true }],
      },
    });
  }, []);

  return (
    <>
      <div className="conversion-unit">
        <TextField
          className="conversion-item-flex"
          label="Unit Full"
          value={conversion.unitFull}
          readOnly={true}
        />
        <TextField
          className="conversion-item-flex"
          label="Unit Abbreviation"
          value={conversion.unitAbbreviation}
          readOnly={true}
        />
        <TextField
          className="conversion-item-style"
          label="Formula"
          value={conversion.formula}
          multiline={true}
          readOnly={true}
        />
      </div>
      <Button
        className="conversion-calculate-button"
        primary
        text="Calculate"
        onClick={() => {
          handleAdd(conversion.formula ?? "");
        }}
      />
    </>
  );
}

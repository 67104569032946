import {
  CrudResponse,
  DescriptorFull,
  DescriptorSummary,
  DescriptorType,
  GetSingleArgs,
  PageAndSearchArgs,
} from "../interfaces";
import CrudService from "../interfaces/CrudService";
import { goFetch } from "../utilities/goFetch";

export type DescriptorUpdateActions =
  | "UpdateDetails"
  | "AddDescriptorOption"
  | "RemoveDescriptorOption";

interface AddOrRemoveOption {
  descriptorId: string;
  option: string;
}

interface UpdateDetails {
  name: string;
  type: DescriptorType;
}

interface DescriptorsService {
  create: () => Promise<CrudResponse>;
  update: (
    id: string,
    action: DescriptorUpdateActions,
    definition: AddOrRemoveOption | UpdateDetails
  ) => Promise<DescriptorFull>;
  delete: (id: string) => Promise<CrudResponse>;
}

const controller = "descriptors";

const service: CrudService<DescriptorFull, DescriptorSummary> &
  DescriptorsService = {
  getByPage: async ({ search, pageNo, pageSize }: PageAndSearchArgs) => {
    const response = await goFetch(`${controller}/page/${pageNo}`)
      .withQueryParameters({
        pageSize: pageSize || 50,
        search: search,
      })
      .get();

    return {
      pageNo: response?.pageNo ?? 0,
      pageSize: response?.pageSize ?? 0,
      pageCount: response?.pageCount ?? 0,
      itemCount: response?.itemCount ?? 0,
      data: response?.data ?? [],
    };
  },

  getSingle: async ({ id }: GetSingleArgs) =>
    await goFetch(`${controller}/${id}`).get(),

  create: async () => await goFetch(controller).post(),

  update: async (id, action, definition) =>
    await goFetch(`${controller}/${id}`)
      .withBody({ action, definition })
      .patch(),

  delete: async (id) => await goFetch(`${controller}/${id}`).delete(),
};

export default service;

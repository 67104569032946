import React from "react";
import { Form } from "../../../components";
import { Checkbox, InstructionWrapper } from "../../../components/fields";
import { Permission } from "../../../interfaces";

interface PermissionsProps {
  permissions: Permission[];
  mode: "view" | "edit";
  onChange?: (permission: Permission, checked: boolean) => void;
}

const hasPermission = (permissions: Permission[], permission: Permission) =>
  permissions.filter((p) => p === permission).length ? true : false;

export default function Permissions({
  permissions,
  mode,
  onChange,
}: PermissionsProps) {
  return (
    <>
      <Form title="User Permissions" className="role-permission-block">
        <InstructionWrapper text="The user management permissions this role has access to.">
          <Checkbox
            label="Users Library"
            value={hasPermission(permissions, "UserLibrary")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("UserLibrary", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
          <Checkbox
            label="Add Users"
            value={hasPermission(permissions, "AddUser")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("AddUser", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
          <Checkbox
            label="Edit Users"
            value={hasPermission(permissions, "EditUser")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("EditUser", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
          <Checkbox
            label="Remove Users"
            value={hasPermission(permissions, "RemoveUser")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("RemoveUser", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
        </InstructionWrapper>
      </Form>
      <Form title="Role Permissions" className="role-permission-block">
        <InstructionWrapper text="The role management permissions this role has access to.">
          <Checkbox
            label="Roles Library"
            value={hasPermission(permissions, "RoleLibrary")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("RoleLibrary", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
          <Checkbox
            label="Add Roles"
            value={hasPermission(permissions, "AddRole")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("AddRole", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
          <Checkbox
            label="Edit Roles"
            value={hasPermission(permissions, "EditRole")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("EditRole", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
          <Checkbox
            label="Remove Roles"
            value={hasPermission(permissions, "RemoveRole")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("RemoveRole", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
        </InstructionWrapper>
      </Form>
      <Form title="Measurable Permissions" className="role-permission-block">
        <InstructionWrapper text="The measurable management permissions this role has access to.">
          <Checkbox
            label="Measurables Library"
            value={hasPermission(permissions, "MeasurableLibrary")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("MeasurableLibrary", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
          <Checkbox
            label="Add Measurables"
            value={hasPermission(permissions, "AddMeasurable")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("AddMeasurable", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
          <Checkbox
            label="Edit Measurables"
            value={hasPermission(permissions, "EditMeasurable")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("EditMeasurable", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
          <Checkbox
            label="Remove Measurables"
            value={hasPermission(permissions, "RemoveMeasurable")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("RemoveMeasurable", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
        </InstructionWrapper>
      </Form>
      <Form title="Descriptor Permissions" className="role-permission-block">
        <InstructionWrapper text="The descriptor management permissions this role has access to.">
          <Checkbox
            label="Descriptors Library"
            value={hasPermission(permissions, "DescriptorLibrary")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("DescriptorLibrary", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
          <Checkbox
            label="Add Descriptors"
            value={hasPermission(permissions, "AddDescriptor")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("AddDescriptor", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
          <Checkbox
            label="Edit Descriptors"
            value={hasPermission(permissions, "EditDescriptor")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("EditDescriptor", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
          <Checkbox
            label="Remove Descriptors"
            value={hasPermission(permissions, "RemoveDescriptor")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("RemoveDescriptor", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
        </InstructionWrapper>
      </Form>
      <Form title="Form Permissions" className="role-permission-block">
        <InstructionWrapper text="The form management permissions this role has access to.">
          <Checkbox
            label="Forms Library"
            value={hasPermission(permissions, "FormLibrary")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("FormLibrary", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
          <Checkbox
            label="Add Forms"
            value={hasPermission(permissions, "AddForm")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("AddForm", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
          <Checkbox
            label="Edit Forms"
            value={hasPermission(permissions, "EditForm")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("EditForm", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
          <Checkbox
            label="Remove Forms"
            value={hasPermission(permissions, "RemoveForm")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("RemoveForm", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
        </InstructionWrapper>
      </Form>
      <Form title="Site Template Permissions" className="role-permission-block">
        <InstructionWrapper text="The site template management permissions this role has access to.">
          <Checkbox
            label="Site Templates Library"
            value={hasPermission(permissions, "SiteTemplateLibrary")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("SiteTemplateLibrary", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
          <Checkbox
            label="Add Site Templates"
            value={hasPermission(permissions, "AddSiteTemplate")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("AddSiteTemplate", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
          <Checkbox
            label="Edit Site Templates"
            value={hasPermission(permissions, "EditSiteTemplate")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("EditSiteTemplate", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
          <Checkbox
            label="Remove Site Templates"
            value={hasPermission(permissions, "RemoveSiteTemplate")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("RemoveSiteTemplate", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
        </InstructionWrapper>
      </Form>
      <Form
        title="Monitoring Group Permissions"
        className="role-permission-block"
      >
        <InstructionWrapper text="The monitoring group management permissions this role has access to.">
          <Checkbox
            label="Monitoring Groups Library"
            value={hasPermission(permissions, "MonitoringGroupLibrary")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("MonitoringGroupLibrary", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
          <Checkbox
            label="Add Monitoring Groups"
            value={hasPermission(permissions, "AddMonitoringGroup")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("AddMonitoringGroup", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
          <Checkbox
            label="Edit Monitoring Groups"
            value={hasPermission(permissions, "EditMonitoringGroup")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("EditMonitoringGroup", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
          <Checkbox
            label="Remove Monitoring Groups"
            value={hasPermission(permissions, "RemoveMonitoringGroup")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("RemoveMonitoringGroup", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
        </InstructionWrapper>
      </Form>
      <Form title="Site Permissions" className="role-permission-block">
        <InstructionWrapper text="The site management permissions this role has access to. Note that any permissions defined here will apply to site filters defined for this role.">
          <Checkbox
            label="Sites Library"
            value={hasPermission(permissions, "SiteLibrary")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("SiteLibrary", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
          <Checkbox
            label="Add Sites"
            value={hasPermission(permissions, "AddSite")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("AddSite", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
          <Checkbox
            label="Edit Sites"
            value={hasPermission(permissions, "EditSite")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("EditSite", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
          <Checkbox
            label="Remove Sites"
            value={hasPermission(permissions, "RemoveSite")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("RemoveSite", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
          <Checkbox
            label="Import Sites"
            value={hasPermission(permissions, "ImportSite")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("ImportSite", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
        </InstructionWrapper>
      </Form>
      <Form title="Measurement Permissions" className="role-permission-block">
        <InstructionWrapper text="The measurement management permissions this role has access to. Note that any permissions defined here will apply to site filters defined for this role.">
          <Checkbox
            label="Measurements Library"
            value={hasPermission(permissions, "MeasurementLibrary")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("MeasurementLibrary", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
          <Checkbox
            label="Add Measurements"
            value={hasPermission(permissions, "AddMeasurement")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("AddMeasurement", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
          <Checkbox
            label="Update Measurement Statuses"
            value={hasPermission(permissions, "UpdateMeasurementStatus")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("UpdateMeasurementStatus", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
          <Checkbox
            label="Edit Measurement Values"
            value={hasPermission(permissions, "EditMeasurementValues")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("EditMeasurementValues", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
          <Checkbox
            label="Remove Measurements"
            value={hasPermission(permissions, "RemoveMeasurement")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("RemoveMeasurement", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
          <Checkbox
            label="Import Measurements"
            value={hasPermission(permissions, "ImportMeasurement")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("ImportMeasurement", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
        </InstructionWrapper>
      </Form>
      <Form title="Notes Permissions" className="role-permission-block">
        <InstructionWrapper text="The note management permissions this role has access to.">
          <Checkbox
            label="Add Notes"
            value={hasPermission(permissions, "AddNote")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("AddNote", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
        </InstructionWrapper>
      </Form>
      <Form
        title="Chart Template Permissions"
        className="role-permission-block"
      >
        <InstructionWrapper text="The chart template management permissions this role has access to.">
          <Checkbox
            label="Chart Templates Library"
            value={hasPermission(permissions, "ChartTemplateLibrary")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("ChartTemplateLibrary", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
          <Checkbox
            label="Add Chart Templates"
            value={hasPermission(permissions, "AddChartTemplate")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("AddChartTemplate", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
          <Checkbox
            label="Edit Chart Templates"
            value={hasPermission(permissions, "EditChartTemplate")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("EditChartTemplate", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
          <Checkbox
            label="Remove Chart Templates"
            value={hasPermission(permissions, "RemoveChartTemplate")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("RemoveChartTemplate", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
        </InstructionWrapper>
      </Form>
      <Form title="Chart Permissions" className="role-permission-block">
        <InstructionWrapper text="The chart management permissions this role has access to.">
          <Checkbox
            label="Chart Library"
            value={hasPermission(permissions, "ChartLibrary")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("ChartLibrary", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
          <Checkbox
            label="Build Charts"
            value={hasPermission(permissions, "AddChart")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("AddChart", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
          <Checkbox
            label="Remove Charts"
            value={hasPermission(permissions, "RemoveChart")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("RemoveChart", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
        </InstructionWrapper>
      </Form>
      <Form title="Materials Permissions" className="role-permission-block">
        <InstructionWrapper text="The materials management permissions this role has access to.">
          <Checkbox
            label="Materials Library"
            value={hasPermission(permissions, "MaterialsLibrary")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("MaterialsLibrary", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
          <Checkbox
            label="Modify Materials"
            value={hasPermission(permissions, "ModifyMaterials")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("ModifyMaterials", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
        </InstructionWrapper>
      </Form>
      <Form
        title="Report Template Permissions"
        className="role-permission-block"
      >
        <InstructionWrapper text="The report template management permissions this role has access to.">
          <Checkbox
            label="Report Templates Library"
            value={hasPermission(permissions, "ReportTemplateLibrary")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("ReportTemplateLibrary", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
          <Checkbox
            label="Add Report Templates"
            value={hasPermission(permissions, "AddReportTemplate")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("AddReportTemplate", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
          <Checkbox
            label="Edit Report Templates"
            value={hasPermission(permissions, "EditReportTemplate")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("EditReportTemplate", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
          <Checkbox
            label="Remove Report Templates"
            value={hasPermission(permissions, "RemoveReportTemplate")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("RemoveReportTemplate", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
        </InstructionWrapper>
      </Form>
      <Form title="KMZ Import Permissions" className="role-permission-block">
        <InstructionWrapper text="The KMZ import permissions this role has access to.">
          <Checkbox
            label="Import Process"
            value={hasPermission(permissions, "ImportProcess")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("ImportProcess", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
          <Checkbox
            label="Import Process Items"
            value={hasPermission(permissions, "ImportProcessItems")}
            onChange={
              mode === "edit"
                ? (e) => onChange?.("ImportProcessItems", e.target.checked)
                : undefined
            }
            readOnly={mode !== "edit"}
          />
        </InstructionWrapper>
      </Form>
    </>
  );
}

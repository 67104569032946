import React from "react";
import { TimePicker as MUITimePicker } from "@mui/x-date-pickers";
import { classNameBuilder } from "../../utilities";
import moment from "moment";
import TextField from "./TextField";

interface TimeFieldProps {
  id?: string;
  error?: boolean;
  label?: string;
  value?: Date;
  className?: string;
  readOnly?: boolean;
  disabled?: boolean;
  onChange?: (time: moment.Moment | null) => void;
}

export default function TimeField({
  id,
  error,
  label,
  value,
  className,
  readOnly,
  disabled,
  onChange,
  ...props
}: TimeFieldProps) {
  return (
    <MUITimePicker
      {...props}
      className={classNameBuilder(
        "h-app-time-field",
        readOnly ? "readonly" : "",
        className ?? ""
      )}
      value={moment(value)}
      renderInput={(params) => (
        <TextField id={id} {...params} error={error} readOnly={readOnly} />
      )}
      label={label}
      onChange={onChange ?? (() => {})}
      disabled={disabled}
      readOnly={readOnly}
      ampm={false}
    />
  );
}

import React from "react";
import { FaTrash } from "react-icons/fa";
import Button from "../button/Button";
import { classNameBuilder } from "../../utilities";

interface MultiItemProps<TValue, TComponentProps> {
  type: string;
  values: TValue[];
  itemComponent: (props: TComponentProps) => JSX.Element;
  setComponentProps: (item: TValue, index: number) => TComponentProps;
  extractItemKey: (item: TValue, index: number) => string;
  onItemDelete?: (index: number) => void;
  onItemAdd?: () => void;
  readOnly?: boolean;
  allowAdd?: boolean;
  allowDelete?: boolean;
  noDataMessage?: string;
  className?: string;
}

export default function MultiItem<TValue, TComponentProps>({
  type,
  values,
  itemComponent,
  setComponentProps,
  extractItemKey,
  onItemAdd,
  onItemDelete,
  readOnly,
  noDataMessage,
  allowAdd,
  allowDelete,
  className,
}: MultiItemProps<TValue, TComponentProps>) {
  const ItemComponent = itemComponent;

  return (
    <div
      className={classNameBuilder("h-app-multi-item-field", className ?? "")}
    >
      {!values.length ? (
        <p className="no-data">{noDataMessage || "Nothing to display"}</p>
      ) : (
        values.map((v, i) => (
          <div
            key={extractItemKey(v, i)}
            className="multi-item-component-wrapper"
          >
            <div className="multi-item-component">
              <ItemComponent key="component" {...setComponentProps(v, i)} />
            </div>
            {!readOnly && allowDelete && (
              <Button
                className="multi-item-delete"
                icon={FaTrash}
                primary
                raised
                onClick={() => onItemDelete?.(i)}
              />
            )}
          </div>
        ))
      )}
      {!readOnly && allowAdd && (
        <div className="multi-item-buttons">
          <Button
            className="multi-item-add"
            text={`Add another ${type.toLowerCase()}`}
            primary
            raised
            onClick={() => onItemAdd?.()}
          />
        </div>
      )}
    </div>
  );
}

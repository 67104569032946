import React from "react";
import { useD3 } from "../../hooks";
import Constants from "./Constants";
import { Selection } from "d3";
import {
  addDiamondProjectionAxisLabels,
  addDiamondProjectionGrid,
  addSeriesLegend,
  addTernaryAxisLabels,
  addTernaryGrid,
  calculatePlotHeight,
  calculateRightMargin,
  getSeriesColor,
} from "./utilities";
import { Series } from "./interfaces";
import { groupArray } from "../../utilities";
import Defaults from "./Defaults";

type PiperSeries<TData> = Series<TData> & {
  getElementGroup: (point: TData) => string;
};

interface ElementValue {
  element: string;
  value: number;
}

interface PlotElement {
  elements: ElementValue[];
  plotValue: number;
  relativeValue: number;
}

interface TernaryPlotData {
  aAxis: PlotElement;
  bAxis: PlotElement;
  cAxis: PlotElement;
  x: number;
  y: number;
}

interface DiamondPlotData {
  x: number;
  y: number;
}

interface SeriesPlotData {
  name: string;
  color: string;
  plotData: {
    cationPlot: TernaryPlotData;
    anionPlot: TernaryPlotData;
    projectionPlot: DiamondPlotData;
  }[];
}

interface Styles {
  gridColor?: string;
  cationsBackgroundColor?: string;
  anionsBackgroundColor?: string;
  matrixBackgroundColor?: string;
}

interface PiperChartProps<TData> {
  data: TData[][];
  elements: {
    cations: { a: string[]; b: string[]; c: string[] };
    anions: { a: string[]; b: string[]; c: string[] };
  };
  getElementValue: (pointGroup: TData[], element: string) => number;
  showGrid?: boolean;
  gridCount?: number;
  gridValueIncrements?: "All" | "Even" | "Odd";
  gridValueFormat?: "Fraction" | "Percentage";
  axis?: {
    cationsLabels?: {
      a?: string;
      b?: string;
      c?: string;
    };
    anionsLabels?: {
      a?: string;
      b?: string;
      c?: string;
    };
  };
  series: PiperSeries<TData>;
  styles?: Styles;
}

function addTernary(
  data: SeriesPlotData[],
  axisDataAccessor: (data: SeriesPlotData) => TernaryPlotData[],
  svg: Selection<SVGSVGElement, unknown, null, undefined>,
  baseX: number,
  baseY: number,
  baseLength: number,
  equiHeight: number,
  gridCount: number,
  gridValueIncrements: "All" | "Even" | "Odd",
  gridValueFormat: "Fraction" | "Percentage",
  axisLabels: {
    a: string;
    b: string;
    c: string;
  },
  direction: "clockwise" | "counter-clockwise",
  backgroundColor?: string
) {
  const ternaryY = baseY - equiHeight;

  if (backgroundColor)
    svg
      .select(".plot-area")
      .append("polygon")
      .attr(
        "points",
        `${baseX},${baseY} ${baseX + baseLength},${baseY} ${
          baseX + baseLength / 2
        },${ternaryY}`
      )
      .attr("fill", backgroundColor);

  svg
    .select(".plot-area")
    .append("line")
    .attr("x1", baseX)
    .attr("x2", baseX + baseLength)
    .attr("y1", baseY)
    .attr("y2", baseY)
    .attr("stroke", "#000000")
    .attr("stroke-width", 1);
  svg
    .select(".plot-area")
    .append("line")
    .attr("x1", baseX + baseLength / 2)
    .attr("x2", baseX)
    .attr("y1", ternaryY)
    .attr("y2", baseY)
    .attr("stroke", "#000000")
    .attr("stroke-width", 1);
  svg
    .select(".plot-area")
    .append("line")
    .attr("x1", baseX + baseLength / 2)
    .attr("x2", baseX + baseLength)
    .attr("y1", ternaryY)
    .attr("y2", baseY)
    .attr("stroke", "#000000")
    .attr("stroke-width", 1);

  // Add Grid
  addTernaryGrid(
    svg,
    baseX,
    baseY,
    baseLength,
    equiHeight,
    gridCount,
    gridValueIncrements,
    gridValueFormat,
    direction
  );

  // Add Axis Labels
  addTernaryAxisLabels(
    svg,
    axisLabels,
    baseX,
    baseY,
    baseLength,
    equiHeight,
    direction
  );

  // Plot Points
  const points: {
    color: string;
    x: number;
    y: number;
  }[] = [];

  data.forEach((series) => {
    axisDataAccessor(series).forEach((p) => {
      points.push({
        color: series.color,
        x: p.x,
        y: p.y,
      });
    });
  });

  const pointGrouping = svg.select(".plot-area").append("g");
  pointGrouping
    .selectAll("circle")
    .data(points)
    .enter()
    .append("circle")
    .attr("cx", (p) => baseX + p.x)
    .attr("cy", (p) => baseY - p.y)
    .attr("r", 3)
    .attr("fill", (p) => p.color);
}

function addDiamondMatrix(
  data: SeriesPlotData[],
  svg: Selection<SVGSVGElement, unknown, null, undefined>,
  renderHeight: number,
  renderWidth: number,
  baseXAdjust: number,
  baseLength: number,
  equiHeight: number,
  gridCount: number,
  margin: { bottom: number; ternaryGutter: number },
  gridValueIncrements: "All" | "Even" | "Odd",
  gridValueFormat: "Fraction" | "Percentage",
  axisLabels: {
    a: string;
    b: string;
  },
  backgroundColor?: string
) {
  const ternaryY = renderHeight - margin.bottom - equiHeight;
  const diamondTopY = ternaryY - equiHeight;
  const adjustedGutter = margin.ternaryGutter * 0.85;

  if (backgroundColor)
    svg
      .select(".plot-area")
      .append("polygon")
      .attr(
        "points",
        `${renderWidth / 2 + baseXAdjust},${
          renderHeight - margin.bottom - adjustedGutter
        } ${renderWidth / 2 + baseXAdjust - baseLength / 2},${
          ternaryY - adjustedGutter
        } ${renderWidth / 2 + baseXAdjust},${diamondTopY - adjustedGutter} ${
          renderWidth / 2 + baseXAdjust + baseLength / 2
        },${ternaryY - adjustedGutter}`
      )
      .attr("fill", backgroundColor);

  // Render Diamond Matrix
  svg
    .select(".plot-area")
    .append("line")
    .attr("x1", renderWidth / 2 + baseXAdjust)
    .attr("x2", renderWidth / 2 + baseXAdjust - baseLength / 2)
    .attr("y1", renderHeight - margin.bottom - adjustedGutter)
    .attr("y2", ternaryY - adjustedGutter)
    .attr("stroke", "#000000")
    .attr("stroke-width", 1);
  svg
    .select(".plot-area")
    .append("line")
    .attr("x1", renderWidth / 2 + baseXAdjust)
    .attr("x2", renderWidth / 2 + baseXAdjust + baseLength / 2)
    .attr("y1", renderHeight - margin.bottom - adjustedGutter)
    .attr("y2", ternaryY - adjustedGutter)
    .attr("stroke", "#000000")
    .attr("stroke-width", 1);
  svg
    .select(".plot-area")
    .append("line")
    .attr("x1", renderWidth / 2 + baseXAdjust - baseLength / 2)
    .attr("x2", renderWidth / 2 + baseXAdjust)
    .attr("y1", ternaryY - adjustedGutter)
    .attr("y2", diamondTopY - adjustedGutter)
    .attr("stroke", "#000000")
    .attr("stroke-width", 1);
  svg
    .select(".plot-area")
    .append("line")
    .attr("x1", renderWidth / 2 + baseXAdjust + baseLength / 2)
    .attr("x2", renderWidth / 2 + baseXAdjust)
    .attr("y1", ternaryY - adjustedGutter)
    .attr("y2", diamondTopY - adjustedGutter)
    .attr("stroke", "#000000")
    .attr("stroke-width", 1);

  const baseX = renderWidth / 2 + baseXAdjust - baseLength / 2;
  const baseY = ternaryY - adjustedGutter;

  // Add Grid
  addDiamondProjectionGrid(
    svg,
    baseX,
    baseY,
    diamondTopY,
    adjustedGutter,
    baseLength,
    equiHeight,
    gridCount,
    gridValueIncrements,
    gridValueFormat
  );

  // Add Axis Labels
  addDiamondProjectionAxisLabels(
    svg,
    baseX,
    baseY,
    baseLength,
    equiHeight,
    axisLabels
  );

  // Plot Points
  const points: {
    color: string;
    x: number;
    y: number;
  }[] = [];

  data.forEach((series) => {
    series.plotData.forEach((p) => {
      points.push({
        color: series.color,
        x: p.projectionPlot.x,
        y: p.projectionPlot.y,
      });
    });
  });

  const pointGrouping = svg.select(".plot-area").append("g");
  pointGrouping
    .selectAll("circle")
    .data(points)
    .enter()
    .append("circle")
    .attr("cx", (p) => baseX + p.x)
    .attr("cy", (p) => baseY - p.y)
    .attr("r", 3)
    .attr("fill", (p) => p.color);
}

function extractSeriesData<TData>(
  data: TData[][],
  elements: {
    cations: { a: string[]; b: string[]; c: string[] };
    anions: { a: string[]; b: string[]; c: string[] };
  },
  getElementValue: (pointGroup: TData[], element: string) => number,
  series: PiperSeries<TData>,
  baseLength: number,
  equiHeight: number
): SeriesPlotData[] {
  const extractAxisData = (elements: string[], data: TData[]): PlotElement => {
    let plotValue = 0;
    const axisElements = elements.map((element) => {
      const value = getElementValue(data, element);
      plotValue += value;

      return { element, value };
    });

    return {
      elements: axisElements,
      plotValue,
      relativeValue: -1,
    };
  };

  const relativeValue = (context: PlotElement, others: PlotElement[]) =>
    context.plotValue / others.reduce((acc: number, e) => acc + e.plotValue, 0);

  return data.map((seriesData, seriesIndex) => {
    const grouped = groupArray(seriesData, series.getElementGroup).map(
      (group) => {
        const cationAAxis = extractAxisData(elements.cations.a, group.data);
        const cationBAxis = extractAxisData(elements.cations.b, group.data);
        const cationCAxis = extractAxisData(elements.cations.c, group.data);
        const anionAAxis = extractAxisData(elements.anions.a, group.data);
        const anionBAxis = extractAxisData(elements.anions.b, group.data);
        const anionCAxis = extractAxisData(elements.anions.c, group.data);

        const cationDivisors = [cationAAxis, cationBAxis, cationCAxis];
        const anionDivisors = [anionAAxis, anionBAxis, anionCAxis];

        const cationARelative = relativeValue(cationAAxis, cationDivisors);
        const cationBRelative = relativeValue(cationBAxis, cationDivisors);
        const cationCRelative = relativeValue(cationCAxis, cationDivisors);
        const anionARelative = relativeValue(anionAAxis, anionDivisors);
        const anionBRelative = relativeValue(anionBAxis, anionDivisors);
        const anionCRelative = relativeValue(anionCAxis, anionDivisors);

        const cationX =
          baseLength - (cationBRelative / 2 + cationCRelative) * baseLength;
        const cationY = cationBRelative * equiHeight;
        const anionX = (anionBRelative / 2 + anionCRelative) * baseLength;
        const anionY = anionBRelative * equiHeight;

        const projectedX =
          (anionY + Math.sqrt(3) * anionX - cationY + Math.sqrt(3) * cationX) /
          (2 * Math.sqrt(3));
        const projectedY =
          Math.sqrt(3) * projectedX + cationY - Math.sqrt(3) * cationX;

        return {
          cationPlot: {
            aAxis: {
              ...cationAAxis,
              relativeValue: cationARelative,
            },
            bAxis: {
              ...cationBAxis,
              relativeValue: cationBRelative,
            },
            cAxis: {
              ...cationCAxis,
              relativeValue: cationCRelative,
            },
            x: cationX,
            y: cationY,
          },
          anionPlot: {
            aAxis: {
              ...anionAAxis,
              relativeValue: anionARelative,
            },
            bAxis: {
              ...anionBAxis,
              relativeValue: anionBRelative,
            },
            cAxis: {
              ...anionCAxis,
              relativeValue: anionCRelative,
            },
            x: anionX,
            y: anionY,
          },
          projectionPlot: {
            x: projectedX,
            y: projectedY,
          },
        };
      }
    );

    return {
      name:
        series?.getSeriesName?.(seriesData[0], seriesIndex) ??
        `Series ${seriesIndex + 1}`,
      color: getSeriesColor(seriesData[0], seriesIndex, series),
      plotData: grouped,
    } as SeriesPlotData;
  });
}

export default function PiperChart<TData>({
  data,
  elements,
  getElementValue,
  showGrid,
  gridCount,
  gridValueIncrements,
  gridValueFormat,
  series,
  styles,
  axis,
}: PiperChartProps<TData>) {
  const ref = useD3(
    (svg) => {
      const { renderWidth, renderHeight, plotWidth } = Constants;
      const seriesBottomHeight =
        renderHeight - calculatePlotHeight(data, series, 0, 0);
      const margin = {
        top: 0,
        left:
          data.length > 1 && series?.location === "Right"
            ? 50
            : 50 + Math.log(seriesBottomHeight) * 30,
        right:
          series?.location === "Bottom"
            ? 0
            : calculateRightMargin(data, [], series) - 70,
        ternaryGutter: 90,
        bottom: 60 + (seriesBottomHeight ? 20 + seriesBottomHeight : 0),
      };
      const baseLength =
        renderWidth / 2 - margin.left - margin.right - margin.ternaryGutter / 2;
      const equiHeight = (baseLength * Math.sqrt(3)) / 2;
      const grids = showGrid ? gridCount ?? 5 : 0;

      const seriesData = extractSeriesData(
        data,
        elements,
        getElementValue,
        series,
        baseLength,
        equiHeight
      );

      // Cation (Left) Ternary
      addTernary(
        seriesData,
        (d) => d.plotData.map((p) => p.cationPlot),
        svg,
        margin.left,
        renderHeight - margin.bottom,
        baseLength,
        equiHeight,
        grids,
        gridValueIncrements ?? "All",
        gridValueFormat ?? "Percentage",
        {
          a: axis?.cationsLabels?.a || elements.cations.a.join(" + "),
          b: axis?.cationsLabels?.b || elements.cations.b.join(" + "),
          c: axis?.cationsLabels?.c || elements.cations.c.join(" + "),
        },
        "clockwise",
        styles?.cationsBackgroundColor
      );

      // Anion (Right) Ternary
      addTernary(
        seriesData,
        (d) => d.plotData.map((p) => p.anionPlot),
        svg,
        margin.left + baseLength + margin.ternaryGutter,
        renderHeight - margin.bottom,
        baseLength,
        equiHeight,
        grids,
        gridValueIncrements ?? "All",
        gridValueFormat ?? "Percentage",
        {
          a: axis?.anionsLabels?.a || elements.anions.a.join(" + "),
          b: axis?.anionsLabels?.b || elements.anions.b.join(" + "),
          c: axis?.anionsLabels?.c || elements.anions.c.join(" + "),
        },
        "counter-clockwise",
        styles?.anionsBackgroundColor
      );

      // Diamond Matrix
      addDiamondMatrix(
        seriesData,
        svg,
        renderHeight,
        renderWidth,
        -margin.right,
        baseLength,
        equiHeight,
        grids,
        margin,
        gridValueIncrements ?? "All",
        gridValueFormat ?? "Percentage",
        {
          a: [
            ...(axis?.cationsLabels?.c
              ? [axis.cationsLabels.c]
              : elements.cations.c),
            ...(axis?.cationsLabels?.b
              ? [axis.cationsLabels.b]
              : elements.cations.b),
          ].join(" + "),
          b: [
            ...(axis?.anionsLabels?.b
              ? [axis.anionsLabels.b]
              : elements.anions.b),
            ...(axis?.anionsLabels?.c
              ? [axis.anionsLabels.c]
              : elements.anions.c),
          ].join(" + "),
        },
        styles?.matrixBackgroundColor
      );

      // Series Legend
      if (data.length > 1) addSeriesLegend(svg, series, data, plotWidth, 0);
    },
    [data]
  );

  return (
    <svg
      ref={ref}
      className="h-app-chart-piper"
      viewBox={`0 0 ${Constants.renderWidth} ${Constants.renderHeight}`}
      height={Constants.renderHeight}
      width="100%"
      preserveAspectRatio="xMidYMid meet"
    >
      <Defaults />
      <defs>
        <marker
          id="arrowheadright"
          markerWidth="10"
          markerHeight="13"
          refX="0"
          refY="6.5"
          orient="auto"
        >
          <polygon points="0 0, 10 6.5, 0 13" />
        </marker>
        <marker
          id="arrowheadleft"
          markerWidth="10"
          markerHeight="13"
          refX="0"
          refY="6.5"
          orient="auto"
        >
          <polygon points="0 6.5, 10 0, 10 13" />
        </marker>
      </defs>
      <g className="plot-area" />
      <g className="series" />
    </svg>
  );
}

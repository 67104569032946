import React, { useCallback, useEffect, useMemo } from "react";
import { Form } from "../../../components";
import { ChartMeasurementFieldOptions, StiffConfig } from "../../../interfaces";
import ReferenceSummary from "../../../interfaces/ReferenceSummary";
import {
  ColorPicker,
  InstructionWrapper,
  RadioButtons,
  SearchAndChips,
  TextField,
} from "../../../components/fields";
import { useStateReducer } from "../../../hooks";
import getOrderedMeasurables, {
  OrderedMeasurable,
} from "../utilities/getOrderedMeasurables";
import { clone, validateObject } from "../../../utilities";
import chartMeasurementFieldToOptions from "../utilities/chartMeasurementFieldToOptions";
import { Validation } from "../../../utilities/validateObject";

interface StiffChartConfigProps {
  siteTemplates: ReferenceSummary[];
  config?: StiffConfig;
  onChange?: (config: StiffConfig) => void;
  readOnly?: boolean;
}

interface State {
  cationElements?: OrderedMeasurable[];
  anionElements?: OrderedMeasurable[];
  polygonColor?: string;
  diagramLabel?: ChartMeasurementFieldOptions;
  diagramSubLabel?: ChartMeasurementFieldOptions;
  xAxisUnitLabel?: string;
}

const groupingOptions = chartMeasurementFieldToOptions();

export default function StiffChartConfig({
  siteTemplates,
  config,
  onChange,
  readOnly,
}: StiffChartConfigProps) {
  const [state, setState] = useStateReducer<State>({
    cationElements: config?.cationElements ?? [],
    anionElements: config?.anionElements ?? [],
    diagramLabel: config?.diagramLabel ?? "SiteName",
    diagramSubLabel: config?.diagramSubLabel ?? "MeasurementTimestamp",
    polygonColor: config?.polygonColor,
    xAxisUnitLabel: config?.xAxisUnitLabel,
  });
  const validationRules: Validation = useMemo(
    () => ({
      cationElements: {
        func: (m: ReferenceSummary[]) => m.length > 0,
        message: "This field is required.",
      },
      anionElements: {
        func: (m: ReferenceSummary[]) => m.length > 0,
        message: "This field is required.",
      },
    }),
    []
  );
  const validation = validateObject(validationRules, state);

  const handleMeasurableSearch = useCallback(
    async (search: string) =>
      await getOrderedMeasurables(
        siteTemplates.map((e) => e.id),
        search
      ),
    [siteTemplates]
  );

  const handleMeasurableAdd = useCallback(
    (
      measurable: ReferenceSummary,
      elements: "cationElements" | "anionElements"
    ) => {
      const measurables = clone(state[elements] ?? []);
      measurables.push(measurable);
      setState({ [elements]: measurables });
    },
    [state, setState]
  );

  const handleMeasurableRemove = useCallback(
    (id: string, elements: "cationElements" | "anionElements") => {
      const measurables = clone(state[elements] ?? []);
      measurables.splice(
        measurables.findIndex((m) => m.id === id),
        1
      );
      setState({ [elements]: measurables });
    },
    [state, setState]
  );

  useEffect(() => {
    if (readOnly) return;

    onChange?.({
      cationElements: state.cationElements ?? [],
      anionElements: state.anionElements ?? [],
      diagramLabel: state.diagramLabel ?? "SiteName",
      diagramSubLabel: state.diagramSubLabel ?? "MeasurementTimestamp",
      polygonColor: state.polygonColor,
      xAxisUnitLabel: state.xAxisUnitLabel,
    });
  }, [state, readOnly, onChange]);

  return (
    <>
      <Form title="Dataset Configuration">
        <InstructionWrapper
          text="The measurables that are used for the Cations side of the plot."
          error={validation["cationElements"]}
        >
          <SearchAndChips
            label="Cation Elements"
            values={state.cationElements}
            getOptionLabel={(o) => o.name}
            getOptionValue={(o) => o.id}
            onSearch={readOnly ? undefined : handleMeasurableSearch}
            onSelect={
              readOnly
                ? undefined
                : (m) => handleMeasurableAdd(m, "cationElements")
            }
            onRemove={
              readOnly
                ? undefined
                : (_l, id) =>
                    handleMeasurableRemove(id.toString(), "cationElements")
            }
            error={validation["cationElements"] ? true : false}
            readOnly={readOnly}
          />
        </InstructionWrapper>
        <InstructionWrapper
          text="The measurables that are used for the Anions side of the plot."
          error={validation["anionElements"]}
        >
          <SearchAndChips
            label="Anion Elements"
            values={state.anionElements}
            getOptionLabel={(o: ReferenceSummary) => o.name}
            getOptionValue={(o: ReferenceSummary) => o.id}
            onSearch={readOnly ? undefined : handleMeasurableSearch}
            onSelect={
              readOnly
                ? undefined
                : (m: ReferenceSummary) =>
                    handleMeasurableAdd(m, "anionElements")
            }
            onRemove={
              readOnly
                ? undefined
                : (_l, id) =>
                    handleMeasurableRemove(id.toString(), "anionElements")
            }
            error={validation["anionElements"] ? true : false}
            readOnly={readOnly}
          />
        </InstructionWrapper>
      </Form>
      <Form title="Chart Configuration">
        <InstructionWrapper text="The title used for each plot on the chart.">
          <RadioButtons
            horizontal
            label="Title"
            items={groupingOptions}
            value={state.diagramLabel}
            onChange={
              readOnly
                ? undefined
                : (_, diagramLabel: ChartMeasurementFieldOptions) =>
                    setState({ diagramLabel })
            }
            readOnly={readOnly}
          />
        </InstructionWrapper>
        <InstructionWrapper text="The sub-title used for each plot on the chart.">
          <RadioButtons
            horizontal
            label="Sub-title"
            items={groupingOptions}
            value={state.diagramSubLabel}
            onChange={
              readOnly
                ? undefined
                : (_, diagramSubLabel: ChartMeasurementFieldOptions) =>
                    setState({ diagramSubLabel })
            }
            readOnly={readOnly}
          />
        </InstructionWrapper>
        <InstructionWrapper text="The unit label displayed for each plot on the chart.">
          <TextField
            label="Unit"
            value={state.xAxisUnitLabel}
            onChange={
              readOnly
                ? undefined
                : (e) => setState({ xAxisUnitLabel: e.target.value })
            }
            readOnly={readOnly}
          />
        </InstructionWrapper>
      </Form>
      <Form title="Styling">
        <InstructionWrapper
          text="Colours and styling applied to the elements of the chart."
          row
        >
          <ColorPicker
            label="Polygon Colour"
            value={state.polygonColor ?? ""}
            onChange={
              readOnly
                ? undefined
                : (polygonColor) => setState({ polygonColor })
            }
            readOnly={readOnly}
          />
        </InstructionWrapper>
      </Form>
    </>
  );
}

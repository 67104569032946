import { Module } from "../../interfaces";
import { routes } from "../../_config";
import {
  BiAbacus,
  BiLineChart,
  BiMapAlt,
  BiMapPin,
  BiSolidFilePdf,
} from "react-icons/bi";

const modules: Module[] = [
  {
    id: "site-management",
    name: "Sites",
    icon: BiMapPin,
    route: routes.sites,
    permission: "SiteLibrary",
  },
  {
    id: "measurements-management",
    name: "Measurements",
    icon: BiAbacus,
    route: routes.measurements,
    permission: "MeasurementLibrary",
  },
  {
    id: "map-view",
    name: "Map",
    icon: BiMapAlt,
    route: routes.map,
  },
  {
    id: "chart-management",
    name: "Charts",
    icon: BiLineChart,
    route: routes.charts,
    permission: "ChartLibrary",
  },
  {
    id: "report-builder",
    name: "Report Builder",
    icon: BiSolidFilePdf,
    route: routes.reportBuilder,
  },
];

export default modules;

import {
  ReportTemplateFull,
  ReportType,
  CrudResponse,
  GetSingleArgs,
  PageAndSearchArgs,
  ReportTemplateSummary,
  CoordinateReferenceSystem,
  CoordinateDatum,
} from "../interfaces";
import CrudService from "../interfaces/CrudService";
import { goFetch } from "../utilities/goFetch";

export interface LithologyAndBoreholeConstructionConfigUpdate {
  siteDetails?: {
    collarHeightFieldId?: string;
    coordinates?: {
      ellipsoid?: CoordinateReferenceSystem;
      datum?: CoordinateDatum;
    };
  };
  lithology?: {
    depthFromFieldId?: string;
    depthToFieldId?: string;
    typeFieldId?: string;
    attributeFieldIds?: string[];
  };
  penetrationRate?: {
    depthFromFieldId?: string;
    depthToFieldId?: string;
    valueFieldId?: string;
  };
  construction?: {
    depthFromFieldId?: string;
    depthToFieldId?: string;
    innerRadiusFieldId?: string;
    outerRadiusFieldId?: string;
    typeFieldId?: string;
  };
  casing?: {
    depthFromFieldId?: string;
    depthToFieldId?: string;
    radiusFieldId?: string;
    typeFieldId?: string;
  };
  electricalConductivity?: {
    depthFieldId?: string;
    valueFieldId?: string;
  };
  water?: {
    waterLevelFieldId?: string;
    waterStrikeTopDepthFieldId?: string;
    waterStrikeTopValueFieldId?: string;
    waterStrikeBottomDepthFieldId?: string;
    waterStrikeBottomValueFieldId?: string;
  };
}

export interface ReportTemplateUpdateDefinition {
  name?: string;
  type: ReportType;
  siteTemplateIds: string[];
  config?: LithologyAndBoreholeConstructionConfigUpdate;
}

interface ReportTemplatesService {
  getBySiteTemplate: (
    siteTemplateId: string
  ) => Promise<ReportTemplateSummary[]>;
  create: () => Promise<CrudResponse>;
  update: (
    id: string,
    definition: ReportTemplateUpdateDefinition
  ) => Promise<ReportTemplateFull>;
  delete: (id: string) => Promise<CrudResponse>;
}

const controller = "report-templates";

const service: CrudService<ReportTemplateFull, ReportTemplateSummary> &
  ReportTemplatesService = {
  getByPage: async ({ search, pageNo, pageSize }: PageAndSearchArgs) => {
    const response = await goFetch(`${controller}/page/${pageNo}`)
      .withQueryParameters({
        pageSize: pageSize || 50,
        search: search,
      })
      .get();

    return {
      pageNo: response?.pageNo ?? 0,
      pageSize: response?.pageSize ?? 0,
      pageCount: response?.pageCount ?? 0,
      itemCount: response?.itemCount ?? 0,
      data: response?.data ?? [],
    };
  },

  getSingle: async ({ id }: GetSingleArgs) =>
    await goFetch(`${controller}/${id}`).get(),

  getBySiteTemplate: async (siteTemplateId) =>
    await goFetch(`${controller}/by-site-template/${siteTemplateId}`).get(),

  create: async () => await goFetch(controller).post(),

  update: async (id, definition) =>
    await goFetch(`${controller}/${id}`).withBody(definition).patch(),

  delete: async (id) => await goFetch(`${controller}/${id}`).delete(),
};

export default service;

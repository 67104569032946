import React from "react";
import { AxisSettings, Series } from "./interfaces";
import LineChart from "./LineChart";

interface SchoellerChartProps<TData> {
  data: TData[][];
  getPointValue: (point: TData) => number;
  getPointLabel: (point: TData) => string;
  showGrid?: boolean;
  axis?: AxisSettings;
  series?: Series<TData>;
  styles?: {
    gridColor?: string;
  };
}

export default function SchoellerChart<TData>({
  data,
  getPointValue,
  getPointLabel,
  showGrid,
  axis,
  styles,
  series,
}: SchoellerChartProps<TData>) {
  return (
    <LineChart
      data={data}
      formatPointValue={(v) => `${v}`}
      getPointLabel={getPointLabel}
      getPointValue={getPointValue}
      axis={{ ...(axis ?? {}), y: { ...(axis?.y ?? {}), scale: "Log" } }}
      series={series}
      showGrid={showGrid}
      styles={styles}
      padDataWithEmpty
    />
  );
}

import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useStateReducer } from "../../hooks";
import { NavigableComponent, MonitoringGroupFull } from "../../interfaces";
import { FaHandPointer } from "react-icons/fa";
import { getQueryParameter } from "../../utilities";
import { routes } from "../../_config";
import { LoadingIndicator, Scrollable } from "../../components";
import EditMonitoringGroupPage from "./components/EditMonitoringGroup";
import ViewMonitoringGroupPage from "./components/ViewMonitoringGroup";
import { monitoringGroupsService } from "../../services";

interface MonitoringGroupsItemPageProps {
  index?: boolean;
  onLoad: () => void;
}

interface State {
  monitoringGroup?: MonitoringGroupFull | null;
  isSaveDisabled?: boolean;
  loading?: boolean;
}

export default function MonitoringGroupsItemPage({
  setBreadcrumbs,
  setToolbarConfig,
  index,
  onLoad,
}: NavigableComponent & MonitoringGroupsItemPageProps) {
  const { settingId } = useParams();
  const [state, setState] = useStateReducer<State>({
    isSaveDisabled: true,
    loading: true,
  });
  const { monitoringGroup, loading } = state;
  const location = useLocation();
  const mode = getQueryParameter(location.search, "mode");

  useEffect(() => {
    const load = async () => {
      const _monitoringGroup =
        mode === "edit"
          ? await monitoringGroupsService.getSingle({
              id: settingId ?? "",
            })
          : await monitoringGroupsService.getCurrent({
              id: settingId ?? "",
            });

      setState({ monitoringGroup: _monitoringGroup, loading: false });
      onLoad();

      setBreadcrumbs(
        index
          ? [
              { text: "Settings", to: routes.settings.go() },
              { text: "Monitoring Groups" },
            ]
          : [
              { text: "Settings", to: routes.settings.go() },
              { text: "Monitoring Groups", to: routes.monitoringGroups.raw },
              { text: _monitoringGroup.name },
            ]
      );
    };

    setState({ monitoringGroup: null, isSaveDisabled: true, loading: true });
    load();
  }, [settingId, mode, index, setState, setBreadcrumbs, onLoad]);

  let content = <></>;

  if (index)
    content = (
      <>
        <FaHandPointer /> Select a monitoring group on the left.
      </>
    );
  else if (!monitoringGroup || loading) content = <LoadingIndicator />;
  else
    content = (
      <Scrollable>
        {mode === "edit" || mode === "add" ? (
          <EditMonitoringGroupPage
            setToolbarConfig={setToolbarConfig}
            setBreadcrumbs={setBreadcrumbs}
            monitoringGroup={monitoringGroup}
          />
        ) : (
          <ViewMonitoringGroupPage
            setToolbarConfig={setToolbarConfig}
            setBreadcrumbs={setBreadcrumbs}
            monitoringGroup={monitoringGroup}
          />
        )}
      </Scrollable>
    );

  return content;
}

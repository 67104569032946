import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  PatternKey,
  Patterns,
} from "../../../components/svg-patterns/Patterns";
import { TextField } from "../../../components/fields";
import SvgPattern from "../../../components/svg-patterns/SvgPattern";
import { Button, Clickable } from "../../../components";
import { MdPhotoLibrary } from "react-icons/md";
import { hideModal, showModal } from "../../../components/modal/Modal";
import { Pagination } from "@mui/material";
import { classNameBuilder } from "../../../utilities";

interface MaterialPickerProps {
  material?: PatternKey;
  onChange?: (material: PatternKey) => void;
  readOnly?: boolean;
}

export default function MaterialPicker({
  material,
  onChange,
  readOnly,
}: MaterialPickerProps) {
  const selectedMaterial = useRef<PatternKey | undefined>(material);

  const handleLibraryOpen = useCallback(
    () =>
      showModal({
        content: (
          <MaterialLibraryModel
            selected={material}
            handleChange={(m) => {
              selectedMaterial.current = m;
            }}
          />
        ),
        options: {
          title: "Materials Library",
          disableBodyScrolling: true,
          actions: [
            {
              text: "Ok",
              onClick: () => {
                if (selectedMaterial.current)
                  onChange?.(selectedMaterial.current);

                hideModal();
              },
              primary: true,
            },
            { text: "Cancel", onClick: hideModal },
          ],
        },
      }),
    [material, onChange]
  );

  return (
    <TextField
      className="h-app-material-picker"
      label="Material"
      startAdornment={
        material ? (
          <SvgPattern
            className="preview"
            pattern={material}
            fill="transparent"
            height={40}
            width={40}
            scale={2}
          />
        ) : (
          <span className="no-material" />
        )
      }
      endAdornment={
        !readOnly ? (
          <Button
            className="material-library"
            icon={MdPhotoLibrary}
            primary
            onClick={handleLibraryOpen}
          />
        ) : undefined
      }
      readOnly
      value={material ?? "None"}
    />
  );
}

const MaterialLibraryModel = ({
  selected,
  handleChange,
}: {
  selected?: PatternKey;
  handleChange?: (material: PatternKey) => void;
}) => {
  const pageSize = useMemo(() => 21, []);
  const patterns = useMemo(() => Patterns, []);
  const pageCount = useMemo(() => {
    const count = Object.keys(patterns).length;
    const pages = Math.floor(count / pageSize);
    const remainder = count % pageSize;

    return pages + (remainder > 0 ? 1 : 0);
  }, [patterns, pageSize]);

  const [pageNo, setPageNo] = useState(1);
  const [isSelected, setIsSelected] = useState(selected);

  const data = useMemo(() => {
    const start = (pageNo - 1) * pageSize;
    const keys = Object.keys(patterns).slice(start, start + pageSize);

    return keys.map((key) => key as PatternKey);
  }, [patterns, pageSize, pageNo]);

  useEffect(() => {
    if (isSelected) handleChange?.(isSelected);
  }, [isSelected, handleChange]);

  return (
    <div className="materials-library-model">
      <div className="materials">
        {data.map((d) => (
          <Clickable
            className={classNameBuilder(
              "pattern",
              d === isSelected ? "selected" : ""
            )}
            key={`pattern-${d}`}
            onClick={() => setIsSelected(d)}
          >
            <SvgPattern
              pattern={d}
              fill="transparent"
              height={85}
              width={85}
              scale={1.5}
            />
          </Clickable>
        ))}
      </div>
      <Pagination
        className="pager"
        shape="rounded"
        size="small"
        page={pageNo}
        count={pageCount}
        color="primary"
        siblingCount={0}
        boundaryCount={1}
        showFirstButton
        showLastButton
        onChange={(_, pageNo) => setPageNo(pageNo)}
      />
    </div>
  );
};

import React, { useMemo } from "react";
import {
  InstructionWrapper,
  List,
  RadioButtons,
  TextField,
} from "../../../components/fields";
import { ChartMeasurementFieldOptions } from "../../../interfaces";
import chartMeasurementFieldToOptions from "../utilities/chartMeasurementFieldToOptions";
import moment from "moment";
import { classNameBuilder } from "../../../utilities";

interface LabelSelectorProps {
  instructionText: string;
  label: string;
  value?: ChartMeasurementFieldOptions;
  format?: string;
  readOnly?: boolean;
  onChange?: (value: ChartMeasurementFieldOptions) => void;
  onFormatChange?: (value: string) => void;
}

interface DateFormatOption {
  label: string;
  value: string;
  example: string;
}

const groupingOptions = chartMeasurementFieldToOptions();
const dateFormatOptions = () => {
  const now = moment();

  return [
    { label: "Year only", value: "YYYY", example: now.format("YYYY") },
    {
      label: "Year and month number",
      value: "YYYY/MM",
      example: now.format("YYYY/MM"),
    },
    {
      label: "Year, month and day",
      value: "YYYY/MM/DD",
      example: now.format("YYYY/MM/DD"),
    },
    {
      label: "Year, month and day with time",
      value: "YYYY/MM/DD HH:mm:ss",
      example: now.format("YYYY/MM/DD HH:mm:ss"),
    },
    {
      label: "Month name and year",
      value: "MMM YYYY",
      example: now.format("MMM YYYY"),
    },
    {
      label: "Day, month name and year",
      value: "DD MMM YYYY",
      example: now.format("DD MMM YYYY"),
    },
    {
      label: "Day, month name and year with time",
      value: "DD MMM YYYY, HH:mm:ss",
      example: now.format("DD MMM YYYY, HH:mm:ss"),
    },
  ];
};
const getDisplay = (value: string) =>
  dateFormatOptions().find((f) => f.value === value);

export default function LabelSelector({
  instructionText,
  label,
  value,
  format,
  readOnly,
  onChange,
  onFormatChange,
}: LabelSelectorProps) {
  const formatOptions = useMemo(dateFormatOptions, []);

  return (
    <InstructionWrapper text={instructionText}>
      <RadioButtons
        horizontal
        label={label}
        items={groupingOptions}
        value={value}
        onChange={
          readOnly || !onChange
            ? undefined
            : (_, groupingLabel: ChartMeasurementFieldOptions) =>
                onChange(groupingLabel)
        }
        readOnly={readOnly}
      />
      {value === "MeasurementTimestamp" ? (
        readOnly ? (
          <TextField
            label="Format"
            value={format ? getDisplay(format)?.label : "Not Specified"}
            readOnly
          />
        ) : (
          <List
            label="Format"
            options={formatOptions}
            getOptionLabel={(o: DateFormatOption) => o.label}
            getOptionValue={(o: DateFormatOption) => o.value}
            optionRenderer={(props, o: DateFormatOption) => (
              <li
                {...props}
                className={classNameBuilder(
                  props.className ?? "",
                  "label-selector-with-format-example"
                )}
              >
                <span className="label-selector-value">{o.label}</span>
                <span className="label-selector-format">{o.example}</span>
              </li>
            )}
            isOptionEqualToValue={(o: DateFormatOption, v: DateFormatOption) =>
              o.value === v.value
            }
            value={format}
            onChange={(_, o: DateFormatOption) => onFormatChange?.(o.value)}
          />
        )
      ) : undefined}
    </InstructionWrapper>
  );
}

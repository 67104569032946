import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import securityService from "./services/securityService";
import { contentTypes, configureGoFetch } from "./utilities/goFetch";
import { showAlert } from "./components/alert/Alert";
import { AlertTypeEnum } from "./enums";
import { global } from "./_config";
import mapboxgl from "mapbox-gl";

const apiBase = process.env.REACT_APP_API_ROOT;

configureGoFetch({
  appRoot: apiBase,
  corsMode: "cors",
  credentials: "omit",
  headers: securityService.checkAuthentication().user
    ? {
        Authorization: `Bearer ${securityService.getAuthToken()}`,
        "Content-Type": contentTypes.json,
      }
    : { "Content-Type": contentTypes.json },
  defaultResponseParser: async (response, customErrorHandler) => {
    switch (response.status) {
      case 401:
        await securityService.logout();
        return null;
      case 204:
        return null;
      case 200:
        const responseText = await response.text();
        try {
          return JSON.parse(responseText);
        } catch (e) {
          return responseText;
        }
      default:
        let message = `Could not retrieve data: ${response.statusText}`;

        try {
          const json = await response.json();

          if (json.message) message = json.message;

          if (customErrorHandler) {
            customErrorHandler(message);
            return;
          }

          if (/([a-zA-Z]+\.?)+:\s.+/.test(message))
            message = message.substring(message.indexOf(":") + 2);
        } catch (e) {}

        showAlert({
          content: message,
          options: {
            type: AlertTypeEnum.Error,
            actions: [
              {
                text: "Ok",
                primary: true,
              },
            ],
          },
        });

        return null;
    }
  },
  defaultErrorHandler: (err, _, reject) => {
    return reject(err);
  },
});

//@ts-ignore
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default; // eslint-disable-line
mapboxgl.accessToken = global.tokens.mapBox;

const root = ReactDOM.createRoot(
  document.getElementById("root") ?? document.getElementsByTagName("body")[0]
);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

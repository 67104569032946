import React, { useRef, useState } from "react";
import { publish, useStateReducer, useSubscription } from "../../hooks";
import { classNameBuilder } from "../../utilities";
import { Scrollable, Button } from "../";
import "./_styles.scss";
import { ButtonBase } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { StateTopicEnum } from "../../enums";
import { AlertProps } from "../alert/Alert";
import { IconType } from "react-icons";

const modalTopic = "__modal_internal";
const modalActionsTopic = "__modal_actions_internal";

interface ModalProps {
  content: React.ReactNode;
  options?: ModalOptions;
}

interface ModalOptions {
  title?: string;
  className?: string;
  actions?: ModalAction[];
  disableBodyScrolling?: boolean;
  disallowOutsideClick?: boolean;
  clearContentBeforeClose?: boolean;
  fullScreen?: boolean;
}

interface ModalAction {
  text?: string;
  primary?: boolean;
  disabled?: boolean;
  onClick?: Function;
  icon?: IconType;
}

interface State {
  modal?: ModalProps | null;
  closing?: boolean;
  allowCoverClick?: boolean;
  alert?: boolean;
  content?: React.ReactNode;
}

function ModalActions({ actions }: { actions: ModalAction[] }) {
  const [state, setState] = useState(actions);

  useSubscription<ModalAction[]>(modalActionsTopic, (modalActions) => {
    setState(
      state.map((a, i) => ({
        ...a,
        ...modalActions[i],
      }))
    );
  });

  return (
    <div className="modal-actions">
      {state.map((a, i) => (
        <Button
          key={`modal-action-${i}`}
          onClick={(e: any) => a.onClick?.(e) ?? hideModal()}
          text={a.text}
          primary={a.primary}
          raised={a.primary}
          disabled={a.disabled}
          icon={a.icon}
        />
      ))}
    </div>
  );
}

export default function Modal() {
  const [state, setState] = useStateReducer<State>({
    modal: null,
    closing: false,
    allowCoverClick: true,
    alert: false,
    content: null,
  });

  const { modal, allowCoverClick, closing, alert, content } = state;
  const closingTimeout = useRef<NodeJS.Timeout | undefined>();

  useSubscription<ModalProps>(modalTopic, (modal) => {
    if (!modal) return handleClose();

    setState({ modal, content: modal.content });
    return;
  });

  useSubscription<AlertProps>(StateTopicEnum.Alert, (alert) =>
    setState({ alert: alert ? true : false })
  );

  const handleClose = () => {
    if (closingTimeout?.current) clearTimeout(closingTimeout.current);

    publish(StateTopicEnum.Modal, false);

    const stateToSet: { closing: boolean; content?: React.ReactNode } = {
      closing: true,
    };

    if (modal?.options?.clearContentBeforeClose) stateToSet.content = null;

    setState(stateToSet);

    closingTimeout.current = setTimeout(() => {
      setState({ closing: false, modal: null, content: null });
    }, 550);
  };

  const handleCoverClick = () => {
    if (!allowCoverClick || modal?.options?.disallowOutsideClick) return;

    handleClose();
  };

  return modal ? (
    <div
      className={classNameBuilder(
        "h-app-modal-cover",
        closing ? "closing" : "",
        alert ? "has-alert" : ""
      )}
      onClick={handleCoverClick}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={classNameBuilder(
          "h-app-modal",
          modal.options?.fullScreen ? "modal-full-screen" : "",
          modal.options?.className ?? ""
        )}
      >
        <h1 className="modal-title">
          {modal.options?.title}
          <ButtonBase onClick={handleClose} className="close-modal">
            <FontAwesomeIcon icon={faTimes} />
          </ButtonBase>
        </h1>
        <div className="modal-body">
          {modal.options?.disableBodyScrolling ? (
            content
          ) : (
            <Scrollable>{content}</Scrollable>
          )}
        </div>
        {modal.options?.actions?.length ? (
          <ModalActions actions={modal.options.actions} />
        ) : null}
      </div>
    </div>
  ) : null;
}

export function showModal({ content, options }: ModalProps) {
  publish(StateTopicEnum.Modal, true);
  publish(modalTopic, { content, options });
}

export function hideModal() {
  publish(modalTopic, false);
}

export function updateModalActionState(modalActions: ModalAction[]) {
  publish(modalActionsTopic, modalActions);
}

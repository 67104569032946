import { Selection, scaleLinear, scaleLog } from "d3";
import { Guide } from "../interfaces";

export default function addChartGuides(
  svg: Selection<SVGSVGElement, unknown, null, undefined>,
  guides: Guide[],
  margin: { top: number; left: number; right: number },
  min: number,
  max: number,
  width: number,
  height: number,
  scale: "Log" | "Linear"
) {
  const yAxisScale = (scale === "Log" ? scaleLog() : scaleLinear())
    .domain([min, max])
    .range([height, margin.top])
    .nice();
  const guideElements = svg
    .select(".guides")
    .selectAll("g")
    .data(guides)
    .enter()
    .append("g");
  guideElements
    .append("line")
    .attr("x1", margin.left)
    .attr("x2", width - margin.right)
    .attr("y1", (g) => yAxisScale(g.yValue))
    .attr("y2", (g) => yAxisScale(g.yValue))
    .attr("stroke", (g) => g.color)
    .attr("stroke-width", 2)
    .attr("stroke-dasharray", (g) => (g.style === "Dashed" ? "10,10" : ""));
  guideElements
    .append("text")
    .attr("x", width - margin.right + 10)
    .attr("y", (g) => yAxisScale(g.yValue) + 3)
    .attr("fill", (g) => g.color)
    .style("font-size", "12px")
    .text((g) => g.yValue);
}

import { Fab } from "@mui/material";
import React, { useRef } from "react";
import { FaPlus } from "react-icons/fa";
import { useStateReducer } from "../../hooks";
import TextField from "./TextField";
import "./_styles.scss";

interface OptionsBuilderProps {
  error?: boolean;
  onUpdateValue: Function;
}

export const OptionsBuilder = (props: OptionsBuilderProps) => {
  const [state, setState] = useStateReducer({
    value: "",
  });
  const isValid = useRef(true);

  const onAdd = () => {
    props.onUpdateValue(state.value);

    setState({ ...state, value: "" });
  };

  const handleChange = (value: string) => {
    setState({ ...state, value });
  };

  const validate = () => {
    if (state.value && !state.value.match(/^[A-Za-z0-9:?\s,_-]+$/g)) {
      isValid.current = false;
      return true;
    } else {
      isValid.current = true;
      return false;
    }
  };

  return (
    <div className="option-row">
      <TextField
        label="Name"
        className="option-field"
        value={state.value}
        onChange={(e: any) => handleChange(e.target.value)}
        error={props.error || validate()}
        helperText={"Please only use Alphanumeric characters (A-z 0-9)"}
      />
      <Fab
        size="small"
        color="primary"
        aria-label="add"
        className="option-adder"
        onClick={onAdd}
        disabled={!isValid.current || !state.value}
      >
        <FaPlus />
      </Fab>
    </div>
  );
};

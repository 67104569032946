import React, { useCallback, useEffect, useMemo } from "react";
import { Form } from "../../../components";
import {
  ChartMeasurementFieldOptions,
  LegendPositionOptions,
  SchoellerConfig,
  SeriesGroupingOptions,
} from "../../../interfaces";
import ReferenceSummary from "../../../interfaces/ReferenceSummary";
import {
  InstructionWrapper,
  NumberField,
  RadioButtons,
  SearchAndChips,
  TextField,
} from "../../../components/fields";
import { useStateReducer } from "../../../hooks";
import seriesGroupingToOptions from "../utilities/seriesGroupingToOptions";
import legendToOptions from "../utilities/legendToOptions";
import getOrderedMeasurables, {
  OrderedMeasurable,
} from "../utilities/getOrderedMeasurables";
import { clone, validateObject } from "../../../utilities";
import { Validation } from "../../../utilities/validateObject";
import LabelSelector from "./LabelSelector";

interface SchoellerChartConfigProps {
  siteTemplates: ReferenceSummary[];
  config?: SchoellerConfig;
  onChange?: (config: SchoellerConfig) => void;
  readOnly?: boolean;
}

interface State {
  measurables?: OrderedMeasurable[];
  seriesGrouping?: SeriesGroupingOptions;
  seriesLabel?: ChartMeasurementFieldOptions;
  seriesLabelFormat?: string;
  xAxisLabel?: string;
  yAxisLabel?: string;
  yAxisMin?: number;
  yAxisMax?: number;
  legendPosition?: LegendPositionOptions;
}

const seriesOptions = seriesGroupingToOptions();
const legendOptions = legendToOptions();

export default function SchoellerChartConfig({
  siteTemplates,
  config,
  onChange,
  readOnly,
}: SchoellerChartConfigProps) {
  const [state, setState] = useStateReducer<State>({
    measurables: config?.measurables,
    seriesGrouping: config?.seriesGrouping ?? "Site",
    seriesLabel: config?.seriesLabel ?? "SiteName",
    seriesLabelFormat: config?.seriesLabelFormat,
    xAxisLabel: config?.xAxisLabel,
    yAxisLabel: config?.yAxisLabel,
    yAxisMax: config?.yAxisMax,
    yAxisMin: config?.yAxisMin,
    legendPosition: config?.legendPosition ?? "Right",
  });
  const validationRules: Validation = useMemo(
    () => ({
      measurables: {
        func: (m: ReferenceSummary[]) => m?.length > 0,
        message: "This field is required.",
      },
    }),
    []
  );
  const validation = validateObject(validationRules, state);

  const handleMeasurableSearch = useCallback(
    async (search: string) =>
      await getOrderedMeasurables(
        siteTemplates.map((e) => e.id),
        search
      ),
    [siteTemplates]
  );

  const handleMeasurableAdd = useCallback(
    (measurable: ReferenceSummary) => {
      const measurables = clone(state.measurables ?? []);
      measurables.push(measurable);
      setState({ measurables });
    },
    [state.measurables, setState]
  );

  const handleMeasurableRemove = useCallback(
    (_: string, id: string | number) => {
      const measurables = clone(state.measurables ?? []);
      measurables.splice(
        measurables.findIndex((m) => m.id === id),
        1
      );
      setState({ measurables });
    },
    [state.measurables, setState]
  );

  useEffect(() => {
    if (readOnly) return;

    onChange?.({
      legendPosition: state.legendPosition ?? "Right",
      measurables: state.measurables ?? [],
      seriesGrouping: state.seriesGrouping ?? "Site",
      seriesLabel: state.seriesLabel ?? "SiteName",
      seriesLabelFormat: state.seriesLabelFormat,
      xAxisLabel: state.xAxisLabel ?? "",
      yAxisLabel: state.yAxisLabel ?? "",
      yAxisMax: state.yAxisMax,
      yAxisMin: state.yAxisMin,
    });
  }, [state, readOnly, onChange]);

  return (
    <>
      <Form title="Dataset Configuration">
        <InstructionWrapper
          text="The measurables that are used to plot the points for the lines against the Y-axis of the chart."
          error={validation["measurables"]}
        >
          <SearchAndChips
            label="Measurables"
            values={state.measurables}
            getOptionLabel={(o: ReferenceSummary) => o.name}
            getOptionValue={(o: ReferenceSummary) => o.id}
            onSearch={readOnly ? undefined : handleMeasurableSearch}
            onSelect={readOnly ? undefined : handleMeasurableAdd}
            onRemove={readOnly ? undefined : handleMeasurableRemove}
            error={validation["measurables"] ? true : false}
            readOnly={readOnly}
          />
        </InstructionWrapper>
        <InstructionWrapper text="The field used for grouping the data into series.">
          <RadioButtons
            horizontal
            label="Series Grouping"
            items={seriesOptions}
            value={state.seriesGrouping}
            onChange={
              readOnly
                ? undefined
                : (_, seriesGrouping: SeriesGroupingOptions) =>
                    setState({ seriesGrouping })
            }
            readOnly={readOnly}
          />
        </InstructionWrapper>
        <LabelSelector
          instructionText="The field used for displaying the label of each series."
          label="Series Label"
          value={state.seriesLabel}
          format={state.seriesLabelFormat}
          onChange={(seriesLabel) => setState({ seriesLabel })}
          onFormatChange={(seriesLabelFormat) =>
            setState({ seriesLabelFormat })
          }
          readOnly={readOnly}
        />
      </Form>
      <Form title="X-Axis Configuration">
        <InstructionWrapper text="The label displayed below the X axis.">
          <TextField
            label="Label"
            value={state.xAxisLabel}
            onChange={
              readOnly
                ? undefined
                : (e) => setState({ xAxisLabel: e.target.value })
            }
            readOnly={readOnly}
          />
        </InstructionWrapper>
      </Form>
      <Form title="Y-Axis Configuration">
        <InstructionWrapper text="The label displayed to the left of the Y axis.">
          <TextField
            label="Label"
            value={state.yAxisLabel}
            onChange={
              readOnly
                ? undefined
                : (e) => setState({ yAxisLabel: e.target.value })
            }
            readOnly={readOnly}
          />
        </InstructionWrapper>
        <InstructionWrapper
          text="Settings for the ticks on the Y axis, including the minimum and maximum values for the Y axis."
          row
        >
          <NumberField
            label="Minimum"
            value={state.yAxisMin}
            onChange={
              readOnly
                ? undefined
                : (e) =>
                    setState({
                      yAxisMin:
                        e.target.value === ""
                          ? undefined
                          : parseFloat(e.target.value),
                    })
            }
            readOnly={readOnly}
          />
          <NumberField
            label="Maximum"
            value={state.yAxisMax}
            onChange={
              readOnly
                ? undefined
                : (e) =>
                    setState({
                      yAxisMax:
                        e.target.value === ""
                          ? undefined
                          : parseFloat(e.target.value),
                    })
            }
            readOnly={readOnly}
          />
        </InstructionWrapper>
      </Form>
      <Form title="Chart Configuration">
        <InstructionWrapper text="The location of the series legend, if more than 1 series is present in the dataset.">
          <RadioButtons
            horizontal
            label="Series Legend Location"
            items={legendOptions}
            value={state.legendPosition}
            onChange={
              readOnly
                ? undefined
                : (_, legendPosition: LegendPositionOptions) =>
                    setState({ legendPosition })
            }
            readOnly={readOnly}
          />
        </InstructionWrapper>
      </Form>
    </>
  );
}

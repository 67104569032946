import React from "react";
import TextField, { TextFieldProps } from "./TextField";
import NumberFormat from "react-number-format";
import { classNameBuilder } from "../../utilities";

class NumberFieldProps {
  thousandSeparator?: boolean;
  decimalScale?: number;
  currency?: { symbol: string };
}

export default function NumberField({
  readOnly,
  className,
  thousandSeparator,
  decimalScale,
  currency,
  startAdornment,
  ...props
}: NumberFieldProps & TextFieldProps) {
  return (
    <NumberFormat
      thousandSeparator={thousandSeparator}
      decimalScale={decimalScale}
      isNumericString
      className={classNameBuilder("h-app-number-field", className ?? "")}
      customInput={TextField}
      startAdornment={
        currency ? (
          <span className="currency-symbol">{currency.symbol}</span>
        ) : (
          startAdornment
        )
      }
      endAdornment={props.endAdornment}
      readOnly={readOnly}
      id={props.id}
      label={props.label}
      placeholder={props.placeholder}
      value={props.value as string}
      onChange={props.onChange}
      onKeyDown={props.onKeyDown}
      onBlur={props.onBlur}
      size="small"
      error={props.error}
      disabled={props.disabled}
      InputProps={props.InputProps}
    />
  );
}

import React from "react";
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
} from "@mui/material";
import { TextField } from "./";
import { classNameBuilder, isObject } from "../../utilities";

interface ListProps {
  label?: string;
  error?: boolean;
  value?: any;
  className?: string;
  id?: string;
  onChange?: (
    event: React.SyntheticEvent<Element, Event>,
    value: any,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any> | undefined
  ) => void;
  disabled?: boolean;
  readOnly?: boolean;
  options: any[];
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  getOptionDisabled?: (option: any) => boolean;
  getOptionLabel?: (option: any) => string;
  getOptionValue?: (option: any) => any;
  getStartAdornment?: (option: any) => React.ReactNode;
  getEndAdornment?: (option: any) => React.ReactNode;
  isOptionEqualToValue?: (option: any, value: any) => boolean;
  multiple?: boolean;
  groupBy?: (option: any) => string;
  allowClear?: boolean;
  blurOnSelect?: boolean;
  onInputChange?: (
    event: React.SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => void;
  inputValue?: string;
  placeholder?: string;
  onFocus?: () => void;
  optionRenderer?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: any
  ) => React.ReactNode;
  customFilter?: (value: string, option: any) => boolean;
}

export default function List({
  label,
  value,
  className,
  id,
  onChange,
  error,
  disabled,
  readOnly,
  options,
  startAdornment,
  endAdornment,
  getOptionDisabled,
  getOptionLabel,
  getOptionValue,
  getStartAdornment,
  getEndAdornment,
  isOptionEqualToValue,
  multiple,
  groupBy,
  allowClear,
  blurOnSelect,
  onInputChange,
  inputValue,
  placeholder,
  onFocus,
  optionRenderer,
  customFilter,
}: ListProps) {
  let val;

  if (!value) val = null;
  else if (isObject(value)) val = value;
  else if (getOptionValue) {
    val = options.filter((o) => getOptionValue(o) === value);

    if (!multiple) val = val[0];
  }

  return (
    <Autocomplete
      id={id}
      className={classNameBuilder(
        "h-app-autocomplete-field",
        readOnly ? "readonly" : "",
        className ?? ""
      )}
      onFocus={onFocus}
      onInputChange={onInputChange}
      inputValue={inputValue}
      onChange={onChange}
      value={val}
      disabled={disabled}
      readOnly={readOnly}
      options={options}
      getOptionDisabled={getOptionDisabled}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={(o, v) => v && isOptionEqualToValue?.(o, v)}
      multiple={multiple}
      groupBy={groupBy}
      size="small"
      renderOption={(props, option) => {
        if (optionRenderer) return optionRenderer(props, option);

        return (
          <li
            {...props}
            key={
              getOptionValue?.(option) ??
              getOptionLabel?.(option) ??
              new Date().getTime()
            }
          >
            {getStartAdornment?.(option)}
            {getOptionLabel?.(option) ?? option}
            {getEndAdornment?.(option)}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          autoComplete="new-password"
          error={error}
          startAdornment={getStartAdornment?.(value) ?? startAdornment}
          endAdornment={getEndAdornment?.(value) ?? endAdornment}
          placeholder={placeholder}
        />
      )}
      disableClearable={!allowClear}
      blurOnSelect={blurOnSelect ?? false}
      filterOptions={
        customFilter
          ? (options, { inputValue }) =>
              options.filter((o) => customFilter(inputValue, o))
          : undefined
      }
    />
  );
}

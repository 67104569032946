import React, { useCallback, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Clickable } from "..";
import { classNameBuilder } from "../../utilities";
import { useStateReducer } from "../../hooks";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import { IconType } from "react-icons/lib";

interface SideNavGroupProps {
  label?: string;
  icon?: IconType;
  children?: any;
  className?: string;
}

interface State {
  expanded?: boolean;
  height?: number;
}

export default function SideNavGroup({
  label,
  icon: Icon,
  children,
  className,
}: SideNavGroupProps) {
  const location = useLocation();
  const [state, setState] = useStateReducer<State>({
    expanded: children?.filter(
      (c: any) => location.pathname.indexOf(c.props.to) === 0
    ).length
      ? true
      : false,
    height: 0,
  });
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setState({ height: containerRef.current?.scrollHeight ?? 0 });
  }, [setState]);

  const handleClick = useCallback(() => {
    setState({ expanded: !state.expanded });
  }, [setState, state.expanded]);

  return (
    <>
      <Clickable
        className={classNameBuilder(
          "h-app-side-nav-link expandable",
          state.expanded ? "expanded" : "",
          className ?? ""
        )}
        onClick={handleClick}
      >
        {Icon && <Icon />}
        {label}
        <FontAwesomeIcon icon={faChevronDown} />
      </Clickable>
      <div
        className="h-app-side-nav-group"
        ref={containerRef}
        style={{
          height: state.expanded ? state.height : 0,
        }}
      >
        {children}
      </div>
    </>
  );
}

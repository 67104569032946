import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useStateReducer } from "../../hooks";
import { NavigableComponent, RoleFull } from "../../interfaces";
import { rolesService } from "../../services";
import { FaHandPointer } from "react-icons/fa";
import { getQueryParameter } from "../../utilities";
import { routes } from "../../_config";
import ViewRole from "./components/ViewRole";
import EditRole from "./components/EditRole";
import "./_styles.scss";
import { LoadingIndicator } from "../../components";

interface RolesItemPageProps {
  index?: boolean;
  onLoad: () => void;
}
interface State {
  role?: RoleFull | null;
  isSaveDisabled?: boolean;
  loading?: boolean;
}

export default function RolesItemPage({
  setBreadcrumbs,
  setToolbarConfig,
  index,
  onLoad,
}: NavigableComponent & RolesItemPageProps) {
  const { settingId } = useParams();
  const [state, setState] = useStateReducer<State>({
    isSaveDisabled: true,
    loading: true,
  });
  const { role, loading } = state;
  const location = useLocation();
  const mode = getQueryParameter(location.search, "mode");

  useEffect(() => {
    const load = async () => {
      const _role = await rolesService.getSingle({
        id: settingId ?? "",
      });

      setState({ role: _role, loading: false });
      onLoad();

      setBreadcrumbs(
        index
          ? [{ text: "Settings", to: routes.settings.go() }, { text: "Roles" }]
          : [
              { text: "Settings", to: routes.settings.go() },
              { text: "Roles", to: routes.roles.raw },
              { text: _role.name },
            ]
      );
    };

    setState({ role: null, isSaveDisabled: true, loading: false });
    load();
  }, [settingId, mode, index, setState, onLoad, setBreadcrumbs]);

  let content = <></>;

  if (index)
    content = (
      <>
        <FaHandPointer /> Select a site on the left.
      </>
    );
  else if (!role || settingId !== role.id || loading)
    content = <LoadingIndicator />;
  else
    content =
      mode === "edit" || mode === "add" ? (
        <EditRole
          setToolbarConfig={setToolbarConfig}
          setBreadcrumbs={setBreadcrumbs}
          role={role}
        />
      ) : (
        <ViewRole
          setToolbarConfig={setToolbarConfig}
          setBreadcrumbs={setBreadcrumbs}
          role={role}
        />
      );

  return content;
}

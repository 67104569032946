import { FormControl, InputLabel, OutlinedInput } from "@mui/material";
import React, { ChangeEvent, useMemo, useRef, useState } from "react";
import { classNameBuilder, getFileType } from "../../utilities";
import { v4 as uuidv4 } from "uuid";
import Button from "../button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaTimes } from "react-icons/fa";

interface FileValue {
  display: string;
  path: string;
}

interface FileUploadProps {
  id?: string;
  label?: string;
  value?: FileValue;
  onChange?: (value?: File) => void;
  className?: string;
  disabled?: boolean;
  readOnly?: boolean;
  error?: boolean;
  acceptedTypes?: string[];
}

export default function FileUpload({
  id,
  label,
  className,
  disabled,
  readOnly,
  value,
  acceptedTypes,
  onChange,
}: FileUploadProps) {
  const [htmlId] = useMemo(() => id ?? uuidv4(), [id]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileValue, setFileValue] = useState(value?.display ?? "");
  const fileName = fileValue ? fileValue.split(/(\\|\/)/g).pop() : "";
  const { fileClass, fileIcon } = getFileType(fileValue);

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFileValue(e.target.value);
    if (onChange) onChange(e.target?.files?.[0]);
  };

  const handleClear = () => {
    setFileValue("");
    if (onChange) onChange(undefined);
  };

  return (
    <FormControl
      className={classNameBuilder(
        "h-app-input-field",
        "h-app-file-upload",
        className ?? ""
      )}
    >
      {label && <InputLabel htmlFor={htmlId}>{label}</InputLabel>}
      <OutlinedInput
        id={htmlId}
        label={label}
        disabled={disabled}
        readOnly
        value={fileName || "No file selected"}
        size="small"
        startAdornment={
          fileValue ? (
            <FontAwesomeIcon
              icon={fileIcon}
              className={classNameBuilder("file-icon", fileClass)}
            />
          ) : null
        }
        endAdornment={
          !fileValue ? (
            <Button text="Upload" primary onClick={handleUploadClick} />
          ) : (
            <Button
              icon={FaTimes}
              primary
              onClick={handleClear}
              className="clear-button"
            />
          )
        }
      />
      <input
        ref={fileInputRef}
        type="file"
        hidden
        onChange={handleFileChange}
        value={fileValue}
        accept={acceptedTypes?.map((t) => `.${t}`).join(",")}
      />
    </FormControl>
  );
}

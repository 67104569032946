import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useStateReducer } from "../../hooks";
import { NavigableComponent, FormFull } from "../../interfaces";
import { fieldFormsService } from "../../services";
import { FaHandPointer } from "react-icons/fa";
import { getQueryParameter } from "../../utilities";
import { routes } from "../../_config";
import { LoadingIndicator, Scrollable } from "../../components";
import ViewFieldForm from "./components/ViewFieldForm";
import EditFieldForm from "./components/EditFieldForm";

interface DescriptorsItemPageProps {
  index?: boolean;
  onLoad: () => void;
}
interface State {
  fieldForm?: FormFull | null;
  isSaveDisabled?: boolean;
  loading?: boolean;
}

export default function FieldFormsItemPage({
  setBreadcrumbs,
  setToolbarConfig,
  index,
  onLoad,
}: NavigableComponent & DescriptorsItemPageProps) {
  const { settingId } = useParams();
  const [state, setState] = useStateReducer<State>({
    isSaveDisabled: true,
    loading: true,
  });
  const { fieldForm } = state;
  const loading = fieldForm ? false : true;
  const location = useLocation();
  const mode = getQueryParameter(location.search, "mode");

  useEffect(() => {
    const load = async () => {
      const _fieldForm =
        mode === "edit"
          ? await fieldFormsService.getSingle({
              id: settingId ?? "",
            })
          : await fieldFormsService.getCurrent({ id: settingId ?? "" });

      setState({ fieldForm: _fieldForm, loading: false });
      onLoad();

      setBreadcrumbs(
        index
          ? [
              { text: "Settings", to: routes.settings.go() },
              { text: "Field Forms" },
            ]
          : [
              { text: "Settings", to: routes.settings.go() },
              { text: "Field Forms", to: routes.fieldForms.raw },
              { text: _fieldForm.name },
            ]
      );
    };

    setState({ fieldForm: null, isSaveDisabled: true, loading: true });
    load();
  }, [settingId, mode, index, setBreadcrumbs, setState, onLoad]);

  let content = <></>;

  if (index)
    content = (
      <>
        <FaHandPointer /> Select a site on the left.
      </>
    );
  else if (!fieldForm || loading) content = <LoadingIndicator />;
  else
    content = (
      <Scrollable>
        {mode === "edit" || mode === "add" ? (
          <EditFieldForm
            setToolbarConfig={setToolbarConfig}
            setBreadcrumbs={setBreadcrumbs}
            fieldForm={fieldForm}
          />
        ) : (
          <ViewFieldForm
            setToolbarConfig={setToolbarConfig}
            setBreadcrumbs={setBreadcrumbs}
            fieldForm={fieldForm}
          />
        )}
      </Scrollable>
    );

  return content;
}

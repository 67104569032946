import moment from "moment";
import {
  CrudResponse,
  GetSingleArgs,
  MeasurementFull,
  MeasurementStatusType,
  MeasurementSummary,
  MeasurementValueType,
  PageAndSearchArgs,
  RangeType,
} from "../interfaces";
import CrudService from "../interfaces/CrudService";
import { goFetch } from "../utilities/goFetch";

const controller = "measurements";

interface AddOrUpdateValue {
  referenceId: string;
  type: MeasurementValueType;
  values: (string | number)[];
  ranges?: (RangeType | null)[];
  groupId: string | null;
  groupName: string | null;
}

interface RemoveValue {
  referenceId: string;
  type: MeasurementValueType;
}

interface UpdateStatus {
  status: MeasurementStatusType;
}

interface MeasurementsService {
  create: (
    siteId: string,
    formId: string,
    measurementTimestamp: Date
  ) => Promise<CrudResponse>;
  update: (
    id: string,
    action: "AddValue" | "RemoveValue" | "UpdateValue" | "UpdateStatus",
    definition: AddOrUpdateValue | RemoveValue | UpdateStatus
  ) => Promise<MeasurementFull>;
  delete: (id: string) => Promise<CrudResponse>;
  exportMeasurements: (
    fieldFormId: string,
    filters?: {
      siteIds?: string[];
      status?: string[];
    }
  ) => Promise<{ [key: string]: string | number | Date | null }[]>;
  downloadMeasurementExports: (
    fieldFormId: string,
    filters?: {
      siteIds?: string[];
      status?: string[];
    }
  ) => Promise<string>;
  importMeasurementTemplate: (
    fieldFormId: string
  ) => Promise<{ [key: string]: string | number | Date | null }[]>;
  downloadImportMeasurementTemplate: (fieldFormId: string) => Promise<string>;
  importMeasurements: (
    fieldFormId: string,
    file: File
  ) => Promise<{ success: boolean; message?: string }>;
}

const service: CrudService<MeasurementFull, MeasurementSummary> &
  MeasurementsService = {
  getByPage: async ({ search, pageNo, pageSize }: PageAndSearchArgs) => {
    const response = await goFetch(`${controller}/page/${pageNo}`)
      .withQueryParameters({
        pageSize: pageSize || 50,
        search: search,
      })
      .get();

    return {
      pageNo: response?.pageNo ?? 0,
      pageSize: response?.pageSize ?? 0,
      pageCount: response?.pageCount ?? 0,
      itemCount: response?.itemCount ?? 0,
      data: response?.data ?? [],
    };
  },

  getSingle: async ({ id }: GetSingleArgs) =>
    await goFetch(`${controller}/${id}`).get(),

  create: async (siteId, formId, measurementTimestamp) =>
    await goFetch(controller)
      .withBody({
        siteId,
        formId,
        measurementTimestamp: moment(measurementTimestamp).format(),
      })
      .post(),

  update: async (id, action, definition) =>
    await goFetch(`${controller}/${id}`)
      .withBody({ action, definition })
      .patch(),

  delete: async (id) => await goFetch(`${controller}/${id}`).delete(),

  importMeasurementTemplate: async (fieldFormId: string) =>
    await goFetch(`${controller}/import-template/${fieldFormId}`).get(),

  exportMeasurements: async (fieldFormId, filters) =>
    await goFetch(`${controller}/export/${fieldFormId}`)
      .withQueryParameters(filters ?? {})
      .get(),

  downloadMeasurementExports: async (fieldFormId, filters) =>
    await goFetch(`${controller}/export/${fieldFormId}/download`)
      .withQueryParameters(filters ?? {})
      .get(),

  downloadImportMeasurementTemplate: async (fieldFormId) =>
    await goFetch(
      `${controller}/import-template/${fieldFormId}/download`
    ).get(),

  importMeasurements: async (fieldFormId, file) => {
    const formData = new FormData();
    formData.append("file", file);

    let message: string | undefined;
    let response = await goFetch(`${controller}/import/${fieldFormId}`)
      .withFormData(formData)
      .withCustomErrorHandler((error) => {
        message = error;
      })
      .post();

    return {
      success: response ? true : false,
      message,
    };
  },
};

export default service;

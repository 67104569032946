import React, { useCallback, useEffect, useRef } from "react";
import {
  BiDownArrow,
  BiFontColor,
  BiRuler,
  BiTrash,
  BiUpArrow,
} from "react-icons/bi";
import { Button, Form, Tab, Tabs } from "../../../components";
import {
  InstructionWrapper,
  RadioButtons,
  SearchAndChips,
  TextField,
} from "../../../components/fields";
import { useStateReducer } from "../../../hooks";
import {
  MeasurableGroup,
  PageAndSearchArgs,
  SiteTemplateFull,
} from "../../../interfaces";
import ReferenceSummary from "../../../interfaces/ReferenceSummary";
import {
  descriptorsService,
  measurablesService,
  siteTemplatesService,
} from "../../../services";
import { classNameBuilder, validateObject } from "../../../utilities";

interface GroupEditorProps {
  siteTemplate: SiteTemplateFull;
  group: MeasurableGroup;
  onUpdate: (groups: MeasurableGroup[]) => void;
}

interface ItemType {
  type: "Measurables" | "Descriptors";
  item: ReferenceSummary;
}

interface State {
  name?: string;
  measurables?: ReferenceSummary[];
  descriptors?: ReferenceSummary[];
  order?: string[];
  newType?: "Descriptors" | "Measurables";
  newSelectedItem?: ItemType;
  selectedItems?: ItemType[];
  justMovedField?: string;
}

export default function GroupEditor({
  siteTemplate,
  group,
  onUpdate,
}: GroupEditorProps) {
  const timeoutRef = useRef<NodeJS.Timer>();

  const determineSelectedItems = useCallback(
    (
      order: string[],
      descriptors: ReferenceSummary[],
      measurables: ReferenceSummary[]
    ) => {
      const sorted: ItemType[] = [];
      const unsorted: ItemType[] = [];

      order.forEach((o) => {
        const descriptor = descriptors.find((i) => i.id === o);

        if (descriptor) {
          sorted.push({
            type: "Descriptors",
            item: descriptor,
          });
          return;
        }

        const measurable = measurables.find((i) => i.id === o);

        if (measurable) {
          sorted.push({
            type: "Measurables",
            item: measurable,
          });
          return;
        }
      });

      measurables.forEach((i) => {
        const item = sorted.find((o) => o.item.id === i.id);

        if (!item)
          unsorted.push({
            type: "Measurables",
            item: i,
          });
      });

      descriptors?.forEach((i) => {
        const item = sorted.find((o) => o.item.id === i.id);

        if (!item)
          unsorted.push({
            type: "Descriptors",
            item: i,
          });
      });

      return [...sorted, ...unsorted];
    },
    []
  );

  const [state, setState] = useStateReducer<State>({
    name: group.name,
    measurables: group.measurables,
    descriptors: group.descriptors,
    order: group.order,
    selectedItems: determineSelectedItems(
      group.order,
      group.descriptors,
      group.measurables
    ),
    newType: "Measurables",
  });
  const { selectedItems } = state;
  const validation = validateObject(
    {
      name: {
        regex: /.+/,
        message: "This field is required.",
      },
    },
    state
  );

  const handleUpdateDetails = async () => {
    const response = await siteTemplatesService.update(
      siteTemplate.id,
      "UpdateMeasurableGroup",
      {
        groupId: group.id,
        name: state.name,
        order: state.order,
      }
    );

    onUpdate(response.measurableGroups);
  };

  const handleAdd = useCallback(async () => {
    const { newSelectedItem: item, descriptors, measurables, order } = state;

    if (!item) return;

    let response: SiteTemplateFull;

    if (item.type === "Descriptors") {
      descriptors?.push({
        id: item.item.id,
        name: item.item.name,
      });
      order?.push(item.item.id);

      response = await siteTemplatesService.update(
        siteTemplate.id,
        "AddDescriptor",
        {
          groupId: group.id,
          descriptorId: item.item.id,
        }
      );

      onUpdate(response.measurableGroups);
    } else if (item.type === "Measurables") {
      measurables?.push({
        id: item.item.id,
        name: item.item.name,
      });
      order?.push(item.item.id);

      response = await siteTemplatesService.update(
        siteTemplate.id,
        "AddMeasurable",
        {
          groupId: group.id,
          measurableId: item.item.id,
        }
      );
      response = await siteTemplatesService.update(
        siteTemplate.id,
        "UpdateMeasurableGroup",
        {
          groupId: group.id,
          name: state.name,
          order: order,
        }
      );

      onUpdate(response.measurableGroups);
    }

    setState({
      descriptors,
      measurables,
      order,
      newSelectedItem: undefined,
      selectedItems: determineSelectedItems(
        order ?? [],
        descriptors ?? [],
        measurables ?? []
      ),
      justMovedField: item.item.id,
    });
  }, [
    state,
    setState,
    determineSelectedItems,
    siteTemplate.id,
    group.id,
    onUpdate,
  ]);

  const handleRemove = useCallback(
    async (type: "Descriptors" | "Measurables", id: string) => {
      let { measurables, descriptors, order } = state;

      order = order?.filter((o) => o !== id);

      if (type === "Measurables") {
        measurables = measurables?.filter((m) => m.id !== id);

        const response = await siteTemplatesService.update(
          siteTemplate.id,
          "RemoveMeasurable",
          {
            groupId: group.id,
            measurableId: id,
          }
        );

        onUpdate(response.measurableGroups);
      } else if (type === "Descriptors") {
        descriptors = descriptors?.filter((m) => m.id !== id);

        const response = await siteTemplatesService.update(
          siteTemplate.id,
          "RemoveDescriptor",
          {
            groupId: group.id,
            descriptorId: id,
          }
        );

        onUpdate(response.measurableGroups);
      }

      setState({
        measurables,
        descriptors,
        order,
        selectedItems: determineSelectedItems(
          order ?? [],
          descriptors ?? [],
          measurables ?? []
        ),
        justMovedField: undefined,
      });
    },
    [
      determineSelectedItems,
      siteTemplate.id,
      group.id,
      setState,
      state,
      onUpdate,
    ]
  );

  const handleMove = useCallback(
    async (id: string, direction: "up" | "down") => {
      if (!selectedItems) return;

      const currentIndex = selectedItems.findIndex((i) => i.item.id === id);

      if (currentIndex < 0) return;

      const itemsBefore = selectedItems.slice(0, currentIndex);
      const itemsAfter = selectedItems.slice(
        currentIndex + 1,
        selectedItems.length
      );

      const order: string[] = [];

      if (direction === "down") {
        itemsBefore.forEach((i) => {
          order.push(i.item.id);
        });
        order.push(itemsAfter[0].item.id);
        order.push(id);
        itemsAfter.slice(1).forEach((i) => {
          order.push(i.item.id);
        });
      } else {
        itemsBefore.slice(0, currentIndex - 1).forEach((i) => {
          order.push(i.item.id);
        });
        order.push(id);
        order.push(itemsBefore[itemsBefore.length - 1].item.id);
        itemsAfter.forEach((i) => {
          order.push(i.item.id);
        });
      }

      const response = await siteTemplatesService.update(
        siteTemplate.id,
        "UpdateMeasurableGroup",
        {
          groupId: group.id,
          name: state.name,
          order: order,
        }
      );

      onUpdate(response.measurableGroups);

      setState({
        order,
        selectedItems: determineSelectedItems(
          order,
          state.descriptors ?? [],
          state.measurables ?? []
        ),
        justMovedField: id,
      });
    },
    [
      determineSelectedItems,
      selectedItems,
      setState,
      state.name,
      state.descriptors,
      state.measurables,
      siteTemplate.id,
      group.id,
      onUpdate,
    ]
  );

  useEffect(() => {
    if (state.justMovedField) clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      setState({ justMovedField: undefined });
    }, 1500);
  }, [state.justMovedField, setState]);

  const handleFieldSearch = useCallback(
    async (search: string) => {
      const args: PageAndSearchArgs = {
        pageSize: 20,
        pageNo: 1,
        search,
      };
      const response =
        state.newType === "Measurables"
          ? await measurablesService.getByPage(args)
          : await descriptorsService.getByPage(args);

      const data: ItemType[] = [];

      response.data.forEach((item) => {
        if (state.order?.find((o) => o === item.id)) return;

        for (const group of siteTemplate.measurableGroups)
          if (group.order.find((o) => o === item.id)) return;

        data.push({
          type: state.newType!,
          item: item,
        });
      });

      return data;
    },
    [state.newType, state.order, siteTemplate.measurableGroups]
  );

  return (
    <Tabs disableRouteNavigation className="measurable-group-editor-modal">
      <Tab
        key="group-overview-tab"
        identifier="group-overview-tab"
        heading="Overview"
      >
        <Form>
          <InstructionWrapper
            text="The name of the group."
            error={validation["name"]}
          >
            <TextField
              label="Name"
              required
              value={state.name}
              onChange={(e) => setState({ name: e.target.value })}
              onBlur={handleUpdateDetails}
              error={validation["name"] ? true : false}
            />
          </InstructionWrapper>
        </Form>
      </Tab>
      <Tab
        key="group-fields-tab"
        identifier="group-fields-tab"
        heading="Fields"
      >
        <Form>
          {selectedItems?.length ? (
            selectedItems?.map((item, i) => (
              <div
                key={`selected-item-${item.item.id}`}
                className={classNameBuilder(
                  "selected-form-field",
                  state.justMovedField === item.item.id ? "moved" : ""
                )}
              >
                <span className="s-icon">
                  {item.type === "Descriptors" ? <BiFontColor /> : <BiRuler />}
                </span>
                <span className="s-name">{item.item.name}</span>
                <Button
                  icon={BiUpArrow}
                  disabled={i === 0}
                  onClick={() => handleMove(item.item.id, "up")}
                />
                <Button
                  icon={BiDownArrow}
                  disabled={i === selectedItems.length - 1}
                  onClick={() => handleMove(item.item.id, "down")}
                />
                <Button
                  icon={BiTrash}
                  className="delete"
                  onClick={() => handleRemove(item.type, item.item.id)}
                />
              </div>
            ))
          ) : (
            <div className="no-selected-form-fields">No fields selected</div>
          )}
          <div className="add-form-field">
            <RadioButtons
              value={state.newType}
              items={[
                { label: "Measurables", value: "Measurables" },
                { label: "Descriptors", value: "Descriptors" },
              ]}
              horizontal
              onChange={(_, v) =>
                setState({
                  newType: v === "Measurables" ? "Measurables" : "Descriptors",
                  newSelectedItem: undefined,
                })
              }
            />
            <SearchAndChips
              label={`Search ${state.newType}`}
              values={state.newSelectedItem ? [state.newSelectedItem] : []}
              onSearch={handleFieldSearch}
              onSelect={(v: ItemType) => setState({ newSelectedItem: v })}
              onRemove={() => setState({ newSelectedItem: undefined })}
              getOptionLabel={(o: ItemType) => o.item.name}
              getOptionValue={(o: ItemType) => o.item.id}
              singleSelect
            />
            <Button
              text="Add"
              className="add-new-item"
              primary
              raised
              onClick={handleAdd}
              disabled={state.newSelectedItem ? false : true}
            />
          </div>
        </Form>
      </Tab>
    </Tabs>
  );
}

import React from "react";
import { useState } from "react";
import Helmet from "react-helmet";
import { BrowserRouter } from "react-router-dom";
import { Alert, BreadcrumbTrail, Modal, SideNav, Toolbar } from "./components";
import {
  primary,
  secondary,
  error,
  warning,
  info,
  success,
} from "./styles/materialUiTheme";
import "./styles/_bundle.scss";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useStateReducer, useSubscription } from "./hooks";
import { classNameBuilder } from "./utilities";
import AppRoutes from "./AppRoutes";
import AuthProvider from "./AuthProvider";
import { StateTopicEnum } from "./enums";
import { Breadcrumb, UserFull } from "./interfaces";
import { ToolbarProps } from "./components/toolbar/Toolbar";

const theme = createTheme({
  palette: {
    primary,
    secondary,
    error,
    warning,
    info,
    success,
    mode: "light",
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
      },
    },
  },
});

interface State {
  coverCount?: number;
  user?: UserFull;
}

export default function App() {
  const [state, setState] = useStateReducer<State>({
    coverCount: 0,
  });
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);
  const [toolbarConfig, setToolbarConfig] = useState<ToolbarProps>({
    toolbarItems: [],
  });

  useSubscription<boolean>(StateTopicEnum.Modal, (modal) => {
    const coverCount = state.coverCount ?? 0;
    setState({ coverCount: coverCount + (modal ? 1 : -1) });
  });

  useSubscription<boolean>(StateTopicEnum.Alert, (alert) => {
    const coverCount = state.coverCount ?? 0;
    setState({ coverCount: coverCount + (alert ? 1 : -1) });
  });

  useSubscription<any>(StateTopicEnum.User, (user) => {
    setState({ user });
  });

  const { user, coverCount } = state;

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <AuthProvider>
          <BrowserRouter basename={process.env.REACT_APP_UI_ROOT}>
            <Helmet>
              <title>Loading...</title>
            </Helmet>
            <div
              className={classNameBuilder(
                "h-app-container",
                (coverCount ?? 0) > 0 ? "has-cover" : "",
                user ? "" : "signed-out"
              )}
            >
              {user ? (
                <>
                  <SideNav />
                  <BreadcrumbTrail breadcrumbs={breadcrumbs} />
                  <Toolbar {...toolbarConfig} />
                </>
              ) : null}
              <AppRoutes
                setBreadcrumbs={setBreadcrumbs}
                setToolbarConfig={setToolbarConfig}
              />
            </div>
            <Modal />
            <Alert />
          </BrowserRouter>
        </AuthProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

import {
  ChartTemplateFull,
  ChartType,
  CrudResponse,
  GetSingleArgs,
  PageAndSearchArgs,
} from "../interfaces";
import {
  AxisFormatType,
  BarConfigBase,
  BoxAndWhiskerConfigBase,
  ChartTemplateSummary,
  LegendPositionOptions,
  LineConfigBase,
  ScatterConfigBase,
  SchoellerConfigBase,
  StiffConfigBase,
} from "../interfaces/ChartTemplate";
import CrudService from "../interfaces/CrudService";
import { goFetch } from "../utilities/goFetch";

export interface BarConfigUpdate extends BarConfigBase {
  measurableId: string;
}

export interface BoxAndWhiskerConfigUpdate extends BoxAndWhiskerConfigBase {
  measurableId: string;
}

export interface LineConfigUpdate extends LineConfigBase {
  measurableId: string;
}

export interface ScatterConfigUpdate extends ScatterConfigBase {
  xAxisMeasurableId: string;
  yAxisMeasurableId: string;
}

export interface SchoellerConfigUpdate extends SchoellerConfigBase {
  measurableIds: string[];
}

export interface StiffConfigUpdate extends StiffConfigBase {
  cationElementIds: string[];
  anionElementIds: string[];
}

export interface PiperConfigUpdate {
  cationsAElementIds: string[];
  cationsBElementIds: string[];
  cationsCElementIds: string[];
  cationsAAxisLabel: string;
  cationsBAxisLabel: string;
  cationsCAxisLabel: string;
  anionsAElementIds: string[];
  anionsBElementIds: string[];
  anionsCElementIds: string[];
  anionsAAxisLabel: string;
  anionsBAxisLabel: string;
  anionsCAxisLabel: string;
  legendPosition: LegendPositionOptions;
  axisFormat: AxisFormatType;
  cationsColor?: string;
  anionsColor?: string;
  projectionColor?: string;
}

export interface DurovConfigUpdate extends PiperConfigUpdate {
  rightPlotMeasurableId: string;
  rightPlotAxisLabel?: string;
  rightPlotAxisMin?: number;
  rightPlotAxisMax?: number;
  rightPlotColor?: string;
  bottomPlotMeasurableId: string;
  bottomPlotAxisLabel?: string;
  bottomPlotAxisMin?: number;
  bottomPlotAxisMax?: number;
  bottomPlotColor?: string;
}

export interface ChartTemplateUpdateDefinition {
  name?: string;
  type: ChartType;
  siteTemplateIds: string[];
  config?:
    | BarConfigUpdate
    | BoxAndWhiskerConfigUpdate
    | LineConfigUpdate
    | ScatterConfigUpdate
    | SchoellerConfigUpdate
    | StiffConfigUpdate
    | PiperConfigUpdate
    | DurovConfigUpdate;
}

interface ChartTemplatesService {
  create: () => Promise<CrudResponse>;
  update: (
    id: string,
    definition: ChartTemplateUpdateDefinition
  ) => Promise<ChartTemplateFull>;
  delete: (id: string) => Promise<CrudResponse>;
}

const controller = "chart-templates";

const service: CrudService<ChartTemplateFull, ChartTemplateSummary> &
  ChartTemplatesService = {
  getByPage: async ({ search, pageNo, pageSize }: PageAndSearchArgs) => {
    const response = await goFetch(`${controller}/page/${pageNo}`)
      .withQueryParameters({
        pageSize: pageSize || 50,
        search: search,
      })
      .get();

    return {
      pageNo: response?.pageNo ?? 0,
      pageSize: response?.pageSize ?? 0,
      pageCount: response?.pageCount ?? 0,
      itemCount: response?.itemCount ?? 0,
      data: response?.data ?? [],
    };
  },

  getSingle: async ({ id }: GetSingleArgs) =>
    await goFetch(`${controller}/${id}`).get(),

  create: async () => await goFetch(controller).post(),

  update: async (id, definition) =>
    await goFetch(`${controller}/${id}`).withBody(definition).patch(),

  delete: async (id) => await goFetch(`${controller}/${id}`).delete(),
};

export default service;

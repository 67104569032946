import React, { useEffect } from "react";
import Form from "../../../components/form/Form";
import {
  Checkbox,
  InstructionWrapper,
  MultiItem,
  TextField,
} from "../../../components/fields";
import { NavigableComponent, MeasurableFull } from "../../../interfaces";
import { ToolItem } from "../../../components/toolbar/Toolbar";
import { FaArrowLeft, FaEdit, FaTrash } from "react-icons/fa";
import { hideAlert, showAlert } from "../../../components/alert/Alert";
import { AlertTypeEnum, StateTopicEnum } from "../../../enums";
import { routes } from "../../../_config";
import { useNavigate, useParams } from "react-router-dom";
import { measurablesService } from "../../../services";
import { publish, useAuthentication } from "../../../hooks";
import "../_styles.scss";
import { LibrarySection } from "../../../components/library/Library";
import UnitConversionItem from "./UnitConversionItem";

interface ViewFieldDataProps {
  measurable: MeasurableFull;
}

export default function ViewField({
  setToolbarConfig,
  measurable,
}: ViewFieldDataProps & NavigableComponent) {
  const auth = useAuthentication();
  const { settingId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    let toolbarItems: (ToolItem | "|")[] = [
      {
        label: "Back",
        icon: FaArrowLeft,
        primary: true,
        raised: true,
        onClick: () =>
          navigate(routes.settingCategory.go("measurables-library")),
      },
    ];

    const canEdit = auth?.hasPermission("EditMeasurable");
    const canDelete = auth?.hasPermission("RemoveMeasurable");

    if (canEdit || canDelete) toolbarItems.push("|");

    if (canEdit)
      toolbarItems.push({
        label: "Edit",
        icon: FaEdit,
        onClick: () => {
          navigate(
            `${routes.settingItem.go(
              "measurables-library",
              settingId
            )}?mode=edit`
          );
        },
      });

    if (canDelete)
      toolbarItems.push({
        label: "Delete",
        icon: FaTrash,
        onClick: () => {
          showAlert({
            content:
              "Are you sure you want to delete this measurable? This action is not reversable.",
            options: {
              type: AlertTypeEnum.Warning,
              actions: [
                {
                  text: "Yes",
                  primary: true,
                  onClick: async () => {
                    const response = await measurablesService.delete!(
                      settingId!
                    );

                    hideAlert();

                    if (response?.message) {
                      navigate(
                        routes.settingCategory.go("measurables-library")
                      );
                      publish<LibrarySection>(
                        StateTopicEnum.LibrarySectionRefresh,
                        "paged"
                      );
                    }
                  },
                },
                { text: "No" },
              ],
            },
          });
        },
      });

    setToolbarConfig({
      allowSearch: true,
      toolbarItems,
      searchPath: routes.measurables.go(),
    });
  }, [settingId, navigate, setToolbarConfig]);

  return (
    <>
      <Form title="Identifying Information">
        <InstructionWrapper text="An identifying name for the measurable (i.e. distance).">
          <TextField label="Name" value={measurable.name} readOnly />
        </InstructionWrapper>
      </Form>
      <Form title="Measurement Unit">
        <InstructionWrapper
          text="The unit that the measurement is represented in (i.e. meters)."
          row
        >
          <TextField label="Full Name" value={measurable.unitFull} readOnly />
          <TextField
            label="Abbreviation"
            value={measurable.unitAbbreviation}
            readOnly
          />
        </InstructionWrapper>
      </Form>
      <Form title="Value Constraints">
        <InstructionWrapper
          text="The minimum and maximum values for this measurement."
          row
        >
          <TextField label="Min" value={measurable.minValue} readOnly />
          <TextField label="Max" value={measurable.maxValue} readOnly />
        </InstructionWrapper>
      </Form>
      <Form title="Display">
        <InstructionWrapper text="Whether range selection for values are allowed (i.e. lower than, greater than, equals, etc).">
          <Checkbox
            label="Allow Range Selection"
            value={measurable.allowRangeSelection}
            readOnly
          />
        </InstructionWrapper>
        <InstructionWrapper text="The display format for the value.">
          <TextField
            label="Display Format"
            value={measurable.displayFormat}
            readOnly
          />
        </InstructionWrapper>
      </Form>
      <Form title="Unit Conversion">
        <MultiItem
          type="Unit Conversion"
          values={measurable.unitConversions ?? []}
          itemComponent={UnitConversionItem}
          setComponentProps={(unit, index) => ({
            conversion: unit,
            id: measurable.id,
            name: measurable.name,
            readOnly: true,
          })}
          extractItemKey={(_, index) => `tag-${index}`}
          readOnly
        />
      </Form>
    </>
  );
}

import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form } from "../../../components";
import { showAlert } from "../../../components/alert/Alert";
import { InstructionWrapper, TextField } from "../../../components/fields";
import { LibrarySection } from "../../../components/library/Library";
import {
  hideModal,
  updateModalActionState,
} from "../../../components/modal/Modal";
import { StateTopicEnum } from "../../../enums";
import { publish, useStateReducer } from "../../../hooks";
import { rolesService } from "../../../services";
import { validateObject } from "../../../utilities";
import { routes } from "../../../_config";

interface State {
  name?: string;
}

export default function AddRoleModal() {
  const navigate = useNavigate();
  const [state, setState] = useStateReducer<State>({
    name: "",
  });
  const validation = validateObject(
    {
      name: {
        regex: /.{5,}/,
        message: "This field must be at least 5 characters long.",
      },
    },
    state
  );

  const handleSubmit = useCallback(async () => {
    const response = await rolesService.create(state.name ?? "");

    if (response.id) {
      hideModal();
      navigate(
        `${routes.settingItem.go("roles-library", response.id)}?mode=edit`
      );
      publish<LibrarySection>(StateTopicEnum.LibrarySectionRefresh, "paged");
    } else {
      showAlert({ content: response.message });
    }
  }, [navigate, state.name]);

  const handleChange = useCallback(
    (value: string) => {
      setState({ name: value });
    },
    [setState]
  );

  useEffect(() => {
    setTimeout(
      () =>
        updateModalActionState([{ disabled: true, onClick: handleSubmit }, {}]),
      10
    );
  }, [handleSubmit]);

  useEffect(() => {
    updateModalActionState([
      {
        disabled: validation["name"] ? true : false,
        onClick: handleSubmit,
      },
      {},
    ]);
  }, [state.name, handleSubmit, validation]);

  return (
    <Form title="Identifying Information">
      <InstructionWrapper
        text="Specify a unique name for the role."
        error={validation["name"]}
      >
        <TextField
          label="Name"
          value={state.name}
          onChange={(e) => handleChange(e.target.value)}
          error={validation["name"] ? true : false}
        />
      </InstructionWrapper>
    </Form>
  );
}

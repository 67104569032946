export default function coordinateReferenceSystemToOptions() {
  return [
    { value: "WebMercator", label: "Web Mercator" },
    { value: "GaussConform", label: "Gauss Conform" },
    { value: "TransverseMercator", label: "Transverse Mercator" },
    {
      value: "UniversalTransverseMercator",
      label: "UTM",
    },
  ];
}

import React, { useCallback, useEffect, useMemo, useRef } from "react";
import {
  BiDownArrow,
  BiFontColor,
  BiRuler,
  BiTrash,
  BiUpArrow,
} from "react-icons/bi";
import { Button, Form, Tab, Tabs } from "../../../components";
import {
  Checkbox,
  InstructionWrapper,
  List,
  TextField,
} from "../../../components/fields";
import { useStateReducer } from "../../../hooks";
import { SiteTemplateFull } from "../../../interfaces";
import {
  FormDescriptor,
  FormFull,
  FormMeasurable,
  FormMeasurableGroup,
} from "../../../interfaces/Form";
import ReferenceSummary from "../../../interfaces/ReferenceSummary";
import { fieldFormsService } from "../../../services";
import {
  asyncify,
  classNameBuilder,
  clone,
  hasErrors,
  validateObject,
} from "../../../utilities";
import { Validation } from "../../../utilities/validateObject";

interface GroupEditorProps {
  form: FormFull;
  siteTemplate: SiteTemplateFull;
  group: FormMeasurableGroup;
  onUpdate: (groups: FormMeasurableGroup[]) => void;
}

interface ItemType {
  type: "Measurables" | "Descriptors";
  item: ReferenceSummary;
  mandatory?: boolean;
}

interface State {
  name?: string;
  instructions?: string;
  multiItem?: boolean;
  mandatoryMultiItem?: boolean;
  measurables?: FormMeasurable[];
  descriptors?: FormDescriptor[];
  order?: string[];
  newSelectedItem?: ItemType;
  newIsMandatory?: boolean;
  justMovedField?: string;
}

const determineSelectedItems = (
  order: string[],
  descriptors: FormDescriptor[],
  measurables: FormMeasurable[]
) => {
  const sorted: ItemType[] = [];
  const unsorted: ItemType[] = [];

  order.forEach((o) => {
    const descriptor = descriptors.find((i) => i.id === o);

    if (descriptor) {
      sorted.push({
        type: "Descriptors",
        item: descriptor,
        mandatory: descriptor.mandatory,
      });
      return;
    }

    const measurable = measurables.find((i) => i.id === o);

    if (measurable) {
      sorted.push({
        type: "Measurables",
        item: measurable,
        mandatory: measurable.mandatory,
      });
      return;
    }
  });

  measurables.forEach((i) => {
    const item = sorted.find((o) => o.item.id === i.id);

    if (!item)
      unsorted.push({
        type: "Measurables",
        item: i,
        mandatory: i.mandatory,
      });
  });

  descriptors?.forEach((i) => {
    const item = sorted.find((o) => o.item.id === i.id);

    if (!item)
      unsorted.push({
        type: "Descriptors",
        item: i,
        mandatory: i.mandatory,
      });
  });

  return [...sorted, ...unsorted];
};

export default function GroupEditor({
  form,
  siteTemplate,
  group,
  onUpdate,
}: GroupEditorProps) {
  const timeoutRef = useRef<NodeJS.Timer>();

  const [state, setState] = useStateReducer<State>({
    name: group.name,
    instructions: group.instructions,
    measurables: group.measurables,
    descriptors: group.descriptors,
    multiItem: group.multiItem,
    mandatoryMultiItem: group.mandatoryMultiItem,
    order: group.order,
  });
  const selectedItems = useMemo(
    () =>
      determineSelectedItems(
        state.order ?? [],
        state.descriptors ?? [],
        state.measurables ?? []
      ),
    [state.order, state.descriptors, state.measurables]
  );
  const validationRules = useMemo(
    () =>
      ({
        name: {
          regex: /.+/,
          message: "This field is required.",
        },
      } as Validation),
    []
  );
  const validation = useMemo(
    () => validateObject(validationRules, state),
    [state, validationRules]
  );

  const allItems = useMemo(() => {
    const allMeasurables: ItemType[] = [];
    const allDescriptors: ItemType[] = [];

    siteTemplate.measurableGroups.forEach((g) => {
      g.descriptors.forEach((item) =>
        !allDescriptors.find((i) => i.item.id === item.id)
          ? allDescriptors.push({ type: "Descriptors", item })
          : null
      );
      g.measurables.forEach((item) =>
        !allMeasurables.find((i) => i.item.id === item.id)
          ? allMeasurables.push({ type: "Measurables", item })
          : null
      );
    });

    return [
      ...allMeasurables.sort((a, b) =>
        a.item.name === b.item.name ? 0 : a.item.name > b.item.name ? 1 : -1
      ),
      ...allDescriptors.sort((a, b) =>
        a.item.name === b.item.name ? 0 : a.item.name > b.item.name ? 1 : -1
      ),
    ];
  }, [siteTemplate]);

  const handleUpdateDetails = useCallback(
    async (args: {
      name: string;
      instructions: string;
      order: string[];
      multiItem: boolean;
      mandatoryMultiItem: boolean;
    }) => {
      if (hasErrors(validateObject(validationRules, args))) return;

      const response = await fieldFormsService.update(
        form.id,
        "UpdateMeasurableGroup",
        { ...args, groupId: group.id }
      );

      onUpdate(response.measurableGroups);
    },
    [form.id, group.id, onUpdate, validationRules]
  );

  const handleCheckboxUpdate = async () => {
    await asyncify(handleUpdateDetails, 150);
  };

  const handleAdd = useCallback(async () => {
    const item = state.newSelectedItem;
    const mandatory = state.newIsMandatory;
    const descriptors = clone(state.descriptors ?? []);
    const measurables = clone(state.measurables ?? []);
    const order = clone(state.order ?? []);

    if (!item) return;

    if (item.type === "Descriptors") {
      descriptors.push({
        id: item.item.id,
        name: item.item.name,
        type: "FreeText",
        mandatory: mandatory ?? false,
      });
      order.push(item.item.id);

      await fieldFormsService.update(form.id, "AddDescriptor", {
        groupId: group.id,
        descriptorId: item.item.id,
        isMandatory: mandatory,
      });
    } else if (item.type === "Measurables") {
      measurables.push({
        id: item.item.id,
        name: item.item.name,
        mandatory: mandatory ?? false,
        unitAbbreviation: "",
        unitFull: "",
      });
      order.push(item.item.id);

      await fieldFormsService.update(form.id, "AddMeasurable", {
        groupId: group.id,
        measurableId: item.item.id,
        isMandatory: mandatory,
      });
    }

    await handleUpdateDetails({
      name: state.name ?? "",
      instructions: state.instructions ?? "",
      multiItem: state.multiItem ?? false,
      mandatoryMultiItem: state.mandatoryMultiItem ?? false,
      order: order ?? [],
    });

    setState({
      descriptors,
      measurables,
      order,
      newSelectedItem: undefined,
      newIsMandatory: false,
      justMovedField: item.item.id,
    });
  }, [
    state.name,
    state.instructions,
    state.multiItem,
    state.mandatoryMultiItem,
    state.descriptors,
    state.measurables,
    state.order,
    state.newSelectedItem,
    state.newIsMandatory,
    handleUpdateDetails,
    setState,
    form.id,
    group.id,
  ]);

  const handleRemove = useCallback(
    async (type: "Descriptors" | "Measurables", id: string) => {
      let order = state.order;
      let measurables = state.measurables;
      let descriptors = state.descriptors;

      order = order?.filter((o) => o !== id);

      if (type === "Measurables") {
        measurables = measurables?.filter((m) => m.id !== id);

        await fieldFormsService.update(form.id, "RemoveMeasurable", {
          groupId: group.id,
          measurableId: id,
        });
      } else if (type === "Descriptors") {
        descriptors = descriptors?.filter((m) => m.id !== id);

        await fieldFormsService.update(form.id, "RemoveDescriptor", {
          groupId: group.id,
          descriptorId: id,
        });
      }

      await handleUpdateDetails({
        name: state.name ?? "",
        instructions: state.instructions ?? "",
        multiItem: state.multiItem ?? false,
        mandatoryMultiItem: state.mandatoryMultiItem ?? false,
        order: order ?? [],
      });

      setState({
        measurables,
        descriptors,
        order,
        justMovedField: undefined,
      });
    },
    [
      form.id,
      group.id,
      setState,
      state.name,
      state.instructions,
      state.multiItem,
      state.mandatoryMultiItem,
      state.descriptors,
      state.measurables,
      state.order,
      handleUpdateDetails,
    ]
  );

  const handleMove = useCallback(
    async (id: string, direction: "up" | "down") => {
      if (!selectedItems) return;

      const currentIndex = selectedItems.findIndex((i) => i.item.id === id);

      if (currentIndex < 0) return;

      const itemsBefore = selectedItems.slice(0, currentIndex);
      const itemsAfter = selectedItems.slice(
        currentIndex + 1,
        selectedItems.length
      );

      const order: string[] = [];

      if (direction === "down") {
        itemsBefore.forEach((i) => {
          order.push(i.item.id);
        });
        order.push(itemsAfter[0].item.id);
        order.push(id);
        itemsAfter.slice(1).forEach((i) => {
          order.push(i.item.id);
        });
      } else {
        itemsBefore.slice(0, currentIndex - 1).forEach((i) => {
          order.push(i.item.id);
        });
        order.push(id);
        order.push(itemsBefore[itemsBefore.length - 1].item.id);
        itemsAfter.forEach((i) => {
          order.push(i.item.id);
        });
      }

      await handleUpdateDetails({
        name: state.name ?? "",
        instructions: state.instructions ?? "",
        multiItem: state.multiItem ?? false,
        mandatoryMultiItem: state.mandatoryMultiItem ?? false,
        order,
      });

      setState({
        order,
        justMovedField: id,
      });
    },
    [
      selectedItems,
      state.name,
      state.instructions,
      state.multiItem,
      state.mandatoryMultiItem,
      setState,
      handleUpdateDetails,
    ]
  );

  useEffect(() => {
    if (state.justMovedField) clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      setState({ justMovedField: undefined });
    }, 1500);
  }, [state.justMovedField, setState]);

  return (
    <Tabs disableRouteNavigation className="measurable-group-editor-modal">
      <Tab
        key="group-overview-tab"
        identifier="group-overview-tab"
        heading="Overview"
      >
        <Form>
          <InstructionWrapper
            text="The name of the group."
            error={validation["name"]}
          >
            <TextField
              label="Name"
              required
              value={state.name}
              onChange={(e) => setState({ name: e.target.value })}
              onBlur={() =>
                handleUpdateDetails({
                  name: state.name ?? "",
                  instructions: state.instructions ?? "",
                  multiItem: state.multiItem ?? false,
                  mandatoryMultiItem: state.mandatoryMultiItem ?? false,
                  order: state.order ?? [],
                })
              }
              error={validation["name"] ? true : false}
            />
          </InstructionWrapper>
          <InstructionWrapper text="The instructions to be presented to users when completing this group.">
            <TextField
              label="Instructions"
              value={state.instructions}
              onChange={(e) => setState({ instructions: e.target.value })}
              onBlur={() =>
                handleUpdateDetails({
                  name: state.name ?? "",
                  instructions: state.instructions ?? "",
                  multiItem: state.multiItem ?? false,
                  mandatoryMultiItem: state.mandatoryMultiItem ?? false,
                  order: state.order ?? [],
                })
              }
              multiline
            />
          </InstructionWrapper>
          <InstructionWrapper text="Allow capturing this group more than once during a single measurement.">
            <Checkbox
              label="Optional multi-capture"
              value={state.multiItem ?? false}
              onChange={(e) => {
                setState({
                  multiItem: e.target.checked,
                  mandatoryMultiItem: false,
                });
                handleCheckboxUpdate();
              }}
            />
          </InstructionWrapper>
          {state.multiItem && (
            <InstructionWrapper text="Force capturing this group more than once during a single measurement.">
              <Checkbox
                label="Mandatory multi-capture"
                value={state.mandatoryMultiItem ?? false}
                onChange={(e) => {
                  setState({ mandatoryMultiItem: e.target.checked });
                  handleCheckboxUpdate();
                }}
              />
            </InstructionWrapper>
          )}
        </Form>
      </Tab>
      <Tab
        key="group-fields-tab"
        identifier="group-fields-tab"
        heading="Fields"
      >
        <Form>
          {selectedItems?.length ? (
            selectedItems?.map((item, i) => (
              <div
                key={`selected-item-${item.item.id}`}
                className={classNameBuilder(
                  "selected-form-field",
                  state.justMovedField === item.item.id ? "moved" : ""
                )}
              >
                <span className="s-icon">
                  {item.type === "Descriptors" ? <BiFontColor /> : <BiRuler />}
                </span>
                <span className="s-name">{item.item.name}</span>
                <Checkbox label="Mandatory" value={item.mandatory} readOnly />
                <Button
                  icon={BiUpArrow}
                  disabled={i === 0}
                  onClick={() => handleMove(item.item.id, "up")}
                />
                <Button
                  icon={BiDownArrow}
                  disabled={i === selectedItems.length - 1}
                  onClick={() => handleMove(item.item.id, "down")}
                />
                <Button
                  icon={BiTrash}
                  className="delete"
                  onClick={() => handleRemove(item.type, item.item.id)}
                />
              </div>
            ))
          ) : (
            <div className="no-selected-form-fields">No fields selected</div>
          )}
          <div className="add-form-field">
            <List
              label="Field To Add"
              options={allItems.filter((i) => {
                if (selectedItems?.find((s) => s.item.id === i.item.id))
                  return false;

                for (const grp of form.measurableGroups.filter(
                  (g) => g.id !== group.id
                ))
                  if (grp.order.find((o) => o === i.item.id)) return false;

                return true;
              })}
              value={state.newSelectedItem}
              getOptionValue={(o: ItemType) => o.item.id}
              getOptionLabel={(o: ItemType) => o.item.name}
              isOptionEqualToValue={(o: ItemType, v: ItemType) =>
                o.item.id === v.item.id
              }
              groupBy={(o) => o.type}
              onChange={(_, v: ItemType) => setState({ newSelectedItem: v })}
            />
            <Checkbox
              label="Mandatory"
              value={state.newIsMandatory ?? false}
              onChange={(e) => setState({ newIsMandatory: e.target.checked })}
            />
            <Button
              text="Add"
              primary
              raised
              onClick={handleAdd}
              disabled={state.newSelectedItem ? false : true}
            />
          </div>
        </Form>
      </Tab>
    </Tabs>
  );
}

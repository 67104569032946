import mapboxgl from "mapbox-gl";
import React, { useEffect, useRef } from "react";
import { asyncify, classNameBuilder } from "../../utilities";
import "mapbox-gl/dist/mapbox-gl.css";
import { useStateReducer } from "../../hooks";
import Clickable from "../clickable/Clickable";
import {
  BiLandscape,
  BiMapAlt,
  BiPointer,
  BiTargetLock,
  BiZoomIn,
  BiZoomOut,
} from "react-icons/bi";
import "./_styles.scss";

interface MapDataProps {
  latitude: number;
  longitude: number;
  className?: string;
}

interface State {
  latitude: number;
  longitude: number;
}

interface InteractionState {
  loaded?: boolean;
  tileset?: "raster" | "vector";
  mode?: "interactive" | "fixed";
  zoomLevel?: number;
}

const latLngInValid = (lat: number, lng: number) =>
  lat > 90 || lat < -90 || lng > 180 || lng < -180;

export default function MapSinglePoint({
  latitude,
  longitude,
  className,
}: MapDataProps) {
  const mapContainerRef = useRef(null);
  const mapRef = useRef<mapboxgl.Map>();
  const marker = useRef<mapboxgl.Marker>();
  const [state, setState] = useStateReducer<State>({
    latitude,
    longitude,
  });
  const [interactionState, setInteractionState] =
    useStateReducer<InteractionState>({
      tileset: "vector",
      mode: "fixed",
      zoomLevel: 12,
      loaded: false,
    });

  useEffect(() => {
    asyncify(() => {
      if (mapRef.current) {
        mapRef.current?.flyTo({
          center: [state.longitude, state.latitude],
          essential: true,
          zoom: interactionState.zoomLevel,
        });
        return;
      }

      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current as any,
        style:
          interactionState.tileset === "raster"
            ? "mapbox://styles/mapbox/satellite-streets-v11"
            : "mapbox://styles/mapbox/streets-v11",
        center: !latLngInValid(latitude, longitude)
          ? [state.longitude, state.latitude]
          : [0, 0],
        zoom: interactionState.zoomLevel,
        interactive: false,
      });

      handlePlaceMarker();

      setInteractionState({ loaded: true });
    }, 500);
  }, []);

  useEffect(() => {
    if (!interactionState.loaded) return;

    mapRef.current?.setStyle(
      interactionState.tileset === "raster"
        ? "mapbox://styles/mapbox/satellite-streets-v11"
        : "mapbox://styles/mapbox/streets-v11"
    );
  }, [interactionState.tileset]);

  useEffect(() => {
    if (!interactionState.loaded) return;

    if (!interactionState.zoomLevel) return;

    mapRef.current?.flyTo({
      center: [state.longitude, state.latitude],
      essential: true,
      zoom: interactionState.zoomLevel,
    });
  }, [interactionState.zoomLevel]);

  useEffect(() => {
    if (!interactionState.loaded) return;

    if (interactionState.mode === "fixed") {
      mapRef.current?.touchZoomRotate.disable();
      mapRef.current?.touchPitch.disable();
      mapRef.current?.scrollZoom.disable();
      mapRef.current?.keyboard.disable();
      mapRef.current?.dragRotate.disable();
      mapRef.current?.dragPan.disable();
      mapRef.current?.doubleClickZoom.disable();
      mapRef.current?.boxZoom.disable();

      mapRef.current?.flyTo({
        center: [state.longitude, state.latitude],
        essential: true,
        zoom: interactionState.zoomLevel,
      });
    } else {
      mapRef.current?.touchZoomRotate.enable();
      mapRef.current?.touchPitch.enable();
      mapRef.current?.scrollZoom.enable();
      mapRef.current?.keyboard.enable();
      mapRef.current?.dragRotate.enable();
      mapRef.current?.dragPan.enable();
      mapRef.current?.doubleClickZoom.enable();
      mapRef.current?.boxZoom.enable();
    }
  }, [interactionState.mode]);

  useEffect(() => {
    marker.current?.remove();
    handlePlaceMarker();
  }, [state.latitude, state.longitude]);

  const handlePlaceMarker = () => {
    if (latLngInValid(state.latitude, state.longitude)) {
      marker.current = undefined;
      return;
    }

    marker.current = new mapboxgl.Marker()
      .setLngLat([state.longitude, state.latitude])
      .addTo(mapRef.current as any);

    mapRef.current?.flyTo({
      center: [state.longitude, state.latitude],
      essential: true,
      zoom: interactionState.zoomLevel,
    });
  };

  const valueUpdated =
    state.latitude !== latitude || state.longitude !== longitude;

  return (
    <div
      className={classNameBuilder(
        "h-map-view-page-map-wrapper single-point",
        className ?? ""
      )}
    >
      <div ref={mapContainerRef} className="h-map-view-page-map" />
      <div className="map-button-container">
        {interactionState.tileset === "raster" ? (
          <Clickable
            className="map-button"
            onClick={() => setInteractionState({ tileset: "vector" })}
            title="View vector map"
          >
            <BiMapAlt />
          </Clickable>
        ) : (
          <Clickable
            className="map-button"
            onClick={() => setInteractionState({ tileset: "raster" })}
            title="View raster map"
          >
            <BiLandscape />
          </Clickable>
        )}
        {interactionState.mode === "fixed" ? (
          <>
            <Clickable
              className="map-button"
              onClick={() =>
                setInteractionState({
                  zoomLevel: (interactionState.zoomLevel ?? 1) + 1,
                })
              }
              title="Zoom in"
            >
              <BiZoomIn />
            </Clickable>
            <Clickable
              className="map-button"
              onClick={() =>
                setInteractionState({
                  zoomLevel: (interactionState.zoomLevel ?? 1) - 1,
                })
              }
              title="Zoom out"
            >
              <BiZoomOut />
            </Clickable>
            <Clickable
              className="map-button"
              onClick={() => setInteractionState({ mode: "interactive" })}
              title="Enable interactive mode"
            >
              <BiPointer />
            </Clickable>
          </>
        ) : (
          <Clickable
            className="map-button"
            onClick={() => setInteractionState({ mode: "fixed" })}
            title="Disable interactive mode"
          >
            <BiTargetLock />
          </Clickable>
        )}
      </div>
      {valueUpdated ? (
        <div
          className="changed-overlay"
          onClick={() => setState({ latitude, longitude })}
        >
          Supplied coordinates have changed. Click to rerender the map.
        </div>
      ) : null}
    </div>
  );
}

import React from "react";

export default function BoxAndWhiskerChartIcon() {
  return (
    <svg
      viewBox="0 0 15.875 15.875"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      height="1em"
      width="1em"
      stroke="currentColor"
    >
      <g>
        <path
          d="M 4.3286313,2.5761776 V 6.3835077"
          style={{ strokeWidth: 1.3 }}
        />
        <path
          d="M 6.5794224,2.4985267 H 2.0815057"
          style={{ strokeWidth: 1.3 }}
        />
        <path
          d="M 6.5794224,13.781615 H 2.0815057"
          style={{ strokeWidth: 1.3 }}
        />
        <path
          d="M 14.016834,14.269567 H 9.5189178"
          style={{ strokeWidth: 1.3 }}
        />
        <path
          d="M 14.016834,1.2557956 H 9.5189178"
          style={{ strokeWidth: 1.3 }}
        />
        <path
          d="m 4.3286313,10.994726 v 2.794792"
          style={{ strokeWidth: 1.3 }}
        />
        <path
          d="M 2.183023,6.4387174 H 6.4857275 V 10.958987 H 2.183023 Z"
          style={{ fill: "transparent", strokeWidth: 1.3 }}
        />
        <path
          d="M 11.758678,1.3229167 V 4.2875652"
          style={{ strokeWidth: 1.3 }}
        />
        <path
          d="M 11.758678,7.8031757 V 14.257194"
          style={{ strokeWidth: 1.3 }}
        />
        <path
          d="M 9.6032576,4.3028379 H 13.925586 V 7.7821636 H 9.6032576 Z"
          style={{ fill: "transparent", strokeWidth: 1.3 }}
        />
      </g>
    </svg>
  );
}

import Constants from "../Constants";
import { Series } from "../interfaces";
import calculateSeriesRows from "./calculateSeriesRows";

export default function calculatePlotHeight<TData>(
  data: TData[][],
  series?: Series<TData>,
  xAxisLabelHeight?: number,
  bandAndGuideLegendHeight?: number,
  canvas?: HTMLCanvasElement
) {
  const { plotHeight, plotBottomPadding } = Constants;
  let xAxisLabelSpace = xAxisLabelHeight ?? plotBottomPadding;

  if (series?.location !== "Bottom")
    return plotHeight - xAxisLabelSpace - (bandAndGuideLegendHeight ?? 0);

  const labelRows = calculateSeriesRows(data, series, canvas);

  return (
    plotHeight -
    xAxisLabelSpace -
    labelRows.totalHeight -
    (bandAndGuideLegendHeight ?? 0)
  );
}

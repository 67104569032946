import React, { useRef, useState, useCallback } from "react";
import { ButtonBase } from "@mui/material";
import List from "./List";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { classNameBuilder } from "../../utilities";
import { IconType } from "react-icons/lib";
import Button from "../button/Button";

interface SearchAndChipsProps<T> {
  label?: string;
  error?: boolean;
  values?: T[];
  className?: string;
  id?: string;
  onSelect?: (value: T) => void;
  onRemove?: (label: string, value: string | number, option: T) => void;
  onSearch?: (search: string) => Promise<T[]>;
  disabled?: boolean;
  readOnly?: boolean;
  getOptionLabel: (option: T) => string;
  getOptionValue: (option: T) => string | number;
  groupBy?: (option: T) => string;
  inputValue?: string;
  placeholder?: string;
  addProps?: {
    buttonIcon?: IconType;
    onAdd?: (value: string) => void;
  };
  hideChips?: boolean;
  singleSelect?: boolean;
}

export default function SearchAndChips<T>({
  label,
  className,
  values,
  id,
  onSelect,
  error,
  disabled,
  readOnly,
  getOptionLabel,
  getOptionValue,
  groupBy,
  inputValue,
  placeholder,
  onSearch,
  onRemove,
  addProps,
  hideChips,
  singleSelect,
}: SearchAndChipsProps<T>) {
  const [options, setOptions] = useState<
    (T | { isAddOption: boolean; addValue: string })[]
  >([]);
  const debounceTimeout = useRef<NodeJS.Timeout | undefined>();

  const populateOptions = useCallback(
    async (search: string) => {
      const options: (T | { isAddOption: boolean; addValue: string })[] =
        (await onSearch?.(search)) ?? [];

      if (addProps) options.push({ isAddOption: true, addValue: search });

      setOptions(options);
    },
    [onSearch, setOptions, addProps]
  );

  const handleInputChange = useCallback(
    (value: string) => {
      if (debounceTimeout.current) clearTimeout(debounceTimeout.current);

      debounceTimeout.current = setTimeout(() => {
        populateOptions(value);
      }, 500);
    },
    [populateOptions]
  );

  const handleChange = useCallback(
    (value: any) => {
      if (value.isAddOption) {
        addProps?.onAdd?.(value.addValue);
        return;
      }
      if (
        values?.filter((v) => getOptionValue(v) === getOptionValue(value))
          ?.length
      )
        return;

      onSelect?.(value);
    },
    [addProps, values, getOptionValue, onSelect]
  );

  return (
    <div className="h-app-search-and-chips-field">
      {readOnly ? (
        <h3>{label}</h3>
      ) : (
        <List
          label={label}
          className={className}
          id={id}
          onChange={(_, v) => handleChange(v)}
          error={error}
          disabled={disabled}
          readOnly={readOnly}
          getOptionLabel={(option) =>
            option.isAddOption ? "##ADD##" : getOptionLabel(option)
          }
          getOptionValue={(option) =>
            option.isAddOption ? "##ADD##" : getOptionValue(option)
          }
          groupBy={groupBy}
          onInputChange={(_, v) => handleInputChange(v)}
          inputValue={inputValue}
          placeholder={placeholder}
          options={options}
          blurOnSelect
          onFocus={() => populateOptions("")}
          optionRenderer={(props, option) => {
            if (option.isAddOption)
              return (
                <li {...props} key="add-option">
                  <Button
                    className="h-search-and-chips-add-option"
                    text={
                      option.addValue
                        ? `Add "${option.addValue}"`
                        : "Add empty option"
                    }
                    icon={addProps?.buttonIcon}
                    primary
                  />
                </li>
              );

            return (
              <li
                {...props}
                key={
                  getOptionValue?.(option) ??
                  getOptionLabel?.(option) ??
                  new Date().getTime()
                }
              >
                {getOptionLabel?.(option) ?? option}
              </li>
            );
          }}
          customFilter={() => true}
        />
      )}
      {!hideChips ? (
        <div className="chips">
          {values && values.length > 0 ? (
            values.map((v) => {
              const value = getOptionValue(v);
              const display = getOptionLabel(v);

              return (
                <span
                  className={classNameBuilder(
                    "chip",
                    singleSelect ? "single-select" : "",
                    readOnly ? "read-only" : ""
                  )}
                  key={`chip-${value}`}
                  title={display}
                >
                  {display}
                  {readOnly ? null : (
                    <ButtonBase
                      className="delete-chip"
                      onClick={() => onRemove?.(display, value, v)}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </ButtonBase>
                  )}
                </span>
              );
            })
          ) : (
            <span className="chip no-data">No items selected</span>
          )}
        </div>
      ) : null}
    </div>
  );
}

import React, { useEffect, useCallback, useRef, useMemo } from "react";
import Form from "../../../components/form/Form";
import {
  DatePicker,
  InstructionWrapper,
  List,
  TextField,
} from "../../../components/fields";
import { publish, useStateReducer } from "../../../hooks";
import {
  NavigableComponent,
  DescriptorFull,
  DescriptorType,
} from "../../../interfaces";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Chip } from "@mui/material";
import { OptionsBuilder } from "../../../components/fields/OptionsBuilder";
import { hideAlert, showAlert } from "../../../components/alert/Alert";
import { AlertTypeEnum, StateTopicEnum } from "../../../enums";
import { routes } from "../../../_config";
import { FaSave, FaTimes, FaTrash } from "react-icons/fa";
import { ToolItem } from "../../../components/toolbar/Toolbar";
import { asyncify, getQueryParameter } from "../../../utilities";
import "../_styles.scss";
import { descriptorsService } from "../../../services";
import { LibrarySection } from "../../../components/library/Library";
import validateObject, {
  hasErrors,
  Validation,
} from "../../../utilities/validateObject";
import { DescriptorUpdateActions } from "../../../services/descriptorsService";

interface EditDescriptorDataProps {
  descriptor: DescriptorFull;
}

interface State {
  id?: string;
  name?: string;
  type?: DescriptorType;
  options?: string[];
  addedOptions?: string[];
  removedOptions?: string[];
  date?: Date;
}

export default function EditDescriptor({
  setToolbarConfig,
  descriptor,
}: EditDescriptorDataProps & NavigableComponent) {
  const { settingId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const mode = getQueryParameter(location.search, "mode");
  const [state, setState] = useStateReducer<State>({
    name: descriptor.name,
    type: descriptor.type || "FreeText",
    options: (descriptor.options as string[]).sort() || ["Item 1", "Item 2"],
    date: descriptor.date,
  });
  const saveState = useRef<State>(state);
  const validationRules: Validation = useMemo(
    () => ({
      name: {
        regex: /.+/,
        message: "This field is required.",
      },
      type: {
        regex: /.+/,
        message: "This field is required.",
      },
    }),
    []
  );

  const validation = validateObject(validationRules, state);

  const handleSave = useCallback(async () => {
    await asyncify(() => {}, 10);

    if (hasErrors(validateObject(validationRules, saveState.current))) return;

    const doUpdate = async (
      action: DescriptorUpdateActions,
      definition: any
    ) => {
      const response = await descriptorsService.update?.(
        descriptor.id,
        action,
        definition
      );

      if (!response?.id) {
        showAlert({
          content: "Could not update the descriptor.",
          options: {
            type: AlertTypeEnum.Error,
            actions: [{ text: "Ok" }],
          },
        });
        return false;
      }

      return true;
    };

    if (
      !(await doUpdate("UpdateDetails", {
        name: saveState.current.name,
        type: saveState.current.type,
        date: saveState.current.date,
      }))
    )
      return;

    if (saveState.current.addedOptions)
      await Promise.all(
        saveState.current.addedOptions.map((o) =>
          doUpdate("AddDescriptorOption", {
            option: o,
            descriptorId: descriptor.id,
          })
        )
      );

    if (saveState.current.removedOptions)
      await Promise.all(
        saveState.current.removedOptions.map((o) =>
          doUpdate("RemoveDescriptorOption", {
            option: o,
            descriptorId: descriptor.id,
          })
        )
      );

    publish<LibrarySection>(StateTopicEnum.LibrarySectionRefresh, "paged");
    navigate(routes.settingItem.go("descriptors-library", settingId));

    showAlert({
      content: "Descriptor saved.",
      options: {
        type: AlertTypeEnum.Info,
        actions: [
          {
            text: "Ok",
            primary: true,
          },
        ],
      },
    });
  }, [navigate, settingId, descriptor.id, validationRules]);

  const handleDiscard = useCallback(() => {
    showAlert({
      content:
        "Are you sure you want to discard this draft descriptor? This action is not reversable.",
      options: {
        type: AlertTypeEnum.Warning,
        actions: [
          {
            text: "Yes",
            primary: true,
            onClick: async () => {
              hideAlert();

              const response = await descriptorsService.delete!(settingId!);

              if (response?.message)
                navigate(routes.settingCategory.go("descriptors-library"));
            },
          },
          { text: "No" },
        ],
      },
    });
  }, [navigate, settingId]);

  useEffect(() => {
    saveState.current = state;
  }, [state]);

  useEffect(() => {
    let toolbarItems: (ToolItem | "|")[] = [];

    toolbarItems =
      mode !== "edit"
        ? [
            {
              label: "Save",
              icon: FaSave,
              onClick: handleSave,
              primary: true,
              raised: true,
            },
            "|",
            {
              label: "Discard",
              icon: FaTrash,
              onClick: handleDiscard,
            },
          ]
        : [
            {
              label: "Save",
              icon: FaSave,
              onClick: handleSave,
              primary: true,
              raised: true,
            },
            "|",
            {
              label: "Cancel",
              icon: FaTimes,
              onClick: () => {
                navigate(
                  routes.settingItem.go("descriptors-library", settingId)
                );
              },
            },
          ];

    setToolbarConfig({
      allowSearch: true,
      toolbarItems,
      searchPath: routes.descriptors.go(),
    });
  }, [handleSave, handleDiscard, mode, navigate, setToolbarConfig, settingId]);

  const addOption = (value: string) => {
    const options = state.options ?? [];
    options.push(value);
    const addedOptions = state.addedOptions ?? [];
    addedOptions.push(value);

    setState({ options, addedOptions });
  };

  const removeOption = (value: string) => {
    const options = state.options ?? [];
    options.splice(
      options.findIndex((o) => o === value),
      1
    );
    const removedOptions = state.removedOptions ?? [];
    removedOptions.push(value);

    setState({ options, removedOptions });
  };

  return (
    <Form title="Identifying Information">
      <InstructionWrapper
        text="An identifying name for the descriptor (i.e. type of material)."
        error={validation["name"]}
      >
        <TextField
          label="Name"
          value={state.name}
          onChange={(e) => setState({ name: e.target.value })}
          error={validation["name"] ? true : false}
        />
      </InstructionWrapper>
      <InstructionWrapper text="Add a custom date for this measurement." row>
        <DatePicker
          label="Date"
          value={state.date}
          onChange={(date) => date && setState({ date })}
        />
      </InstructionWrapper>
      <InstructionWrapper
        text="The type of input to present users with. Specified Items will present users with a predefined list, while Free Text allows any value to be entered."
        error={validation["type"]}
      >
        <List
          label="Type"
          value={state.type}
          options={[
            { label: "Free Text", value: "FreeText" },
            { label: "Specified Items", value: "SpecifiedItems" },
          ]}
          getOptionLabel={(o) => o.label}
          getOptionValue={(o) => o.value}
          isOptionEqualToValue={(o, v) => o.value === v.value}
          onChange={(_, v) => {
            setState({ type: v.value });
          }}
          error={!state.type}
        />
      </InstructionWrapper>
      {state.type === "SpecifiedItems" ? (
        <InstructionWrapper text="Use this section to specify options to select from when capturing a field form.">
          <OptionsBuilder
            onUpdateValue={addOption}
            error={!state.options?.length}
          />
          {state.options?.map((o, i) => {
            return (
              <Chip
                key={`option-${i}`}
                label={o}
                onDelete={() => removeOption(o)}
                className="option-chip"
              />
            );
          })}
        </InstructionWrapper>
      ) : null}
    </Form>
  );
}

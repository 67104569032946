import React from "react";
import { PatternKey, Patterns } from "./Patterns";

interface SvgPatternProps {
  pattern: PatternKey;
  height: number;
  width: number;
  fill: string;
  scale?: number;
  className?: string;
}

export default function SvgPattern({
  pattern,
  height,
  width,
  fill,
  scale,
  className,
}: SvgPatternProps) {
  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      className={className}
    >
      <defs>{Patterns[pattern]}</defs>
      <rect x1={0} y1={0} height={height} width={width} fill={fill} />
      <rect
        x1={0}
        y1={0}
        height={height}
        width={width}
        fill={`url(#${pattern})`}
        transform={`scale(${scale ?? 3})`}
      />
    </svg>
  );
}

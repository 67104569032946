export default function fileToBase64(file: File) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const res = reader.result as string;

      if (!res) {
        reject();
        return;
      }

      resolve(res.substring(res.indexOf(",") + 1));
    };
    reader.onerror = reject;
  });
}

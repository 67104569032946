import React from "react";

export default function StiffChartIcon() {
  return (
    <svg
      viewBox="0 0 15.875 15.875"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      height="1em"
      width="1em"
      stroke="currentColor"
    >
      <g>
        <path
          d="M 7.9374998,1.3229167 V 14.552083"
          style={{ strokeWidth: 1.3 }}
        />
        <path
          d="M 14.552083,14.546395 H 1.3229166"
          style={{ strokeWidth: 1.3 }}
        />
        <path
          d="m 1.6972811,3.6726062 10.3910669,0 -2.2683766,4.5429268 4.2916446,3.755242 H 3.2020488 L 4.6817374,8.2704876 Z"
          style={{ fill: "transparent", strokeWidth: 1.3 }}
        />
      </g>
    </svg>
  );
}

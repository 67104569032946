import React, {
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import { classNameBuilder } from "../../utilities";
import Clickable from "../clickable/Clickable";
import Scrollable from "../scrollable/Scrollable";
import { TabProps } from "./Tab";
import "./_styles.scss";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

interface TabsProps {
  className?: string;
  children: ReactElement<TabProps> | ReactElement<TabProps>[];
  disableRouteNavigation?: boolean;
}

export default function Tabs({
  className,
  children,
  disableRouteNavigation,
}: TabsProps) {
  const tabBarRef = useRef(null);
  const scrollPlaceholderRef = useRef<HTMLSpanElement>(null);
  const isScrollingRef = useRef(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentTab, setCurrentTab] = useState<{
    props: TabProps;
    index: number;
  }>();
  const [hasOverflow, setHasOverflow] = useState(false);
  const toMap = useMemo(
    () =>
      !Array.isArray(children)
        ? [children as ReactElement<TabProps>]
        : (children as ReactElement<TabProps>[]),
    [children]
  );

  const handleTabClick = (props: TabProps, index: number) => {
    if (!disableRouteNavigation) {
      searchParams.set("tab", props.identifier);
      setSearchParams(searchParams);
    }

    setCurrentTab({ props, index });
    props.onShow?.();
  };

  useEffect(() => {
    if (currentTab || !toMap.length) return;

    const identifer =
      !disableRouteNavigation && searchParams.has("tab")
        ? searchParams.get("tab")
        : undefined;

    let index = 0;
    let tab = identifer
      ? toMap.filter((v, i) => {
          if (v.props.identifier === identifer) {
            index = i;
            return true;
          }

          return false;
        })[0]
      : toMap[0];

    if (!tab) {
      index = 0;
      tab = toMap[0];
    }

    setCurrentTab({ index, props: tab.props });
  }, [children, currentTab, disableRouteNavigation, searchParams, toMap]);

  useEffect(() => {
    const resizeHandler = () => {
      if (!tabBarRef.current) return;

      const tabBar = tabBarRef.current as HTMLDivElement;

      if (scrollPlaceholderRef.current)
        scrollPlaceholderRef.current.style.marginLeft = "0px";

      setHasOverflow(tabBar.scrollWidth > tabBar.clientWidth);
    };

    window.addEventListener("resize", resizeHandler);
    resizeHandler();

    return () => window.removeEventListener("resize", resizeHandler);
  }, [setHasOverflow]);

  const setScrolling = (scrolling: boolean) => {
    isScrollingRef.current = scrolling;
  };

  const handleScroll = (increment: number) => {
    if (!scrollPlaceholderRef.current || !isScrollingRef.current) return;

    const currentScroll = parseFloat(
      scrollPlaceholderRef.current.style.marginLeft.replace("px", "") || "0"
    );
    let newScroll = currentScroll + increment;

    if (newScroll > 0) newScroll = 0;

    if (tabBarRef.current && increment < 0) {
      const tabBar = tabBarRef.current as HTMLDivElement;

      if (tabBar.scrollWidth <= tabBar.offsetWidth) newScroll = currentScroll;
    }

    scrollPlaceholderRef.current.style.marginLeft = `${newScroll}px`;

    requestAnimationFrame(() => handleScroll(increment));
  };

  return (
    <div className={classNameBuilder("h-app-tabs-container", className ?? "")}>
      <div
        className={classNameBuilder(
          "h-app-tab-bar",
          hasOverflow ? "overflow" : ""
        )}
        ref={tabBarRef}
      >
        <span ref={scrollPlaceholderRef} className="scroll-placeholder" />
        {toMap.map((tab, i) => (
          <Clickable
            key={`tab-${i}`}
            onClick={() => handleTabClick(tab.props, i)}
            className={i + 1 === toMap.length ? "last-tab" : ""}
          >
            <span
              className={classNameBuilder(
                "h-app-tab",
                tab.props.className ?? ""
              )}
            >
              {tab.props.heading}
              <i className={currentTab?.index === i ? "selected" : ""} />
            </span>
          </Clickable>
        ))}
        {hasOverflow ? (
          <>
            <span className="scroll-button-placeholder" />
            <Clickable
              className="tab-scroll-left"
              onMouseDown={() => {
                setScrolling(true);
                handleScroll(3);
              }}
              onMouseUp={() => {
                setScrolling(false);
              }}
            >
              <FaChevronLeft />
            </Clickable>
            <Clickable
              className="tab-scroll-right"
              onMouseDown={() => {
                setScrolling(true);
                handleScroll(-3);
              }}
              onMouseUp={() => {
                setScrolling(false);
              }}
            >
              <FaChevronRight />
            </Clickable>
          </>
        ) : undefined}
        <i className="bottom-border" />
      </div>
      <div className="h-app-tab-content">
        {toMap.map((tab, i) => (
          <Scrollable
            key={`tab-content-${i}`}
            className={classNameBuilder(
              "tab-content",
              currentTab?.index === i ? "selected" : ""
            )}
          >
            {tab.props.children}
          </Scrollable>
        ))}
      </div>
    </div>
  );
}

import React, { useEffect } from "react";
import Form from "../../../components/form/Form";
import {
  InstructionWrapper,
  SearchAndChips,
  TextField,
} from "../../../components/fields";
import { NavigableComponent, UserFull } from "../../../interfaces";
import { FaArrowLeft, FaEdit, FaTrash } from "react-icons/fa";
import { hideAlert, showAlert } from "../../../components/alert/Alert";
import { AlertTypeEnum, StateTopicEnum } from "../../../enums";
import { routes } from "../../../_config";
import { useNavigate, useParams } from "react-router-dom";
import { usersService } from "../../../services";
import "../_styles.scss";
import { LibrarySection } from "../../../components/library/Library";
import { publish, useAuthentication } from "../../../hooks";
import { ToolItem } from "../../../components/toolbar/Toolbar";

interface ViewFieldDataProps {
  user: UserFull;
}

export default function ViewUser({
  setToolbarConfig,
  user,
}: ViewFieldDataProps & NavigableComponent) {
  const auth = useAuthentication();
  const { settingId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    let toolbarItems: (ToolItem | "|")[] = [
      {
        label: "Back",
        icon: FaArrowLeft,
        primary: true,
        raised: true,
        onClick: () => navigate(routes.settingCategory.go("users-library")),
      },
    ];

    const canEdit = auth?.hasPermission("EditUser");
    const canDelete = auth?.hasPermission("RemoveUser");

    if (canEdit || canDelete) toolbarItems.push("|");

    if (canEdit)
      toolbarItems.push({
        label: "Edit",
        icon: FaEdit,
        onClick: () =>
          navigate(
            `${routes.settingItem.go("users-library", settingId)}?mode=edit`
          ),
      });

    if (canDelete)
      toolbarItems.push({
        label: "Delete",
        icon: FaTrash,
        onClick: () => {
          showAlert({
            content:
              "Are you sure you want to delete this user? This action is not reversable.",
            options: {
              type: AlertTypeEnum.Warning,
              actions: [
                {
                  text: "Yes",
                  primary: true,
                  onClick: async () => {
                    const response = await usersService.delete?.(
                      settingId ?? ""
                    );

                    hideAlert();

                    if (response?.message) {
                      navigate(routes.settingCategory.go("users-library"));
                      publish<LibrarySection>(
                        StateTopicEnum.LibrarySectionRefresh,
                        "paged"
                      );
                    }
                  },
                },
                { text: "No" },
              ],
            },
          });
        },
      });

    setToolbarConfig({
      allowSearch: true,
      toolbarItems,
      searchPath: routes.users.go(),
    });
  }, [navigate, setToolbarConfig, settingId]);

  return (
    <>
      <Form title="Identifying Information">
        <InstructionWrapper text="The account name that the user logs onto Zamanzi with.">
          <TextField label="Account Name" value={user.accountName} readOnly />
        </InstructionWrapper>
        <InstructionWrapper text="The name and surname of the user." row>
          <TextField label="Name" value={user.name} readOnly />
          <TextField label="Surname" value={user.surname} readOnly />
        </InstructionWrapper>
        <InstructionWrapper text="The email address where the user can access communication.">
          <TextField label="Email Address" value={user.emailAddress} readOnly />
        </InstructionWrapper>
      </Form>
      <Form title="System Roles">
        <InstructionWrapper text="The system roles assigned to this user.">
          <SearchAndChips
            readOnly
            values={user.roles}
            getOptionLabel={(o) => o.name}
            getOptionValue={(o) => o.id}
          />
        </InstructionWrapper>
      </Form>
    </>
  );
}

import { StateTopicEnum } from "../enums";
import { publish } from "../hooks";
import { Permission, UserFull } from "../interfaces";
import LoginResult from "../interfaces/LoginResult";
import LoginReturn from "../interfaces/LoginReturn";
import { asyncify } from "../utilities";
import { configureGoFetch, contentTypes, goFetch } from "../utilities/goFetch";

const authTokenKey = "zamanzi_token";

interface LoginArgs {
  username: string;
  password: string;
}

interface OTPArgs {
  userId: string;
  otp: string;
}

interface GetProfileArgs {
  username: string;
}

const controller = "auth";

class Service {
  checkAuthentication() {
    const user = localStorage.getItem("user");
    const permissions = localStorage.getItem("permissions");

    return {
      user: user ? (JSON.parse(user) as UserFull) : undefined,
      permissions: permissions
        ? (JSON.parse(permissions) as Permission[])
        : undefined,
    };
  }

  getAuthToken() {
    return localStorage.getItem(authTokenKey);
  }

  async login({ username, password }: LoginArgs): Promise<LoginReturn | null> {
    const response = await goFetch(`${controller}/sign-in`)
      .withBody({ username, password })
      .post();

    if (!response) return { loginSuccess: false, otpRequired: false };

    if (response.token === "") {
      return { loginSuccess: true, otpRequired: true };
    }

    if (response.token) localStorage.setItem(authTokenKey, response.token);
    localStorage.setItem("userId", response.userId);
    localStorage.setItem(authTokenKey, response.token);

    configureGoFetch({
      headers: {
        Authorization: `Bearer ${response.token}`,
        "Content-Type": contentTypes.json,
      },
    });

    return { loginSuccess: true, otpRequired: false };
  }

  async validateOTP({ userId, otp }: OTPArgs): Promise<LoginResult | null> {
    const response = await goFetch(
      `${controller}/validate-otp?userId=${userId}&otp=${otp}`
    ).post();

    if (!response) return null;

    localStorage.setItem(authTokenKey, response.token);

    configureGoFetch({
      headers: {
        Authorization: `Bearer ${response.token}`,
        "Content-Type": contentTypes.json,
      },
    });

    return {
      token: response.token,
      userId: response.userId,
    };
  }

  async verificationEmail(username: string): Promise<LoginResult | null> {
    const response = await goFetch(
      `${controller}/verification-email?accountName=${username}`
    ).post();

    if (!response) return null;

    configureGoFetch({
      headers: {
        Authorization: `Bearer ${response.token}`,
        "Content-Type": contentTypes.json,
      },
    });

    return {
      token: response.token,
      userId: response.userId,
    };
  }

  async accountVerify(token: string): Promise<any | null> {
    const response = await goFetch(
      `${controller}/account-verify?token=${token}`
    ).post();

    if (!response) return null;

    configureGoFetch({
      headers: {
        Authorization: `Bearer ${response.token}`,
        "Content-Type": contentTypes.json,
      },
    });

    return response;
  }

  async logout() {
    asyncify(() => {
      localStorage.removeItem(authTokenKey);
      localStorage.removeItem("user");
      localStorage.removeItem("permissions");

      configureGoFetch({
        headers: {
          "Content-Type": contentTypes.json,
        },
      });

      publish(StateTopicEnum.User, null);
    }, 0);
  }

  async getPermissions() {
    const response = await goFetch(`${controller}/permissions`).get();

    return response as Permission[];
  }

  async getMe() {
    const response = await goFetch(`${controller}/me`).get();

    return response as UserFull;
  }

  async getProfile({ username }: GetProfileArgs) {
    return await asyncify(() => {}, 400);
  }
}

const service = new Service();

export default service;

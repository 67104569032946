import React, { useCallback, useEffect, useMemo } from "react";
import Form from "../../../components/form/Form";
import {
  ColorPicker,
  InstructionWrapper,
  TextField,
} from "../../../components/fields";
import { MeasurableGroup, NavigableComponent } from "../../../interfaces";
import { ToolItem } from "../../../components/toolbar/Toolbar";
import { FaArrowLeft, FaEdit, FaTrash } from "react-icons/fa";
import { routes } from "../../../_config";
import { useNavigate, useParams } from "react-router-dom";
import { Chip } from "@mui/material";
import { publish, useAuthentication } from "../../../hooks";
import "../_styles.scss";
import { SiteTemplateFull } from "../../../interfaces";
import { siteTemplatesService } from "../../../services";
import { AlertTypeEnum, StateTopicEnum } from "../../../enums";
import { hideAlert, showAlert } from "../../../components/alert/Alert";
import { LibrarySection } from "../../../components/library/Library";
import { BiFontColor, BiRuler } from "react-icons/bi";
import { clone } from "../../../utilities";
import ReferenceSummary from "../../../interfaces/ReferenceSummary";

interface ViewSiteTemplateProps {
  siteTemplate: SiteTemplateFull;
}

interface FieldItem {
  type: "Descriptor" | "Measurable";
  item: ReferenceSummary;
}

export default function ViewField({
  setToolbarConfig,
  siteTemplate,
}: ViewSiteTemplateProps & NavigableComponent) {
  const auth = useAuthentication();
  const { settingId } = useParams();
  const navigate = useNavigate();

  const handleEdit = useCallback(async () => {
    showAlert({
      content:
        "Are you sure you want to create a new version of this site template?",
      options: {
        type: AlertTypeEnum.Question,
        actions: [
          {
            text: "Yes",
            primary: true,
            onClick: async () => {
              const response = await siteTemplatesService.create(
                "VersionExisting",
                { familyId: settingId }
              );

              navigate(
                `${routes.settingItem.go(
                  "site-templates-library",
                  response.id
                )}?mode=edit`
              );
              hideAlert();
              publish<LibrarySection>(
                StateTopicEnum.LibrarySectionRefresh,
                "incomplete"
              );
            },
          },
          {
            text: "No",
          },
        ],
      },
    });
  }, [navigate, settingId]);

  useEffect(() => {
    let toolbarItems: (ToolItem | "|")[] = [
      {
        label: "Back",
        icon: FaArrowLeft,
        primary: true,
        raised: true,
        onClick: () =>
          navigate(routes.settingCategory.go("site-templates-library")),
      },
    ];

    const canEdit = auth?.hasPermission("EditSiteTemplate");
    const canDelete = auth?.hasPermission("RemoveSiteTemplate");

    if (canEdit || canDelete) toolbarItems.push("|");

    if (canEdit)
      toolbarItems.push({
        label: "Edit",
        icon: FaEdit,
        onClick: handleEdit,
      });

    if (canDelete)
      toolbarItems.push({
        label: "Delete",
        icon: FaTrash,
        onClick: () => {
          showAlert({
            content:
              "Are you sure you want to delete this site template? This action is not reversable.",
            options: {
              type: AlertTypeEnum.Warning,
              actions: [
                {
                  text: "Yes",
                  primary: true,
                  onClick: async () => {
                    const response = await siteTemplatesService.delete?.(
                      settingId ?? "",
                      "DeleteFamily"
                    );

                    hideAlert();

                    if (response?.message) {
                      navigate(
                        routes.settingCategory.go("site-templates-library")
                      );
                      publish<LibrarySection>(
                        StateTopicEnum.LibrarySectionRefresh,
                        "paged"
                      );
                    }
                  },
                },
                { text: "No" },
              ],
            },
          });
        },
      });

    setToolbarConfig({
      allowSearch: true,
      toolbarItems,
      searchPath: routes.siteTemplates.go(),
    });
  }, [handleEdit, setToolbarConfig, settingId, navigate]);

  const sortedGroups = useMemo(() => {
    const order = siteTemplate.measurableGroupsOrder ?? [];
    const groups = clone(siteTemplate.measurableGroups) ?? [];
    const orderedGroups: MeasurableGroup[] = [];

    order.forEach((o) => {
      const group = groups.find((g) => g.id === o);

      if (group) {
        orderedGroups.push(group);
        groups.splice(groups.indexOf(group), 1);
      }
    });

    groups.forEach((g) => orderedGroups.push(g));

    return orderedGroups;
  }, [siteTemplate]);

  return (
    <>
      <Form title="Identifying Information">
        <InstructionWrapper text="An identifying name for the site template.">
          <TextField label="Name" value={siteTemplate.name} readOnly />
        </InstructionWrapper>
        <InstructionWrapper
          text="An icon and color used when rendering the site on a map."
          row
        >
          <TextField label="Icon" value={siteTemplate.icon} readOnly />
          <ColorPicker
            label="Color"
            readOnly
            value={siteTemplate.primaryColor ?? ""}
          />
        </InstructionWrapper>
      </Form>
      <Form title="Field Groups">
        <InstructionWrapper>
          {sortedGroups.map((group, index) => {
            const combinedArray = [
              ...group.descriptors.map(
                (item) => ({ type: "Descriptor", item } as FieldItem)
              ),
              ...group.measurables.map(
                (item) => ({ type: "Measurable", item } as FieldItem)
              ),
            ];

            return (
              <div className="measurable-group-row" key={index}>
                <div className="measurable-group">
                  <TextField
                    value={group.name}
                    label="Measurable group name"
                    readOnly
                  />
                  {combinedArray.length > 0 && (
                    <div className="field-chips">
                      <div className="fields">
                        {group.order?.map((o: any, index: number) => {
                          let item = combinedArray.find((c) => {
                            return c.item.id === o;
                          });

                          if (!item) return null;

                          const isDescriptor =
                            item.type === "Descriptor" ? true : false;

                          return (
                            <Chip
                              key={`${o}-${index}`}
                              label={item.item.name}
                              className={`${
                                isDescriptor
                                  ? "measurable-chip"
                                  : "descriptor-chip"
                              }`}
                              icon={
                                isDescriptor ? <BiFontColor /> : <BiRuler />
                              }
                            />
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </InstructionWrapper>
      </Form>
    </>
  );
}

import React, { useCallback, useEffect, useMemo } from "react";
import Form from "../../../components/form/Form";
import {
  Checkbox,
  InstructionWrapper,
  TextField,
} from "../../../components/fields";
import { NavigableComponent, FormFull } from "../../../interfaces";
import { ToolItem } from "../../../components/toolbar/Toolbar";
import { FaArrowLeft, FaEdit, FaTrash } from "react-icons/fa";
import { routes } from "../../../_config";
import { useNavigate, useParams } from "react-router-dom";
import { Chip } from "@mui/material";
import "../_styles.scss";
import { fieldFormsService } from "../../../services";
import { hideAlert, showAlert } from "../../../components/alert/Alert";
import { AlertTypeEnum, StateTopicEnum } from "../../../enums";
import { publish, useAuthentication } from "../../../hooks";
import { LibrarySection } from "../../../components/library/Library";
import { BiFontColor, BiRuler } from "react-icons/bi";
import { FormDescriptor, FormMeasurableGroup } from "../../../interfaces/Form";
import { clone } from "../../../utilities";

interface ViewFieldDataProps {
  fieldForm: FormFull;
}

export default function ViewFieldForm({
  setToolbarConfig,
  fieldForm,
}: ViewFieldDataProps & NavigableComponent) {
  const auth = useAuthentication();
  const { settingId } = useParams();
  const navigate = useNavigate();

  const handleEdit = useCallback(async () => {
    showAlert({
      content:
        "Are you sure you want to create a new version of this field form?",
      options: {
        type: AlertTypeEnum.Question,
        actions: [
          {
            text: "Yes",
            primary: true,
            onClick: async () => {
              const response = await fieldFormsService.create(
                "VersionExisting",
                { familyId: settingId }
              );

              navigate(
                `${routes.settingItem.go(
                  "field-forms-library",
                  response.id
                )}?mode=edit`
              );
              hideAlert();
              publish<LibrarySection>(
                StateTopicEnum.LibrarySectionRefresh,
                "incomplete"
              );
            },
          },
          {
            text: "No",
          },
        ],
      },
    });
  }, [navigate, settingId]);

  useEffect(() => {
    let toolbarItems: (ToolItem | "|")[] = [
      {
        label: "Back",
        icon: FaArrowLeft,
        primary: true,
        raised: true,
        onClick: () =>
          navigate(routes.settingCategory.go("field-forms-library")),
      },
    ];

    const canEdit = auth?.hasPermission("EditForm");
    const canDelete = auth?.hasPermission("RemoveForm");

    if (canEdit || canDelete) toolbarItems.push("|");

    if (canEdit)
      toolbarItems.push({
        label: "Edit",
        icon: FaEdit,
        onClick: handleEdit,
      });

    if (canDelete)
      toolbarItems.push({
        label: "Delete",
        icon: FaTrash,
        onClick: () => {
          showAlert({
            content:
              "Are you sure you want to delete this form? This action is not reversable.",
            options: {
              type: AlertTypeEnum.Warning,
              actions: [
                {
                  text: "Yes",
                  primary: true,
                  onClick: async () => {
                    const response = await fieldFormsService.delete!(
                      settingId!,
                      "DeleteFamily"
                    );

                    hideAlert();

                    if (response?.message) {
                      navigate(
                        routes.settingCategory.go("field-forms-library")
                      );
                      publish<LibrarySection>(
                        StateTopicEnum.LibrarySectionRefresh,
                        "paged"
                      );
                    }
                  },
                },
                { text: "No" },
              ],
            },
          });
        },
      });

    setToolbarConfig({
      allowSearch: true,
      toolbarItems,
      searchPath: routes.fieldForms.go(),
    });
  }, [handleEdit, setToolbarConfig, settingId, navigate]);

  const sortedGroups = useMemo(() => {
    const order = fieldForm.measurableGroupsOrder ?? [];
    const groups = clone(fieldForm.measurableGroups) ?? [];
    const orderedGroups: FormMeasurableGroup[] = [];

    order.forEach((o) => {
      const group = groups.find((g) => g.id === o);

      if (group) {
        orderedGroups.push(group);
        groups.splice(groups.indexOf(group), 1);
      }
    });

    groups.forEach((g) => orderedGroups.push(g));

    return orderedGroups;
  }, [fieldForm]);

  return (
    <>
      <Form title="Identifying Information">
        <InstructionWrapper text="The site template to which this form applies.">
          <TextField
            label="Site Template"
            value={fieldForm.siteTemplate?.name}
            readOnly
          />
        </InstructionWrapper>
        <InstructionWrapper text="An identifying name for the form.">
          <TextField label="Name" readOnly value={fieldForm.name} />
        </InstructionWrapper>
        <InstructionWrapper text="Instructions presented to users when completing the form.">
          <TextField
            label="Instructions"
            multiline
            readOnly
            value={fieldForm.instructions}
          />
        </InstructionWrapper>
        <InstructionWrapper text="Indication of whether the form requires approval after values where captured and submitted using the mobile app.">
          <Checkbox
            label="Requires Approval"
            value={fieldForm.requiresApprovalOnSubmit ?? false}
          />
        </InstructionWrapper>
      </Form>
      <Form title="Field Groups">
        <InstructionWrapper>
          {sortedGroups.map((group, index) => {
            const combinedArray = [...group.measurables, ...group.descriptors];

            return (
              <div className="measurable-group-row" key={index}>
                <div className="measurable-group">
                  <TextField
                    value={group.name}
                    label="Measurable Group Name"
                    readOnly
                  />
                  {combinedArray.length > 0 && (
                    <div className="field-chips">
                      <div className="fields">
                        {group.order?.map((o: any, index: number) => {
                          let item = combinedArray.find((c) => {
                            return c.id === o;
                          });

                          if (!item) return null;

                          const isDescriptor = (item as FormDescriptor).type
                            ? true
                            : false;

                          return (
                            <Chip
                              key={`${o}-${index}`}
                              label={item.name}
                              className={`${
                                isDescriptor
                                  ? "measurable-chip"
                                  : "descriptor-chip"
                              }`}
                              icon={
                                isDescriptor ? <BiFontColor /> : <BiRuler />
                              }
                            />
                          );
                        })}
                      </div>
                    </div>
                  )}
                  <div className="measureable-checkbox-items">
                    <Checkbox
                      label="Allow Multiple Capturing"
                      value={group.multiItem}
                      readOnly
                    />
                    <Checkbox
                      label="Mandatory Multiple Capturing"
                      value={group.mandatoryMultiItem}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </InstructionWrapper>
      </Form>
    </>
  );
}

import React from "react";
import { IconType } from "react-icons";
import { classNameBuilder } from "../../utilities";
import "./_styles.scss";

interface PageWithHeaderProps {
  title: string;
  subTitles?: {
    icon?: IconType;
    label: string;
    value: string;
    className?: string;
  }[];
  children: React.ReactNode;
}

export default function PageWithHeader({
  title,
  subTitles,
  children,
}: PageWithHeaderProps) {
  return (
    <div className="h-app-page-with-header">
      <div className="awh-heading">
        <h1>{title}</h1>
        {subTitles?.length
          ? subTitles.map((st, i) => {
              const Icon = st.icon;

              return (
                <div
                  className={classNameBuilder(
                    "awh-subtitle",
                    st.className ?? ""
                  )}
                  key={`sub-title-${i}`}
                >
                  {Icon ? <Icon /> : null}
                  <div>
                    <label>{st.label}</label>
                    <span>{st.value}</span>
                  </div>
                </div>
              );
            })
          : null}
      </div>
      <div className="awh-content">{children}</div>
    </div>
  );
}

import React, { useEffect } from "react";
import Form from "../../../components/form/Form";
import {
  DatePicker,
  InstructionWrapper,
  TextField,
} from "../../../components/fields";
import { NavigableComponent, DescriptorFull } from "../../../interfaces";
import { ToolItem } from "../../../components/toolbar/Toolbar";
import { FaArrowLeft, FaEdit, FaTrash } from "react-icons/fa";
import { hideAlert, showAlert } from "../../../components/alert/Alert";
import { AlertTypeEnum, StateTopicEnum } from "../../../enums";
import { routes } from "../../../_config";
import { useNavigate, useParams } from "react-router-dom";
import { Chip } from "@mui/material";
import { publish, useAuthentication } from "../../../hooks";
import "../_styles.scss";
import { descriptorsService } from "../../../services";
import { LibrarySection } from "../../../components/library/Library";

interface ViewFieldDataProps {
  descriptor: DescriptorFull;
}

export default function ViewField({
  setToolbarConfig,
  descriptor,
}: ViewFieldDataProps & NavigableComponent) {
  const auth = useAuthentication();
  const { settingId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    let toolbarItems: (ToolItem | "|")[] = [
      {
        label: "Back",
        icon: FaArrowLeft,
        primary: true,
        raised: true,
        onClick: () =>
          navigate(routes.settingCategory.go("descriptors-library")),
      },
    ];

    const canEdit = auth?.hasPermission("EditDescriptor");
    const canDelete = auth?.hasPermission("RemoveDescriptor");

    if (canEdit || canDelete) toolbarItems.push("|");

    if (canEdit)
      toolbarItems.push({
        label: "Edit",
        icon: FaEdit,
        onClick: () =>
          navigate(
            `${routes.settingItem.go(
              "descriptors-library",
              settingId
            )}?mode=edit`
          ),
      });

    if (canDelete)
      toolbarItems.push({
        label: "Delete",
        icon: FaTrash,
        onClick: () => {
          showAlert({
            content:
              "Are you sure you want to delete this descriptor? This action is not reversable.",
            options: {
              type: AlertTypeEnum.Warning,
              actions: [
                {
                  text: "Yes",
                  primary: true,
                  onClick: async () => {
                    const response = await descriptorsService.delete!(
                      settingId!
                    );

                    hideAlert();

                    if (response?.message) {
                      navigate(
                        routes.settingCategory.go("descriptors-library")
                      );
                      publish<LibrarySection>(
                        StateTopicEnum.LibrarySectionRefresh,
                        "paged"
                      );
                    }
                  },
                },
                { text: "No" },
              ],
            },
          });
        },
      });

    setToolbarConfig({
      allowSearch: true,
      toolbarItems,
      searchPath: routes.descriptors.go(),
    });
  }, [settingId, navigate, setToolbarConfig]);

  return (
    <>
      <Form title="Identifying Information">
        <InstructionWrapper text="An identifying name for the descriptor (i.e. type of material).">
          <TextField label="Name" value={descriptor.name} readOnly />
        </InstructionWrapper>
        <InstructionWrapper text="Add a custom date for this measurement." row>
          <DatePicker
            label="Date"
            value={descriptor.date}
            maxDate={new Date()}
          />
        </InstructionWrapper>
        <InstructionWrapper text="The type of input to present users with. Specified Items will present users with a predefined list, while Free Text allows any value to be entered.">
          <TextField
            label="Type"
            value={
              descriptor.type === "SpecifiedItems"
                ? "Specified Items"
                : "Free Text"
            }
            className="type-field-readonly"
            readOnly
          />
          {descriptor.type === "SpecifiedItems" &&
            descriptor.options?.sort().map((opt: any, key: number) => {
              return (
                <Chip key={key} label={opt} className="option-chip-readonly" />
              );
            })}
        </InstructionWrapper>
      </Form>
    </>
  );
}

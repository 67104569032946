import { Series } from "../interfaces";

const standardColors = [
  "#ea5545",
  "#27aeef",
  "#87bc45",
  "#ef9b20",
  "#b33dc6",
  "#ede15b",
  "#f46a9b",
  "#edbf33",
  "#bdcf32",
  "#e60049",
  "#0b82cc",
  "#50e991",
  "#ffa300",
  "#9b19f5",
  "#e6d800",
  "#dc0ab4",
  "#b3d4ff",
  "#00bfa0",
  "#fd7f6f",
  "#7eb0d5",
  "#b2e061",
  "#bd7ebe",
  "#ffb55a",
  "#ffee65",
  "#beb9db",
  "#fdcce5",
  "#8bd3c7",
];

export default function getSeriesColor<TData>(
  point: TData,
  seriesIndex: number,
  series?: Series<TData>
) {
  return (
    series?.getSeriesColor?.(point, seriesIndex) ||
    standardColors[seriesIndex] ||
    "#888888"
  );
}

import { Selection } from "d3";

export default function addDiamondProjectionGrid(
  svg: Selection<SVGSVGElement, unknown, null, undefined>,
  baseX: number,
  baseY: number,
  diamondTopY: number,
  adjustedGutter: number,
  baseLength: number,
  equiHeight: number,
  gridCount: number,
  gridValueIncrements: "All" | "Even" | "Odd",
  gridValueFormat: "Fraction" | "Percentage"
) {
  const gridLines: { x1: number; x2: number; y1: number; y2: number }[] = [];
  const gridLabels: {
    x: number;
    y: number;
    label: string;
    align: "start" | "end";
  }[] = [];
  const topY = diamondTopY - adjustedGutter;
  const baseInc = baseLength / (gridCount * 2);
  const heightInc = equiHeight / gridCount;

  for (let i = 1; i < gridCount; i++) {
    gridLines.push({
      x1: baseX + baseInc * i,
      x2: baseX + baseLength / 2 + baseInc * i,
      y1: baseY + heightInc * i,
      y2: topY + heightInc * i,
    });
    gridLines.push({
      x1: baseX + baseInc * i,
      x2: baseX + baseLength / 2 + baseInc * i,
      y1: baseY - heightInc * i,
      y2: baseY + equiHeight - heightInc * i,
    });

    if (gridValueIncrements === "Even" && i % 2 === 1) continue;
    if (gridValueIncrements === "Odd" && i % 2 === 0) continue;

    const labelValue =
      gridValueFormat === "Fraction"
        ? ((baseInc * 2 * i) / baseLength).toFixed(1)
        : `${(((baseInc * 2 * i) / baseLength) * 100).toFixed(0)}%`;

    gridLabels.push({
      x: baseX + baseInc * i - 5,
      y: baseY - heightInc * i,
      label: labelValue,
      align: "end",
    });
    gridLabels.push({
      x: baseX + baseLength - baseInc * i + 5,
      y: baseY - heightInc * i,
      label: labelValue,
      align: "start",
    });
  }

  const gridGrouping = svg.select(".plot-area").append("g");

  gridGrouping
    .selectAll("line")
    .data(gridLines)
    .enter()
    .append("line")
    .attr("x1", (g) => g.x1)
    .attr("x2", (g) => g.x2)
    .attr("y1", (g) => g.y1)
    .attr("y2", (g) => g.y2)
    .attr("stroke", "#aaaaaa")
    .attr("stroke-width", 1)
    .attr("stroke-dasharray", "5,5");

  gridGrouping
    .selectAll("text")
    .data(gridLabels)
    .enter()
    .append("text")
    .attr("x", (g) => g.x)
    .attr("y", (g) => g.y)
    .attr("text-anchor", (g) => g.align)
    .style("font-size", "12px")
    .text((g) => g.label);
}

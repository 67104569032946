import React, { useEffect, useMemo, useState } from "react";
import { Form } from "../../../components";
import {
  AreaBand,
  BoxAndWhiskerConfig,
  Guide,
  WhiskerType,
} from "../../../interfaces";
import ReferenceSummary from "../../../interfaces/ReferenceSummary";
import {
  Checkbox,
  ColorPicker,
  InstructionWrapper,
  List,
  NumberField,
  RadioButtons,
  TextField,
} from "../../../components/fields";
import { useStateReducer } from "../../../hooks";
import BandsAndGuides from "./BandsAndGuides";
import getOrderedMeasurables, {
  OrderedMeasurable,
} from "../utilities/getOrderedMeasurables";
import { validateObject } from "../../../utilities";
import { Validation } from "../../../utilities/validateObject";

interface BoxAndWhiskerChartConfigProps {
  siteTemplates: ReferenceSummary[];
  config?: BoxAndWhiskerConfig;
  onChange?: (config: BoxAndWhiskerConfig) => void;
  readOnly?: boolean;
}

interface State {
  measurable?: ReferenceSummary;
  xAxisLabel?: string;
  yAxisLabel?: string;
  yAxisTickCount?: number;
  yAxisTickPrecision?: number;
  yAxisMin?: number;
  yAxisMax?: number;
  showGrid?: boolean;
  whiskerType?: WhiskerType;
  guides?: Guide[];
  areaBands?: AreaBand[];
  barColor?: string;
  outlierTopColor?: string;
  outlierBottomColor?: string;
  barStrokeColor?: string;
  latestValueColor?: string;
}

const whiskerOptions = [
  { value: "IQR", label: "1.5x IQR" },
  { value: "MinMax", label: "Min/Max" },
];

export default function BoxAndWhiskerChartConfig({
  siteTemplates,
  config,
  onChange,
  readOnly,
}: BoxAndWhiskerChartConfigProps) {
  const [measurables, setMeasurables] = useState<OrderedMeasurable[]>();
  const [state, setState] = useStateReducer<State>({
    measurable: config?.measurable,
    xAxisLabel: config?.xAxisLabel,
    yAxisLabel: config?.yAxisLabel,
    yAxisTickCount: config?.yAxisTickCount,
    yAxisTickPrecision: config?.yAxisTickPrecision,
    yAxisMax: config?.yAxisMax,
    yAxisMin: config?.yAxisMin,
    showGrid: config?.showGrid ?? true,
    whiskerType: config?.whiskerType ?? "IQR",
    areaBands: config?.areaBands ?? [],
    guides: config?.guides ?? [],
    barColor: config?.barColor,
    barStrokeColor: config?.barStrokeColor,
    outlierTopColor: config?.outlierTopColor,
    latestValueColor: config?.latestValueColor,
    outlierBottomColor: config?.outlierBottomColor,
  });
  const validationRules: Validation = useMemo(
    () => ({
      measurable: {
        func: (m: ReferenceSummary) => m?.id && m?.id !== "-1",
        message: "This field is required.",
      },
    }),
    []
  );
  const validation = validateObject(validationRules, state);

  useEffect(() => {
    if (readOnly) return;

    const load = async () => {
      setMeasurables(
        await getOrderedMeasurables(siteTemplates.map((e) => e.id))
      );
    };

    load();
  }, [siteTemplates, readOnly, setMeasurables]);

  useEffect(() => {
    if (readOnly) return;

    onChange?.({
      areaBands: state.areaBands ?? [],
      guides: state.guides ?? [],
      measurable: state.measurable ?? { id: "-1", name: "Not Specified" },
      showGrid: state.showGrid ?? true,
      xAxisLabel: state.xAxisLabel ?? "",
      yAxisLabel: state.yAxisLabel ?? "",
      yAxisMax: state.yAxisMax,
      yAxisMin: state.yAxisMin,
      yAxisTickCount: state.yAxisTickCount,
      yAxisTickPrecision: state.yAxisTickPrecision,
      whiskerType: state.whiskerType ?? "IQR",
      barColor: state.barColor,
      barStrokeColor: state.barStrokeColor,
      outlierTopColor: state.outlierTopColor,
      latestValueColor: state.latestValueColor,
      outlierBottomColor: state.outlierBottomColor,
    });
  }, [state, readOnly, onChange]);

  return (
    <>
      <Form title="Dataset Configuration">
        <InstructionWrapper
          text="The measurable that is used to plot the points for the lines against the Y-axis of the chart."
          error={validation["measurable"]}
        >
          {readOnly ? (
            <TextField
              label="Measurable"
              value={state.measurable?.name}
              readOnly
            />
          ) : (
            <List
              label="Measurable"
              options={measurables ?? []}
              getOptionLabel={(m) => m.name}
              getOptionValue={(m) => m.id}
              isOptionEqualToValue={(o, v) => o.id === v.id}
              value={state.measurable}
              onChange={(_, measurable: ReferenceSummary) =>
                setState({ measurable })
              }
              error={validation["measurable"] ? true : false}
            />
          )}
        </InstructionWrapper>
        <InstructionWrapper text="The method of calculating the maximum and minimum values for the whiskers. When using Min/Max, no outliers will be shown.">
          <RadioButtons
            horizontal
            label="Whisker Type"
            items={whiskerOptions}
            value={state.whiskerType}
            onChange={
              readOnly
                ? undefined
                : (_, whiskerType: WhiskerType) => setState({ whiskerType })
            }
            readOnly={readOnly}
          />
        </InstructionWrapper>
      </Form>
      <Form title="X-Axis Configuration">
        <InstructionWrapper text="The label displayed below the X axis.">
          <TextField
            label="Label"
            value={state.xAxisLabel}
            onChange={
              readOnly
                ? undefined
                : (e) => setState({ xAxisLabel: e.target.value })
            }
            readOnly={readOnly}
          />
        </InstructionWrapper>
      </Form>
      <Form title="Y-Axis Configuration">
        <InstructionWrapper text="The label displayed to the left of the Y axis.">
          <TextField
            label="Label"
            value={state.yAxisLabel}
            onChange={
              readOnly
                ? undefined
                : (e) => setState({ yAxisLabel: e.target.value })
            }
            readOnly={readOnly}
          />
        </InstructionWrapper>
        <InstructionWrapper
          text="Settings for the ticks on the Y axis, including the number of ticks, decimal scale for their display and the minimum and maximum values for the Y axis."
          row
        >
          <NumberField
            label="Tick Count"
            value={state.yAxisTickCount}
            decimalScale={0}
            onChange={
              readOnly
                ? undefined
                : (e) =>
                    setState({
                      yAxisTickCount:
                        e.target.value === ""
                          ? undefined
                          : parseInt(e.target.value),
                    })
            }
            readOnly={readOnly}
          />
          <NumberField
            label="Decimal Scale"
            value={state.yAxisTickPrecision}
            decimalScale={0}
            onChange={
              readOnly
                ? undefined
                : (e) =>
                    setState({
                      yAxisTickPrecision:
                        e.target.value === ""
                          ? undefined
                          : parseInt(e.target.value),
                    })
            }
            readOnly={readOnly}
          />
          <NumberField
            label="Minimum"
            value={state.yAxisMin}
            onChange={
              readOnly
                ? undefined
                : (e) =>
                    setState({
                      yAxisMin:
                        e.target.value === ""
                          ? undefined
                          : parseFloat(e.target.value),
                    })
            }
            readOnly={readOnly}
          />
          <NumberField
            label="Maximum"
            value={state.yAxisMax}
            onChange={
              readOnly
                ? undefined
                : (e) =>
                    setState({
                      yAxisMax:
                        e.target.value === ""
                          ? undefined
                          : parseFloat(e.target.value),
                    })
            }
            readOnly={readOnly}
          />
        </InstructionWrapper>
      </Form>
      <Form title="Chart Configuration">
        <InstructionWrapper text="Whether a dashed grid is shown on the plot area of the chart or not.">
          <Checkbox
            label="Show Grid"
            value={state.showGrid}
            onChange={
              readOnly ? undefined : (_, showGrid) => setState({ showGrid })
            }
            readOnly={readOnly}
          />
        </InstructionWrapper>
      </Form>
      <BandsAndGuides
        areaBands={state.areaBands ?? []}
        guides={state.guides ?? []}
        onGuideUpdate={readOnly ? undefined : (guides) => setState({ guides })}
        onAreaBandUpdate={
          readOnly ? undefined : (areaBands) => setState({ areaBands })
        }
        readOnly={readOnly}
      />
      <Form title="Styling">
        <InstructionWrapper
          text="Colours and styling applied to the boxes and whiskers of the chart."
          row
        >
          <ColorPicker
            label="Inner Quartiles Colour"
            value={state.barColor ?? ""}
            onChange={
              readOnly ? undefined : (barColor) => setState({ barColor })
            }
            readOnly={readOnly}
          />
          <ColorPicker
            label="Stroke Colour"
            value={state.barStrokeColor ?? ""}
            onChange={
              readOnly
                ? undefined
                : (barStrokeColor) => setState({ barStrokeColor })
            }
            readOnly={readOnly}
          />
          <ColorPicker
            label="Latest Value Colour"
            value={state.latestValueColor ?? ""}
            onChange={
              readOnly
                ? undefined
                : (latestValueColor) => setState({ latestValueColor })
            }
            readOnly={readOnly}
          />
        </InstructionWrapper>
        <InstructionWrapper
          text="Colours and styling applied to the boxes and whiskers of the chart."
          row
        >
          <ColorPicker
            label="Top Outlier Colour"
            value={state.outlierTopColor ?? ""}
            onChange={
              readOnly
                ? undefined
                : (outlierTopColor) => setState({ outlierTopColor })
            }
            readOnly={readOnly}
          />
          <ColorPicker
            label="Bottom Outlier Colour"
            value={state.outlierBottomColor ?? ""}
            onChange={
              readOnly
                ? undefined
                : (outlierBottomColor) => setState({ outlierBottomColor })
            }
            readOnly={readOnly}
          />
        </InstructionWrapper>
      </Form>
    </>
  );
}

import { CrudResponse } from "../interfaces";
import { goFetch } from "../utilities/goFetch";

interface AtachmentsService {
  create: (
    fileName: string,
    contentType: string,
    data: string,
    tags?: string[]
  ) => Promise<CrudResponse>;
  link: (id: string, thumbnail: boolean) => Promise<string>;
}

const controller = "attachments";

const service: AtachmentsService = {
  link: async (id, thumbnail) =>
    await goFetch(`${controller}/${id}/link`)
      .withQueryParameters({ thumbnail })
      .get(),

  create: async (fileName, contentType, data, tags) =>
    await goFetch(`${controller}/base64`)
      .withBody({
        fileName,
        contentType,
        data,
      })
      .withQueryParameters({ tags })
      .post(),
};

export default service;

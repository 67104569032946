import React from "react";

export default function DurovChartIcon() {
  return (
    <svg
      viewBox="0 0 60 60"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      height="1em"
      width="1em"
      stroke="currentColor"
    >
      <path
        id="path111"
        style={{ fill: "none", strokeWidth: 4 }}
        d="m 28.855508,2.1198189 -4.827684,8.3617071 -4.827685,8.361706 h 9.655369 9.655371 L 33.683194,10.481526 Z M 38.867786,18.843232 V 38.867786 H 58.892865 V 18.843232 Z m 0,20.024554 H 18.843231 v 20.025079 h 20.024555 z m -20.024555,-19.667645 -8.361707,4.827685 -8.3617055,4.827684 8.3617055,4.827684 8.361707,4.827685 V 28.85551 Z"
      />
    </svg>
  );
}

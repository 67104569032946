import React, { useCallback, useEffect } from "react";
import Form from "../../../components/form/Form";
import { InstructionWrapper, TextField } from "../../../components/fields";
import { NavigableComponent, MonitoringGroupFull } from "../../../interfaces";
import { ToolItem } from "../../../components/toolbar/Toolbar";
import { FaArrowLeft, FaEdit, FaTrash } from "react-icons/fa";
import { AlertTypeEnum, StateTopicEnum } from "../../../enums";
import { routes } from "../../../_config";
import { useNavigate, useParams } from "react-router-dom";
import "../_styles.scss";
import { monitoringGroupsService } from "../../../services";
import { publish, useAuthentication } from "../../../hooks";
import { hideAlert, showAlert } from "../../../components/alert/Alert";
import { LibrarySection } from "../../../components/library/Library";
interface ViewMonitoringGroupPageProps {
  monitoringGroup: MonitoringGroupFull;
}

export default function ViewMonitoringGroupPage({
  setToolbarConfig,
  monitoringGroup,
}: ViewMonitoringGroupPageProps & NavigableComponent) {
  const auth = useAuthentication();
  const { settingId } = useParams();
  const navigate = useNavigate();

  const handleEdit = useCallback(async () => {
    showAlert({
      content:
        "Are you sure you want to create a new version of this monitoring group?",
      options: {
        type: AlertTypeEnum.Question,
        actions: [
          {
            text: "Yes",
            primary: true,
            onClick: async () => {
              const response = await monitoringGroupsService.create(
                "VersionExisting",
                { familyId: settingId }
              );

              navigate(
                `${routes.settingItem.go(
                  "monitoring-groups-library",
                  response.id
                )}?mode=edit`
              );
              hideAlert();
              publish<LibrarySection>(
                StateTopicEnum.LibrarySectionRefresh,
                "incomplete"
              );
            },
          },
          {
            text: "No",
          },
        ],
      },
    });
  }, [navigate, settingId]);

  useEffect(() => {
    let toolbarItems: (ToolItem | "|")[] = [
      {
        label: "Back",
        icon: FaArrowLeft,
        primary: true,
        raised: true,
        onClick: () =>
          navigate(routes.settingCategory.go("monitoring-groups-library")),
      },
    ];

    const canEdit = auth?.hasPermission("EditMonitoringGroup");
    const canDelete = auth?.hasPermission("RemoveMonitoringGroup");

    if (canEdit || canDelete) toolbarItems.push("|");

    if (canEdit)
      toolbarItems.push({
        label: "Edit",
        icon: FaEdit,
        onClick: handleEdit,
      });

    if (canDelete)
      toolbarItems.push({
        label: "Delete",
        icon: FaTrash,
        onClick: () => {
          showAlert({
            content:
              "Are you sure you want to delete this monitoring group? This action is not reversable.",
            options: {
              type: AlertTypeEnum.Warning,
              actions: [
                {
                  text: "Yes",
                  primary: true,
                  onClick: async () => {
                    const response = await monitoringGroupsService.delete!(
                      settingId!,
                      "DeleteFamily"
                    );

                    hideAlert();

                    if (response?.message) {
                      navigate(
                        routes.settingCategory.go("monitoring-groups-library")
                      );
                      publish<LibrarySection>(
                        StateTopicEnum.LibrarySectionRefresh,
                        "paged"
                      );
                    }
                  },
                },
                { text: "No" },
              ],
            },
          });
        },
      });

    setToolbarConfig({
      allowSearch: true,
      toolbarItems,
      searchPath: routes.monitoringGroups.go(),
    });
  }, [handleEdit, setToolbarConfig, navigate, settingId]);

  return (
    <Form title="Identifying Information">
      <InstructionWrapper text="An identifying name for the monitoring group (i.e. river mouth).">
        <TextField label="Name" value={monitoringGroup.name} readOnly />
      </InstructionWrapper>
      <InstructionWrapper text="A class associated with the monitoring group.">
        <TextField label="Class" value={monitoringGroup.class} readOnly />
      </InstructionWrapper>
      <InstructionWrapper text="A group associated with the class.">
        <TextField label="Group" value={monitoringGroup.group} readOnly />
      </InstructionWrapper>
      <InstructionWrapper text="A sub-group associated with the group.">
        <TextField
          label="Sub-Group"
          value={monitoringGroup.subGroup}
          readOnly
        />
      </InstructionWrapper>
    </Form>
  );
}

import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form } from "../../../components";
import { showAlert } from "../../../components/alert/Alert";
import { InstructionWrapper, TextField } from "../../../components/fields";
import { LibrarySection } from "../../../components/library/Library";
import {
  updateModalActionState,
  hideModal,
} from "../../../components/modal/Modal";
import { StateTopicEnum } from "../../../enums";
import { useStateReducer, publish } from "../../../hooks";
import { usersService } from "../../../services";
import { validateObject } from "../../../utilities";
import { routes } from "../../../_config";

interface State {
  accountName?: string;
  password?: string;
}

export default function AddUserModal() {
  const navigate = useNavigate();
  const [state, setState] = useStateReducer<State>({
    accountName: "",
    password: "",
  });
  const validation = validateObject(
    {
      accountName: {
        regex: /.{5,}/,
        message: "This field must be at least 5 characters long.",
      },
      password: {
        func: (password: string) => {
          if (!password || password.length < 10) return false;

          if (
            !/[a-z]+/.test(password) ||
            !/[A-Z]+/.test(password) ||
            !/[0-9]+/.test(password)
          )
            return false;

          return true;
        },
        message:
          "Password should be at least 10 characters and contain lowercase, uppercase and numeric characters.",
      },
    },
    state
  );

  const handleSubmit = useCallback(async () => {
    const createResponse = await usersService.create(state.accountName!);

    if (createResponse.id) {
      await usersService.update?.(createResponse.id, "UpdateCredentials", {
        accountName: state.accountName!,
        password: state.password!,
      });

      hideModal();
      navigate(
        `${routes.settingItem.go("users-library", createResponse.id)}?mode=edit`
      );
      publish<LibrarySection>(StateTopicEnum.LibrarySectionRefresh, "paged");
    } else {
      showAlert({ content: createResponse.message });
    }
  }, [navigate, state.accountName, state.password]);

  useEffect(() => {
    setTimeout(
      () =>
        updateModalActionState([{ disabled: true, onClick: handleSubmit }, {}]),
      10
    );
  }, [handleSubmit]);

  useEffect(() => {
    updateModalActionState([
      {
        disabled:
          validation["accountName"] || validation["password"] ? true : false,
        onClick: handleSubmit,
      },
      {},
    ]);
  }, [state.accountName, state.password, handleSubmit, validation]);

  return (
    <Form title="Identifying Information">
      <InstructionWrapper
        text="Specify a unique account name for the user."
        error={validation["accountName"]}
      >
        <TextField
          label="Account Name"
          value={state.accountName}
          onChange={(e) => setState({ accountName: e.target.value })}
          error={validation["accountName"] ? true : false}
        />
      </InstructionWrapper>
      <InstructionWrapper
        text="Specify a strong password for the user."
        error={validation["password"]}
      >
        <TextField
          label="Password"
          value={state.password}
          onChange={(e) => setState({ password: e.target.value })}
          error={validation["password"] ? true : false}
          type="password"
        />
      </InstructionWrapper>
    </Form>
  );
}

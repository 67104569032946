export default function groupArray<TData>(
  arr: TData[],
  extractGroup: (element: TData) => string
) {
  const groupMap = arr.reduce((group: { [prop: string]: TData[] }, point) => {
    const cat = extractGroup(point);
    group[cat] = group[cat] ?? [];
    group[cat].push(point);
    return group;
  }, {});

  return Object.keys(groupMap).map((key) => ({
    group: key,
    data: groupMap[key],
  }));
}

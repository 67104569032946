import { PatternKey } from "../components/svg-patterns/Patterns";
import {
  CasingMaterials,
  ConstructionMaterials,
  LithologyMaterials,
} from "../interfaces";
import CrudService from "../interfaces/CrudService";
import { goFetch } from "../utilities/goFetch";

interface UpsertDefinition {
  descriptorId: string;
}

interface PatternMaterialDefinition {
  materials: {
    [key: string]: {
      color: string;
      type: PatternKey;
    };
  };
}

interface LineMaterialDefinition {
  materials: {
    [key: string]: {
      color: string;
      type: "Solid" | "Dashed";
    };
  };
}

interface MaterialsService {
  upsertCasing: (
    definition: UpsertDefinition & LineMaterialDefinition
  ) => Promise<CasingMaterials>;
  upsertConstruction: (
    definition: UpsertDefinition & PatternMaterialDefinition
  ) => Promise<ConstructionMaterials>;
  upsertLithology: (
    definition: UpsertDefinition & PatternMaterialDefinition
  ) => Promise<LithologyMaterials>;
}

type Materials = ConstructionMaterials | CasingMaterials | LithologyMaterials;

const controller = "materials";

const service: CrudService<Materials, Materials> & MaterialsService = {
  getByPage: async ({ search, pageNo, pageSize }) => {
    const response = await goFetch(`${controller}/page/${pageNo}`)
      .withQueryParameters({
        pageSize: pageSize || 50,
        search: search,
      })
      .get();

    return {
      pageNo: response?.pageNo ?? 0,
      pageSize: response?.pageSize ?? 0,
      pageCount: response?.pageCount ?? 0,
      itemCount: response?.itemCount ?? 0,
      data: response?.data ?? [],
    };
  },

  getSingle: async ({ id }) => await goFetch(`${controller}/${id}`).get(),

  upsertCasing: async ({ descriptorId, materials }) =>
    await goFetch(`${controller}/casing`)
      .withBody({
        descriptorId,
        materials,
      })
      .put(),

  upsertConstruction: async ({ descriptorId, materials }) =>
    await goFetch(`${controller}/construction`)
      .withBody({
        descriptorId,
        materials,
      })
      .put(),

  upsertLithology: async ({ descriptorId, materials }) =>
    await goFetch(`${controller}/lithology`)
      .withBody({
        descriptorId,
        materials,
      })
      .put(),
};

export default service;

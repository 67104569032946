import moment from "moment";
import {
  ChartFull,
  ChartSummary,
  ChartTemplateFull,
  CrudResponse,
  DataPointFull,
  GetSingleArgs,
  PageAndSearchArgs,
} from "../interfaces";
import CrudService from "../interfaces/CrudService";
import { goFetch } from "../utilities/goFetch";

export interface ChartUpdateDefinition {
  chartTemplateId: string;
  fromDate?: Date;
  toDate?: Date;
  siteIds: string[];
  seriesColors: string[];
}

interface ChartTemplatesService {
  create: (name: string) => Promise<CrudResponse>;
  update: (
    id: string,
    definition: ChartUpdateDefinition
  ) => Promise<ChartTemplateFull>;
  delete: (id: string) => Promise<CrudResponse>;
  execute: (
    chartTemplateId: string,
    siteIds: string[],
    fromDate?: Date,
    toDate?: Date
  ) => Promise<DataPointFull[]>;
}

const controller = "charts";

const service: CrudService<ChartFull, ChartSummary> & ChartTemplatesService = {
  getByPage: async ({ search, pageNo, pageSize }: PageAndSearchArgs) => {
    const response = await goFetch(`${controller}/page/${pageNo}`)
      .withQueryParameters({
        pageSize: pageSize || 50,
        search: search,
      })
      .get();

    return {
      pageNo: response?.pageNo ?? 0,
      pageSize: response?.pageSize ?? 0,
      pageCount: response?.pageCount ?? 0,
      itemCount: response?.itemCount ?? 0,
      data: response?.data ?? [],
    };
  },

  getSingle: async ({ id }: GetSingleArgs) =>
    await goFetch(`${controller}/${id}`).get(),

  create: async (name) => await goFetch(controller).withBody({ name }).post(),

  update: async (id, definition) =>
    await goFetch(`${controller}/${id}`).withBody(definition).patch(),

  delete: async (id) => await goFetch(`${controller}/${id}`).delete(),

  execute: async (chartTemplateId, siteIds, fromDate, toDate) =>
    await goFetch(`${controller}/execute`)
      .withQueryParameters({
        chartTemplateId,
        siteIds,
        fromDate: fromDate ? moment(fromDate).format() : undefined,
        toDate: toDate ? moment(toDate).format() : undefined,
      })
      .get(),
};

export default service;

import { clone } from "lodash";
import { siteTemplatesService } from "../../../services";

export interface OrderedDescriptor {
  id: string;
  name: string;
  group?: string;
}

export default async function getOrderedDescriptors(
  siteTemplateIds: string[],
  search?: string
) {
  const result = await siteTemplatesService.getAllCurrentInFamily(
    siteTemplateIds
  );
  var resultList: OrderedDescriptor[][] = [];

  result.forEach((result) => {
    const order = result.measurableGroupsOrder ?? [];
    const groups = clone(result.measurableGroups) ?? [];
    const ordered: OrderedDescriptor[] = [];
    const searchRegex = search ? new RegExp(search, "gi") : undefined;

    order.forEach((o) => {
      const group = groups.find((g) => g.id === o);

      if (!group) return;

      group.order.forEach((o) => {
        const descriptor = group.descriptors.find((m) => m.id === o);

        if (!descriptor || (searchRegex && !searchRegex.test(descriptor.name)))
          return;

        ordered.push({
          id: descriptor?.id ?? "",
          name: descriptor?.name ?? "",
          group: group.name,
        });
      });
      resultList.push(ordered);
    });
  });

  if (resultList === null || resultList.length === 0) return [];
  else if (resultList.length === 1) return resultList[0];
  else {
    let commonItems: OrderedDescriptor[] = [];

    for (const item of resultList[0]) {
      const existsInAll = resultList.every((list) =>
        list.some((childItem) => childItem.id === item.id)
      );

      if (
        existsInAll &&
        commonItems.filter((e) => e.id === item.id).length < 1
      ) {
        commonItems.push(item);
      }
    }
    return commonItems;
  }
}

import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useStateReducer } from "../../hooks";
import { NavigableComponent, ReportTemplateFull } from "../../interfaces";
import { reportTemplatesService } from "../../services";
import { FaHandPointer } from "react-icons/fa";
import { getQueryParameter } from "../../utilities";
import { routes } from "../../_config";
import { LoadingIndicator, Scrollable } from "../../components";
import EditReportTemplate from "./components/EditReportTemplate";
import ViewReportTemplate from "./components/ViewReportTemplate";

interface ReportTemplatesItemPageProps {
  index?: boolean;
  onLoad: () => void;
}
interface State {
  reportTemplate?: ReportTemplateFull | null;
  isSaveDisabled?: boolean;
  loading?: boolean;
}

export default function ReportTemplatesItemPage({
  setBreadcrumbs,
  setToolbarConfig,
  index,
  onLoad,
}: NavigableComponent & ReportTemplatesItemPageProps) {
  const { settingId } = useParams();
  const [state, setState] = useStateReducer<State>({ isSaveDisabled: true });
  const { reportTemplate } = state;
  const loading = reportTemplate ? false : true;
  const location = useLocation();
  const mode = getQueryParameter(location.search, "mode");

  useEffect(() => {
    const load = async () => {
      const _reportTemplate = await reportTemplatesService.getSingle({
        id: settingId ?? "",
      });

      setState({ reportTemplate: _reportTemplate, loading: false });

      onLoad();

      setBreadcrumbs(
        index
          ? [
              { text: "Settings", to: routes.settings.go() },
              { text: "Report Templates" },
            ]
          : [
              { text: "Settings", to: routes.settings.go() },
              { text: "Report Templates", to: routes.reportTemplates.raw },
              { text: _reportTemplate.name },
            ]
      );
    };

    setState({ reportTemplate: null, isSaveDisabled: true, loading: true });
    load();
  }, [settingId, mode, setBreadcrumbs, onLoad, index, setState]);

  let content = <></>;

  if (index)
    content = (
      <>
        <FaHandPointer /> Select a report template on the left.
      </>
    );
  else if (!reportTemplate || settingId !== reportTemplate.id || loading)
    content = <LoadingIndicator />;
  else
    content = (
      <Scrollable>
        {mode === "edit" ? (
          <EditReportTemplate
            setToolbarConfig={setToolbarConfig}
            setBreadcrumbs={setBreadcrumbs}
            reportTemplate={reportTemplate}
            onLoad={onLoad}
          />
        ) : (
          <ViewReportTemplate
            setToolbarConfig={setToolbarConfig}
            setBreadcrumbs={setBreadcrumbs}
            reportTemplate={reportTemplate}
          />
        )}
      </Scrollable>
    );

  return content;
}

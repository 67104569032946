import React, { useEffect } from "react";
import { Button, Form } from "../../../components";
import { InstructionWrapper, TextField } from "../../../components/fields";
import { useStateReducer } from "../../../hooks";
import { clone } from "lodash";

interface CalculateProps {
  formula: string;
}

interface State {
  items?: Map<string, string>;
  result?: string;
}

export default function CalculateModal({ formula }: CalculateProps) {
  const [state, setState] = useStateReducer<State>({});

  useEffect(() => {
    let match;
    const regex = /\{[^}]*\}/g;
    let items = clone(state.items ?? new Map<string, string>());

    while ((match = regex.exec(formula))) items.set(match.toString(), "");
    setState({ items });
  }, []);

  const getSplit = (value: string) => {
    let val = value.replace("{", "").replace("}", "");

    let index = val.indexOf("-");

    if (index === -1) return val;

    return val.substring(index + 1);
  };

  return (
    <Form>
      <InstructionWrapper text="Formula and result">
        <TextField multiline label={"Formula"} value={formula ?? ""} />
        <TextField label={"Result"} value={state.result ?? ""} />
      </InstructionWrapper>
      <Button
        style={{ flex: "0 0 30px " }}
        primary
        text="Calculate"
        onClick={() => {
          let result = clone(formula);

          Array.from(state.items ?? new Map<string, string>()).forEach(
            ([key, value]) => {
              result = result
                .replace(key, value)
                .replace(new RegExp(key, "g"), value);
            }
          );

          try {
            let total = eval(result);
            setState({ result: total });
          } catch {
            setState({ result: "Error parsing formula" });
          }
        }}
      />
      <h3>Please capture test values for formula</h3>
      {Array.from(state.items ?? new Map<string, string>()).map(
        ([key, value]) => (
          <>
            <TextField
              key={key}
              label={getSplit(key)}
              value={value ?? ""}
              onChange={(e) => {
                let items = clone(state.items ?? new Map<string, string>());
                items.set(key, e.target.value);
                setState({ items });
              }}
            />
          </>
        )
      )}
    </Form>
  );
}

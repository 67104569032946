import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { ChartType } from "../../../interfaces";
import {
  BiBarChart,
  BiLineChart,
  BiLineChartDown,
  BiScatterChart,
} from "react-icons/bi";
import {
  BoxAndWhiskerChartIcon,
  DurovChartIcon,
  PiperChartIcon,
  StiffChartIcon,
} from "../../../components/custom-icons";
import { Clickable } from "../../../components";
import { classNameBuilder } from "../../../utilities";

const chartTypes: {
  type: ChartType;
  label: string;
  icon: ReactNode;
}[] = [
  { type: "Bar", label: "Bar", icon: <BiBarChart /> },
  { type: "Line", label: "Line", icon: <BiLineChart /> },
  { type: "Scatter", label: "Scatter", icon: <BiScatterChart /> },
  {
    type: "BoxAndWhisker",
    label: "Box and whisker",
    icon: <BoxAndWhiskerChartIcon />,
  },
  { type: "Piper", label: "Piper", icon: <PiperChartIcon /> },
  { type: "Durov", label: "Durov", icon: <DurovChartIcon /> },
  { type: "Schoeller", label: "Schoeller", icon: <BiLineChartDown /> },
  { type: "Stiff", label: "Stiff", icon: <StiffChartIcon /> },
];

interface ChartTypeChartTypeSelectorProps {
  value?: ChartType;
  onChange?: (value: ChartType) => void;
  readOnly?: boolean;
}

export default function ChartTypeSelector({
  onChange,
  value,
  readOnly,
}: ChartTypeChartTypeSelectorProps) {
  const [selected, setSelected] = useState<ChartType | undefined>(value);
  const handleSelect = useCallback(
    (value: ChartType) => {
      setSelected(value);
      onChange?.(value);
    },
    [setSelected, onChange]
  );

  useEffect(() => {
    setSelected(value);
  }, [value]);

  return (
    <div className="chart-type-selector">
      <h3>Chart Type</h3>
      {chartTypes.map((ct, i) =>
        readOnly ? (
          <div
            key={`option-${ct.type}`}
            className={classNameBuilder(
              "chart-type-option",
              selected === ct.type ? "selected" : ""
            )}
          >
            <span className="chart-type-icon">{ct.icon}</span>
            <label>{ct.label}</label>
          </div>
        ) : (
          <Clickable
            className={classNameBuilder(
              "chart-type-option",
              selected === ct.type ? "selected" : ""
            )}
            key={`option-${ct.type}`}
            onClick={() => handleSelect(ct.type)}
          >
            <span className="chart-type-icon">{ct.icon}</span>
            <label>{ct.label}</label>
          </Clickable>
        )
      )}
    </div>
  );
}

import React, { useCallback, useEffect } from "react";
import { ConstructionMaterials, NavigableComponent } from "../../../interfaces";
import { Form } from "../../../components";
import { ColorPicker, InstructionWrapper } from "../../../components/fields";
import { descriptorsService, materialsService } from "../../../services";
import ReferenceSummary from "../../../interfaces/ReferenceSummary";
import { useStateReducer } from "../../../hooks";
import DescriptorSelector from "./DescriptorSelector";
import MaterialPicker from "./MaterialPicker";
import { PatternKey } from "../../../components/svg-patterns/Patterns";
import { routes } from "../../../_config";
import { FaSave, FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

interface ConstructionItemPageProps {
  materials: ConstructionMaterials;
  mode: "view" | "edit";
}

interface State {
  descriptor?: ReferenceSummary;
  options?: string[];
  materialValues?: {
    [key: string]: { color: string; type: PatternKey };
  };
}

export default function ConstructionItemPage({
  materials,
  mode,
  setToolbarConfig,
}: NavigableComponent & ConstructionItemPageProps) {
  const [state, setState] = useStateReducer<State>({
    descriptor: materials.descriptor,
    options: Object.keys(materials.materials),
    materialValues: materials.materials,
  });
  const navigate = useNavigate();

  useEffect(() => {
    const load = async () => {
      if (!state.descriptor) return;

      const descriptor = await descriptorsService.getSingle({
        id: state.descriptor.id,
      });

      setState({ options: descriptor?.options?.sort() ?? [] });
    };

    if (!state.options?.length) load();

    if (mode === "edit")
      setToolbarConfig({
        allowSearch: true,
        searchPath: routes.materials.go(),
        toolbarItems: [
          {
            label: "Save",
            icon: FaSave,
            onClick: async () => {
              if (!state.descriptor || !state.materialValues) return;

              await materialsService.upsertConstruction({
                descriptorId: state.descriptor.id,
                materials: state.materialValues,
              });

              navigate(routes.material.go(materials.id));
            },
            primary: true,
            raised: true,
          },
          "|",
          {
            label: "Cancel",
            icon: FaTimes,
            onClick: () => navigate(routes.material.go(materials.id)),
          },
        ],
      });
  }, [
    materials,
    state.descriptor,
    state.materialValues,
    state.options?.length,
    setState,
    mode,
    setToolbarConfig,
    navigate,
  ]);

  const handleColorChange = useCallback(
    (key: string, color: string) => {
      const mat = state.materialValues?.[key] || {
        color,
        type: "usgs-101",
      };

      setState({
        materialValues: {
          ...state.materialValues,
          [key]: { ...mat, color },
        },
      });
    },
    [state, setState]
  );

  const handleMaterialChange = useCallback(
    (key: string, material: PatternKey) => {
      const mat = state.materialValues?.[key] || {
        color: "#ffffff",
        type: material,
      };

      setState({
        materialValues: {
          ...state.materialValues,
          [key]: { ...mat, type: material },
        },
      });
    },
    [state, setState]
  );

  const handleDescriptorSelect = useCallback(
    (descriptor?: ReferenceSummary) => {
      const newState = { ...state, descriptor };

      if (!descriptor) newState.options = undefined;

      setState(newState);
    },
    [setState, state]
  );

  return (
    <>
      <DescriptorSelector
        descriptor={state.descriptor}
        onSelect={handleDescriptorSelect}
        mode={mode}
      />
      {state.descriptor && state.options ? (
        <Form title="Materials">
          {state.options.map((o) => {
            const mat = state.materialValues?.[o];

            return (
              <InstructionWrapper
                key={`option-${o}`}
                text={
                  <span>
                    Material and colour for construction{" "}
                    <em
                      style={{
                        fontStyle: "normal",
                        display: "inline",
                        fontWeight: 700,
                      }}
                    >
                      {o}
                    </em>
                    .
                  </span>
                }
                row
              >
                <MaterialPicker
                  readOnly={mode !== "edit"}
                  material={mat?.type}
                  onChange={(material) => handleMaterialChange(o, material)}
                />
                <ColorPicker
                  readOnly={mode !== "edit"}
                  label="Colour"
                  value={mat?.color}
                  onChange={(color) => handleColorChange(o, color)}
                />
              </InstructionWrapper>
            );
          })}
        </Form>
      ) : undefined}
    </>
  );
}

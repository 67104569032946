import React from "react";
import { useEffect } from "react";
import { PageWrapper } from "../../components";
import { NavigationContext } from "../../components/side-nav/SideNav";
import { StateTopicEnum } from "../../enums";
import { publish } from "../../hooks";
import { NavigableComponent } from "../../interfaces";
import "./_styles.scss";

export default function ProfilePage({
  setBreadcrumbs,
  setToolbarConfig,
}: NavigableComponent) {
  useEffect(() => {
    setBreadcrumbs?.([{ text: "My Profile" }]);
    setToolbarConfig?.({ toolbarItems: [] });
    publish(StateTopicEnum.NavigationContext, NavigationContext.Modules);
  }, [setToolbarConfig, setBreadcrumbs]);

  const meta = { title: "My Profile" };

  return (
    <PageWrapper meta={meta} secure>
      <div className="h-app-profile-page-content">
        <div className="profile-page">
          Customizable profile page renders here!
          <p>Located in "/src/_global/components/profile-page"</p>
        </div>
      </div>
    </PageWrapper>
  );
}

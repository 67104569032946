export class InterpolatedRoute extends String {
  go(...parts: (string | null | undefined)[]): string {
    const toTest = this.toString();
    const regex = /:[a-zA-Z0-1]+/g;
    let toReturn = toTest;
    let match;
    let counter = 0;

    while ((match = regex.exec(toTest)))
      toReturn = toReturn.replace(match[0], parts[counter++] ?? "#ERROR");

    return toReturn;
  }

  get raw() {
    return this.toString();
  }
}

const routes = {
  home: new InterpolatedRoute("/"),
  login: new InterpolatedRoute("/login"),
  loginVerification: new InterpolatedRoute("/login/:jwtToken"),
  profile: new InterpolatedRoute("/profile"),
  settings: new InterpolatedRoute("/settings"),
  settingCategory: new InterpolatedRoute("/settings/:settingConfig"),
  settingItem: new InterpolatedRoute("/settings/:settingConfig/:settingId"),
  descriptors: new InterpolatedRoute("/settings/descriptors-library"),
  measurables: new InterpolatedRoute("/settings/measurables-library"),
  materials: new InterpolatedRoute("/settings/materials-library"),
  material: new InterpolatedRoute("/settings/materials-library/:materialId"),
  siteTemplates: new InterpolatedRoute("/settings/site-templates-library"),
  chartTemplates: new InterpolatedRoute("/settings/chart-templates-library"),
  reportTemplates: new InterpolatedRoute("/settings/report-templates-library"),
  fieldForms: new InterpolatedRoute("/settings/field-forms-library"),
  monitoringGroups: new InterpolatedRoute(
    "/settings/monitoring-groups-library"
  ),
  users: new InterpolatedRoute("/settings/users-library"),
  roles: new InterpolatedRoute("/settings/roles-library"),
  sites: new InterpolatedRoute("/sites"),
  siteItem: new InterpolatedRoute("/sites/:siteId"),
  siteExport: new InterpolatedRoute("/sites/export"),
  siteImport: new InterpolatedRoute("/sites/import"),
  siteImportTemplate: new InterpolatedRoute("/sites/import/template"),
  measurements: new InterpolatedRoute("/measurements"),
  measurementsItem: new InterpolatedRoute("/measurements/:measurementId"),
  measurementsExport: new InterpolatedRoute("/measurements/export"),
  measurementsImport: new InterpolatedRoute("/measurements/import"),
  measurementsImportTemplate: new InterpolatedRoute(
    "/measurements/import/template"
  ),
  map: new InterpolatedRoute("/map"),
  charts: new InterpolatedRoute("/charts"),
  chartItem: new InterpolatedRoute("/charts/:chartId"),
  buildChart: new InterpolatedRoute("/charts/execute"),
  graphBuilder: new InterpolatedRoute("/graph-builder"),
  reportBuilder: new InterpolatedRoute("/report-builder"),
};

export default routes;

import React from "react";
import { useD3 } from "../../hooks";
import Constants from "./Constants";
import { Selection } from "d3";
import {
  addSeriesLegend,
  addTernaryAxisLabels,
  addTernaryGrid,
  calculatePlotHeight,
  calculateRightMargin,
  getSeriesColor,
} from "./utilities";
import { AxisSettings, Series } from "./interfaces";
import { groupArray } from "../../utilities";
import Defaults from "./Defaults";

type DurovSeries<TData> = Series<TData> & {
  getElementGroup: (point: TData) => string;
};

interface ElementValue {
  element: string;
  value: number;
}

interface PlotElement {
  elements: ElementValue[];
  plotValue: number;
  relativeValue: number;
}

interface CartesianCoordinate {
  x: number;
  y: number;
}

interface TernaryPlotAxis {
  aAxis: PlotElement;
  bAxis: PlotElement;
  cAxis: PlotElement;
}

interface PlotAxis {
  min: number;
  max: number;
}

interface PlotData {
  cationPlot: CartesianCoordinate & TernaryPlotAxis;
  anionPlot: CartesianCoordinate & TernaryPlotAxis;
  projectionPlot: CartesianCoordinate;
  rightPlot: CartesianCoordinate & PlotAxis & { value: number; label: string };
  bottomPlot: CartesianCoordinate & PlotAxis & { value: number; label: string };
}

interface SeriesPlotData {
  name: string;
  color: string;
  plotData: PlotData[];
}

interface Elements {
  cations: { a: string[]; b: string[]; c: string[] };
  anions: { a: string[]; b: string[]; c: string[] };
  rightPlot: string;
  bottomPlot: string;
}

interface Styles {
  gridColor?: string;
  cationsBackgroundColor?: string;
  anionsBackgroundColor?: string;
  matrixBackgroundColor?: string;
  rightPlotBackgroundColor?: string;
  bottomPlotBackgroundColor?: string;
}

interface DurovChartProps<TData> {
  data: TData[][];
  elements: Elements;
  getElementValue: (pointGroup: TData[], element: string) => number;
  showGrid?: boolean;
  gridCount?: number;
  gridValueIncrements?: "All" | "Even" | "Odd";
  gridValueFormat?: "Fraction" | "Percentage";
  series: DurovSeries<TData>;
  styles?: Styles;
  axis?: {
    cationsLabels?: {
      a?: string;
      b?: string;
      c?: string;
    };
    anionsLabels?: {
      a?: string;
      b?: string;
      c?: string;
    };
    rightPlot?: AxisSettings;
    bottomPlot?: AxisSettings;
  };
}

function addTernary(
  data: SeriesPlotData[],
  axisDataAccessor: (
    data: SeriesPlotData
  ) => (CartesianCoordinate & TernaryPlotAxis)[],
  svg: Selection<SVGSVGElement, unknown, null, undefined>,
  baseX: number,
  baseY: number,
  baseLength: number,
  equiHeight: number,
  gridCount: number,
  gridValueIncrements: "All" | "Even" | "Odd",
  gridValueFormat: "Fraction" | "Percentage",
  axisLabels: {
    a: string;
    b: string;
    c: string;
  },
  position: "Top" | "Left",
  styles?: Styles
) {
  if (styles?.cationsBackgroundColor && position === "Top")
    svg
      .select(".plot-area")
      .append("polygon")
      .attr(
        "points",
        `${baseX},${baseY} ${baseX + baseLength},${baseY} ${
          baseX + baseLength / 2
        },${baseY - equiHeight}`
      )
      .attr("fill", styles.cationsBackgroundColor);

  if (styles?.anionsBackgroundColor && position === "Left")
    svg
      .select(".plot-area")
      .append("polygon")
      .attr(
        "points",
        `${baseX},${baseY} ${baseX},${baseY + baseLength} ${
          baseX - equiHeight
        },${baseY + baseLength / 2}`
      )
      .attr("fill", styles.anionsBackgroundColor);

  svg
    .select(".plot-area")
    .append("line")
    .attr("x1", baseX)
    .attr("x2", baseX + (position === "Top" ? baseLength : 0))
    .attr("y1", baseY)
    .attr("y2", baseY + (position === "Left" ? baseLength : 0))
    .attr("stroke", "#000000")
    .attr("stroke-width", 1);
  svg
    .select(".plot-area")
    .append("line")
    .attr("x1", baseX)
    .attr("x2", baseX + (position === "Top" ? baseLength / 2 : -equiHeight))
    .attr("y1", baseY)
    .attr("y2", baseY + (position === "Left" ? baseLength / 2 : -equiHeight))
    .attr("stroke", "#000000")
    .attr("stroke-width", 1);
  svg
    .select(".plot-area")
    .append("line")
    .attr("x1", baseX + (position === "Top" ? baseLength : 0))
    .attr("x2", baseX + (position === "Top" ? baseLength / 2 : -equiHeight))
    .attr("y1", baseY + (position === "Left" ? baseLength : 0))
    .attr("y2", baseY + (position === "Left" ? baseLength / 2 : -equiHeight))
    .attr("stroke", "#000000")
    .attr("stroke-width", 1);

  // Add Grid
  addTernaryGrid(
    svg,
    baseX,
    baseY,
    baseLength,
    equiHeight,
    gridCount,
    gridValueIncrements,
    gridValueFormat,
    "clockwise",
    position === "Left" ? "270degrees" : undefined,
    baseLength * 2
  );

  // Add Axis Labels
  addTernaryAxisLabels(
    svg,
    axisLabels,
    baseX,
    baseY,
    baseLength,
    equiHeight,
    "clockwise",
    false,
    position === "Left" ? "270degrees" : undefined,
    baseLength * 2,
    {
      a: position === "Left" ? [0, -40] : [0, -50],
      b: position === "Left" ? [0, 45] : [0, -45],
      c: position === "Left" ? [0, 50] : [0, 40],
    },
    16
  );

  // Plot Points
  const points: {
    color: string;
    x: number;
    y: number;
  }[] = [];

  data.forEach((series) => {
    axisDataAccessor(series).forEach((p) => {
      points.push({
        color: series.color,
        x: p.x,
        y: p.y,
      });
    });
  });

  const pointGrouping = svg.select(".plot-area").append("g");
  pointGrouping
    .selectAll("circle")
    .data(points)
    .enter()
    .append("circle")
    .attr("cx", (p) => baseX + p.x)
    .attr("cy", (p) => baseY - p.y)
    .attr("r", 3)
    .attr("fill", (p) => p.color);
}

function addProjection(
  data: SeriesPlotData[],
  svg: Selection<SVGSVGElement, unknown, null, undefined>,
  baseX: number,
  baseY: number,
  baseLength: number,
  gridCount: number,
  styles?: Styles
) {
  if (styles?.matrixBackgroundColor)
    svg
      .select(".plot-area")
      .append("polygon")
      .attr(
        "points",
        `${baseX},${baseY} ${baseX + baseLength},${baseY} ${
          baseX + baseLength
        },${baseY + baseLength} ${baseX},${baseY + baseLength}`
      )
      .attr("fill", styles.matrixBackgroundColor);

  svg
    .select(".plot-area")
    .append("line")
    .attr("x1", baseX)
    .attr("x2", baseX + baseLength)
    .attr("y1", baseY + baseLength)
    .attr("y2", baseY + baseLength)
    .attr("stroke", "#000000")
    .attr("stroke-width", 1);
  svg
    .select(".plot-area")
    .append("line")
    .attr("x1", baseX + baseLength)
    .attr("x2", baseX + baseLength)
    .attr("y1", baseY)
    .attr("y2", baseY + baseLength)
    .attr("stroke", "#000000")
    .attr("stroke-width", 1);

  // Add Grid
  const gridInc = baseLength / gridCount;
  const gridLines: { x1: number; x2: number; y1: number; y2: number }[] = [];

  for (let i = 1; i < gridCount; i++) {
    gridLines.push({
      x1: baseX + gridInc * i,
      x2: baseX + gridInc * i,
      y1: baseY,
      y2: baseY + baseLength,
    });
    gridLines.push({
      x1: baseX,
      x2: baseX + baseLength,
      y1: baseY + gridInc * i,
      y2: baseY + gridInc * i,
    });
  }

  const gridGrouping = svg.select(".plot-area").append("g");
  gridGrouping
    .selectAll("line")
    .data(gridLines)
    .enter()
    .append("line")
    .attr("x1", (g) => g.x1)
    .attr("x2", (g) => g.x2)
    .attr("y1", (g) => g.y1)
    .attr("y2", (g) => g.y2)
    .attr("stroke", "#aaaaaa")
    .attr("stroke-width", 1)
    .attr("stroke-dasharray", "5,5");

  // Plot Points
  const points: {
    color: string;
    x: number;
    y: number;
  }[] = [];

  data.forEach((series) => {
    series.plotData.forEach((p) => {
      points.push({
        color: series.color,
        x: p.projectionPlot.x,
        y: p.projectionPlot.y,
      });
    });
  });

  const pointGrouping = svg.select(".plot-area").append("g");
  pointGrouping
    .selectAll("circle")
    .data(points)
    .enter()
    .append("circle")
    .attr("cx", (p) => baseX + p.x)
    .attr("cy", (p) => baseY - p.y)
    .attr("r", 3)
    .attr("fill", (p) => p.color);
}

function addRightPlot(
  data: SeriesPlotData[],
  svg: Selection<SVGSVGElement, unknown, null, undefined>,
  baseX: number,
  baseY: number,
  baseLength: number,
  axis?: AxisSettings,
  styles?: Styles
) {
  if (styles?.rightPlotBackgroundColor)
    svg
      .select(".plot-area")
      .append("polygon")
      .attr(
        "points",
        `${baseX},${baseY} ${baseX + baseLength},${baseY} ${
          baseX + baseLength
        },${baseY + baseLength} ${baseX},${baseY + baseLength}`
      )
      .attr("fill", styles.rightPlotBackgroundColor);

  svg
    .select(".plot-area")
    .append("line")
    .attr("x1", baseX)
    .attr("x2", baseX + baseLength)
    .attr("y1", baseY)
    .attr("y2", baseY)
    .attr("stroke", "#000000")
    .attr("stroke-width", 1);
  svg
    .select(".plot-area")
    .append("line")
    .attr("x1", baseX + baseLength)
    .attr("x2", baseX + baseLength)
    .attr("y1", baseY)
    .attr("y2", baseY + baseLength)
    .attr("stroke", "#000000")
    .attr("stroke-width", 1);
  svg
    .select(".plot-area")
    .append("line")
    .attr("x1", baseX + baseLength)
    .attr("x2", baseX)
    .attr("y1", baseY + baseLength)
    .attr("y2", baseY + baseLength)
    .attr("stroke", "#000000")
    .attr("stroke-width", 1);

  // Add Grid
  const gridCount = axis?.x?.tickCount ?? 10;
  const gridInc = baseLength / gridCount;
  const gridLines: {
    x1: number;
    x2: number;
    y1: number;
    y2: number;
    value: string;
  }[] = [];
  const gridMin = data[0].plotData[0].rightPlot.min;
  const gridMax = data[0].plotData[0].rightPlot.max;
  const gridValueInc = (gridMax - gridMin) / gridCount;
  let currentGridValue = gridMin + gridValueInc;

  for (let i = 1; i < gridCount; i++) {
    gridLines.push({
      x1: baseX + gridInc * i,
      x2: baseX + gridInc * i,
      y1: baseY,
      y2: baseY + baseLength,
      value: currentGridValue.toFixed(axis?.x?.tickPrecision ?? 2),
    });
    currentGridValue += gridValueInc;
  }

  const gridGrouping = svg.select(".plot-area").append("g");
  gridGrouping
    .selectAll("line")
    .data(gridLines)
    .enter()
    .append("line")
    .attr("x1", (g) => g.x1)
    .attr("x2", (g) => g.x2)
    .attr("y1", (g) => g.y1)
    .attr("y2", (g) => g.y2)
    .attr("stroke", "#aaaaaa")
    .attr("stroke-width", 1)
    .attr("stroke-dasharray", "5,5");

  gridGrouping
    .selectAll("text")
    .data([
      ...gridLines,
      {
        x2: baseX + baseLength,
        y1: baseY,
        value: gridMax.toFixed(axis?.x?.tickPrecision ?? 2),
      },
    ])
    .enter()
    .append("text")
    .attr("x", (g) => g.x2)
    .attr("y", (g) => g.y1)
    .attr("text-anchor", "end")
    .style("font-size", "12px")
    .style("transform", `rotate(90deg)`)
    .style("transform-box", "fill-box")
    .style("transform-origin", "right center")
    .text((g) => g.value);

  // Add Axis Label
  svg
    .select(".plot-area")
    .append("text")
    .attr("x", baseX + baseLength / 2)
    .attr("y", baseY + baseLength + 20)
    .attr("text-anchor", "middle")
    .attr("alignment-baseline", "middle")
    .style("font-size", 16)
    .text(axis?.x?.label ?? data[0].plotData[0].rightPlot.label);

  // Plot Points
  const points: {
    color: string;
    x: number;
    y: number;
  }[] = [];

  data.forEach((series) => {
    series.plotData.forEach((p) => {
      points.push({
        color: series.color,
        x: p.rightPlot.x,
        y: p.rightPlot.y,
      });
    });
  });

  const pointGrouping = svg.select(".plot-area").append("g");
  pointGrouping
    .selectAll("circle")
    .data(points)
    .enter()
    .append("circle")
    .attr("cx", (p) => baseX + p.x)
    .attr("cy", (p) => baseY - p.y)
    .attr("r", 3)
    .attr("fill", (p) => p.color);
}

function addBottomPlot(
  data: SeriesPlotData[],
  svg: Selection<SVGSVGElement, unknown, null, undefined>,
  baseX: number,
  baseY: number,
  baseLength: number,
  axis?: AxisSettings,
  styles?: Styles
) {
  if (styles?.bottomPlotBackgroundColor)
    svg
      .select(".plot-area")
      .append("polygon")
      .attr(
        "points",
        `${baseX},${baseY} ${baseX + baseLength},${baseY} ${
          baseX + baseLength
        },${baseY + baseLength} ${baseX},${baseY + baseLength}`
      )
      .attr("fill", styles.bottomPlotBackgroundColor);

  svg
    .select(".plot-area")
    .append("line")
    .attr("x1", baseX)
    .attr("x2", baseX)
    .attr("y1", baseY)
    .attr("y2", baseY + baseLength)
    .attr("stroke", "#000000")
    .attr("stroke-width", 1);
  svg
    .select(".plot-area")
    .append("line")
    .attr("x1", baseX)
    .attr("x2", baseX + baseLength)
    .attr("y1", baseY + baseLength)
    .attr("y2", baseY + baseLength)
    .attr("stroke", "#000000")
    .attr("stroke-width", 1);
  svg
    .select(".plot-area")
    .append("line")
    .attr("x1", baseX + baseLength)
    .attr("x2", baseX + baseLength)
    .attr("y1", baseY + baseLength)
    .attr("y2", baseY)
    .attr("stroke", "#000000")
    .attr("stroke-width", 1);

  // Add Grid
  const gridCount = axis?.y?.tickCount ?? 10;
  const gridInc = baseLength / gridCount;
  const gridLines: {
    x1: number;
    x2: number;
    y1: number;
    y2: number;
    value: string;
  }[] = [];
  const gridMin = data[0].plotData[0].bottomPlot.min;
  const gridMax = data[0].plotData[0].bottomPlot.max;
  const gridValueInc = (gridMax - gridMin) / gridCount;
  let currentGridValue = gridMin + gridValueInc;

  for (let i = 1; i < gridCount; i++) {
    gridLines.push({
      x1: baseX,
      x2: baseX + baseLength,
      y1: baseY + gridInc * i,
      y2: baseY + gridInc * i,
      value: currentGridValue.toFixed(axis?.y?.tickPrecision ?? 2),
    });
    currentGridValue += gridValueInc;
  }

  const gridGrouping = svg.select(".plot-area").append("g");
  gridGrouping
    .selectAll("line")
    .data(gridLines)
    .enter()
    .append("line")
    .attr("x1", (g) => g.x1)
    .attr("x2", (g) => g.x2)
    .attr("y1", (g) => g.y1)
    .attr("y2", (g) => g.y2)
    .attr("stroke", "#aaaaaa")
    .attr("stroke-width", 1)
    .attr("stroke-dasharray", "5,5");

  gridGrouping
    .selectAll("text")
    .data([
      ...gridLines,
      {
        x2: baseX + baseLength,
        y2: baseY + baseLength,
        value: gridMax.toFixed(axis?.y?.tickPrecision ?? 2),
      },
    ])
    .enter()
    .append("text")
    .attr("x", (g) => g.x2 + 5)
    .attr("y", (g) => g.y2 + 3)
    .attr("text-anchor", "left")
    .style("font-size", "12px")
    .text((g) => g.value);

  // Add Axis Label
  svg
    .select(".plot-area")
    .append("text")
    .attr("x", baseX - 20)
    .attr("y", baseY + baseLength / 2)
    .attr("text-anchor", "middle")
    .attr("alignment-baseline", "middle")
    .style("font-size", 16)
    .style("transform", `rotate(-90deg)`)
    .style("transform-box", "fill-box")
    .style("transform-origin", "center center")
    .text(axis?.y?.label ?? data[0].plotData[0].bottomPlot.label);

  // Plot Points
  const points: {
    color: string;
    x: number;
    y: number;
  }[] = [];

  data.forEach((series) => {
    series.plotData.forEach((p) => {
      points.push({
        color: series.color,
        x: p.bottomPlot.x,
        y: p.bottomPlot.y,
      });
    });
  });

  const pointGrouping = svg.select(".plot-area").append("g");
  pointGrouping
    .selectAll("circle")
    .data(points)
    .enter()
    .append("circle")
    .attr("cx", (p) => baseX + p.x)
    .attr("cy", (p) => baseY + p.y)
    .attr("r", 3)
    .attr("fill", (p) => p.color);
}

function extractSeriesData<TData>(
  data: TData[][],
  elements: Elements,
  getElementValue: (pointGroup: TData[], element: string) => number,
  series: DurovSeries<TData>,
  baseLength: number,
  equiHeight: number,
  rightPlotAxis?: { min?: number; max?: number },
  bottomPlotAxis?: { min?: number; max?: number }
): SeriesPlotData[] {
  const extractAxisData = (elements: string[], data: TData[]): PlotElement => {
    let plotValue = 0;
    const axisElements = elements.map((element) => {
      const value = getElementValue(data, element);
      plotValue += value;

      return { element, value };
    });

    return {
      elements: axisElements,
      plotValue,
      relativeValue: -1,
    };
  };

  const relativeValue = (context: PlotElement, others: PlotElement[]) =>
    context.plotValue / others.reduce((acc: number, e) => acc + e.plotValue, 0);

  let rightPlotMin = rightPlotAxis?.min ?? 0;
  let rightPlotMax = rightPlotAxis?.max ?? 0;
  let bottomPlotMin = bottomPlotAxis?.min ?? 0;
  let bottomPlotMax = bottomPlotAxis?.max ?? 0;

  const seriesData = data.map((seriesData, seriesIndex) => {
    const grouped = groupArray(seriesData, series.getElementGroup).map(
      (group) => {
        const cationAAxis = extractAxisData(elements.cations.a, group.data);
        const cationBAxis = extractAxisData(elements.cations.b, group.data);
        const cationCAxis = extractAxisData(elements.cations.c, group.data);
        const anionAAxis = extractAxisData(elements.anions.a, group.data);
        const anionBAxis = extractAxisData(elements.anions.b, group.data);
        const anionCAxis = extractAxisData(elements.anions.c, group.data);

        const cationDivisors = [cationAAxis, cationBAxis, cationCAxis];
        const anionDivisors = [anionAAxis, anionBAxis, anionCAxis];

        const cationARelative = relativeValue(cationAAxis, cationDivisors);
        const cationBRelative = relativeValue(cationBAxis, cationDivisors);
        const cationCRelative = relativeValue(cationCAxis, cationDivisors);
        const anionARelative = relativeValue(anionAAxis, anionDivisors);
        const anionBRelative = relativeValue(anionBAxis, anionDivisors);
        const anionCRelative = relativeValue(anionCAxis, anionDivisors);

        const cationX =
          baseLength - (cationBRelative / 2 + cationCRelative) * baseLength;
        const cationY = cationBRelative * equiHeight;
        const anionY =
          (anionBRelative / 2 + anionCRelative) * baseLength - baseLength;
        const anionX = -anionBRelative * equiHeight;

        const projectedX = cationX;
        const projectedY = anionY;

        const rightPlot = extractAxisData([elements.rightPlot], group.data);
        const bottomPlot = extractAxisData([elements.bottomPlot], group.data);

        if (rightPlot.plotValue > rightPlotMax)
          rightPlotMax = rightPlot.plotValue;

        if (rightPlot.plotValue < rightPlotMin)
          rightPlotMin = rightPlot.plotValue;

        if (bottomPlot.plotValue > bottomPlotMax)
          bottomPlotMax = bottomPlot.plotValue;

        if (bottomPlot.plotValue < bottomPlotMin)
          bottomPlotMin = bottomPlot.plotValue;

        return {
          cationPlot: {
            aAxis: {
              ...cationAAxis,
              relativeValue: cationARelative,
            },
            bAxis: {
              ...cationBAxis,
              relativeValue: cationBRelative,
            },
            cAxis: {
              ...cationCAxis,
              relativeValue: cationCRelative,
            },
            x: cationX,
            y: cationY,
          },
          anionPlot: {
            aAxis: {
              ...anionAAxis,
              relativeValue: anionARelative,
            },
            bAxis: {
              ...anionBAxis,
              relativeValue: anionBRelative,
            },
            cAxis: {
              ...anionCAxis,
              relativeValue: anionCRelative,
            },
            x: anionX,
            y: anionY,
          },
          projectionPlot: {
            x: projectedX,
            y: projectedY,
          },
          rightPlot: {
            value: rightPlot.plotValue,
            label: elements.rightPlot,
          },
          bottomPlot: {
            value: bottomPlot.plotValue,
            label: elements.bottomPlot,
          },
        } as PlotData;
      }
    );

    return {
      name:
        series?.getSeriesName?.(seriesData[0], seriesIndex) ??
        `Series ${seriesIndex + 1}`,
      color: getSeriesColor(seriesData[0], seriesIndex, series),
      plotData: grouped,
    } as SeriesPlotData;
  });

  seriesData.forEach((series) =>
    series.plotData.forEach((point) => {
      point.rightPlot.max = rightPlotMax;
      point.rightPlot.min = rightPlotMin;
      point.bottomPlot.max = bottomPlotMax;
      point.bottomPlot.min = bottomPlotMin;
      point.rightPlot.x =
        (point.rightPlot.value / (rightPlotMax - rightPlotMin)) * baseLength;
      point.rightPlot.y = point.anionPlot.y;
      point.bottomPlot.x = point.cationPlot.x;
      point.bottomPlot.y =
        (point.bottomPlot.value / (bottomPlotMax - bottomPlotMin)) * baseLength;
    })
  );

  return seriesData;
}

export default function DurovChart<TData>({
  data,
  elements,
  getElementValue,
  showGrid,
  gridCount,
  gridValueIncrements,
  gridValueFormat,
  series,
  axis,
  styles,
}: DurovChartProps<TData>) {
  const ref = useD3(
    (svg) => {
      const incrementSum = (index: number) => {
        let sum = 10;

        while (index-- > 0) sum += (index + 2) * 10;

        return sum;
      };

      const { renderWidth, renderHeight, plotWidth, seriesWidth } = Constants;
      const seriesBottomHeight =
        renderHeight - calculatePlotHeight(data, series, 0, 0);
      const seriesRightWidth = calculateRightMargin(data, [], series);
      const seriesColCount = (seriesRightWidth - 20) / seriesWidth;
      const margin = {
        top: 20,
        left:
          data.length > 1 && series?.location === "Right"
            ? 90
            : 196 + seriesBottomHeight / 2.3,
        right:
          series?.location === "Bottom" ? -96 : incrementSum(seriesColCount),
        bottom: 0 + (seriesBottomHeight ? 20 + seriesBottomHeight : 0),
      };

      const baseLength = renderWidth / 3 - margin.left - margin.right;
      const equiHeight = (baseLength * Math.sqrt(3)) / 2;
      const grids = showGrid ? gridCount ?? 5 : 0;

      const seriesData = extractSeriesData(
        data,
        elements,
        getElementValue,
        series,
        baseLength,
        equiHeight,
        axis?.rightPlot?.x,
        axis?.bottomPlot?.y
      );

      // Cation (Top) Ternary
      addTernary(
        seriesData,
        (d) => d.plotData.map((p) => p.cationPlot),
        svg,
        margin.left + equiHeight,
        margin.top + equiHeight,
        baseLength,
        equiHeight,
        grids,
        gridValueIncrements ?? "All",
        gridValueFormat ?? "Percentage",
        {
          a: axis?.cationsLabels?.a || elements.cations.a.join(" + "),
          b: axis?.cationsLabels?.b || elements.cations.b.join(" + "),
          c: axis?.cationsLabels?.c || elements.cations.c.join(" + "),
        },
        "Top",
        styles
      );

      // Anion (Left) Ternary
      addTernary(
        seriesData,
        (d) => d.plotData.map((p) => p.anionPlot),
        svg,
        margin.left + equiHeight,
        margin.top + equiHeight,
        baseLength,
        equiHeight,
        grids,
        gridValueIncrements ?? "All",
        gridValueFormat ?? "Percentage",
        {
          a: axis?.anionsLabels?.a || elements.anions.a.join(" + "),
          b: axis?.anionsLabels?.b || elements.anions.b.join(" + "),
          c: axis?.anionsLabels?.c || elements.anions.c.join(" + "),
        },
        "Left",
        styles
      );

      // Projection
      addProjection(
        seriesData,
        svg,
        margin.left + equiHeight,
        margin.top + equiHeight,
        baseLength,
        grids,
        styles
      );

      // Right Plot
      addRightPlot(
        seriesData,
        svg,
        margin.left + equiHeight + baseLength,
        margin.top + equiHeight,
        baseLength,
        axis?.rightPlot,
        styles
      );

      // Bottom Plot
      addBottomPlot(
        seriesData,
        svg,
        margin.left + equiHeight,
        margin.top + equiHeight + baseLength,
        baseLength,
        axis?.bottomPlot,
        styles
      );

      // Series Legend
      if (data.length > 1) addSeriesLegend(svg, series, data, plotWidth, 0);
    },
    [data]
  );

  return (
    <svg
      ref={ref}
      className="h-app-chart-piper"
      viewBox={`0 0 ${Constants.renderWidth} ${Constants.renderHeight}`}
      height={Constants.renderHeight}
      width="100%"
      preserveAspectRatio="xMidYMid meet"
    >
      <Defaults />
      <defs>
        <marker
          id="arrowheadright"
          markerWidth="10"
          markerHeight="13"
          refX="0"
          refY="6.5"
          orient="auto"
        >
          <polygon points="0 0, 10 6.5, 0 13" />
        </marker>
        <marker
          id="arrowheadleft"
          markerWidth="10"
          markerHeight="13"
          refX="0"
          refY="6.5"
          orient="auto"
        >
          <polygon points="0 6.5, 10 0, 10 13" />
        </marker>
      </defs>
      <g className="plot-area" />
      <g className="series" />
    </svg>
  );
}

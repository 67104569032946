import { Selection } from "d3";
import measureTextWidth from "./measureTextWidth";

const labelTranslateY = 45;

export default function addTernaryAxisLabels(
  svg: Selection<SVGSVGElement, unknown, null, undefined>,
  axisLabels: {
    a: string;
    b: string;
    c: string;
  },
  baseX: number,
  baseY: number,
  baseLength: number,
  equiHeight: number,
  direction: "clockwise" | "counter-clockwise",
  showArrows?: boolean,
  rotation?: "270degrees",
  cAxisAdjust?: number,
  labelTranslate?: {
    a?: number[];
    b?: number[];
    c?: number[];
  },
  fontSize?: number
) {
  if (!fontSize) fontSize = 20;

  const canvas = document.createElement("canvas");
  const labelAWidth = measureTextWidth(axisLabels.a, canvas, `${fontSize}px`);
  const labelBWidth = measureTextWidth(axisLabels.b, canvas, `${fontSize}px`);
  const labelCWidth = measureTextWidth(axisLabels.c, canvas, `${fontSize}px`);
  canvas.remove();

  const rotated = rotation === "270degrees";
  const aTranslateX =
    labelTranslate?.a?.[0] ??
    (!rotated ? (direction === "clockwise" ? -5 : -3) : 0);
  const aTranslateY =
    labelTranslate?.a?.[1] ??
    (!rotated ? (direction === "clockwise" ? 3 : -2) - labelTranslateY : 0);

  const axisLabelAGrouping = svg
    .select(".plot-area")
    .append("g")
    .style(
      "transform",
      `rotate(${
        !rotated ? "-60deg" : "-30deg"
      }) translate(${aTranslateX}px,${aTranslateY}px)`
    )
    .style("transform-box", "fill-box")
    .style("transform-origin", "center center");
  axisLabelAGrouping
    .append("text")
    .attr("x", !rotated ? baseX + baseLength / 4 : baseX - equiHeight / 2)
    .attr("y", !rotated ? baseY - equiHeight / 2 : baseY + baseLength / 4)
    .attr("text-anchor", "middle")
    .attr("alignment-baseline", "middle")
    .style("font-size", fontSize)
    .text(direction === "clockwise" && !rotated ? axisLabels.b : axisLabels.a);

  if (showArrows === undefined || showArrows) {
    axisLabelAGrouping
      .append("line")
      .attr("x1", baseX - baseLength / 4)
      .attr(
        "x2",
        baseX -
          baseLength / 4 +
          (baseLength -
            (direction === "clockwise" ? labelBWidth : labelAWidth)) /
            2 -
          10
      )
      .attr("y1", baseY - equiHeight / 2)
      .attr("y2", baseY - equiHeight / 2)
      .attr("stroke", "#000000")
      .attr("stroke-width", 1)
      .attr(
        "marker-start",
        direction === "counter-clockwise" ? "url(#arrowheadleft)" : ""
      );
    axisLabelAGrouping
      .append("line")
      .attr(
        "x1",
        baseX -
          baseLength / 4 +
          (baseLength -
            (direction === "clockwise" ? labelBWidth : labelAWidth)) /
            2 +
          (direction === "clockwise" ? labelBWidth : labelAWidth) +
          10
      )
      .attr(
        "x2",
        baseX -
          baseLength / 4 +
          baseLength -
          (direction === "clockwise" ? 10 : 0)
      )
      .attr("y1", baseY - equiHeight / 2)
      .attr("y2", baseY - equiHeight / 2)
      .attr("stroke", "#000000")
      .attr("stroke-width", 1)
      .attr(
        "marker-end",
        direction === "clockwise" ? "url(#arrowheadright)" : ""
      );
  }

  const bTranslateX =
    labelTranslate?.b?.[0] ??
    (!rotated ? (direction === "clockwise" ? 0 : 3) : 0);
  const bTranslateY =
    labelTranslate?.b?.[1] ??
    (!rotated ? (direction === "clockwise" ? -6 : -2) - labelTranslateY : 0);

  const axisLabelBGrouping = svg
    .select(".plot-area")
    .append("g")
    .style(
      "transform",
      `rotate(${
        !rotated ? "60deg" : "30deg"
      }) translate(${bTranslateX}px,${bTranslateY}px)`
    )
    .style("transform-box", "fill-box")
    .style("transform-origin", "center center");
  axisLabelBGrouping
    .append("text")
    .attr("x", !rotated ? baseX + (baseLength * 3) / 4 : baseX - equiHeight / 2)
    .attr("y", !rotated ? baseY - equiHeight / 2 : baseY + (baseLength * 3) / 4)
    .attr("text-anchor", "middle")
    .attr("alignment-baseline", "middle")
    .style("font-size", fontSize)
    .text(direction === "clockwise" && !rotated ? axisLabels.a : axisLabels.b);

  if (showArrows === undefined || showArrows) {
    axisLabelBGrouping
      .append("line")
      .attr("x1", baseX + baseLength / 4)
      .attr(
        "x2",
        baseX +
          baseLength / 4 +
          (baseLength -
            (direction === "clockwise" ? labelAWidth : labelBWidth)) /
            2 -
          10
      )
      .attr("y1", baseY - equiHeight / 2)
      .attr("y2", baseY - equiHeight / 2)
      .attr("stroke", "#000000")
      .attr("stroke-width", 1)
      .attr(
        "marker-start",
        direction === "counter-clockwise" ? "url(#arrowheadleft)" : ""
      );
    axisLabelBGrouping
      .append("line")
      .attr(
        "x1",
        baseX +
          (baseLength * 1) / 4 +
          (baseLength -
            (direction === "clockwise" ? labelAWidth : labelBWidth)) /
            2 +
          (direction === "clockwise" ? labelAWidth : labelBWidth) +
          10
      )
      .attr(
        "x2",
        baseX + (baseLength * 5) / 4 - (direction === "clockwise" ? 10 : 0)
      )
      .attr("y1", baseY - equiHeight / 2)
      .attr("y2", baseY - equiHeight / 2)
      .attr("stroke", "#000000")
      .attr("stroke-width", 1)
      .attr("marker-end", "url(#arrowheadright)")
      .attr(
        "marker-end",
        direction === "clockwise" ? "url(#arrowheadright)" : ""
      );
  }

  const cTranslateX = labelTranslate?.c?.[0] ?? 0;
  const cTranslateY =
    labelTranslate?.c?.[1] ?? (!rotated ? labelTranslateY : 0);

  const axisLabelCGrouping = svg
    .select(".plot-area")
    .append("g")
    .style(
      "transform",
      `rotate(${
        !rotated ? "0deg" : "270deg"
      }) translate(${cTranslateX}px,${cTranslateY}px)`
    )
    .style("transform-box", "fill-box")
    .style("transform-origin", "center center");
  axisLabelCGrouping
    .append("text")
    .attr("x", !rotated ? baseX + baseLength / 2 : baseX + (cAxisAdjust ?? 0))
    .attr("y", !rotated ? baseY + (cAxisAdjust ?? 0) : baseY + baseLength / 2)
    .attr("text-anchor", "middle")
    .attr("alignment-baseline", "middle")
    .style("font-size", fontSize)
    .text(axisLabels.c);

  if (showArrows === undefined || showArrows) {
    axisLabelCGrouping
      .append("line")
      .attr("x1", baseX)
      .attr("x2", baseX + (baseLength - labelCWidth) / 2 - 10)
      .attr("y1", baseY)
      .attr("y2", baseY)
      .attr("stroke", "#000000")
      .attr("stroke-width", 1)
      .attr(
        "marker-start",
        direction === "clockwise" ? "url(#arrowheadleft)" : ""
      );
    axisLabelCGrouping
      .append("line")
      .attr("x1", baseX + (baseLength - labelCWidth) / 2 + labelCWidth + 10)
      .attr(
        "x2",
        baseX + baseLength - (direction === "counter-clockwise" ? 10 : 0)
      )
      .attr("y1", baseY)
      .attr("y2", baseY)
      .attr("stroke", "#000000")
      .attr("stroke-width", 1)
      .attr(
        "marker-end",
        direction === "counter-clockwise" ? "url(#arrowheadright)" : ""
      );
  }
}

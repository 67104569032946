import { GetSingleArgs, Module, Permission } from "../interfaces";
import { asyncify } from "../utilities";
import { navigationModules } from "./data";

interface ModuleService {
  getUserModules(permissions: Permission[]): Promise<Module[]>;
  getAllModules(): Promise<Module[]>;
  getSingleModule(args: GetSingleArgs): Promise<Module>;
}

let _CACHE: Module[] = [];

const service: ModuleService = {
  async getUserModules(permissions: Permission[]) {
    const settings = await service.getAllModules();

    return settings.filter(
      (s) => !s.permission || permissions.indexOf(s.permission) >= 0
    );
  },

  async getAllModules() {
    if (_CACHE.length) return _CACHE;

    return await asyncify(() => {
      _CACHE = navigationModules;

      return _CACHE;
    }, 100 + Math.random() * 150);
  },

  async getSingleModule({ id }) {
    const found = _CACHE.filter((m) => m.id === id)[0];

    if (found) return found;

    return (await service.getAllModules()).filter((m) => m.id === id)[0];
  },
};

export default service;

import React, { ReactNode } from "react";
import { ButtonBase } from "@mui/material";
import { useEffect } from "react";
import { useStateReducer } from "../../hooks";
import { classNameBuilder } from "../../utilities";
import "./_styles.scss";
import { IconType } from "react-icons/lib";
import { FaSpinner } from "react-icons/fa";

interface ButtonProps {
  icon?: IconType;
  iconComponent?: ReactNode;
  text?: string;
  onClick?(
    e: React.MouseEventHandler<HTMLButtonElement>
  ): Promise<any> | null | void;
  className?: string;
  primary?: boolean;
  raised?: boolean;
  capitalize?: boolean;
  disabled?: boolean;
  working?: boolean;
  title?: string;
  style?: React.CSSProperties;
  endAdornment?: React.ReactNode;
}

export default function Button({
  icon: Icon,
  iconComponent,
  text,
  onClick,
  className,
  primary,
  raised,
  capitalize,
  disabled,
  working,
  title,
  style,
  endAdornment,
}: ButtonProps) {
  const [state, setState] = useStateReducer({ working: working });

  const handleClick = (e: any) => {
    if (!onClick) return;

    const res = onClick(e);

    if (res instanceof Promise) {
      setState({ working: true });
      res.then(() => setState({ working: false }));
    }
  };

  useEffect(() => {
    setState({ working });
  }, [working, setState]);

  return (
    <ButtonBase
      style={style}
      onClick={handleClick}
      className={classNameBuilder(
        "h-app-button",
        primary ? "primary" : "",
        raised ? "raised" : "",
        capitalize ? "capitalize" : "",
        working || state.working ? "working" : "",
        className ?? ""
      )}
      disabled={disabled || state.working}
      title={title}
    >
      {Icon && !iconComponent && (state.working ? <FaSpinner /> : <Icon />)}
      {iconComponent &&
        !Icon &&
        (state.working ? <FaSpinner /> : iconComponent)}
      {text}
      {!Icon && !iconComponent && state.working && (
        <FaSpinner className="end" />
      )}
      {endAdornment}
    </ButtonBase>
  );
}

import {
  monitoringGroupsService,
  fieldFormsService,
  siteTemplatesService,
  usersService,
  descriptorsService,
  measurablesService,
  rolesService,
  chartTemplatesService,
  materialsService,
  reportTemplatesService,
} from "..";
import { SettingConfig } from "../../interfaces";
import { LibraryCard as MonitoringGroupsLibraryCard } from "../../pages/monitoringgroups-page";
import { IncompleteLibraryCard as MonitoringGroupsIncompleteLibraryCard } from "../../pages/monitoringgroups-page";
import { LibraryCard as FieldFormsLibraryCard } from "../../pages/fieldforms-page";
import { IncompleteLibraryCard as FieldFormsIncompleteLibraryCard } from "../../pages/fieldforms-page";
import { LibraryCard as MeasurablesLibraryCard } from "../../pages/measurables-page";
import { LibraryCard as DescriptorsLibraryCard } from "../../pages/descriptors-page";
import { LibraryCard as SiteTemplatesLibraryCard } from "../../pages/sitetemplates-page";
import { IncompleteLibraryCard as IncompleteSiteTemplatesLibraryCard } from "../../pages/sitetemplates-page";
import { LibraryCard as ChartTemplatesLibraryCard } from "../../pages/charttemplates-page";
import { LibraryCard as MaterialsLibraryCard } from "../../pages/materials-page";
import { LibraryCard as ReportTemplatesLibraryCard } from "../../pages/reporttemplates-page";
import { LibraryCard as UsersLibraryCard } from "../../pages/users-page";
import { LibraryCard as RolesLibraryCard } from "../../pages/roles-page";
import {
  BiFontColor,
  BiLayout,
  BiRadar,
  BiRuler,
  BiShield,
  BiSpreadsheet,
  BiLineChart,
  BiUser,
  BiPalette,
  BiSolidReport,
} from "react-icons/bi";

// NB!! Module property is used on SettingsItemPage to render the correct module, please use single tense and pascal case as you would when naming a File e.g: Measurable.tsx

const categories: SettingConfig[] = [
  {
    id: "descriptors-library",
    name: "Descriptors Library",
    module: "Descriptor",
    description: "Text building blocks of a field form.",
    icon: BiFontColor,
    services: descriptorsService,
    libraryCardTemplate: DescriptorsLibraryCard,
    libraryPermission: "DescriptorLibrary",
    editPermission: "EditDescriptor",
  },
  {
    id: "measurables-library",
    name: "Measurables Library",
    module: "Measurable",
    description: "Numeric building blocks of a field form.",
    icon: BiRuler,
    services: measurablesService,
    libraryCardTemplate: MeasurablesLibraryCard,
    libraryPermission: "MeasurableLibrary",
    editPermission: "EditMeasurable",
  },
  {
    id: "field-forms-library",
    name: "Field Forms",
    module: "FieldForm",
    description: "Manage field forms used by the Zamanzi mobile app here.",
    icon: BiSpreadsheet,
    services: fieldFormsService,
    libraryCardTemplate: FieldFormsLibraryCard,
    incompleteLibraryCardTemplate: FieldFormsIncompleteLibraryCard,
    libraryPermission: "FormLibrary",
    editPermission: "EditForm",
  },
  {
    id: "site-templates-library",
    name: "Site Templates",
    module: "SiteTemplate",
    description: "Manage site templates here.",
    icon: BiLayout,
    services: siteTemplatesService,
    libraryCardTemplate: SiteTemplatesLibraryCard,
    incompleteLibraryCardTemplate: IncompleteSiteTemplatesLibraryCard,
    libraryPermission: "SiteTemplateLibrary",
    editPermission: "EditSiteTemplate",
  },
  {
    id: "monitoring-groups-library",
    name: "Monitoring Groups",
    module: "MonitoringGroup",
    description: "Manage monitoring groups for sites here.",
    icon: BiRadar,
    services: monitoringGroupsService,
    libraryCardTemplate: MonitoringGroupsLibraryCard,
    incompleteLibraryCardTemplate: MonitoringGroupsIncompleteLibraryCard,
    libraryPermission: "MonitoringGroupLibrary",
    editPermission: "EditMonitoringGroup",
  },
  {
    id: "materials-library",
    name: "Materials",
    module: "Materials",
    description:
      "Manage materials used in the rendering lithology and borehole construction here.",
    icon: BiPalette,
    services: materialsService,
    libraryCardTemplate: MaterialsLibraryCard,
    libraryPermission: "MaterialsLibrary",
    editPermission: "ModifyMaterials",
  },
  {
    id: "chart-templates-library",
    name: "Chart Templates",
    module: "ChartTemplate",
    description: "Manage chart templates here.",
    icon: BiLineChart,
    services: chartTemplatesService,
    libraryCardTemplate: ChartTemplatesLibraryCard,
    libraryPermission: "ChartTemplateLibrary",
    editPermission: "EditChartTemplate",
  },
  {
    id: "report-templates-library",
    name: "Report Templates",
    module: "ReportTemplate",
    description: "Manage report templates here.",
    icon: BiSolidReport,
    services: reportTemplatesService,
    libraryCardTemplate: ReportTemplatesLibraryCard,
    libraryPermission: "ReportTemplateLibrary",
    editPermission: "EditReportTemplate",
  },
  {
    id: "roles-library",
    name: "Roles",
    module: "Role",
    description: "Manage system roles here.",
    icon: BiShield,
    services: rolesService,
    libraryCardTemplate: RolesLibraryCard,
    libraryPermission: "RoleLibrary",
    editPermission: "EditRole",
  },
  {
    id: "users-library",
    name: "Users",
    module: "User",
    description: "Manage users and access permissions here.",
    icon: BiUser,
    services: usersService,
    libraryCardTemplate: UsersLibraryCard,
    libraryPermission: "UserLibrary",
    editPermission: "EditUser",
  },
];

export default categories;

import { measureTextWidth } from ".";
import Constants from "../Constants";
import { AxisSettings } from "../interfaces";
import { Selection } from "d3";

export default function addAxisLabels(
  svg: Selection<SVGSVGElement, unknown, null, undefined>,
  canvas: HTMLCanvasElement,
  axis: AxisSettings,
  margin: { top: number; left: number; right: number },
  width: number,
  height: number,
  xAxisLabelHeight: number
) {
  const { axisLabelMarginTop } = Constants;

  if (axis.x?.label)
    svg
      .select(".x-axis")
      .append("text")
      .attr("text-anchor", "middle")
      .attr("font-size", "14px")
      .attr("font-weight", "bold")
      .attr("x", margin.left + (width - margin.left - margin.right) / 2)
      .attr("y", height + xAxisLabelHeight + axisLabelMarginTop + 5)
      .text(axis.x?.label);

  if (axis.y?.label)
    svg
      .select(".y-axis")
      .append("text")
      .attr("text-anchor", "end")
      .attr("font-size", "14px")
      .attr("font-weight", "bold")
      .attr("x", 10)
      .attr(
        "y",
        margin.top +
          height / 2 -
          measureTextWidth(axis.y?.label, canvas, "14px", "bold") / 2
      )
      .style("transform", "rotate(-90deg)")
      .style("transform-box", "fill-box")
      .style("transform-origin", "right center")
      .text(axis.y?.label);
}

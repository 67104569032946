import {
  CrudResponse,
  GetSingleArgs,
  PageAndSearchArgs,
  UserFull,
  UserSummary,
} from "../interfaces";
import CrudService from "../interfaces/CrudService";
import { goFetch } from "../utilities/goFetch";

export type UserUpdateActions =
  | "UpdateDetails"
  | "UpdateCredentials"
  | "UpdateCredentialSettings"
  | "AddRole"
  | "RemoveRole";

interface AddOrRemoveRole {
  roleId: string;
}

interface UpdateCredentials {
  accountName: string;
  password: string;
}

interface UpdateCredentialSettings {
  requireEmailVerification: boolean;
  requirePasswordReset: boolean;
}

interface UpdateUserDetails {
  name: string;
  surname: string;
  emailAddress: string;
}

interface UsersService {
  create: (accountName: string) => Promise<CrudResponse>;
  update: (
    id: string,
    action: UserUpdateActions,
    definition:
      | AddOrRemoveRole
      | UpdateCredentials
      | UpdateCredentialSettings
      | UpdateUserDetails
  ) => Promise<UserFull>;
  delete: (id: string) => Promise<CrudResponse>;
}

const controller = "users";

const service: CrudService<UserFull, UserSummary> & UsersService = {
  getByPage: async ({ search, pageNo, pageSize }: PageAndSearchArgs) => {
    const response = await goFetch(`${controller}/page/${pageNo}`)
      .withQueryParameters({
        pageSize: pageSize || 50,
        search: search,
      })
      .get();

    return {
      pageNo: response?.pageNo ?? 0,
      pageSize: response?.pageSize ?? 0,
      pageCount: response?.pageCount ?? 0,
      itemCount: response?.itemCount ?? 0,
      data: response?.data ?? [],
    };
  },

  getSingle: async ({ id }: GetSingleArgs) =>
    await goFetch(`${controller}/${id}`).get(),

  create: async (accountName) =>
    await goFetch(controller).withBody({ accountName }).post(),

  update: async (id, action, definition) =>
    await goFetch(`${controller}/${id}`)
      .withBody({ action, definition })
      .patch(),

  delete: async (id) => await goFetch(`${controller}/${id}`).delete(),
};

export default service;

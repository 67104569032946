import React, { useCallback, useEffect } from "react";
import {
  BarConfig,
  BoxAndWhiskerConfig,
  ChartTemplateFull,
  DurovConfig,
  LineConfig,
  NavigableComponent,
  PiperConfig,
  ScatterConfig,
  SchoellerConfig,
  StiffConfig,
} from "../../../interfaces";
import { publish, useAuthentication } from "../../../hooks";
import { useNavigate, useParams } from "react-router-dom";
import { ToolItem } from "../../../components/toolbar/Toolbar";
import { FaArrowLeft, FaEdit, FaEye, FaTrash } from "react-icons/fa";
import { routes } from "../../../_config";
import { hideAlert, showAlert } from "../../../components/alert/Alert";
import { AlertTypeEnum, StateTopicEnum } from "../../../enums";
import { chartTemplatesService } from "../../../services";
import { LibrarySection } from "../../../components/library/Library";
import { Form, LoadingIndicator } from "../../../components";
import { InstructionWrapper, TextField } from "../../../components/fields";
import ChartTypeSelector from "./ChartTypeSelector";
import BarChartConfig from "./BarChartConfig";
import LineChartConfig from "./LineChartConfig";
import ScatterChartConfig from "./ScatterChartConfig";
import BoxAndWhiskerChartConfig from "./BoxAndWhiskerChartConfig";
import SchoellerChartConfig from "./SchoellerChartConfig";
import PiperChartConfig from "./PiperChartConfig";
import DurovChartConfig from "./DurovChartConfig";
import StiffChartConfig from "./StiffChartConfig";
import { hideModal, showModal } from "../../../components/modal/Modal";
import PreviewChartModal from "./PreviewChartModal";

interface ViewChartTemplateProps {
  chartTemplate: ChartTemplateFull;
}

export default function ViewChartTemplate({
  chartTemplate,
  setToolbarConfig,
}: NavigableComponent & ViewChartTemplateProps) {
  const auth = useAuthentication();
  const { settingId } = useParams();
  const navigate = useNavigate();

  const handlePreview = useCallback(() => {
    showModal({
      content: (
        <PreviewChartModal
          chartType={chartTemplate.chartType}
          config={chartTemplate.config}
        />
      ),
      options: {
        title: "Preview Chart Template",
        actions: [{ text: "Close", primary: true, onClick: hideModal }],
        className: "chart-preview-modal",
      },
    });
  }, [chartTemplate]);

  useEffect(() => {
    let toolbarItems: (ToolItem | "|")[] = [
      {
        label: "Back",
        icon: FaArrowLeft,
        primary: true,
        raised: true,
        onClick: () =>
          navigate(routes.settingCategory.go("chart-templates-library")),
      },
    ];

    const canEdit = auth?.hasPermission("EditSiteTemplate");
    const canDelete = auth?.hasPermission("RemoveSiteTemplate");

    if (canEdit || canDelete) toolbarItems.push("|");

    if (canEdit)
      toolbarItems.push({
        label: "Edit",
        icon: FaEdit,
        onClick: () =>
          navigate(
            `${routes.settingItem.go(
              "chart-templates-library",
              settingId
            )}?mode=edit`
          ),
      });

    if (canDelete)
      toolbarItems.push({
        label: "Delete",
        icon: FaTrash,
        onClick: () => {
          showAlert({
            content:
              "Are you sure you want to delete this chart template? This action is not reversable.",
            options: {
              type: AlertTypeEnum.Warning,
              actions: [
                {
                  text: "Yes",
                  primary: true,
                  onClick: async () => {
                    const response = await chartTemplatesService.delete?.(
                      settingId ?? ""
                    );

                    hideAlert();

                    if (response?.message) {
                      navigate(
                        routes.settingCategory.go("chart-templates-library")
                      );
                      publish<LibrarySection>(
                        StateTopicEnum.LibrarySectionRefresh,
                        "paged"
                      );
                    }
                  },
                },
                { text: "No" },
              ],
            },
          });
        },
      });

    toolbarItems.push({
      label: "Preview",
      icon: FaEye,
      onClick: handlePreview,
      className: "chart-preview-button",
    });

    setToolbarConfig({
      allowSearch: true,
      toolbarItems,
      searchPath: routes.siteTemplates.go(),
    });
  }, [setToolbarConfig, settingId, navigate, handlePreview]);

  if (!chartTemplate.siteTemplates || !chartTemplate.config)
    return <LoadingIndicator />;

  return (
    <>
      <Form title="Identifying Information">
        <InstructionWrapper text="An identifying name for the chart template.">
          <TextField label="Name" value={chartTemplate.name} readOnly />
        </InstructionWrapper>
        <InstructionWrapper text="The site template(s) that this chart template is associated with.">
          <TextField
            label="Site Template(s)"
            value={chartTemplate.siteTemplates.map((e) => e.name).join(", ")}
            readOnly
          />
        </InstructionWrapper>
        <InstructionWrapper text="The chart type to display.">
          <ChartTypeSelector value={chartTemplate.chartType} readOnly />
        </InstructionWrapper>
      </Form>
      {chartTemplate.chartType === "Bar" && (
        <BarChartConfig
          key="bar-config"
          config={chartTemplate.config as BarConfig}
          siteTemplates={chartTemplate.siteTemplates}
          readOnly
        />
      )}
      {chartTemplate.chartType === "Line" && (
        <LineChartConfig
          key="line-config"
          config={chartTemplate.config as LineConfig}
          siteTemplates={chartTemplate.siteTemplates}
          readOnly
        />
      )}
      {chartTemplate.chartType === "Scatter" && (
        <ScatterChartConfig
          key="line-config"
          config={chartTemplate.config as ScatterConfig}
          siteTemplates={chartTemplate.siteTemplates}
          readOnly
        />
      )}
      {chartTemplate.chartType === "BoxAndWhisker" && (
        <BoxAndWhiskerChartConfig
          key="box-and-whisker-config"
          config={chartTemplate.config as BoxAndWhiskerConfig}
          siteTemplates={chartTemplate.siteTemplates}
          readOnly
        />
      )}
      {chartTemplate.chartType === "Schoeller" && (
        <SchoellerChartConfig
          key="schoeller-config"
          config={chartTemplate.config as SchoellerConfig}
          siteTemplates={chartTemplate.siteTemplates}
          readOnly
        />
      )}
      {chartTemplate.chartType === "Piper" && (
        <PiperChartConfig
          key="piper-config"
          config={chartTemplate.config as PiperConfig}
          siteTemplates={chartTemplate.siteTemplates}
          readOnly
        />
      )}
      {chartTemplate.chartType === "Durov" && (
        <DurovChartConfig
          key="durov-config"
          config={chartTemplate.config as DurovConfig}
          siteTemplates={chartTemplate.siteTemplates}
          readOnly
        />
      )}
      {chartTemplate.chartType === "Stiff" && (
        <StiffChartConfig
          key="stiff-config"
          config={chartTemplate.config as StiffConfig}
          siteTemplates={chartTemplate.siteTemplates}
          readOnly
        />
      )}
    </>
  );
}

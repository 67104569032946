import {
  CrudResponse,
  GetSingleArgs,
  PageAndSearchArgs,
  Permission,
  RoleFull,
  RoleSummary,
} from "../interfaces";
import CrudService from "../interfaces/CrudService";
import { goFetch } from "../utilities/goFetch";

interface RolesService {
  create: (name: string) => Promise<CrudResponse>;
  update: (
    id: string,
    action: "UpdateDetails",
    definition: {
      name: string;
      permissions: Permission[];
      limitSiteTemplatesTo: string[];
      limitMonitoringGroupsTo: string[];
      limitSitesTo: string[];
      limitTagsTo: string[];
      limitDatabasesTo: string[];
    }
  ) => Promise<RoleFull>;
  delete: (id: string) => Promise<CrudResponse>;
}

const controller = "roles";

const service: CrudService<RoleFull, RoleSummary> & RolesService = {
  getByPage: async ({ search, pageNo, pageSize }: PageAndSearchArgs) => {
    const response = await goFetch(`${controller}/page/${pageNo}`)
      .withQueryParameters({
        pageSize: pageSize || 50,
        search: search,
      })
      .get();

    return {
      pageNo: response?.pageNo ?? 0,
      pageSize: response?.pageSize ?? 0,
      pageCount: response?.pageCount ?? 0,
      itemCount: response?.itemCount ?? 0,
      data: response?.data ?? [],
    };
  },

  getSingle: async ({ id }: GetSingleArgs) =>
    await goFetch(`${controller}/${id}`).get(),

  create: async (name) => await goFetch(controller).withBody({ name }).post(),

  update: async (id, action, definition) =>
    await goFetch(`${controller}/${id}`)
      .withBody({ action, definition })
      .patch(),

  delete: async (id) => await goFetch(`${controller}/${id}`).delete(),
};

export default service;

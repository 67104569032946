import { UserFull } from "../interfaces";

const config = {
  applicationName: "Zamanzi",
  utilities: {
    extractAccountNameFromUser: (user?: UserFull) =>
      user?.emailAddress ?? "Not Specified",
    extractDisplayNameFromUser: (user?: UserFull) =>
      user?.display ?? "Not Specified",
  },
  tokens: {
    mapBox:
      "pk.eyJ1Ijoid2VybmVyLXR5cGVkZXYiLCJhIjoiY2w2a2UwY2NhMDB3YTNubWVqMHBtMGlzNSJ9.H8igM52nSceLGjLQivEjzQ",
  },
};

export default config;

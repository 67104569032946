import { DependencyList, useEffect, useRef } from "react";
import * as d3 from "d3";

export const useD3 = (
  render: (svg: d3.Selection<SVGSVGElement, unknown, null, undefined>) => void,
  dependencies: DependencyList | undefined
) => {
  const ref = useRef<SVGSVGElement>(null);

  useEffect(() => {
    render(d3.select(ref.current!));
    return () => {};
  }, dependencies);

  return ref;
};

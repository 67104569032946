import React from "react";
import { faHandPointer } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { PageWrapper } from "../../components";
import { StateTopicEnum } from "../../enums";
import { NavigableComponent } from "../../interfaces";
import "./_styles.scss";
import { publish } from "../../hooks";
import { NavigationContext } from "../../components/side-nav/SideNav";

export default function HomePage({
  setBreadcrumbs,
  setToolbarConfig,
}: NavigableComponent) {
  useEffect(() => {
    setBreadcrumbs?.([]);
    setToolbarConfig?.({ toolbarItems: [] });
    publish(StateTopicEnum.NavigationContext, NavigationContext.Modules);
  }, [setBreadcrumbs, setToolbarConfig]);

  return (
    <PageWrapper
      meta={{ title: "Welcome" }}
      pageId="landing"
      className="h-app-landing"
      secure
    >
      <FontAwesomeIcon icon={faHandPointer} /> Select a module on the left
    </PageWrapper>
  );
}

import React, { useEffect } from "react";
import Form from "../../../components/form/Form";
import {
  InstructionWrapper,
  SearchAndChips,
  TextField,
} from "../../../components/fields";
import { NavigableComponent, RoleFull } from "../../../interfaces";
import { ToolItem } from "../../../components/toolbar/Toolbar";
import { FaArrowLeft, FaEdit, FaTrash } from "react-icons/fa";
import { hideAlert, showAlert } from "../../../components/alert/Alert";
import { AlertTypeEnum, StateTopicEnum } from "../../../enums";
import { routes } from "../../../_config";
import { useNavigate, useParams } from "react-router-dom";
import { rolesService } from "../../../services";
import { publish, useAuthentication, useStateReducer } from "../../../hooks";
import { LibrarySection } from "../../../components/library/Library";
import { Snackbar } from "@mui/material";
import Permissions from "./Permissions";
import { Tab, Tabs } from "../../../components";
interface ViewRoleDataProps {
  role: RoleFull;
}

interface State {
  showSnackbar?: boolean;
  snackBarMessage?: string;
}

export default function ViewRole({
  setToolbarConfig,
  role,
}: ViewRoleDataProps & NavigableComponent) {
  const auth = useAuthentication();
  const { settingId } = useParams();
  const navigate = useNavigate();
  const [state, setState] = useStateReducer<State>({
    showSnackbar: false,
    snackBarMessage: "",
  });

  useEffect(() => {
    let toolbarItems: (ToolItem | "|")[] = [
      {
        label: "Back",
        icon: FaArrowLeft,
        primary: true,
        raised: true,
        onClick: () => navigate(routes.settingCategory.go("roles-library")),
      },
    ];

    const canEdit = auth?.hasPermission("EditRole");
    const canDelete = auth?.hasPermission("RemoveRole");

    if (canEdit || canDelete) toolbarItems.push("|");

    if (canEdit)
      toolbarItems.push({
        label: "Edit",
        icon: FaEdit,
        onClick: () =>
          navigate(
            `${routes.settingItem.go("roles-library", settingId)}?mode=edit`
          ),
      });

    if (canDelete)
      toolbarItems.push({
        label: "Delete",
        icon: FaTrash,
        onClick: () => {
          showAlert({
            content:
              "Are you sure you want to delete this role? This action is not reversable.",
            options: {
              type: AlertTypeEnum.Warning,
              actions: [
                {
                  text: "Yes",
                  primary: true,
                  onClick: async () => {
                    const response = await rolesService.delete?.(
                      settingId ?? ""
                    );

                    hideAlert();

                    if (response?.message) {
                      navigate(routes.settingCategory.go("roles-library"));
                      publish<LibrarySection>(
                        StateTopicEnum.LibrarySectionRefresh,
                        "paged"
                      );
                    } else
                      setState({
                        showSnackbar: true,
                        snackBarMessage: "Server responded with an error",
                      });
                  },
                },
                { text: "No" },
              ],
            },
          });
        },
      });

    setToolbarConfig({
      allowSearch: true,
      toolbarItems,
      searchPath: routes.roles.go(),
    });
  }, [setToolbarConfig, navigate, setState, settingId]);

  return (
    <>
      <Tabs>
        <Tab heading="Overview" identifier="overview" key="tab-overview">
          <Form title="Role Details">
            <InstructionWrapper text="A name identifying this role.">
              <TextField label="Role Name" value={role.name} readOnly />
            </InstructionWrapper>
          </Form>
          <Permissions permissions={role.permissions ?? []} mode="view" />
        </Tab>
        <Tab
          heading="Site Filters"
          identifier="site-filters"
          key="tab-site-filters"
        >
          <Form title="Site Templates">
            <InstructionWrapper text="The site templates that this role is limited to accessing. No limits means this role can see all site templates.">
              <SearchAndChips
                readOnly
                values={role.limitSiteTemplatesTo}
                getOptionLabel={(o) => o.name}
                getOptionValue={(o) => o.id}
              />
            </InstructionWrapper>
          </Form>
          <Form title="Monitoring Groups">
            <InstructionWrapper text="The monitoring groups that this role is limited to accessing. No limits means this role can see all monitoring groups.">
              <SearchAndChips
                readOnly
                values={role.limitMonitoringGroupsTo}
                getOptionLabel={(o) => o.name}
                getOptionValue={(o) => o.id}
              />
            </InstructionWrapper>
          </Form>
          <Form title="Sites">
            <InstructionWrapper text="The individual sites that this role is limited to accessing. No limits means this role can see all sites.">
              <SearchAndChips
                readOnly
                values={role.limitSitesTo}
                getOptionLabel={(o) => o.name}
                getOptionValue={(o) => o.id}
              />
            </InstructionWrapper>
          </Form>
          <Form title="Tags">
            <InstructionWrapper text="The tags that this role is limited to accessing. No limits means this role can see all tags.">
              <SearchAndChips
                readOnly
                values={role.limitTagsTo}
                getOptionLabel={(o) => o}
                getOptionValue={(o) => o}
              />
            </InstructionWrapper>
          </Form>
          <Form title="Databases">
            <InstructionWrapper text="The databases that this role is limited to accessing. No limits means this role can see all databases.">
              <SearchAndChips
                readOnly
                values={role.limitDatabasesTo}
                getOptionLabel={(o) => o}
                getOptionValue={(o) => o}
              />
            </InstructionWrapper>
          </Form>
        </Tab>
      </Tabs>
      <Snackbar
        open={state.showSnackbar}
        autoHideDuration={5000}
        message={state.snackBarMessage}
      />
    </>
  );
}

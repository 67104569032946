import { Selection } from "d3";

export default function addChartGrid(
  svg: Selection<SVGSVGElement, unknown, null, undefined>,
  margin: { top: number; left: number; right: number },
  xAxis: { x: number }[],
  yAxis: { y: number }[],
  height: number,
  width: number,
  color?: string
) {
  svg
    .select(".grid")
    .append("g")
    .attr("class", "grid-x")
    .selectAll("line")
    .data(xAxis)
    .enter()
    .append("line")
    .attr("x1", (t) => t.x + margin.left)
    .attr("x2", (t) => t.x + margin.left)
    .attr("y1", margin.top)
    .attr("y2", height)
    .attr("stroke", color ?? "#bbbbbb")
    .attr("stroke-width", 1)
    .attr("stroke-dasharray", "5,5");

  svg
    .select(".grid")
    .append("g")
    .attr("class", "grid-y")
    .selectAll("line")
    .data(yAxis)
    .enter()
    .append("line")
    .attr("x1", margin.left)
    .attr("x2", width - margin.right)
    .attr("y1", (t) => t.y + margin.top)
    .attr("y2", (t) => t.y + margin.top)
    .attr("stroke", color ?? "#bbbbbb")
    .attr("stroke-width", 1)
    .attr("stroke-dasharray", "5,5");
}

import moment from "moment";
import React from "react";
import { LibraryCardProps, ReportTemplateSummary } from "../../interfaces";

export default function LibraryCard({
  item,
}: LibraryCardProps<ReportTemplateSummary>) {
  const { name, createdOn, updatedOn, siteTemplates, reportType } = item;
  return (
    <>
      <span className="card-primary">{name || "No name specified"}</span>
      <span className="card-secondary">
        Site Template: {siteTemplates.map((e) => e.name).join(" ")}
      </span>
      <span className="card-secondary">Report Type: {reportType}</span>
      <span className="card-secondary">
        Created On: {moment(createdOn).format("DD MMM YYYY, HH:mm:ss")}
      </span>
      <span className="card-secondary">
        Updated On:
        {updatedOn
          ? moment(updatedOn).format("DD MMM YYYY, HH:mm:ss")
          : "Never"}
      </span>
    </>
  );
}

import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { StateTopicEnum } from "./enums";
import {
  AuthContext,
  publish,
  useAuthentication,
  useStateReducer,
  useSubscription,
} from "./hooks";
import { Permission, UserContext, UserFull } from "./interfaces";
import securityService from "./services/securityService";
import { asyncify } from "./utilities";
import { routes } from "./_config";
import LoginReturn from "./interfaces/LoginReturn";

interface ChildrenProps {
  children?: React.ReactNode;
}

interface RequiredChildrenProps {
  children: any;
}

interface State {
  user?: UserFull;
  permissions?: Permission[];
  loading?: boolean;
}

export default function AuthProvider({ children }: ChildrenProps) {
  const [state, setState] = useStateReducer<State>({
    loading: true,
  });
  const { login, logout, checkAuthentication, getPermissions, getMe, verificationEmail, validateOTP } =
    securityService;
  const { user, permissions, loading } = state;

  const signOut = async () => {
    await logout();

    setState({ user: undefined, permissions: undefined });
  };

  const signIn = async (
    username: string,
    password: string
  ): Promise<LoginReturn> => {
    const response = await login({ username, password });

    if (!response || response.loginSuccess === false) {
      setState({ user: undefined, permissions: [] });
      publish(StateTopicEnum.User, null);
      return { loginSuccess: false, otpRequired: false };
    }
    else if (response.otpRequired === true) {
      return { loginSuccess: true, otpRequired: true };
    }

    const user = await getMe();

    if (!user) {
      await signOut();
      return { loginSuccess: false, otpRequired: false };
    }

    const permissions = await getPermissions();

    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("permissions", JSON.stringify(permissions));

    setState({ user, permissions });
    publish(StateTopicEnum.User, user);
    return { loginSuccess: true, otpRequired: false };
  };

  const otpValidation = async (
    userId: string,
    otp: string
  ): Promise<boolean> => {
    const response = await validateOTP({ userId, otp });

    if (!response) {
      setState({ user: undefined, permissions: [] });
      publish(StateTopicEnum.User, null);
      return false;
    }

    const user = await getMe();
    // if the user's detail could not be retrieved we need to destroy the token as the login process could not be completed
    // Need to set user in localStorage in order to preserve the user data after a refresh

    if (!user) {
      await signOut();
      return false;
    }

    const permissions = await getPermissions();

    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("permissions", JSON.stringify(permissions));

    setState({ user, permissions });
    publish(StateTopicEnum.User, user);

    return true;
  };

  const hasPermission = (permission: Permission) =>
    !permissions ? false : permissions.indexOf(permission) >= 0;

  const context: UserContext = {
    user,
    permissions,
    signIn,
    signOut,
    hasPermission,
    verificationEmail,
    otpValidation,
  };

  useEffect(() => {
    const checkAuth = async () => {
      const { user, permissions } = await asyncify(checkAuthentication, 0);

      setState({ user, permissions, loading: false });
      publish(StateTopicEnum.User, user);
    };

    checkAuth();
  }, [checkAuthentication, setState]);

  useSubscription<UserFull>(StateTopicEnum.User, (user) => {
    if (!user) setState({ user: undefined, permissions: undefined });
  });

  if (loading) return <></>;

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
}

interface SecureProps {
  permission?: Permission;
}

export function Secure({
  children,
  permission,
}: RequiredChildrenProps & SecureProps) {
  const auth = useAuthentication();
  const location = useLocation();

  if (!auth?.user)
    return (
      <Navigate to={routes.login.go()} state={{ from: location }} replace />
    );

  if (permission && !auth.hasPermission(permission))
    return (
      <Navigate to={routes.home.go()} state={{ from: location }} replace />
    );

  return children;
}

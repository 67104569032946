import React, { useEffect, useCallback } from "react";
import Form from "../../../components/form/Form";
import { InstructionWrapper, TextField } from "../../../components/fields";
import { publish, useStateReducer } from "../../../hooks";
import { NavigableComponent, MonitoringGroupFull } from "../../../interfaces";
import { useNavigate, useParams } from "react-router-dom";
import { hideAlert, showAlert } from "../../../components/alert/Alert";
import { AlertTypeEnum, StateTopicEnum } from "../../../enums";
import { routes } from "../../../_config";
import { FaSave, FaTimes, FaTrash } from "react-icons/fa";
import { ToolItem } from "../../../components/toolbar/Toolbar";
import _ from "lodash";
import { monitoringGroupsService } from "../../../services";
import { LibrarySection } from "../../../components/library/Library";
import "../_styles.scss";

interface EditMonitoringGroupsPageProps {
  monitoringGroup: MonitoringGroupFull;
}

interface State {
  id?: string;
  name?: string;
  class?: string;
  group?: string;
  subGroup?: string;
}

export default function EditMonitoringGroupPage({
  monitoringGroup,
  setToolbarConfig,
}: EditMonitoringGroupsPageProps & NavigableComponent) {
  const { settingId } = useParams();
  const navigate = useNavigate();
  const [state, setState] = useStateReducer<State>({
    name: monitoringGroup.name || "",
    class: monitoringGroup.class || "",
    group: monitoringGroup.group || "",
    subGroup: monitoringGroup.subGroup || "",
  });

  const handleDelete = useCallback(() => {
    showAlert({
      content:
        "Are you sure you want to discard this draft monitoring group? This action is not reversable.",
      options: {
        type: AlertTypeEnum.Warning,
        actions: [
          {
            text: "Yes",
            primary: true,
            onClick: async () => {
              await monitoringGroupsService.delete!(
                settingId!,
                "DiscardDraftVersion"
              );
              hideAlert();
              navigate(routes.settingCategory.go("monitoring-groups-library"));
              publish<LibrarySection>(
                StateTopicEnum.LibrarySectionRefresh,
                "incomplete"
              );
            },
          },
          { text: "No" },
        ],
      },
    });
  }, [settingId, navigate]);

  const handleSave = useCallback(async () => {
    const response = await monitoringGroupsService.finalize!(settingId!);

    if (response)
      showAlert({
        content: response.message,
        options: {
          type: AlertTypeEnum.Info,
          actions: [
            {
              text: "Ok",
              primary: true,
              onClick: () => {
                hideAlert();
                navigate(
                  routes.settingItem.go("monitoring-groups-library", settingId)
                );
                publish<LibrarySection>(
                  StateTopicEnum.LibrarySectionRefresh,
                  "paged"
                );
                publish<LibrarySection>(
                  StateTopicEnum.LibrarySectionRefresh,
                  "incomplete"
                );
              },
            },
          ],
        },
      });
  }, [settingId, navigate]);

  const handleCancel = useCallback(() => {
    navigate(routes.settingCategory.go("monitoring-groups-library"));
  }, [navigate]);

  const handleChange = useCallback(
    (target: string, value: string) => {
      setState({ [target]: value });
    },
    [setState]
  );

  useEffect(() => {
    let toolbarItems: (ToolItem | "|")[] = [];

    toolbarItems = [
      {
        label: "Save",
        icon: FaSave,
        onClick: handleSave,
        primary: true,
        raised: true,
      },
      "|",
      {
        label: "Cancel",
        icon: FaTimes,
        onClick: handleCancel,
      },
      {
        label: "Discard",
        icon: FaTrash,
        onClick: handleDelete,
      },
    ];

    setToolbarConfig({
      allowSearch: true,
      toolbarItems,
      searchPath: routes.monitoringGroups.go(),
    });
  }, [handleCancel, handleSave, handleDelete, setToolbarConfig]);

  const affectChange = useCallback(
    _.debounce((monitoringGroup) => {
      if (settingId)
        monitoringGroupsService.update!(settingId, "UpdateDetails", {
          ...monitoringGroup,
        });
    }, 1000),
    [settingId]
  );

  useEffect(() => {
    affectChange(state);
  }, [state, affectChange]);

  return (
    <Form title="Identifying Information">
      <InstructionWrapper text="An identifying name for the monitoring group (i.e. river mouth).">
        <TextField
          label="Name"
          value={state.name}
          onChange={(e: any) => handleChange("name", e.target.value)}
        />
      </InstructionWrapper>
      <InstructionWrapper text="A class associated with the monitoring group.">
        <TextField
          label="Class"
          value={state.class}
          onChange={(e: any) => handleChange("class", e.target.value)}
        />
      </InstructionWrapper>
      <InstructionWrapper text="A group associated with the class.">
        <TextField
          label="Group"
          value={state.group}
          onChange={(e: any) => handleChange("group", e.target.value)}
        />
      </InstructionWrapper>
      <InstructionWrapper text="A sub-group associated with the group.">
        <TextField
          label="Sub-Group"
          value={state.subGroup}
          onChange={(e: any) => handleChange("subGroup", e.target.value)}
        />
      </InstructionWrapper>
    </Form>
  );
}

import React, { useEffect, useRef } from "react";
import { Pagination } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useStateReducer, useSubscription } from "../../hooks";
import { getQueryParameter } from "../../utilities";
import Scrollable from "../scrollable/Scrollable";
import LibraryCard from "./LibraryCard";
import "./_styles.scss";
import { StateTopicEnum } from "../../enums";
import { PageAndSearchArgs, PagedData } from "../../interfaces";
import LoadingIndicator from "../loading-indicator/LoadingIndicator";
import { TbDatabaseSearch } from "react-icons/tb";

export interface LibraryConfig<T, A extends {}> {
  searchPath: string;
  service: (args: PageAndSearchArgs & A) => Promise<PagedData<T>>;
  incompleteService?: () => Promise<T[]>;
  cardTemplate: (...args: any[]) => JSX.Element;
  incompleteCardTemplate?: (...args: any[]) => JSX.Element;
  extractKey: (data: T) => string;
  extractPath: (data: T) => string;
  extractIncompletePath?: (data: T) => string;
  providePagingArgs?: () => A | null;
}

interface LibraryProps<T, A extends {}> {
  config: LibraryConfig<T, A>;
}

export interface SearchAndPage {
  search?: string;
  page?: number;
}

export type LibrarySection = "paged" | "incomplete";

interface State {
  page?: number | string;
  search?: string;
  data?: PagedData<any> | null;
  incompleteData?: any[] | null;
}

export default function Library<T, A extends {}>({
  config,
}: LibraryProps<T, A>) {
  const {
    searchPath,
    service,
    incompleteService,
    incompleteCardTemplate,
    cardTemplate,
    extractKey,
    extractPath,
    extractIncompletePath,
    providePagingArgs,
  } = config;
  const location = useLocation();
  const navigate = useNavigate();
  const pageQuery = getQueryParameter<number>(
    location.search,
    "page",
    parseInt
  );
  const pagedRefreshCounter = useRef(0);
  const incompleteRefreshCounter = useRef(0);
  const [state, setState] = useStateReducer<State>({
    page: isNaN(parseInt(pageQuery.toString())) ? 1 : pageQuery,
    search: getQueryParameter(location.search, "search"),
    data: null,
    incompleteData: null,
  });

  const { incompleteData } = state;
  const { pageCount, data } = state.data ?? {};

  const { search, page } = state;
  let pageNo = parseInt(page?.toString() ?? "");

  if (isNaN(pageNo)) pageNo = 1;

  useEffect(() => {
    const loadLibraryData = async () => {
      let args: any = { pageNo, search };

      if (providePagingArgs) {
        const provided: any = providePagingArgs() ?? {};

        Object.keys(provided).forEach((k) => {
          args[k] = provided[k];
        });
      }

      setState({
        data: await service(args),
      });
    };

    setState({ data: null });
    loadLibraryData();
  }, [
    searchPath,
    pageNo,
    search,
    service,
    providePagingArgs,
    setState,
    pagedRefreshCounter.current,
  ]);

  useEffect(() => {
    const loadIncompleteData = async () => {
      if (!incompleteService) return;

      setState({
        incompleteData: await incompleteService(),
      });
    };

    setState({ incompleteData: null });
    loadIncompleteData();
  }, [incompleteService, setState, incompleteRefreshCounter.current]);

  useSubscription<SearchAndPage>(StateTopicEnum.LibrarySearchAndPage, (v) => {
    setState({
      page: v.page,
      search: v.search,
    });
  });

  useSubscription<LibrarySection>(StateTopicEnum.LibrarySectionRefresh, (v) => {
    if (v === "incomplete") incompleteRefreshCounter.current++;
    else if (v === "paged") pagedRefreshCounter.current++;
  });

  const handlePage = (_: any, p: number) => {
    let query = search ? `?search=${search}` : "";
    query += `${query ? "&" : "?"}page=${p}`;

    setState({ page: p });
    navigate(`${searchPath}${query}`, { replace: false });
  };

  return (
    <div className="h-app-library-wrapper">
      {incompleteData?.length ? (
        <React.Fragment key="incomplete-library">
          <h1>
            Continue where you left off ({incompleteData.length} item
            {incompleteData.length === 1 ? "" : "s"}):
          </h1>
          <div className="h-app-incomplete-library">
            <Scrollable className="items">
              <div className="h-app-library-items">
                {incompleteData.map((d: T) => {
                  const Card = incompleteCardTemplate ?? cardTemplate;
                  const key = extractKey?.(d);

                  return (
                    <LibraryCard
                      key={`incomplete-library-item-${key}`}
                      to={extractIncompletePath?.(d)}
                    >
                      <Card item={d} />
                    </LibraryCard>
                  );
                })}
              </div>
            </Scrollable>
          </div>
        </React.Fragment>
      ) : null}
      <div className="h-app-library">
        <Scrollable className="items">
          <div className="h-app-library-items">
            {!data ? (
              <span className="loading">
                <LoadingIndicator />
              </span>
            ) : !data.length ? (
              <div className="no-data-library-card">
                <TbDatabaseSearch />
                No data found.
              </div>
            ) : (
              data.map((d: T) => {
                const Card = cardTemplate;
                const key = extractKey(d);

                return (
                  <LibraryCard key={`library-item-${key}`} to={extractPath(d)}>
                    <Card item={d} />
                  </LibraryCard>
                );
              })
            )}
          </div>
        </Scrollable>
        {(pageCount ?? 0) > 1 ? (
          <Pagination
            className="pager"
            shape="rounded"
            size="small"
            page={pageNo}
            count={pageCount}
            color="primary"
            siblingCount={0}
            boundaryCount={1}
            showFirstButton
            showLastButton
            onChange={handlePage}
          />
        ) : null}
      </div>
    </div>
  );
}

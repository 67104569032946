import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { routes } from "../../_config";
import { AppLogo, Button, Scrollable, SideNavLink, UserAccount } from "../";
import "./_styles.scss";
import { SettingConfig, Module } from "../../interfaces";
import { moduleService, settingService } from "../../services";
import {
  publish,
  useAuthentication,
  useStateReducer,
  useSubscription,
} from "../../hooks";
import { StateTopicEnum } from "../../enums";
import SideNavGroup from "./SideNavGroup";
import { BiArrowBack } from "react-icons/bi";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { classNameBuilder } from "../../utilities";

interface State {
  modules?: Module[] | null;
  settings?: SettingConfig[] | null;
  loading?: boolean;
  context?: NavigationContext;
}

export enum NavigationContext {
  Modules,
  Settings,
}

const Wrapper = ({
  children,
  allowSettings,
}: { children: ReactNode } & SideNavProps) => {
  const [collapsed, setCollapsed] = useState(
    localStorage.getItem("$sideNavCollapsed") === "true"
  );

  const handleCollapse = useCallback(() => {
    localStorage.setItem("$sideNavCollapsed", collapsed ? "false" : "true");
    setCollapsed(!collapsed);
    publish(StateTopicEnum.RefreshMap, {});
  }, [collapsed, setCollapsed]);

  return (
    <nav
      className={classNameBuilder(
        "h-app-side-nav",
        collapsed ? "collapsed" : ""
      )}
      key="side-nav-wrapper"
    >
      <AppLogo />
      <UserAccount allowSettings={allowSettings} />
      <Scrollable>{children}</Scrollable>
      <Button
        className="nav-collapser"
        icon={collapsed ? FaChevronRight : FaChevronLeft}
        onClick={handleCollapse}
      />
    </nav>
  );
};

interface SideNavProps {
  allowSettings: boolean;
}

export default function SideNav() {
  const auth = useAuthentication();
  const [state, setState] = useStateReducer<State>({
    modules: null,
    settings: null,
    loading: true,
    context: NavigationContext.Modules,
  });
  const { modules, settings, loading, context } = state;
  const allowSettings = (settings?.length ?? 0) > 0;

  useEffect(() => {
    const load = async () => {
      const modules = await moduleService.getAllModules();
      const settings = await settingService.getUserSettings(
        auth?.permissions ?? []
      );

      setState({ modules, settings, loading: false });
    };

    load();
  }, [setState]);

  useSubscription<NavigationContext>(
    StateTopicEnum.NavigationContext,
    (ctx) => {
      setState({ context: ctx });
    }
  );

  const renderModule = (
    { id, name, route, children, icon }: Module,
    isChild?: boolean
  ) => {
    if (children?.length)
      return (
        <SideNavGroup label={name} icon={icon}>
          {children.map((module, i) => (
            <React.Fragment key={`child-${i}`}>
              {renderModule(module, true)}
            </React.Fragment>
          ))}
        </SideNavGroup>
      );

    return (
      <SideNavLink
        to={route.go(id)}
        icon={icon}
        className={isChild ? "sub-link" : ""}
        isNavLink
      >
        {name}
      </SideNavLink>
    );
  };

  if (loading) return <Wrapper allowSettings={false}>Loading...</Wrapper>;

  if (context === NavigationContext.Modules)
    return (
      <Wrapper allowSettings={allowSettings}>
        {modules?.map((module, i) => (
          <React.Fragment key={`module-${i}`}>
            {renderModule(module)}
          </React.Fragment>
        ))}
      </Wrapper>
    );

  if (context === NavigationContext.Settings)
    return (
      <Wrapper allowSettings={allowSettings}>
        <SideNavLink
          key="back"
          to="/"
          icon={BiArrowBack}
          className="back"
          isNavLink={false}
        >
          Back
        </SideNavLink>
        {settings?.map((setting, i) => (
          <SideNavLink
            key={`setting-link-${i}`}
            to={routes.settingCategory.go(setting.id)}
            icon={setting.icon}
            isNavLink
          >
            {setting.name}
            <span className="description">{setting.description}</span>
          </SideNavLink>
        ))}
      </Wrapper>
    );

  return <Wrapper allowSettings={false}>Nothing to display</Wrapper>;
}

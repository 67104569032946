import React, { useCallback } from "react";
import { Form } from "../../../components";
import { InstructionWrapper, MultiItem } from "../../../components/fields";
import { AreaBand, Guide } from "../../../interfaces";
import { clone } from "../../../utilities";
import AreaBandConfig from "./AreaBandConfig";
import GuideConfig from "./GuideConfig";
import { v4 as uuid } from "uuid";

interface BandsAndGuidesProps {
  areaBands: AreaBand[];
  guides: Guide[];
  onGuideUpdate?: (guides: Guide[]) => void;
  onAreaBandUpdate?: (areaBands: AreaBand[]) => void;
  readOnly?: boolean;
}

export default function BandsAndGuides({
  areaBands,
  guides,
  onGuideUpdate,
  onAreaBandUpdate,
  readOnly,
}: BandsAndGuidesProps) {
  const handleGuideAdd = useCallback(() => {
    if (readOnly) return;

    onGuideUpdate?.([
      ...guides,
      {
        id: uuid(),
        label: "New Guide",
        color: "#aa0000",
        style: "Dashed",
        value: 0,
      },
    ]);
  }, [guides, onGuideUpdate, readOnly]);

  const handleGuideUpdate = useCallback(
    (guide: Guide, index: number) => {
      if (readOnly || !onGuideUpdate) return;

      const cloned = clone(guides);

      if (!cloned[index]) return;

      cloned[index] = guide;

      onGuideUpdate(cloned);
    },
    [guides, onGuideUpdate, readOnly]
  );

  const handleGuideRemove = useCallback(
    (index: number) => {
      if (readOnly || !onGuideUpdate) return;

      const cloned = clone(guides);
      cloned.splice(index, 1);

      onGuideUpdate(cloned);
    },
    [guides, onGuideUpdate, readOnly]
  );

  const handleAreaBandAdd = useCallback(() => {
    if (readOnly) return;

    onAreaBandUpdate?.([
      ...areaBands,
      {
        id: uuid(),
        label: "New Area Band",
        color: "#aa0000",
        min: 0,
        max: 100,
      },
    ]);
  }, [areaBands, onAreaBandUpdate, readOnly]);

  const handleAreaBandUpdate = useCallback(
    (areaBand: AreaBand, index: number) => {
      if (readOnly || !onAreaBandUpdate) return;

      const cloned = clone(areaBands);

      if (!cloned[index]) return;

      cloned[index] = areaBand;

      onAreaBandUpdate(cloned);
    },
    [areaBands, onAreaBandUpdate, readOnly]
  );

  const handleAreaBandRemove = useCallback(
    (index: number) => {
      if (readOnly || !onAreaBandUpdate) return;

      const cloned = clone(areaBands);
      cloned.splice(index, 1);

      onAreaBandUpdate(cloned);
    },
    [areaBands, onAreaBandUpdate, readOnly]
  );

  return (
    <>
      <Form title="Data Bands">
        <InstructionWrapper text="Coloured data bands that identify regions of values on the plot area.">
          <MultiItem
            className="data-band-builder"
            values={areaBands}
            extractItemKey={(b) => `band-${b.id}`}
            allowAdd={!readOnly}
            allowDelete={!readOnly}
            noDataMessage="No data bands have been specified"
            type="Data Band"
            itemComponent={AreaBandConfig}
            setComponentProps={(areaBand, index) => ({
              areaBand,
              onUpdate: readOnly
                ? undefined
                : (areaBand) => handleAreaBandUpdate(areaBand, index),
              readOnly,
            })}
            onItemAdd={readOnly ? undefined : handleAreaBandAdd}
            onItemDelete={readOnly ? undefined : handleAreaBandRemove}
            readOnly={readOnly}
          />
        </InstructionWrapper>
      </Form>
      <Form title="Guides">
        <InstructionWrapper text="Guidelines displayed on the plot area denoting specific values.">
          <MultiItem
            className="guide-builder"
            values={guides}
            extractItemKey={(g) => `guide-${g.id}`}
            allowAdd={!readOnly}
            allowDelete={!readOnly}
            noDataMessage="No guides have been specified"
            type="Guide"
            itemComponent={GuideConfig}
            setComponentProps={(guide, index) => ({
              guide,
              onUpdate: readOnly
                ? undefined
                : (guide) => handleGuideUpdate(guide, index),
              readOnly,
            })}
            onItemAdd={readOnly ? undefined : handleGuideAdd}
            onItemDelete={readOnly ? undefined : handleGuideRemove}
            readOnly={readOnly}
          />
        </InstructionWrapper>
      </Form>
    </>
  );
}

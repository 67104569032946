import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useStateReducer } from "../../hooks";
import { NavigableComponent, DescriptorFull } from "../../interfaces";
import { descriptorsService } from "../../services";
import { FaHandPointer } from "react-icons/fa";
import { getQueryParameter } from "../../utilities";
import { routes } from "../../_config";
import { LoadingIndicator, Scrollable } from "../../components";
import ViewDescriptor from "./components/ViewDescriptor";
import EditDescriptor from "./components/EditDescriptor";

interface DescriptorsItemPageProps {
  index?: boolean;
  onLoad: () => void;
}
interface State {
  descriptor?: DescriptorFull | null;
  isSaveDisabled?: boolean;
  loading?: boolean;
}

export default function DescriptorsItemPage({
  setBreadcrumbs,
  setToolbarConfig,
  index,
  onLoad,
}: NavigableComponent & DescriptorsItemPageProps) {
  const { settingId } = useParams();
  const [state, setState] = useStateReducer<State>({ isSaveDisabled: true });
  const { descriptor } = state;
  const loading = descriptor ? false : true;
  const location = useLocation();
  const mode = getQueryParameter(location.search, "mode");

  useEffect(() => {
    const load = async () => {
      const _descriptor = await descriptorsService.getSingle({
        id: settingId ?? "",
      });

      setState({ descriptor: _descriptor, loading: false });
      onLoad();

      setBreadcrumbs(
        index
          ? [
              { text: "Settings", to: routes.settings.go() },
              { text: "Descriptors" },
            ]
          : [
              { text: "Settings", to: routes.settings.go() },
              { text: "Descriptors", to: routes.descriptors.raw },
              { text: _descriptor.name },
            ]
      );
    };

    setState({ descriptor: null, isSaveDisabled: true, loading: true });
    load();
  }, [settingId, mode, index, setState, setBreadcrumbs, onLoad]);

  let content = <></>;

  if (index)
    content = (
      <>
        <FaHandPointer /> Select a descriptor on the left.
      </>
    );
  else if (!descriptor || settingId !== descriptor.id || loading)
    content = <LoadingIndicator />;
  else
    content = (
      <Scrollable>
        {mode === "edit" || mode === "add" ? (
          <EditDescriptor
            setToolbarConfig={setToolbarConfig}
            setBreadcrumbs={setBreadcrumbs}
            descriptor={descriptor}
          />
        ) : (
          <ViewDescriptor
            setToolbarConfig={setToolbarConfig}
            setBreadcrumbs={setBreadcrumbs}
            descriptor={descriptor}
          />
        )}
      </Scrollable>
    );

  return content;
}

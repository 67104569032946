import React from "react";
import { DatePicker as MUIDatePicker } from "@mui/x-date-pickers";
import { classNameBuilder } from "../../utilities";
import TextField from "./TextField";

interface DatePickerProps {
  id?: string;
  error?: boolean;
  label?: string;
  value?: Date;
  className?: string;
  onChange?: (
    value: Date | null | undefined,
    keyboardInputValue?: string | undefined
  ) => void;
  disabled?: boolean;
  readOnly?: boolean;
  maxDate?: Date;
  minDate?: Date;
}

export default function DatePicker({
  label,
  value,
  className,
  id,
  onChange,
  error,
  disabled,
  readOnly,
  ...props
}: DatePickerProps) {
  return (
    <MUIDatePicker
      {...props}
      className={classNameBuilder(
        "h-app-date-field",
        readOnly ? "readonly" : "",
        className ?? ""
      )}
      renderInput={(params) => (
        <TextField id={id} {...params} error={error} readOnly={readOnly} />
      )}
      label={label}
      value={value}
      onChange={onChange ?? (() => {})}
      disabled={disabled}
      readOnly={readOnly}
      inputFormat="YYYY/MM/DD"
    />
  );
}

import React from "react";
import { LibraryCardProps, UserSummary } from "../../interfaces";

export default function LibraryCard({ item }: LibraryCardProps<UserSummary>) {
  const { accountName, display } = item;
  return (
    <>
      <span className="card-primary">{display || "No display name"}</span>
      <span className="card-secondary">{accountName}</span>
    </>
  );
}

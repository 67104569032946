import React, { useCallback, useState } from "react";
import { SketchPicker } from "react-color";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Button from "../button/Button";
import { FaCheck, FaTimes } from "react-icons/fa";
import { classNameBuilder } from "../../utilities";
import { TextField } from ".";

interface ColorPickerProps {
  className?: string;
  label?: string;
  value?: string;
  onChange?: (color: string) => void;
  disabled?: boolean;
  readOnly?: boolean;
  endAdornment?: React.ReactNode;
}

export default function ColorPicker({
  label,
  value,
  className,
  onChange,
  disabled,
  readOnly,
  endAdornment,
}: ColorPickerProps) {
  const [showPicker, setShowPicker] = useState(false);
  const [color, setColor] = useState(value);

  const handleChange = useCallback(() => {
    onChange?.(color ?? "#000000");
    setShowPicker(false);
  }, [color, onChange, setShowPicker]);

  const handleCancel = useCallback(() => {
    setColor(value);
    setShowPicker(false);
  }, [value, setColor, setShowPicker]);

  if (readOnly)
    return (
      <TextField
        label={label}
        value={value}
        readOnly
        className={classNameBuilder("h-app-color-picker", className ?? "")}
        startAdornment={
          <span
            className="color-picker-selected"
            style={{ backgroundColor: value || "#ffffff" }}
          />
        }
        endAdornment={endAdornment}
      />
    );

  return (
    <FormControl
      fullWidth
      className={classNameBuilder("h-app-input-field", className ?? "")}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        open={showPicker}
        readOnly={readOnly}
        disabled={disabled}
        label={label}
        value={value}
        size="small"
        className="h-app-color-picker"
        startAdornment={
          <span
            className="color-picker-selected"
            style={{ backgroundColor: value || "#ffffff" }}
          />
        }
        endAdornment={endAdornment}
        onOpen={() => setShowPicker(true)}
      >
        <MenuItem value={value} style={{ display: "none" }}>
          {value}
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            return false;
          }}
          disableTouchRipple
          className="color-picker-menu-item"
        >
          <SketchPicker
            color={color}
            className="h-app-color-picker-modal"
            disableAlpha
            onChange={(c) => setColor(c.hex)}
          />
          <div className="color-picker-buttons">
            <Button icon={FaCheck} primary raised onClick={handleChange} />
            <Button icon={FaTimes} onClick={handleCancel} />
          </div>
        </MenuItem>
      </Select>
    </FormControl>
  );
}

import React, { useMemo } from "react";
import {
  BarConfig,
  LineConfig,
  ScatterConfig,
  PiperConfig,
  DurovConfig,
  SchoellerConfig,
  BoxAndWhiskerConfig,
  StiffConfig,
  ChartType,
  ChartConfigType,
  DataPointFull,
} from "../../../interfaces";
import ReferenceSummary from "../../../interfaces/ReferenceSummary";
import moment from "moment";
import RenderedChart from "../../chart-builder-page/components/RenderedChart";

interface PreviewChartModalProps {
  chartType: ChartType;
  config: ChartConfigType;
}

const randomDate = (from: Date, to: Date) => {
  const fromTime = from.getTime();
  const toTime = to.getTime();

  return new Date(fromTime + Math.random() * (toTime - fromTime));
};

const seedValue = (scale: number, min: number, max?: number) => {
  const rand = Math.random() * scale + min;

  if (max && rand > max) return max;

  return rand;
};

const seedSites = () => {
  const data: ReferenceSummary[] = [];

  for (let i = 1; i <= 10; i++) data.push({ id: `id-${i}`, name: `Site ${i}` });

  return data;
};

const seedMeasurements = () => {
  const data: Date[] = [];

  for (let i = 1; i <= 12; i++)
    data.push(
      randomDate(
        moment("2015-01-01", "YYYY-MM-DD").toDate(),
        moment("2023-12-31", "YYYY-MM-DD").toDate()
      )
    );

  return data;
};

const seedBarChartData = (config: BarConfig): DataPointFull[][] =>
  seedSites().map((site) => {
    const valueScale = seedValue(110, 4);

    return seedMeasurements().map(
      (measurementTimestamp) =>
        ({
          site,
          form: { id: "1", name: "Field Form X" },
          measurementTimestamp,
          measurable: config.measurable,
          value: seedValue(valueScale, 10),
        } as DataPointFull)
    );
  });

const seedLineChartData = (config: LineConfig): DataPointFull[][] =>
  seedSites().map((site) => {
    const valueScale = seedValue(110, 4);

    return seedMeasurements().map((measurementTimestamp) => ({
      site,
      form: { id: "1", name: "Field Form X" },
      measurementTimestamp,
      measurable: config.measurable,
      value: seedValue(valueScale, 3),
    }));
  });

const seedScatterChartData = (config: ScatterConfig): DataPointFull[][] =>
  seedSites().map((site) => {
    const valueScale = seedValue(50, 30);
    const measurements = seedMeasurements();
    const toReturn: DataPointFull[] = [];

    measurements.forEach((measurementTimestamp, i) => {
      toReturn.push({
        site,
        form: { id: "1", name: "Field Form X" },
        measurementTimestamp,
        measurable: config.xAxisMeasurable,
        value: seedValue(valueScale, -5),
      });
      toReturn.push({
        site,
        form: { id: "1", name: "Field Form X" },
        measurementTimestamp,
        measurable: config.yAxisMeasurable,
        value: seedValue(valueScale, -5),
      });
    });

    return toReturn;
  });

const seedPiperChartData = (config: PiperConfig): DataPointFull[][] =>
  seedSites().map((site) => {
    const measurements = seedMeasurements();
    let toReturn: DataPointFull[] = [];

    measurements.forEach((measurementTimestamp) => {
      toReturn = [
        ...toReturn,
        ...config.anionsAElements.map(
          (measurable) =>
            ({
              site,
              form: { id: "1", name: "Field Form X" },
              measurementTimestamp,
              measurable,
              value: seedValue(500, 0),
            } as DataPointFull)
        ),
        ...config.anionsBElements.map(
          (measurable) =>
            ({
              site,
              form: { id: "1", name: "Field Form X" },
              measurementTimestamp,
              measurable,
              value: seedValue(500, 0),
            } as DataPointFull)
        ),
        ...config.anionsCElements.map(
          (measurable) =>
            ({
              site,
              form: { id: "1", name: "Field Form X" },
              measurementTimestamp,
              measurable,
              value: seedValue(500, 0),
            } as DataPointFull)
        ),
        ...config.cationsAElements.map(
          (measurable) =>
            ({
              site,
              form: { id: "1", name: "Field Form X" },
              measurementTimestamp,
              measurable,
              value: seedValue(500, 0),
            } as DataPointFull)
        ),
        ...config.cationsBElements.map(
          (measurable) =>
            ({
              site,
              form: { id: "1", name: "Field Form X" },
              measurementTimestamp,
              measurable,
              value: seedValue(500, 0),
            } as DataPointFull)
        ),
        ...config.cationsCElements.map(
          (measurable) =>
            ({
              site,
              form: { id: "1", name: "Field Form X" },
              measurementTimestamp,
              measurable,
              value: seedValue(500, 0),
            } as DataPointFull)
        ),
      ];
    });

    return toReturn;
  });

const seedDurovChartData = (config: DurovConfig): DataPointFull[][] =>
  seedSites().map((site) => {
    const measurements = seedMeasurements();
    let toReturn: DataPointFull[] = [];

    measurements.forEach((measurementTimestamp) => {
      toReturn = [
        ...toReturn,
        ...config.anionsAElements.map(
          (measurable) =>
            ({
              site,
              form: { id: "1", name: "Field Form X" },
              measurementTimestamp,
              measurable,
              value: seedValue(500, 0),
            } as DataPointFull)
        ),
        ...config.anionsBElements.map(
          (measurable) =>
            ({
              site,
              form: { id: "1", name: "Field Form X" },
              measurementTimestamp,
              measurable,
              value: seedValue(500, 0),
            } as DataPointFull)
        ),
        ...config.anionsCElements.map(
          (measurable) =>
            ({
              site,
              form: { id: "1", name: "Field Form X" },
              measurementTimestamp,
              measurable,
              value: seedValue(500, 0),
            } as DataPointFull)
        ),
        ...config.cationsAElements.map(
          (measurable) =>
            ({
              site,
              form: { id: "1", name: "Field Form X" },
              measurementTimestamp,
              measurable,
              value: seedValue(500, 0),
            } as DataPointFull)
        ),
        ...config.cationsBElements.map(
          (measurable) =>
            ({
              site,
              form: { id: "1", name: "Field Form X" },
              measurementTimestamp,
              measurable,
              value: seedValue(500, 0),
            } as DataPointFull)
        ),
        ...config.cationsCElements.map(
          (measurable) =>
            ({
              site,
              form: { id: "1", name: "Field Form X" },
              measurementTimestamp,
              measurable,
              value: seedValue(500, 0),
            } as DataPointFull)
        ),
        {
          site,
          form: { id: "1", name: "Field Form X" },
          measurementTimestamp,
          measurable: config.rightPlotMeasurable,
          value: seedValue(500, 0),
        } as DataPointFull,
        {
          site,
          form: { id: "1", name: "Field Form X" },
          measurementTimestamp,
          measurable: config.bottomPlotMeasurable,
          value: seedValue(500, 0),
        } as DataPointFull,
      ];
    });
    return toReturn;
  });

const seedSchoellerChartData = (config: SchoellerConfig): DataPointFull[][] =>
  seedSites().map((site) => {
    const measurements = [
      randomDate(
        moment("2015-01-01", "YYYY-MM-DD").toDate(),
        moment("2023-12-31", "YYYY-MM-DD").toDate()
      ),
    ];
    let toReturn: DataPointFull[] = [];

    measurements.forEach((measurementTimestamp) => {
      toReturn = [
        ...toReturn,
        ...config.measurables.map(
          (measurable) =>
            ({
              site,
              form: { id: "1", name: "Field Form X" },
              measurementTimestamp,
              measurable,
              value: seedValue(5, 0.01),
            } as DataPointFull)
        ),
      ];
    });

    return toReturn;
  });

const seedBoxAndWhiskerChartData = (
  config: BoxAndWhiskerConfig
): DataPointFull[][] => {
  const valueScale = seedValue(100, 4);
  const outlierScale = seedValue(300, 50);

  return seedSites().map((site) => {
    const normal = seedMeasurements().map(
      (measurementTimestamp) =>
        ({
          site,
          form: { id: "1", name: "Field Form X" },
          measurementTimestamp,
          measurable: config.measurable,
          value: seedValue(valueScale, 10),
        } as DataPointFull)
    );
    const outliers = seedMeasurements().map(
      (measurementTimestamp) =>
        ({
          site,
          form: { id: "1", name: "Field Form X" },
          measurementTimestamp,
          measurable: config.measurable,
          value: seedValue(outlierScale, -50),
        } as DataPointFull)
    );

    return [...outliers, ...normal];
  });
};

const seedStiffChartData = (config: StiffConfig): DataPointFull[][] =>
  seedSites().map((site) => {
    const measurements = seedMeasurements();
    let toReturn: DataPointFull[] = [];

    measurements.forEach((measurementTimestamp) => {
      toReturn = [
        ...toReturn,
        ...config.anionElements.map(
          (measurable) =>
            ({
              site,
              form: { id: "1", name: "Field Form X" },
              measurementTimestamp,
              measurable,
              value: seedValue(500, 0),
            } as DataPointFull)
        ),
        ...config.cationElements.map(
          (measurable) =>
            ({
              site,
              form: { id: "1", name: "Field Form X" },
              measurementTimestamp,
              measurable,
              value: seedValue(500, 0),
            } as DataPointFull)
        ),
      ];
    });

    return toReturn;
  });

export default function PreviewChartModal({
  chartType,
  config,
}: PreviewChartModalProps) {
  const data = useMemo(() => {
    switch (chartType) {
      case "Bar":
        return seedBarChartData(config as BarConfig);
      case "Line":
        return seedLineChartData(config as LineConfig);
      case "Scatter":
        return seedScatterChartData(config as ScatterConfig);
      case "Piper":
        return seedPiperChartData(config as PiperConfig);
      case "Durov":
        return seedDurovChartData(config as DurovConfig);
      case "Schoeller":
        return seedSchoellerChartData(config as SchoellerConfig);
      case "BoxAndWhisker":
        return seedBoxAndWhiskerChartData(config as BoxAndWhiskerConfig);
      case "Stiff":
        return seedStiffChartData(config as StiffConfig);
      default:
        return [];
    }
  }, [chartType, config]);

  return (
    <RenderedChart chartType={chartType} config={config} data={data.flat()} />
  );
}

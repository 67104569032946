import React from "react";

export default function PiperChartIcon() {
  return (
    <svg
      viewBox="0 0 60 60"
      version="1.1"
      id="svg5"
      xmlns="http://www.w3.org/2000/svg"
      height="1em"
      width="1em"
      stroke="currentColor"
    >
      <path
        id="path111"
        style={{ fill: "none", strokeWidth: 4 }}
        d="M 29.99976,6.7527612 23.792476,16.879778 17.584711,27.007275 23.792476,37.134293 29.99976,47.261311 36.207525,37.134293 42.414811,27.007275 36.207525,16.879778 Z M 13.514107,33.179967 7.3068227,43.306983 1.0995384,53.434001 H 13.514107 25.929158 L 19.721872,43.306983 Z m 32.971305,0 -6.207765,10.127016 -6.207285,10.127018 h 12.41505 12.41505 L 52.693177,43.306983 Z"
      />
    </svg>
  );
}

enum StateTopicEnum {
  Alert = "_alert",
  LibraryPage = "_libraryPage",
  LibrarySearch = "_librarySearch",
  LibrarySearchAndPage = "_librarySearchAndPage",
  LibrarySectionRefresh = "_librarySectionRefresh",
  Modal = "_modal",
  ModalFinalize = "_modalFinalize",
  NavigationContext = "_navigationContext",
  User = "_user",
  Site = "_site",
  MapFilters = "_mapFilters",
  Notes = "_notes",
  History = "_history",
  HistoryTab = "_historytab",
  NoteTab = "_notetab",
  NoteCount = "_noteCount",
  NotesPage = "_notesPage",
  RefreshMap = "_refreshMap",
  CopiedChart = "_copiedChart",
  ReportPdfUrl = "_reportPdfUrl",
}

export default StateTopicEnum;

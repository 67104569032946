import {
  CrudResponse,
  GetSingleArgs,
  ImportItem,
  ImportItemStatusType,
  PageAndSearchArgs,
  PagedData,
} from "../interfaces";
import { goFetch } from "../utilities/goFetch";

interface MeasurementImportItemsService {
  getByPage: (pageArgs: PageAndSearchArgs) => Promise<PagedData<ImportItem>>;
  getSingle: (args: GetSingleArgs) => Promise<ImportItem>;
  delete: (id: string) => Promise<CrudResponse>;
  deleteByStatus: (status: ImportItemStatusType) => Promise<CrudResponse>;
}

const controller = "measurement-import-items";

const service: MeasurementImportItemsService = {
  getByPage: async ({ search, pageNo, pageSize }) => {
    const response = await goFetch(`${controller}/page/${pageNo}`)
      .withQueryParameters({
        pageSize: pageSize || 50,
        search: search,
      })
      .get();

    return {
      pageNo: response?.pageNo ?? 0,
      pageSize: response?.pageSize ?? 0,
      pageCount: response?.pageCount ?? 0,
      itemCount: response?.itemCount ?? 0,
      data: response?.data ?? [],
    };
  },

  getSingle: async ({ id }) => await goFetch(`${controller}/${id}`).get(),

  delete: async (id) => await goFetch(`${controller}/${id}`).delete(),

  deleteByStatus: async (status) =>
    await goFetch(`${controller}`).withQueryParameters({ status }).delete(),
};

export default service;

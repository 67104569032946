import { Selection } from "d3";
import { Series } from "../interfaces";
import getSeriesColor from "./getSeriesColor";
import Constants from "../Constants";
import calculateSeriesRows from "./calculateSeriesRows";

export default function addSeriesLegend<TData>(
  svg: Selection<SVGSVGElement, unknown, null, undefined>,
  series: Series<TData> | undefined,
  data: TData[][],
  chartWidth: number,
  bandAndGuideLegendHeight: number
) {
  const {
    seriesHeight,
    seriesWidth,
    seriesRectangleSize,
    seriesLineItemCount,
    renderHeight,
    plotHeight,
    plotWidth,
  } = Constants;
  let seriesData: { label: string; color: string; x: number; y: number }[] = [];

  if (!series?.location || series.location === "Right") {
    const startAtY =
      (renderHeight -
        (data.length > seriesLineItemCount
          ? seriesLineItemCount
          : data.length) *
          seriesHeight) /
      2;
    const colCount = Math.ceil(data.length / seriesLineItemCount);
    let currentY = startAtY;
    let currentColCount = 1;
    let xAdjust = seriesWidth * colCount;

    seriesData = data.map((s, i) => {
      if (currentColCount > seriesLineItemCount) {
        currentY = startAtY;
        currentColCount = 1;
        xAdjust -= seriesWidth;
      }

      const y = currentY;

      if (currentColCount <= seriesLineItemCount) currentY += seriesHeight;

      let label = series?.getSeriesName?.(data[i][0], i) ?? `Series ${i + 1}`;

      if (label.length > 20) label = `${label.substring(0, 19)}...`;

      currentColCount++;

      return {
        label,
        color: getSeriesColor(s[0], i, series),
        x: chartWidth - xAdjust + 20,
        y,
      };
    });
  } else {
    if (bandAndGuideLegendHeight) bandAndGuideLegendHeight += 10;

    const { labelData, totalHeight } = calculateSeriesRows(data, series);
    const yStart = plotHeight - totalHeight - bandAndGuideLegendHeight;
    let currentRow = 1;
    let currentX = (plotWidth - labelData[0].rowWidth) / 2;

    seriesData = labelData.map((l, i) => {
      const s = data[i];
      let x = currentX;

      if (currentRow !== l.row) {
        currentX = (plotWidth - l.rowWidth) / 2 + l.totalWidth;
        x = (plotWidth - l.rowWidth) / 2;
      } else currentX += l.totalWidth;

      currentRow = l.row;

      return {
        label: l.label,
        color: getSeriesColor(s[0], i, series),
        y: yStart + (l.row - 1) * seriesHeight,
        x,
      };
    });
  }

  const seriesGrouping = svg.select(".series").append("g");
  const seriesElements = seriesGrouping
    .selectAll("g")
    .data(seriesData)
    .enter()
    .append("g");
  seriesElements
    .append("rect")
    .attr("x", (s) => s.x)
    .attr("y", (s) => s.y + (seriesHeight - seriesRectangleSize) / 2)
    .attr("height", seriesRectangleSize)
    .attr("width", seriesRectangleSize)
    .attr("fill", (s) => s.color);
  seriesElements
    .append("text")
    .attr("x", (s) => s.x + seriesRectangleSize + 5)
    .attr("y", (s) => s.y + seriesHeight - 6)
    .style("font-size", "12px")
    .text((s) => s.label);
}

import React, { useEffect, useCallback, useMemo } from "react";
import Form from "../../../components/form/Form";
import {
  Checkbox,
  InstructionWrapper,
  List,
  MultiItem,
  NumberField,
  TextField,
} from "../../../components/fields";
import { publish, useStateReducer } from "../../../hooks";
import { NavigableComponent, MeasurableFull } from "../../../interfaces";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { asyncify, clone, getQueryParameter } from "../../../utilities";
import { routes } from "../../../_config";
import { hideAlert, showAlert } from "../../../components/alert/Alert";
import { AlertTypeEnum, StateTopicEnum } from "../../../enums";
import { ToolItem } from "../../../components/toolbar/Toolbar";
import { FaSave, FaTimes, FaTrash } from "react-icons/fa";
import { measurablesService } from "../../../services";
import { useRef } from "react";
import { LibrarySection } from "../../../components/library/Library";
import validateObject, {
  hasErrors,
  Validation,
} from "../../../utilities/validateObject";
import UnitConversionItem from "./UnitConversionItem";
import { UnitConversion } from "../../../interfaces/Measurable";
import UnitConversionModal from "./UnitConversionModal";
import { hideModal, showModal } from "../../../components/modal/Modal";

interface EditMeasurableDataProps {
  measurable: MeasurableFull;
}

interface State {
  id?: string;
  name?: string;
  unitFull?: string;
  unitAbbreviation?: string;
  minValue?: number;
  maxValue?: number;
  allowRangeSelection?: boolean;
  displayFormat?: string;
  formatTemplate?: string;
  unitConversions?: UnitConversion[];
}

export default function EditMeasurable({
  setToolbarConfig,
  measurable,
}: EditMeasurableDataProps & NavigableComponent) {
  const { settingId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const mode = getQueryParameter(location.search, "mode");
  const [state, setState] = useStateReducer<State>({ ...measurable });
  const saveState = useRef<State>(state);
  const validationRules: Validation = useMemo(
    () => ({
      name: {
        regex: /.+/,
        message: "This field is required.",
      },
      unitFull: {
        regex: /.+/,
        message: "This field is required.",
      },
      unitAbbreviation: {
        regex: /.+/,
        message: "This field is required.",
      },
      minValue: {
        regex: /-?\d+(\.\d+)?/,
        message: "This field is required.",
      },
      maxValue: {
        regex: /-?\d+(\.\d+)?/,
        message: "This field is required.",
      },
    }),
    []
  );

  const validation = validateObject(validationRules, state);

  const handleSave = useCallback(async () => {
    await asyncify(() => {}, 10);

    if (hasErrors(validateObject(validationRules, saveState.current))) return;

    const s = saveState.current;

    const response = await measurablesService.update?.(measurable.id, {
      name: s.name ?? "",
      unitFull: s.unitFull,
      unitAbbreviation: s.unitAbbreviation,
      minValue: s.minValue,
      maxValue: s.maxValue,
      allowRangeSelection: s.allowRangeSelection,
      displayFormat: s.displayFormat,
      unitConversions: s.unitConversions,
    });

    if (!response?.id) {
      showAlert({
        content: "Could not update the measurable.",
        options: {
          type: AlertTypeEnum.Error,
          actions: [{ text: "Ok" }],
        },
      });
      return;
    }

    publish<LibrarySection>(StateTopicEnum.LibrarySectionRefresh, "paged");
    navigate(routes.settingItem.go("measurables-library", settingId));

    showAlert({
      content: "Measurable saved.",
      options: {
        type: AlertTypeEnum.Info,
        actions: [
          {
            text: "Ok",
            primary: true,
          },
        ],
      },
    });
  }, [navigate, settingId, measurable.id, validationRules]);

  const handleTagDelete = useCallback(
    (index: number) => {
      const updated = clone(state.unitConversions) ?? [];
      updated?.splice(index, 1);

      setState({
        unitConversions: updated,
      });
    },
    [state.unitConversions, setState]
  );

  const handleDiscard = useCallback(() => {
    showAlert({
      content:
        "Are you sure you want to discard this draft measurable? This action is not reversable.",
      options: {
        type: AlertTypeEnum.Warning,
        actions: [
          {
            text: "Yes",
            primary: true,
            onClick: async () => {
              hideAlert();

              const response = await measurablesService.delete!(settingId!);

              if (response?.message)
                navigate(routes.settingCategory.go("measurables-library"));
            },
          },
          { text: "No" },
        ],
      },
    });
  }, [navigate, settingId]);

  const handleFormateTemplateClick = useCallback(
    (format: string) => {
      setState({ displayFormat: format });
    },
    [setState]
  );

  const onChange = (
    unitAbbreviation?: string,
    unitFull?: string,
    formula?: string
  ) => {
    setState({
      unitConversions: [
        ...(state.unitConversions ?? []),
        { unitFull, unitAbbreviation, formula },
      ],
    });
  };

  useEffect(() => {
    saveState.current = state;
  }, [state]);

  useEffect(() => {
    let toolbarItems: (ToolItem | "|")[] = [];

    toolbarItems =
      mode !== "edit"
        ? [
            {
              label: "Save",
              icon: FaSave,
              onClick: handleSave,
              primary: true,
              raised: true,
            },
            "|",
            {
              label: "Discard",
              icon: FaTrash,
              onClick: handleDiscard,
            },
          ]
        : [
            {
              label: "Save",
              icon: FaSave,
              onClick: handleSave,
              primary: true,
              raised: true,
            },
            "|",
            {
              label: "Cancel",
              icon: FaTimes,
              onClick: () => {
                navigate(
                  routes.settingItem.go("measurables-library", settingId)
                );
              },
            },
          ];

    setToolbarConfig({
      allowSearch: true,
      toolbarItems,
      searchPath: routes.measurables.go(),
    });
  }, [handleSave, handleDiscard, mode, navigate, setToolbarConfig, settingId]);

  return (
    <>
      <Form title="Identifying Information">
        <InstructionWrapper
          text="An identifying name for the measurable (i.e. distance)."
          error={validation["name"]}
        >
          <TextField
            label="Name"
            value={state?.name || ""}
            onChange={(e) => setState({ name: e.target.value })}
            error={validation["name"] ? true : false}
          />
        </InstructionWrapper>
      </Form>
      <Form title="Measurement Unit">
        <InstructionWrapper
          text="The unit that the measurement is represented in (i.e. meters)."
          error={validation["unitFull"] ?? validation["unitAbbreviation"]}
          row
        >
          <TextField
            label="Full Name"
            value={state?.unitFull}
            onChange={(e) => setState({ unitFull: e.target.value })}
            error={validation["unitFull"] ? true : false}
          />
          <TextField
            label="Abbreviation"
            value={state?.unitAbbreviation}
            onChange={(e) => setState({ unitAbbreviation: e.target.value })}
            error={validation["unitAbbreviation"] ? true : false}
          />
        </InstructionWrapper>
      </Form>
      <Form title="Value Constraints">
        <InstructionWrapper
          text="The minimum and maximum values for this measurement."
          error={validation["minValue"] ?? validation["maxValue"]}
          row
        >
          <NumberField
            label="Min"
            value={state?.minValue}
            onChange={(e) => setState({ minValue: parseFloat(e.target.value) })}
            error={validation["minValue"] ? true : false}
          />
          <NumberField
            label="Max"
            value={state?.maxValue}
            onChange={(e) => setState({ maxValue: parseFloat(e.target.value) })}
            error={validation["maxValue"] ? true : false}
          />
        </InstructionWrapper>
      </Form>
      <Form title="Display">
        <InstructionWrapper text="Whether range selection for values are allowed (i.e. lower than, greater than, equals, etc).">
          <Checkbox
            label="Allow Range Selection"
            value={state?.allowRangeSelection}
            onChange={(_, checked) =>
              setState({ allowRangeSelection: checked })
            }
          />
        </InstructionWrapper>
        <InstructionWrapper text="The display format for the value." row>
          <TextField
            label="Display Format"
            value={state?.displayFormat}
            onChange={(e) => setState({ displayFormat: e.target.value })}
          />
          <List
            label="Format Templates"
            value={""}
            options={[
              {
                label: "Empty",
                value: "",
              },
              {
                label: "Thousand separator with no decimals (i.e. 123,456)",
                value: "###,###",
              },
              {
                label:
                  "Thousand separator with with 2 decimals (i.e. 123,456.00)",
                value: "###,###.00",
              },
              {
                label:
                  "No thousand separator with with no decimals (i.e. 123456)",
                value: "#",
              },
              {
                label:
                  "No thousand separator with with 4 decimals (i.e. 123456.1200)",
                value: "#.0000",
              },
              {
                label: "Currency with static prefix (i.e. $123,456.00)",
                value: "$###,###.00",
              },
            ]}
            getOptionLabel={(o) => o.label}
            getOptionValue={(o) => o.value}
            isOptionEqualToValue={(v, o) => o.value === v.value}
            onChange={(_, v) => handleFormateTemplateClick(v.value)}
          />
        </InstructionWrapper>
      </Form>
      <Form title="Unit Conversion">
        <MultiItem
          className="unit-conversion-builder"
          type="Unit Conversion"
          values={state.unitConversions ?? []}
          itemComponent={UnitConversionItem}
          setComponentProps={(unit, index) => ({
            conversion: unit,
            id: measurable.id,
            name: measurable.name,
          })}
          extractItemKey={(_, index) => `tag-${index}`}
          allowAdd
          allowDelete
          onItemAdd={() => {
            showModal({
              content: (
                <UnitConversionModal
                  id={measurable.id}
                  name={measurable.name}
                  handleSubmit={onChange}
                />
              ),
              options: {
                title: "Add Unit Conversion",
                disableBodyScrolling: true,
                actions: [
                  {
                    text: "Save",
                    primary: true,
                    onClick: () => {},
                  },
                  {
                    text: "Cancel",
                    primary: false,
                    onClick: () => {
                      hideModal();
                    },
                  },
                ],
                disallowOutsideClick: true,
              },
            });
          }}
          onItemDelete={handleTagDelete}
        />
      </Form>
    </>
  );
}

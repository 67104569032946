import React, { useCallback, useEffect, useMemo, useRef } from "react";
import Form from "../../../components/form/Form";
import {
  InstructionWrapper,
  SearchAndChips,
  TextField,
} from "../../../components/fields";
import { publish, useStateReducer } from "../../../hooks";
import { NavigableComponent, RoleFull } from "../../../interfaces";
import { useNavigate, useParams } from "react-router-dom";
import { FaSave, FaTimes } from "react-icons/fa";
import { showAlert } from "../../../components/alert/Alert";
import { AlertTypeEnum, StateTopicEnum } from "../../../enums";
import { routes } from "../../../_config";
import { LibrarySection } from "../../../components/library/Library";
import { Tab, Tabs } from "../../../components";
import Permissions from "./Permissions";
import { validateObject } from "../../../utilities";
import {
  monitoringGroupsService,
  rolesService,
  sitesService,
  siteTemplatesService,
} from "../../../services";
import ReferenceSummary from "../../../interfaces/ReferenceSummary";

interface EditRoleDataProps {
  role: RoleFull;
}

export default function EditRole({
  role,
  setToolbarConfig,
}: EditRoleDataProps & NavigableComponent) {
  const { settingId } = useParams();
  const navigate = useNavigate();
  const [roleState, setRoleState] = useStateReducer<RoleFull>({
    ...role,
  });
  const saveState = useRef<RoleFull>({ ...role });
  const validation = useMemo(
    () =>
      validateObject(
        {
          name: {
            regex: /.{5,}/,
            message: "This field must be at least 5 characters long.",
          },
        },
        roleState
      ),
    [roleState]
  );
  const hasErrors = useCallback(
    () =>
      Object.keys(validation).filter((k) => (validation[k] ? true : false))
        .length > 0,
    [validation]
  );

  const handleSave = useCallback(async () => {
    if (hasErrors()) return;

    const response = await rolesService.update?.(role.id, "UpdateDetails", {
      name: saveState.current.name,
      permissions: saveState.current.permissions ?? [],
      limitSiteTemplatesTo:
        saveState.current.limitSiteTemplatesTo?.map((l) => l.id) ?? [],
      limitMonitoringGroupsTo:
        saveState.current.limitMonitoringGroupsTo?.map((l) => l.id) ?? [],
      limitSitesTo: saveState.current.limitSitesTo?.map((l) => l.id) ?? [],
      limitTagsTo: saveState.current.limitTagsTo ?? [],
      limitDatabasesTo: saveState.current.limitDatabasesTo ?? [],
    });

    if (!response?.id)
      showAlert({
        content: "Could not update the role.",
        options: {
          type: AlertTypeEnum.Error,
          actions: [{ text: "Ok" }],
        },
      });

    publish<LibrarySection>(StateTopicEnum.LibrarySectionRefresh, "paged");
    navigate(routes.settingItem.go("roles-library", settingId));

    showAlert({
      content: "Role saved.",
      options: {
        type: AlertTypeEnum.Info,
        actions: [
          {
            text: "Ok",
            primary: true,
          },
        ],
      },
    });
  }, [hasErrors, navigate, role.id, settingId]);

  const handleCancel = useCallback(() => {
    navigate(routes.settingItem.go("roles-library", settingId));
  }, [navigate, settingId]);

  useEffect(() => {
    setToolbarConfig({
      allowSearch: true,
      toolbarItems: [
        {
          label: "Save",
          icon: FaSave,
          onClick: handleSave,
          primary: true,
          raised: true,
        },
        "|",
        {
          label: "Cancel",
          icon: FaTimes,
          onClick: handleCancel,
        },
      ],
      searchPath: routes.roles.go(),
    });
  }, [setToolbarConfig, handleSave, handleCancel]);

  useEffect(() => {
    saveState.current = roleState;
  }, [roleState]);

  const handleSearchSiteTemplates = useCallback(async (search: string) => {
    const response = await siteTemplatesService.getByPage({
      pageSize: 20,
      pageNo: 1,
      search,
    });

    return response.data;
  }, []);

  const handleSearchMonitoringGroups = useCallback(async (search: string) => {
    const response = await monitoringGroupsService.getByPage({
      pageSize: 20,
      pageNo: 1,
      search,
    });

    return response.data;
  }, []);

  const handleSearchSites = useCallback(async (search: string) => {
    const response = await sitesService.getByPage({
      pageSize: 20,
      pageNo: 1,
      search,
    });

    return response.data.map(
      (s) => ({ id: s.id, name: s.description } as ReferenceSummary)
    );
  }, []);

  const handleSearchTags = useCallback(async (search: string) => {
    const response = await sitesService.searchTags(search);

    return response.filter((v) => (v ? true : false));
  }, []);

  const handleSearchDatabases = useCallback(async (search: string) => {
    const response = await sitesService.searchDatabases(search);

    return response.filter((v) => (v ? true : false));
  }, []);

  return (
    <>
      <Tabs>
        <Tab heading="Overview" identifier="overview" key="tab-overview">
          <Form title="Role Details">
            <InstructionWrapper
              text="A name identifying this role."
              error={validation["name"]}
            >
              <TextField
                label="Role Name"
                value={roleState.name}
                error={validation["name"] ? true : false}
                onChange={(e) =>
                  setRoleState({ ...roleState, name: e.target.value })
                }
              />
            </InstructionWrapper>
          </Form>
          <Permissions
            permissions={roleState.permissions ?? []}
            mode="edit"
            onChange={(p, c) => {
              const permissions = roleState.permissions ?? [];

              if (c) permissions.push(p);
              else permissions.splice(permissions.indexOf(p), 1);

              setRoleState({ ...roleState, permissions });
            }}
          />
        </Tab>
        <Tab
          heading="Site Filters"
          identifier="site-filters"
          key="tab-site-filters"
        >
          <Form title="Site Templates">
            <InstructionWrapper text="The site templates that this role is limited to accessing. No limits means this role can see all site templates.">
              <SearchAndChips
                label="Search Site Templates"
                values={roleState.limitSiteTemplatesTo}
                onSearch={handleSearchSiteTemplates}
                getOptionLabel={(o) => o.name}
                getOptionValue={(o) => o.id}
                onSelect={(v) => {
                  const limitSiteTemplatesTo =
                    roleState.limitSiteTemplatesTo ?? [];
                  limitSiteTemplatesTo.push({ id: v.id, name: v.name });

                  setRoleState({ ...roleState, limitSiteTemplatesTo });
                }}
                onRemove={(_l, _v, v) => {
                  const limitSiteTemplatesTo =
                    roleState.limitSiteTemplatesTo ?? [];
                  limitSiteTemplatesTo.splice(
                    limitSiteTemplatesTo.findIndex((o) => o.id === v.id),
                    1
                  );

                  setRoleState({ ...roleState, limitSiteTemplatesTo });
                }}
              />
            </InstructionWrapper>
          </Form>
          <Form title="Monitoring Groups">
            <InstructionWrapper text="The monitoring groups that this role is limited to accessing. No limits means this role can see all monitoring groups.">
              <SearchAndChips
                label="Search Monitoring Groups"
                values={roleState.limitMonitoringGroupsTo}
                onSearch={handleSearchMonitoringGroups}
                getOptionLabel={(o) => o.name}
                getOptionValue={(o) => o.id}
                onSelect={(v) => {
                  const limitMonitoringGroupsTo =
                    roleState.limitMonitoringGroupsTo ?? [];
                  limitMonitoringGroupsTo.push({ id: v.id, name: v.name });

                  setRoleState({ ...roleState, limitMonitoringGroupsTo });
                }}
                onRemove={(_l, _v, v) => {
                  const limitMonitoringGroupsTo =
                    roleState.limitMonitoringGroupsTo ?? [];
                  limitMonitoringGroupsTo.splice(
                    limitMonitoringGroupsTo.findIndex((o) => o.id === v.id),
                    1
                  );

                  setRoleState({ ...roleState, limitMonitoringGroupsTo });
                }}
              />
            </InstructionWrapper>
          </Form>
          <Form title="Sites">
            <InstructionWrapper text="The individual sites that this role is limited to accessing. No limits means this role can see all sites.">
              <SearchAndChips
                label="Search Sites"
                values={roleState.limitSitesTo}
                onSearch={handleSearchSites}
                getOptionLabel={(o) => o.name}
                getOptionValue={(o) => o.id}
                onSelect={(v) => {
                  const limitSitesTo = roleState.limitSitesTo ?? [];
                  limitSitesTo.push(v);

                  setRoleState({ ...roleState, limitSitesTo });
                }}
                onRemove={(_l, _v, v) => {
                  const limitSitesTo = roleState.limitSitesTo ?? [];
                  limitSitesTo.splice(
                    limitSitesTo.findIndex((o) => o.id === v.id),
                    1
                  );

                  setRoleState({ ...roleState, limitSitesTo });
                }}
              />
            </InstructionWrapper>
          </Form>
          <Form title="Tags">
            <InstructionWrapper text="The tags that this role is limited to accessing. No limits means this role can see all tags.">
              <SearchAndChips
                label="Search Tags"
                values={roleState.limitTagsTo}
                onSearch={handleSearchTags}
                getOptionLabel={(o) => o}
                getOptionValue={(o) => o}
                onSelect={(v) => {
                  const limitTagsTo = roleState.limitTagsTo ?? [];
                  limitTagsTo.push(v);

                  setRoleState({ ...roleState, limitTagsTo });
                }}
                onRemove={(_, v) => {
                  const limitTagsTo = roleState.limitTagsTo ?? [];
                  limitTagsTo.splice(
                    limitTagsTo.findIndex((o) => o === v),
                    1
                  );

                  setRoleState({ ...roleState, limitTagsTo });
                }}
              />
            </InstructionWrapper>
          </Form>

          <Form title="Databases">
            <InstructionWrapper text="The databases that this role is limited to accessing. No limits means this role can see all databases.">
              <SearchAndChips
                label="Search Databases"
                values={roleState.limitDatabasesTo}
                onSearch={handleSearchDatabases}
                getOptionLabel={(o) => o}
                getOptionValue={(o) => o}
                onSelect={(v) => {
                  const limitDatabasesTo = roleState.limitDatabasesTo ?? [];
                  limitDatabasesTo.push(v);

                  setRoleState({ ...roleState, limitDatabasesTo });
                }}
                onRemove={(_, v) => {
                  const limitDatabasesTo = roleState.limitDatabasesTo ?? [];
                  limitDatabasesTo.splice(
                    limitDatabasesTo.findIndex((o) => o === v),
                    1
                  );

                  setRoleState({ ...roleState, limitDatabasesTo });
                }}
              />
            </InstructionWrapper>
          </Form>
        </Tab>
      </Tabs>
    </>
  );
}

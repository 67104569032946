import { Selection, scaleLinear, scaleLog } from "d3";
import { Band } from "../interfaces";

export default function addChartBands(
  svg: Selection<SVGSVGElement, unknown, null, undefined>,
  bands: Band[],
  margin: { top: number; left: number; right: number },
  min: number,
  max: number,
  width: number,
  height: number,
  scale: "Log" | "Linear"
) {
  bands = bands
    .map((b) => {
      if (b.yValueFrom < min) b.yValueFrom = min;
      if (b.yValueFrom > max) b.yValueFrom = max;
      if (b.yValueTo > max) b.yValueTo = max;
      if (b.yValueTo < min) b.yValueTo = min;

      if (b.yValueFrom === b.yValueTo)
        return {
          yValueFrom: 0,
          yValueTo: 0,
          color: "DISCARD",
          name: "DISCARD",
        };

      return b;
    })
    .filter((b) => b.color !== "DISCARD" && b.name !== "DISCARD");

  const yAxisScale = (scale === "Log" ? scaleLog() : scaleLinear())
    .domain([min, max])
    .range([height, margin.top])
    .nice();
  const hasOverlap = (val: number, others: number[]) =>
    others.indexOf(val) >= 0;
  const fromValues = bands.map((b) => b.yValueFrom);

  const bandElements = svg
    .select(".bands")
    .selectAll("g")
    .data(bands)
    .enter()
    .append("g");
  bandElements
    .append("rect")
    .attr("x", margin.left)
    .attr("width", width - margin.right - margin.left)
    .attr("y", (g) => yAxisScale(g.yValueTo))
    .attr("height", (g) => yAxisScale(g.yValueFrom) - yAxisScale(g.yValueTo))
    .attr("fill", (g) => g.color);
  bandElements
    .append("text")
    .attr("x", width - margin.right + 10)
    .attr("y", (g) => yAxisScale(g.yValueFrom) + 3)
    .style("font-size", "12px")
    .text((g) => g.yValueFrom);
  bandElements
    .filter((g) => !hasOverlap(g.yValueTo, fromValues))
    .append("text")
    .attr("x", width - margin.right + 10)
    .attr("y", (g) => yAxisScale(g.yValueTo) + 3)
    .style("font-size", "12px")
    .text((g) => g.yValueTo);
}

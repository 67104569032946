import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { classNameBuilder } from "../../utilities";
import { error as errorTheme } from "../../styles/materialUiTheme";
import React from "react";

interface RadioItem {
  label: string;
  value: string;
}

interface RadioButtonsProps {
  label?: string;
  value?: any;
  defaultValue?: any;
  className?: string;
  id?: string;
  onChange?(e: React.ChangeEvent<HTMLInputElement>, value: string): void;
  error?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  items?: RadioItem[];
  horizontal?: boolean;
}

export default function RadioButtons({
  label,
  value,
  defaultValue,
  className,
  id,
  onChange,
  error,
  disabled,
  readOnly,
  items,
  horizontal,
}: RadioButtonsProps) {
  return (
    <FormControl
      id={id}
      className={classNameBuilder(
        "h-app-input-field",
        "h-app-radio-buttons",
        readOnly ? "readonly" : "",
        className ?? ""
      )}
      disabled={disabled || readOnly}
    >
      <FormLabel>{label}</FormLabel>
      <RadioGroup
        defaultValue={defaultValue}
        value={value === undefined ? "" : value}
        onChange={onChange}
        row={horizontal}
      >
        {items?.map((item, i) => (
          <FormControlLabel
            key={`radio-option-${i}`}
            value={item.value}
            label={item.label}
            control={
              <Radio
                disabled={disabled}
                sx={error ? { color: errorTheme.main } : null}
              />
            }
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

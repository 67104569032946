import React, { useEffect, useMemo, useState } from "react";
import { Form } from "../../../components";
import {
  AreaBand,
  AxisScaleType,
  ChartMeasurementFieldOptions,
  Guide,
  LegendPositionOptions,
  LineConfig,
  SeriesGroupingOptions,
} from "../../../interfaces";
import ReferenceSummary from "../../../interfaces/ReferenceSummary";
import {
  Checkbox,
  InstructionWrapper,
  List,
  NumberField,
  RadioButtons,
  TextField,
} from "../../../components/fields";
import { useStateReducer } from "../../../hooks";
import seriesGroupingToOptions from "../utilities/seriesGroupingToOptions";
import legendToOptions from "../utilities/legendToOptions";
import BandsAndGuides from "./BandsAndGuides";
import getOrderedMeasurables, {
  OrderedMeasurable,
} from "../utilities/getOrderedMeasurables";
import { validateObject } from "../../../utilities";
import { Validation } from "../../../utilities/validateObject";
import LabelSelector from "./LabelSelector";

interface LineChartConfigProps {
  siteTemplates: ReferenceSummary[];
  config?: LineConfig;
  onChange?: (config: LineConfig) => void;
  readOnly?: boolean;
}

interface State {
  measurable?: ReferenceSummary;
  groupingLabel?: ChartMeasurementFieldOptions;
  groupingLabelFormat?: string;
  seriesGrouping?: SeriesGroupingOptions;
  seriesLabel?: ChartMeasurementFieldOptions;
  seriesLabelFormat?: string;
  xAxisLabel?: string;
  showPointValues?: boolean;
  yAxisLabel?: string;
  yAxisTickCount?: number;
  yAxisTickPrecision?: number;
  yAxisMin?: number;
  yAxisMax?: number;
  legendPosition?: LegendPositionOptions;
  showGrid?: boolean;
  guides?: Guide[];
  areaBands?: AreaBand[];
  scale?: AxisScaleType;
}

const seriesOptions = seriesGroupingToOptions();
const legendOptions = legendToOptions();
const scaleOptions = [
  { value: "Linear", label: "Linear" },
  { value: "Log", label: "Logarithmic" },
];

export default function LineChartConfig({
  siteTemplates,
  config,
  onChange,
  readOnly,
}: LineChartConfigProps) {
  const [measurables, setMeasurables] = useState<OrderedMeasurable[]>();
  const [state, setState] = useStateReducer<State>({
    measurable: config?.measurable,
    groupingLabel: config?.groupingLabel ?? "SiteName",
    groupingLabelFormat: config?.groupingLabelFormat,
    seriesGrouping: config?.seriesGrouping ?? "Site",
    seriesLabel: config?.seriesLabel ?? "SiteName",
    seriesLabelFormat: config?.seriesLabelFormat,
    xAxisLabel: config?.xAxisLabel,
    showPointValues: config?.showPointValues ?? true,
    yAxisLabel: config?.yAxisLabel,
    yAxisTickCount: config?.yAxisTickCount,
    yAxisTickPrecision: config?.yAxisTickPrecision,
    yAxisMax: config?.yAxisMax,
    yAxisMin: config?.yAxisMin,
    legendPosition: config?.legendPosition ?? "Right",
    showGrid: config?.showGrid ?? true,
    areaBands: config?.areaBands ?? [],
    guides: config?.guides ?? [],
    scale: config?.scale ?? "Linear",
  });
  const validationRules: Validation = useMemo(
    () => ({
      measurable: {
        func: (m: ReferenceSummary) => m?.id && m?.id !== "-1",
        message: "This field is required.",
      },
    }),
    []
  );
  const validation = validateObject(validationRules, state);

  useEffect(() => {
    if (readOnly) return;

    const load = async () => {
      setMeasurables(
        await getOrderedMeasurables(siteTemplates.map((e) => e.id))
      );
    };

    load();
  }, [siteTemplates, readOnly, setMeasurables]);

  useEffect(() => {
    if (readOnly) return;

    onChange?.({
      areaBands: state.areaBands ?? [],
      groupingLabel: state.groupingLabel ?? "SiteName",
      groupingLabelFormat: state.groupingLabelFormat,
      guides: state.guides ?? [],
      legendPosition: state.legendPosition ?? "Right",
      measurable: state.measurable ?? { id: "-1", name: "Not Specified" },
      seriesGrouping: state.seriesGrouping ?? "Site",
      seriesLabel: state.seriesLabel ?? "SiteName",
      seriesLabelFormat: state.seriesLabelFormat,
      showPointValues: state.showPointValues ?? true,
      showGrid: state.showGrid ?? true,
      xAxisLabel: state.xAxisLabel ?? "",
      yAxisLabel: state.yAxisLabel ?? "",
      yAxisMax: state.yAxisMax,
      yAxisMin: state.yAxisMin,
      yAxisTickCount: state.yAxisTickCount,
      yAxisTickPrecision: state.yAxisTickPrecision,
      scale: state.scale ?? "Linear",
    });
  }, [state, readOnly, onChange]);

  return (
    <>
      <Form title="Dataset Configuration">
        <InstructionWrapper
          text="The measurable that is used to plot the points for the lines against the Y-axis of the chart."
          error={validation["measurable"]}
        >
          {readOnly ? (
            <TextField
              label="Measurable"
              value={state.measurable?.name}
              readOnly
            />
          ) : (
            <List
              label="Measurable"
              options={measurables ?? []}
              getOptionLabel={(m) => m.name}
              getOptionValue={(m) => m.id}
              isOptionEqualToValue={(o, v) => o.id === v.id}
              value={state.measurable}
              onChange={(_, measurable: ReferenceSummary) =>
                setState({ measurable })
              }
              error={validation["measurable"] ? true : false}
            />
          )}
        </InstructionWrapper>
        <LabelSelector
          instructionText="The field used for grouping the measurements against the X-axis of the chart."
          label="Grouping"
          value={state.groupingLabel}
          format={state.groupingLabelFormat}
          onChange={(groupingLabel) => setState({ groupingLabel })}
          onFormatChange={(groupingLabelFormat) =>
            setState({ groupingLabelFormat })
          }
          readOnly={readOnly}
        />
        <InstructionWrapper text="The field used for grouping the data into series.">
          <RadioButtons
            horizontal
            label="Series Grouping"
            items={seriesOptions}
            value={state.seriesGrouping}
            onChange={
              readOnly
                ? undefined
                : (_, seriesGrouping: SeriesGroupingOptions) =>
                    setState({ seriesGrouping })
            }
            readOnly={readOnly}
          />
        </InstructionWrapper>
        <LabelSelector
          instructionText="The field used for displaying the label of each series."
          label="Series Label"
          value={state.seriesLabel}
          format={state.seriesLabelFormat}
          onChange={(seriesLabel) => setState({ seriesLabel })}
          onFormatChange={(seriesLabelFormat) =>
            setState({ seriesLabelFormat })
          }
          readOnly={readOnly}
        />
      </Form>
      <Form title="X-Axis Configuration">
        <InstructionWrapper text="The label displayed below the X axis.">
          <TextField
            label="Label"
            value={state.xAxisLabel}
            onChange={
              readOnly
                ? undefined
                : (e) => setState({ xAxisLabel: e.target.value })
            }
            readOnly={readOnly}
          />
        </InstructionWrapper>
        <InstructionWrapper
          text="Whether labels are added for the point values of the lines."
          row
        >
          <Checkbox
            label="Show Point Values"
            value={state.showPointValues}
            onChange={
              readOnly
                ? undefined
                : (_, showPointValues) => setState({ showPointValues })
            }
            readOnly={readOnly}
          />
        </InstructionWrapper>
      </Form>
      <Form title="Y-Axis Configuration">
        <InstructionWrapper text="The label displayed to the left of the Y axis.">
          <TextField
            label="Label"
            value={state.yAxisLabel}
            onChange={
              readOnly
                ? undefined
                : (e) => setState({ yAxisLabel: e.target.value })
            }
            readOnly={readOnly}
          />
        </InstructionWrapper>
        <InstructionWrapper
          text="Settings for the ticks on the Y axis, including the number of ticks, decimal scale for their display and the minimum and maximum values for the Y axis."
          row
        >
          <NumberField
            label="Tick Count"
            value={state.yAxisTickCount}
            decimalScale={0}
            onChange={
              readOnly
                ? undefined
                : (e) =>
                    setState({
                      yAxisTickCount:
                        e.target.value === ""
                          ? undefined
                          : parseInt(e.target.value),
                    })
            }
            readOnly={readOnly}
          />
          <NumberField
            label="Decimal Scale"
            value={state.yAxisTickPrecision}
            decimalScale={0}
            onChange={
              readOnly
                ? undefined
                : (e) =>
                    setState({
                      yAxisTickPrecision:
                        e.target.value === ""
                          ? undefined
                          : parseInt(e.target.value),
                    })
            }
            readOnly={readOnly}
          />
          <NumberField
            label="Minimum"
            value={state.yAxisMin}
            onChange={
              readOnly
                ? undefined
                : (e) =>
                    setState({
                      yAxisMin:
                        e.target.value === ""
                          ? undefined
                          : parseFloat(e.target.value),
                    })
            }
            readOnly={readOnly}
          />
          <NumberField
            label="Maximum"
            value={state.yAxisMax}
            onChange={
              readOnly
                ? undefined
                : (e) =>
                    setState({
                      yAxisMax:
                        e.target.value === ""
                          ? undefined
                          : parseFloat(e.target.value),
                    })
            }
            readOnly={readOnly}
          />
        </InstructionWrapper>
        <InstructionWrapper text="The scale used to render the values on the axis.">
          <RadioButtons
            horizontal
            label="Scale"
            items={scaleOptions}
            value={state.scale}
            onChange={
              readOnly
                ? undefined
                : (_, scale: AxisScaleType) => setState({ scale })
            }
            readOnly={readOnly}
          />
        </InstructionWrapper>
      </Form>
      <Form title="Chart Configuration">
        <InstructionWrapper text="The location of the series legend, if more than 1 series is present in the dataset.">
          <RadioButtons
            horizontal
            label="Series Legend Location"
            items={legendOptions}
            value={state.legendPosition}
            onChange={
              readOnly
                ? undefined
                : (_, legendPosition: LegendPositionOptions) =>
                    setState({ legendPosition })
            }
            readOnly={readOnly}
          />
        </InstructionWrapper>
        <InstructionWrapper text="Whether a dashed grid is shown on the plot area of the chart or not.">
          <Checkbox
            label="Show Grid"
            value={state.showGrid}
            onChange={
              readOnly ? undefined : (_, showGrid) => setState({ showGrid })
            }
            readOnly={readOnly}
          />
        </InstructionWrapper>
      </Form>
      <BandsAndGuides
        areaBands={state.areaBands ?? []}
        guides={state.guides ?? []}
        onGuideUpdate={readOnly ? undefined : (guides) => setState({ guides })}
        onAreaBandUpdate={
          readOnly ? undefined : (areaBands) => setState({ areaBands })
        }
        readOnly={readOnly}
      />
    </>
  );
}

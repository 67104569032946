import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useStateReducer } from "../../hooks";
import { NavigableComponent, MeasurableFull } from "../../interfaces";
import { measurablesService } from "../../services";
import { FaHandPointer } from "react-icons/fa";
import { getQueryParameter } from "../../utilities";
import { routes } from "../../_config";
import { LoadingIndicator, Scrollable } from "../../components";
import ViewMeasurable from "./components/ViewMeasurable";
import EditMeasurable from "./components/EditMeasurable";

interface MeasurablesItemPageProps {
  index?: boolean;
  onLoad: () => void;
}
interface State {
  measurable?: MeasurableFull | null;
  isSaveDisabled?: boolean;
  loading?: boolean;
}

export default function MeasurablesItemPage({
  setBreadcrumbs,
  setToolbarConfig,
  index,
  onLoad,
}: NavigableComponent & MeasurablesItemPageProps) {
  const { settingId } = useParams();
  const [state, setState] = useStateReducer<State>({
    isSaveDisabled: true,
    loading: true,
  });
  const { measurable } = state;
  const loading = measurable ? false : true;
  const location = useLocation();
  const mode = getQueryParameter(location.search, "mode");

  useEffect(() => {
    const load = async () => {
      const _measurable = await measurablesService.getSingle({
        id: settingId ?? "",
      });

      setState({ measurable: _measurable, loading: false });
      onLoad();

      setBreadcrumbs(
        index
          ? [
              { text: "Settings", to: routes.settings.go() },
              { text: "Measurables" },
            ]
          : [
              { text: "Settings", to: routes.settings.go() },
              { text: "Measurables", to: routes.measurables.raw },
              { text: _measurable.name },
            ]
      );
    };

    setState({ measurable: null, isSaveDisabled: true, loading: true });
    load();
  }, [settingId, mode, index, setState, setBreadcrumbs, onLoad]);

  let content = <></>;

  if (index)
    content = (
      <>
        <FaHandPointer /> Select a site on the left.
      </>
    );
  else if (!measurable || settingId !== measurable.id || loading)
    content = <LoadingIndicator />;
  else
    content = (
      <Scrollable>
        {mode === "edit" || mode === "add" ? (
          <EditMeasurable
            setToolbarConfig={setToolbarConfig}
            setBreadcrumbs={setBreadcrumbs}
            measurable={measurable}
          />
        ) : (
          <ViewMeasurable
            setToolbarConfig={setToolbarConfig}
            setBreadcrumbs={setBreadcrumbs}
            measurable={measurable}
          />
        )}
      </Scrollable>
    );

  return content;
}

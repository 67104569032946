import React, { useCallback, useEffect, useMemo, useRef } from "react";
import Form from "../../../components/form/Form";
import {
  ColorPicker,
  InstructionWrapper,
  List,
  TextField,
} from "../../../components/fields";
import { publish, useStateReducer } from "../../../hooks";
import { NavigableComponent } from "../../../interfaces";
import { useNavigate, useParams } from "react-router-dom";
import { Button as MUIButton, ButtonGroup, Chip } from "@mui/material";
import { hideAlert, showAlert } from "../../../components/alert/Alert";
import { AlertTypeEnum, StateTopicEnum } from "../../../enums";
import { routes } from "../../../_config";
import {
  FaArrowDown,
  FaArrowUp,
  FaPlus,
  FaSave,
  FaTimes,
  FaTrash,
} from "react-icons/fa";
import { ToolItem } from "../../../components/toolbar/Toolbar";
import "../_styles.scss";
import { SiteTemplateFull, MeasurableGroup } from "../../../interfaces";
import { siteTemplatesService } from "../../../services";
import { hideModal, showModal } from "../../../components/modal/Modal";
import { LibrarySection } from "../../../components/library/Library";
import { BiEditAlt, BiFontColor, BiRuler, BiTrash } from "react-icons/bi";
import validateObject, {
  hasErrors,
  Validation,
} from "../../../utilities/validateObject";
import { asyncify, classNameBuilder, clone } from "../../../utilities";
import { Button, Clickable } from "../../../components";
import ReferenceSummary from "../../../interfaces/ReferenceSummary";
import GroupEditor from "./GroupEditor";

interface EditSiteTemplateDataProps {
  siteTemplate: SiteTemplateFull;
  onLoad: () => void;
}

interface State {
  name?: string;
  icon?: string;
  primaryColor?: string;
  measurableGroups?: MeasurableGroup[];
  measurableGroupsOrder?: string[];
  justMovedGroup?: string;
}

interface FieldItem {
  type: "Descriptor" | "Measurable";
  item: ReferenceSummary;
}

export default function EditSiteTemplate({
  setToolbarConfig,
  siteTemplate,
  onLoad,
}: EditSiteTemplateDataProps & NavigableComponent) {
  const { settingId } = useParams();
  const navigate = useNavigate();
  const [state, setState] = useStateReducer<State>({
    name: siteTemplate.name || "",
    icon: siteTemplate.icon || "",
    primaryColor: siteTemplate.primaryColor || "",
    measurableGroups: siteTemplate.measurableGroups || [],
    measurableGroupsOrder: siteTemplate.measurableGroupsOrder || [],
  });
  const saveState = useRef<State>(state);
  const validationRules: Validation = useMemo(
    () => ({
      name: {
        regex: /.+/,
        message: "This field is required.",
      },
      icon: {
        regex: /.+/,
        message: "This field is required.",
      },
      primaryColor: {
        regex: /.+/,
        message: "This field is required.",
      },
    }),
    []
  );

  const validation = validateObject(validationRules, state);

  const sortedGroups = useMemo(() => {
    const order = state.measurableGroupsOrder ?? [];
    const groups = clone(state.measurableGroups) ?? [];
    const orderedGroups: MeasurableGroup[] = [];

    order.forEach((o) => {
      const group = groups.find((g) => g.id === o);

      if (group) {
        orderedGroups.push(group);
        groups.splice(groups.indexOf(group), 1);
      }
    });

    groups.forEach((g) => orderedGroups.push(g));

    return orderedGroups;
  }, [state.measurableGroups, state.measurableGroupsOrder]);

  const handleSave = useCallback(async () => {
    await asyncify(() => {}, 10);

    if (hasErrors(validateObject(validationRules, saveState.current))) return;

    const response = await siteTemplatesService.finalize!(settingId!);

    if (!response.id) {
      return;
    }

    publish<LibrarySection>(StateTopicEnum.LibrarySectionRefresh, "paged");
    publish<LibrarySection>(StateTopicEnum.LibrarySectionRefresh, "incomplete");

    showAlert({
      content: "Site template saved.",
      options: {
        type: AlertTypeEnum.Info,
        actions: [
          {
            text: "Ok",
            primary: true,
          },
        ],
      },
    });

    navigate(routes.settingItem.go("site-templates-library", settingId));
  }, [navigate, settingId, validationRules]);

  const handleUpdate = useCallback(() => {
    if (hasErrors(validateObject(validationRules, saveState.current))) return;

    siteTemplatesService.update(siteTemplate.id, "UpdateDetails", {
      name: state.name,
      icon: state.icon,
      primaryColor: state.primaryColor,
    });
  }, [
    state.name,
    state.icon,
    state.primaryColor,
    validationRules,
    siteTemplate.id,
  ]);

  useEffect(() => {
    if (hasErrors(validateObject(validationRules, state))) return;

    siteTemplatesService.update(siteTemplate.id, "UpdateDetails", {
      name: state.name,
      icon: state.icon,
      primaryColor: state.primaryColor,
    });
  }, [state, state.icon, state.primaryColor, siteTemplate.id, validationRules]);

  const handleDiscard = useCallback(async () => {
    showAlert({
      content:
        "Are you sure you want to discard this draft site template? This action is not reversable.",
      options: {
        type: AlertTypeEnum.Warning,
        actions: [
          {
            text: "Yes",
            primary: true,
            onClick: async () => {
              hideAlert();

              const response = await siteTemplatesService.delete!(
                settingId!,
                "DiscardDraftVersion"
              );

              if (response.message) {
                navigate(routes.settingCategory.go("site-templates-library"));
                publish<LibrarySection>(
                  StateTopicEnum.LibrarySectionRefresh,
                  "incomplete"
                );
              }
            },
          },
          {
            text: "No",
          },
        ],
      },
    });
  }, [navigate, settingId]);

  useEffect(() => {
    let toolbarItems: (ToolItem | "|")[] = [];

    onLoad();

    toolbarItems = [
      {
        label: "Save",
        icon: FaSave,
        onClick: handleSave,
        primary: true,
        raised: true,
      },
      "|",
      {
        label: "Cancel",
        icon: FaTimes,
        onClick: () => {
          navigate(routes.settingCategory.go("site-templates-library"));
        },
      },
      {
        label: "Discard",
        icon: FaTrash,
        onClick: handleDiscard,
      },
    ];

    setToolbarConfig({
      allowSearch: true,
      toolbarItems,
      searchPath: routes.siteTemplates.go(),
    });
  }, [
    handleSave,
    handleDiscard,
    navigate,
    setToolbarConfig,
    settingId,
    onLoad,
  ]);

  useEffect(() => {
    saveState.current = state;
  }, [state]);

  const handleEditGroup = (measurableGroup: MeasurableGroup) => {
    showModal({
      content: (
        <GroupEditor
          siteTemplate={{
            ...siteTemplate,
            measurableGroups: state.measurableGroups ?? [],
          }}
          group={measurableGroup}
          onUpdate={(groups) => setState({ measurableGroups: groups })}
        />
      ),
      options: {
        title: "Edit Field Group",
        disableBodyScrolling: true,
        actions: [
          {
            text: "Finish",
            primary: true,
            onClick: () => {
              hideModal();
            },
          },
        ],
        disallowOutsideClick: true,
      },
    });
  };

  const handleAddGroup = async () => {
    const response = await siteTemplatesService.update(
      siteTemplate.id,
      "AddMeasurableGroup",
      { name: "New Field Group" }
    );

    const group =
      response.measurableGroups[response.measurableGroups.length - 1];

    setState({
      measurableGroups: response.measurableGroups,
      measurableGroupsOrder: response.measurableGroupsOrder,
    });

    await asyncify(() => handleEditGroup(group), 100);
  };

  const handleDeleteGroup = async (id: string) => {
    const response = await siteTemplatesService.update(
      siteTemplate.id,
      "RemoveMeasurableGroup",
      {
        groupId: id,
      }
    );

    state.measurableGroupsOrder?.splice(
      state.measurableGroupsOrder.indexOf(id),
      1
    );

    setState({
      measurableGroups: response.measurableGroups,
      measurableGroupsOrder: state.measurableGroupsOrder,
    });
  };

  const handleMoveGroup = async (id: string, direction: "up" | "down") => {
    const order = sortedGroups.map((g) => g.id);
    const index = order.indexOf(id);
    const newIndex = direction === "up" ? index - 1 : index + 1;
    order.splice(index, 1);
    order.splice(newIndex, 0, id);

    setState({ measurableGroupsOrder: order, justMovedGroup: id });

    await siteTemplatesService.update(
      siteTemplate.id,
      "UpdateMeasurableGroupOrder",
      {
        order,
      }
    );
  };

  return (
    <>
      <Form title="Identifying Information">
        <InstructionWrapper
          text="An identifying name for the site template."
          error={validation["name"]}
        >
          <TextField
            label="Name"
            required
            value={state.name}
            onChange={(e) => setState({ name: e.target.value })}
            onBlur={handleUpdate}
            error={validation["name"] ? true : false}
          />
        </InstructionWrapper>
        <InstructionWrapper
          text="An icon and color used when rendering the site on a map."
          row
        >
          <List
            label="Icon"
            options={["Square", "Circle", "Diamond", "Triangle"]}
            onChange={(_, value) => setState({ icon: value })}
            value={state.icon}
            error={validation["icon"] ? true : false}
            getOptionLabel={(o: string) => o}
            getOptionValue={(o: string) => o}
            isOptionEqualToValue={(option, value) => option === value}
          />
          <ColorPicker
            label="Color"
            value={state.primaryColor ?? ""}
            onChange={(polygonColor) =>
              setState({ primaryColor: polygonColor })
            }
          />
        </InstructionWrapper>
      </Form>
      <Form title="Configuration">
        <InstructionWrapper>
          {sortedGroups.map((group, index) => {
            const combinedArray = [
              ...group.descriptors.map(
                (item) => ({ type: "Descriptor", item } as FieldItem)
              ),
              ...group.measurables.map(
                (item) => ({ type: "Measurable", item } as FieldItem)
              ),
            ];

            return (
              <div
                className={classNameBuilder(
                  "measurable-group-row",
                  state.justMovedGroup === group.id ? "moved" : ""
                )}
                key={`${group.id}-${index}`}
              >
                <div className="measurable-group">
                  <TextField
                    value={group.name}
                    label="Field Group Name"
                    readOnly
                    endAdornment={
                      <div className="adornment-actions">
                        <Clickable
                          className="measurable-group-action-button"
                          onClick={() => handleEditGroup(group)}
                        >
                          <BiEditAlt className="edit-action" />
                        </Clickable>
                        <Clickable
                          className="measurable-group-action-button"
                          onClick={() => handleDeleteGroup(group.id)}
                        >
                          <BiTrash className="delete-action" />
                        </Clickable>
                      </div>
                    }
                  />
                  {combinedArray.length > 0 && (
                    <div className="field-chips">
                      <div className="fields">
                        {group.order?.map((o: any, index: number) => {
                          let item = combinedArray.find((c) => {
                            return c.item.id === o;
                          });

                          if (!item) return null;

                          const isDescriptor =
                            item.type === "Descriptor" ? true : false;

                          return (
                            <Chip
                              key={`${o}-${index}`}
                              label={item.item.name}
                              icon={
                                isDescriptor ? <BiFontColor /> : <BiRuler />
                              }
                              className={
                                isDescriptor
                                  ? "descriptor-chip"
                                  : "measurable-chip"
                              }
                            />
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
                <div className="action-section">
                  <ButtonGroup
                    aria-label="text button group"
                    orientation="vertical"
                    size="small"
                    className="actions-button-group"
                  >
                    <MUIButton
                      className="action-button"
                      disabled={index === 0}
                      onClick={() => handleMoveGroup(group.id, "up")}
                    >
                      <div className="measurable-group-actions">
                        <FaArrowUp />
                      </div>
                    </MUIButton>
                    <MUIButton
                      className="action-button"
                      disabled={index === sortedGroups.length - 1}
                      onClick={() => handleMoveGroup(group.id, "down")}
                    >
                      <div className="measurable-group-actions">
                        <FaArrowDown />
                      </div>
                    </MUIButton>
                  </ButtonGroup>
                </div>
              </div>
            );
          })}
          <div className="measurables-button-bar">
            <div>
              <Button
                onClick={handleAddGroup}
                className="add-measurable-group"
                text="Add Group"
                icon={FaPlus}
                primary
                raised
              />
            </div>
          </div>
        </InstructionWrapper>
      </Form>
    </>
  );
}

import React, { useCallback } from "react";
import { Form } from "../../../components";
import { InstructionWrapper, SearchAndChips } from "../../../components/fields";
import { descriptorsService } from "../../../services";
import { DescriptorSummary } from "../../../interfaces";
import ReferenceSummary from "../../../interfaces/ReferenceSummary";

interface DescriptorSelectorProps {
  descriptor?: ReferenceSummary;
  onSelect: (descriptor?: ReferenceSummary) => void;
  mode: "view" | "edit";
}

export default function DescriptorSelector({
  descriptor,
  onSelect,
  mode,
}: DescriptorSelectorProps) {
  const handleDescriptorSearch = useCallback(async (search: string) => {
    const response = await descriptorsService.getByPage({
      pageNo: 1,
      pageSize: 1000,
      search,
    });

    return response.data.filter((d) => d.type === "SpecifiedItems");
  }, []);

  const handleDescriptorSelect = useCallback(
    (descriptor: DescriptorSummary) => onSelect(descriptor),
    [onSelect]
  );

  const handleDescriptorRemove = useCallback(
    () => onSelect(undefined),
    [onSelect]
  );

  return (
    <Form title="Descriptor">
      <InstructionWrapper
        text="The descriptor used to derive the options for defining the colours and materials."
        error={!descriptor ? "This field is required" : ""}
      >
        <SearchAndChips
          readOnly={mode === "view"}
          placeholder="Search descriptors"
          onSearch={handleDescriptorSearch}
          getOptionLabel={(d) => d.name}
          getOptionValue={(d) => d.id}
          singleSelect
          onSelect={handleDescriptorSelect}
          onRemove={handleDescriptorRemove}
          values={
            descriptor
              ? [
                  {
                    id: descriptor.id,
                    name: descriptor.name,
                    type: "SpecifiedItems",
                    createdOn: new Date(),
                  },
                ]
              : []
          }
        />
      </InstructionWrapper>
    </Form>
  );
}

import moment from "moment";
import React from "react";
import { FormSummary, LibraryCardProps } from "../../interfaces";

export default function IncompleteLibraryCard({
  item,
}: LibraryCardProps<FormSummary>) {
  const { name, createdOn, siteTemplate } = item;
  return (
    <>
      <span className="card-primary">{name || "No name specified"}</span>
      <span className="card-secondary">
        Template: {siteTemplate?.name || "Not Specified"}
      </span>
      <span className="card-secondary">
        Created On: {moment(createdOn).format("DD MMM YYYY, HH:mm:ss")}
      </span>
    </>
  );
}

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form } from "../../../components";
import {
  AxisFormatType,
  DurovConfig,
  LegendPositionOptions,
} from "../../../interfaces";
import ReferenceSummary from "../../../interfaces/ReferenceSummary";
import {
  ColorPicker,
  InstructionWrapper,
  List,
  NumberField,
  RadioButtons,
  SearchAndChips,
  TextField,
} from "../../../components/fields";
import { useStateReducer } from "../../../hooks";
import legendToOptions from "../utilities/legendToOptions";
import getOrderedMeasurables, {
  OrderedMeasurable,
} from "../utilities/getOrderedMeasurables";
import { clone, validateObject } from "../../../utilities";
import { Validation } from "../../../utilities/validateObject";

interface DurovChartConfigProps {
  siteTemplates: ReferenceSummary[];
  config?: DurovConfig;
  onChange?: (config: DurovConfig) => void;
  readOnly?: boolean;
}

interface State {
  cationsAElements?: OrderedMeasurable[];
  cationsBElements?: OrderedMeasurable[];
  cationsCElements?: OrderedMeasurable[];
  cationsAAxisLabel?: string;
  cationsBAxisLabel?: string;
  cationsCAxisLabel?: string;
  anionsAElements?: OrderedMeasurable[];
  anionsBElements?: OrderedMeasurable[];
  anionsCElements?: OrderedMeasurable[];
  anionsAAxisLabel?: string;
  anionsBAxisLabel?: string;
  anionsCAxisLabel?: string;
  axisFormat?: AxisFormatType;
  legendPosition?: LegendPositionOptions;
  cationsColor?: string;
  anionsColor?: string;
  projectionColor?: string;
  rightPlotMeasurable?: OrderedMeasurable;
  rightPlotAxisLabel?: string;
  rightPlotAxisMin?: number;
  rightPlotAxisMax?: number;
  rightPlotColor?: string;
  bottomPlotMeasurable?: OrderedMeasurable;
  bottomPlotAxisLabel?: string;
  bottomPlotAxisMin?: number;
  bottomPlotAxisMax?: number;
  bottomPlotColor?: string;
}

const legendOptions = legendToOptions();
const axisFormatOptions = [
  { label: "Percentage", value: "Percentage" },
  { label: "Fraction", value: "Fraction" },
];

export default function DurovChartConfig({
  siteTemplates,
  config,
  onChange,
  readOnly,
}: DurovChartConfigProps) {
  const [measurables, setMeasurables] = useState<OrderedMeasurable[]>();
  const [state, setState] = useStateReducer<State>({
    cationsAElements: config?.cationsAElements,
    cationsBElements: config?.cationsBElements,
    cationsCElements: config?.cationsCElements,
    cationsAAxisLabel: config?.cationsAAxisLabel,
    cationsBAxisLabel: config?.cationsBAxisLabel,
    cationsCAxisLabel: config?.cationsCAxisLabel,
    anionsAElements: config?.anionsAElements,
    anionsBElements: config?.anionsBElements,
    anionsCElements: config?.anionsCElements,
    anionsAAxisLabel: config?.anionsAAxisLabel,
    anionsBAxisLabel: config?.anionsBAxisLabel,
    anionsCAxisLabel: config?.anionsCAxisLabel,
    axisFormat: config?.axisFormat ?? "Percentage",
    legendPosition: config?.legendPosition ?? "Right",
    cationsColor: config?.cationsColor,
    anionsColor: config?.anionsColor,
    projectionColor: config?.projectionColor,
    rightPlotMeasurable: config?.rightPlotMeasurable,
    rightPlotColor: config?.rightPlotColor,
    rightPlotAxisLabel: config?.rightPlotAxisLabel,
    rightPlotAxisMin: config?.rightPlotAxisMin,
    rightPlotAxisMax: config?.rightPlotAxisMax,
    bottomPlotMeasurable: config?.bottomPlotMeasurable,
    bottomPlotAxisLabel: config?.bottomPlotAxisLabel,
    bottomPlotAxisMin: config?.bottomPlotAxisMin,
    bottomPlotAxisMax: config?.bottomPlotAxisMax,
    bottomPlotColor: config?.bottomPlotColor,
  });
  const validationRules: Validation = useMemo(
    () => ({
      cationsAElements: {
        func: (m: ReferenceSummary[]) => m?.length > 0,
        message: "This field is required.",
      },
      cationsBElements: {
        func: (m: ReferenceSummary[]) => m?.length > 0,
        message: "This field is required.",
      },
      cationsCElements: {
        func: (m: ReferenceSummary[]) => m?.length > 0,
        message: "This field is required.",
      },
      anionsAElements: {
        func: (m: ReferenceSummary[]) => m?.length > 0,
        message: "This field is required.",
      },
      anionsBElements: {
        func: (m: ReferenceSummary[]) => m?.length > 0,
        message: "This field is required.",
      },
      anionsCElements: {
        func: (m: ReferenceSummary[]) => m?.length > 0,
        message: "This field is required.",
      },
      rightPlotMeasurable: {
        func: (m: ReferenceSummary) => m?.id && m.id !== "-1",
        message: "This field is required.",
      },
      bottomPlotMeasurable: {
        func: (m: ReferenceSummary) => m?.id && m.id !== "-1",
        message: "This field is required.",
      },
    }),
    []
  );
  const validation = validateObject(validationRules, state);

  const handleMeasurableSearch = useCallback(
    async (search: string) =>
      await getOrderedMeasurables(
        siteTemplates.map((e) => e.id),
        search
      ),
    [siteTemplates]
  );

  const handleMeasurableAdd = useCallback(
    (
      measurable: ReferenceSummary,
      elements:
        | "cationsAElements"
        | "cationsBElements"
        | "cationsCElements"
        | "anionsAElements"
        | "anionsBElements"
        | "anionsCElements"
    ) => {
      const measurables = clone(state[elements] ?? []);
      measurables.push(measurable);
      setState({ [elements]: measurables });
    },
    [state, setState]
  );

  const handleMeasurableRemove = useCallback(
    (
      id: string,
      elements:
        | "cationsAElements"
        | "cationsBElements"
        | "cationsCElements"
        | "anionsAElements"
        | "anionsBElements"
        | "anionsCElements"
    ) => {
      const measurables = clone(state[elements] ?? []);
      measurables.splice(
        measurables.findIndex((m) => m.id === id),
        1
      );
      setState({ [elements]: measurables });
    },
    [state, setState]
  );

  useEffect(() => {
    if (readOnly) return;

    const load = async () => {
      setMeasurables(
        await getOrderedMeasurables(siteTemplates.map((e) => e.id))
      );
    };

    load();
  }, [siteTemplates, readOnly, setMeasurables]);

  useEffect(() => {
    if (readOnly) return;

    onChange?.({
      cationsAElements: state.cationsAElements ?? [],
      cationsBElements: state.cationsBElements ?? [],
      cationsCElements: state.cationsCElements ?? [],
      cationsAAxisLabel: state.cationsAAxisLabel ?? "",
      cationsBAxisLabel: state.cationsBAxisLabel ?? "",
      cationsCAxisLabel: state.cationsCAxisLabel ?? "",
      anionsAElements: state.anionsAElements ?? [],
      anionsBElements: state.anionsBElements ?? [],
      anionsCElements: state.anionsCElements ?? [],
      anionsAAxisLabel: state.anionsAAxisLabel ?? "",
      anionsBAxisLabel: state.anionsBAxisLabel ?? "",
      anionsCAxisLabel: state.anionsCAxisLabel ?? "",
      axisFormat: state.axisFormat ?? "Percentage",
      legendPosition: state.legendPosition ?? "Right",
      cationsColor: state.cationsColor,
      anionsColor: state.anionsColor,
      projectionColor: state.projectionColor,
      bottomPlotMeasurable: state.bottomPlotMeasurable ?? {
        id: "-1",
        name: "Not Specified",
      },
      bottomPlotAxisLabel: state.bottomPlotAxisLabel,
      bottomPlotAxisMin: state.bottomPlotAxisMin,
      bottomPlotAxisMax: state.bottomPlotAxisMax,
      bottomPlotColor: state.bottomPlotColor,
      rightPlotMeasurable: state.rightPlotMeasurable ?? {
        id: "-1",
        name: "Not Specified",
      },
      rightPlotAxisLabel: state.rightPlotAxisLabel,
      rightPlotAxisMin: state.rightPlotAxisMin,
      rightPlotAxisMax: state.rightPlotAxisMax,
      rightPlotColor: state.rightPlotColor,
    });
  }, [state, readOnly, onChange]);

  return (
    <>
      <Form title="Dataset Configuration">
        <InstructionWrapper
          text="The measurables that are used on the A, B and C axes of the Cations plot."
          row
          error={
            validation["cationsAElements"] ||
            validation["cationsBElements"] ||
            validation["cationsCElements"]
          }
        >
          <SearchAndChips
            label="Cations A"
            values={state.cationsAElements}
            getOptionLabel={(o) => o.name}
            getOptionValue={(o) => o.id}
            onSearch={readOnly ? undefined : handleMeasurableSearch}
            onSelect={
              readOnly
                ? undefined
                : (m) => handleMeasurableAdd(m, "cationsAElements")
            }
            onRemove={
              readOnly
                ? undefined
                : (_, id) =>
                    handleMeasurableRemove(id.toString(), "cationsAElements")
            }
            error={validation["cationsAElements"] ? true : false}
            readOnly={readOnly}
          />
          <SearchAndChips
            label="Cations B"
            values={state.cationsBElements}
            getOptionLabel={(o) => o.name}
            getOptionValue={(o) => o.id}
            onSearch={readOnly ? undefined : handleMeasurableSearch}
            onSelect={
              readOnly
                ? undefined
                : (m) => handleMeasurableAdd(m, "cationsBElements")
            }
            onRemove={
              readOnly
                ? undefined
                : (_, id) =>
                    handleMeasurableRemove(id.toString(), "cationsBElements")
            }
            error={validation["cationsBElements"] ? true : false}
            readOnly={readOnly}
          />
          <SearchAndChips
            label="Cations C"
            values={state.cationsCElements}
            getOptionLabel={(o) => o.name}
            getOptionValue={(o) => o.id}
            onSearch={readOnly ? undefined : handleMeasurableSearch}
            onSelect={
              readOnly
                ? undefined
                : (m) => handleMeasurableAdd(m, "cationsCElements")
            }
            onRemove={
              readOnly
                ? undefined
                : (_, id) =>
                    handleMeasurableRemove(id.toString(), "cationsCElements")
            }
            error={validation["cationsCElements"] ? true : false}
            readOnly={readOnly}
          />
        </InstructionWrapper>
        <InstructionWrapper
          text="The measurables that are used on the A, B and C axes of the Anions plot."
          row
          error={
            validation["anionsAElements"] ||
            validation["anionsBElements"] ||
            validation["anionsCElements"]
          }
        >
          <SearchAndChips
            label="Anions A"
            values={state.anionsAElements}
            getOptionLabel={(o) => o.name}
            getOptionValue={(o) => o.id}
            onSearch={readOnly ? undefined : handleMeasurableSearch}
            onSelect={
              readOnly
                ? undefined
                : (m) => handleMeasurableAdd(m, "anionsAElements")
            }
            onRemove={
              readOnly
                ? undefined
                : (_, id) =>
                    handleMeasurableRemove(id.toString(), "anionsAElements")
            }
            error={validation["anionsAElements"] ? true : false}
            readOnly={readOnly}
          />
          <SearchAndChips
            label="Anions B"
            values={state.anionsBElements}
            getOptionLabel={(o) => o.name}
            getOptionValue={(o) => o.id}
            onSearch={readOnly ? undefined : handleMeasurableSearch}
            onSelect={
              readOnly
                ? undefined
                : (m) => handleMeasurableAdd(m, "anionsBElements")
            }
            onRemove={
              readOnly
                ? undefined
                : (_, id) =>
                    handleMeasurableRemove(id.toString(), "anionsBElements")
            }
            error={validation["anionsBElements"] ? true : false}
            readOnly={readOnly}
          />
          <SearchAndChips
            label="Anions C"
            values={state.anionsCElements}
            getOptionLabel={(o) => o.name}
            getOptionValue={(o) => o.id}
            onSearch={readOnly ? undefined : handleMeasurableSearch}
            onSelect={
              readOnly
                ? undefined
                : (m) => handleMeasurableAdd(m, "anionsCElements")
            }
            onRemove={
              readOnly
                ? undefined
                : (_, id) =>
                    handleMeasurableRemove(id.toString(), "anionsCElements")
            }
            error={validation["anionsCElements"] ? true : false}
            readOnly={readOnly}
          />
        </InstructionWrapper>
        <InstructionWrapper
          text="The measurable that is used for the right rectangle plot of the chart."
          error={validation["rightPlotMeasurable"]}
        >
          {readOnly ? (
            <TextField
              label="Right Plot Measurable"
              value={state.rightPlotMeasurable?.name}
              readOnly
            />
          ) : (
            <List
              label="Right Plot Measurable"
              options={measurables ?? []}
              getOptionLabel={(m) => m.name}
              getOptionValue={(m) => m.id}
              isOptionEqualToValue={(o, v) => o.id === v.id}
              value={state.rightPlotMeasurable}
              onChange={(_, rightPlotMeasurable) =>
                setState({ rightPlotMeasurable })
              }
              error={validation["rightPlotMeasurable"] ? true : false}
            />
          )}
        </InstructionWrapper>
        <InstructionWrapper
          text="The measurable that is used for the bottom rectangle plot of the chart."
          error={validation["bottomPlotMeasurable"]}
        >
          {readOnly ? (
            <TextField
              label="Bottom Plot Measurable"
              value={state.bottomPlotMeasurable?.name}
              readOnly
            />
          ) : (
            <List
              label="Bottom Plot Measurable"
              options={measurables ?? []}
              getOptionLabel={(m) => m.name}
              getOptionValue={(m) => m.id}
              isOptionEqualToValue={(o, v) => o.id === v.id}
              value={state.bottomPlotMeasurable}
              onChange={(_, bottomPlotMeasurable) =>
                setState({ bottomPlotMeasurable })
              }
              error={validation["bottomPlotMeasurable"] ? true : false}
            />
          )}
        </InstructionWrapper>
      </Form>
      <Form title="Cations Axis Configuration">
        <InstructionWrapper
          text="The labels displayed on the axes of the Cations plot. Defaults to the measurable name(s) if no label value is specified."
          row
        >
          <TextField
            label="A Axis"
            value={state.cationsAAxisLabel}
            onChange={
              readOnly
                ? undefined
                : (e) => setState({ cationsAAxisLabel: e.target.value })
            }
            readOnly={readOnly}
          />
          <TextField
            label="B Axis"
            value={state.cationsBAxisLabel}
            onChange={
              readOnly
                ? undefined
                : (e) => setState({ cationsBAxisLabel: e.target.value })
            }
            readOnly={readOnly}
          />
          <TextField
            label="C Axis"
            value={state.cationsCAxisLabel}
            onChange={
              readOnly
                ? undefined
                : (e) => setState({ cationsCAxisLabel: e.target.value })
            }
            readOnly={readOnly}
          />
        </InstructionWrapper>
      </Form>
      <Form title="Anions Axis Configuration">
        <InstructionWrapper
          text="The labels displayed on the axes of the Anions plot. Defaults to the measurable name(s) if no label value is specified."
          row
        >
          <TextField
            label="A Axis"
            value={state.anionsAAxisLabel}
            onChange={
              readOnly
                ? undefined
                : (e) => setState({ anionsAAxisLabel: e.target.value })
            }
            readOnly={readOnly}
          />
          <TextField
            label="B Axis"
            value={state.anionsBAxisLabel}
            onChange={
              readOnly
                ? undefined
                : (e) => setState({ anionsBAxisLabel: e.target.value })
            }
            readOnly={readOnly}
          />
          <TextField
            label="C Axis"
            value={state.anionsCAxisLabel}
            onChange={
              readOnly
                ? undefined
                : (e) => setState({ anionsCAxisLabel: e.target.value })
            }
            readOnly={readOnly}
          />
        </InstructionWrapper>
      </Form>
      <Form title="Right Plot Axis Configuration">
        <InstructionWrapper
          text="The labels, min and max values for the right plot's X axis."
          row
        >
          <TextField
            label="Label"
            value={state.rightPlotAxisLabel}
            onChange={
              readOnly
                ? undefined
                : (e) => setState({ rightPlotAxisLabel: e.target.value })
            }
            readOnly={readOnly}
          />
          <NumberField
            label="Min"
            value={state.rightPlotAxisMin}
            onChange={
              readOnly
                ? undefined
                : (e) =>
                    setState({
                      rightPlotAxisMin: e.target.value
                        ? parseFloat(e.target.value)
                        : undefined,
                    })
            }
            readOnly={readOnly}
          />
          <NumberField
            label="Max"
            value={state.rightPlotAxisMax}
            onChange={
              readOnly
                ? undefined
                : (e) =>
                    setState({
                      rightPlotAxisMax: e.target.value
                        ? parseFloat(e.target.value)
                        : undefined,
                    })
            }
            readOnly={readOnly}
          />
        </InstructionWrapper>
      </Form>
      <Form title="Bottom Plot Axis Configuration">
        <InstructionWrapper
          text="The labels, min and max values for the bottom plot's Y axis."
          row
        >
          <TextField
            label="Label"
            value={state.bottomPlotAxisLabel}
            onChange={
              readOnly
                ? undefined
                : (e) => setState({ bottomPlotAxisLabel: e.target.value })
            }
            readOnly={readOnly}
          />
          <NumberField
            label="Min"
            value={state.bottomPlotAxisMin}
            onChange={
              readOnly
                ? undefined
                : (e) =>
                    setState({
                      bottomPlotAxisMin: e.target.value
                        ? parseFloat(e.target.value)
                        : undefined,
                    })
            }
            readOnly={readOnly}
          />
          <NumberField
            label="Max"
            value={state.bottomPlotAxisMax}
            onChange={
              readOnly
                ? undefined
                : (e) =>
                    setState({
                      bottomPlotAxisMax: e.target.value
                        ? parseFloat(e.target.value)
                        : undefined,
                    })
            }
            readOnly={readOnly}
          />
        </InstructionWrapper>
      </Form>
      <Form title="Chart Configuration">
        <InstructionWrapper text="The location of the series legend, if more than 1 series is present in the dataset.">
          <RadioButtons
            horizontal
            label="Series Legend Location"
            items={legendOptions}
            value={state.legendPosition}
            onChange={
              readOnly
                ? undefined
                : (_, legendPosition: LegendPositionOptions) =>
                    setState({ legendPosition })
            }
            readOnly={readOnly}
          />
          <RadioButtons
            horizontal
            label="Axis Format"
            items={axisFormatOptions}
            value={state.axisFormat}
            onChange={
              readOnly
                ? undefined
                : (_, axisFormat: AxisFormatType) => setState({ axisFormat })
            }
            readOnly={readOnly}
          />
        </InstructionWrapper>
      </Form>
      <Form title="Styling">
        <InstructionWrapper
          text="Colours and styling applied to the elements of the chart."
          row
        >
          <ColorPicker
            label="Cations Background"
            value={state.cationsColor ?? ""}
            onChange={
              readOnly
                ? undefined
                : (cationsColor) => setState({ cationsColor })
            }
            readOnly={readOnly}
          />
          <ColorPicker
            label="Anions Background"
            value={state.anionsColor ?? ""}
            onChange={
              readOnly ? undefined : (anionsColor) => setState({ anionsColor })
            }
            readOnly={readOnly}
          />
          <ColorPicker
            label="Projection Background"
            value={state.projectionColor ?? ""}
            onChange={
              readOnly
                ? undefined
                : (projectionColor) => setState({ projectionColor })
            }
            readOnly={readOnly}
          />
        </InstructionWrapper>
        <InstructionWrapper
          text="Colours and styling applied to the right and bottom plots of the chart."
          row
        >
          <ColorPicker
            label="Right Plot Background"
            value={state.rightPlotColor ?? ""}
            onChange={
              readOnly
                ? undefined
                : (rightPlotColor) => setState({ rightPlotColor })
            }
            readOnly={readOnly}
          />
          <ColorPicker
            label="Bottom Plot Background"
            value={state.bottomPlotColor ?? ""}
            onChange={
              readOnly
                ? undefined
                : (bottomPlotColor) => setState({ bottomPlotColor })
            }
            readOnly={readOnly}
          />
        </InstructionWrapper>
      </Form>
    </>
  );
}

export default function measureTextWidth(
  text: string,
  canvas?: HTMLCanvasElement,
  fontSize?: string,
  fontWeight?: string
) {
  let removeCanvas = false;

  if (!canvas) {
    canvas = document.createElement("canvas");
    removeCanvas = true;
  }

  const ctx = canvas.getContext("2d");

  if (ctx)
    ctx.font = `${fontWeight ?? ""} ${
      fontSize ?? "12px"
    } -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`;

  const width = ctx?.measureText(text).width;

  if (removeCanvas) canvas.remove();

  return width ?? 0;
}

import React, { useMemo } from "react";
import {
  BarConfig,
  BoxAndWhiskerConfig,
  ChartConfigType,
  ChartMeasurementFieldOptions,
  ChartType,
  DataPointFull,
  DurovConfig,
  LineConfig,
  PiperConfig,
  ScatterConfig,
  SchoellerConfig,
  SeriesGroupingOptions,
  StiffConfig,
} from "../../../interfaces";
import {
  BarChart,
  LineChart,
  ScatterChart,
  BoxAndWhiskerChart,
  SchoellerChart,
  PiperChart,
  DurovChart,
  StiffChart,
} from "../../../components/charts";
import { groupArray } from "../../../utilities";
import moment from "moment";

interface RenderedChartProps {
  chartType: ChartType;
  config: ChartConfigType;
  data: DataPointFull[];
  seriesColors?: string[];
}

export default function RenderedChart({
  chartType,
  config,
  data,
  seriesColors,
}: RenderedChartProps) {
  const seriesData = useMemo(() => {
    let groupingOption: SeriesGroupingOptions = "Site";
    let seriesLabel: ChartMeasurementFieldOptions = "SiteName";
    let seriesFormat: string | undefined;
    let pointLabel: ChartMeasurementFieldOptions = "SiteName";
    let pointFormat: string | undefined;

    switch (chartType) {
      case "Bar":
        groupingOption = (config as BarConfig).seriesGrouping;
        seriesLabel = (config as BarConfig).seriesLabel;
        seriesFormat = (config as BarConfig).seriesLabelFormat;
        pointLabel = (config as BarConfig).groupingLabel;
        pointFormat = (config as BarConfig).groupingLabelFormat;
        break;
      case "Line":
        groupingOption = (config as LineConfig).seriesGrouping;
        seriesLabel = (config as LineConfig).seriesLabel;
        seriesFormat = (config as LineConfig).seriesLabelFormat;
        pointLabel = (config as LineConfig).groupingLabel;
        pointFormat = (config as LineConfig).groupingLabelFormat;
        break;
      case "Scatter":
        groupingOption = (config as ScatterConfig).seriesGrouping;
        seriesLabel = (config as ScatterConfig).seriesLabel;
        seriesFormat = (config as ScatterConfig).seriesLabelFormat;
        pointLabel = (config as ScatterConfig).groupingLabel;
        pointFormat = (config as ScatterConfig).groupingLabelFormat;
        break;
      case "Schoeller":
        groupingOption = (config as SchoellerConfig).seriesGrouping;
        seriesLabel = (config as SchoellerConfig).seriesLabel;
        seriesFormat = (config as SchoellerConfig).seriesLabelFormat;
        break;
    }

    const getLabelValue = (
      point: DataPointFull,
      field: ChartMeasurementFieldOptions,
      format?: string
    ) => {
      switch (field) {
        case "FormName":
          return point.form.name;
        case "MeasurementTimestamp":
          return format
            ? moment(point.measurementTimestamp).format(format)
            : moment(point.measurementTimestamp).format("YYYY/MM/DD HH:mm:ss");
        case "SiteName":
          return point.site.name;
      }
    };

    const series = groupArray(data, (dp) =>
      groupingOption === "Site"
        ? dp.site.id
        : dp.measurementTimestamp.toString()
    ).map((grp) =>
      grp.data.map((p) => ({
        seriesLabel: getLabelValue(p, seriesLabel, seriesFormat),
        pointLabel: getLabelValue(p, pointLabel, pointFormat),
        pointValue: p.value,
        pointMeasurable: p.measurable,
        site: p.site,
        form: p.form,
        measurement: { date: p.measurementTimestamp },
      }))
    );

    return series;
  }, [chartType, config, data]);

  return (
    <div id="zamanzi-chart-output" className="rendered-chart">
      {chartType === "Bar" && (
        <BarChart
          data={seriesData}
          getBarValue={(p) => p.pointValue}
          getBarLabel={(p) => p.pointLabel}
          formatBarValue={(p) => p.pointValue.toFixed(3)}
          showBarValues={(config as BarConfig).showBarValues}
          showGrid={(config as BarConfig).showGrid}
          stacked={(config as BarConfig).stacked}
          axis={{
            y: {
              label:
                (config as BarConfig).yAxisLabel ||
                (config as BarConfig).measurable.name,
              tickCount: (config as BarConfig).yAxisTickCount,
              tickPrecision: (config as BarConfig).yAxisTickPrecision,
              max: (config as BarConfig).yAxisMax,
            },
            x: {
              label:
                (config as BarConfig).xAxisLabel ||
                ((config as BarConfig).seriesGrouping === "Measurement"
                  ? "Measurements"
                  : "Sites"),
            },
          }}
          series={{
            getSeriesName: (p) => p.seriesLabel,
            getSeriesColor: (_, seriesIndex) =>
              seriesColors?.[seriesIndex] ?? "",
            location: (config as BarConfig).legendPosition,
          }}
          guides={(config as BarConfig).guides.map((g) => ({
            name: g.label,
            yValue: g.value,
            color: g.color,
            style: g.style,
          }))}
          bands={(config as BarConfig).areaBands.map((a) => ({
            name: a.label,
            yValueFrom: a.min,
            yValueTo: a.max,
            color: a.color,
          }))}
        />
      )}
      {chartType === "Line" && (
        <LineChart
          data={seriesData}
          getPointValue={(p) => p.pointValue}
          getPointLabel={(p) => p.pointLabel}
          formatPointValue={(p) => p.pointValue.toFixed(3)}
          showPointValues={(config as LineConfig).showPointValues}
          showGrid={(config as LineConfig).showGrid}
          axis={{
            y: {
              label:
                (config as LineConfig).yAxisLabel ||
                (config as LineConfig).measurable.name,
              tickCount: (config as LineConfig).yAxisTickCount,
              tickPrecision: (config as LineConfig).yAxisTickPrecision,
              min: (config as LineConfig).yAxisMin,
              max: (config as LineConfig).yAxisMax,
              scale: (config as LineConfig).scale,
            },
            x: {
              label:
                (config as LineConfig).xAxisLabel ||
                ((config as LineConfig).seriesGrouping === "Measurement"
                  ? "Measurements"
                  : "Sites"),
            },
          }}
          series={{
            getSeriesName: (p) => p.seriesLabel,
            getSeriesColor: (_, seriesIndex) =>
              seriesColors?.[seriesIndex] ?? "",
            location: (config as LineConfig).legendPosition,
          }}
          guides={(config as LineConfig).guides.map((g) => ({
            name: g.label,
            yValue: g.value,
            color: g.color,
            style: g.style,
          }))}
          bands={(config as LineConfig).areaBands.map((a) => ({
            name: a.label,
            yValueFrom: a.min,
            yValueTo: a.max,
            color: a.color,
          }))}
        />
      )}
      {chartType === "Scatter" && (
        <ScatterChart
          data={seriesData}
          getPointXValue={(p) =>
            p.filter(
              (p) =>
                p.pointMeasurable.id ===
                (config as ScatterConfig).xAxisMeasurable.id
            )[0]?.pointValue ?? 0
          }
          getPointYValue={(p) =>
            p.filter(
              (p) =>
                p.pointMeasurable.id ===
                (config as ScatterConfig).yAxisMeasurable.id
            )[0]?.pointValue ?? 0
          }
          showGrid={(config as ScatterConfig).showGrid}
          axis={{
            y: {
              label: (config as ScatterConfig).yAxisLabel,
              //color: (config as BarConfig).yAxisLabel,
              tickCount: (config as ScatterConfig).yAxisTickCount,
              tickPrecision: (config as ScatterConfig).yAxisTickPrecision,
              min: (config as ScatterConfig).yAxisMin,
              max: (config as ScatterConfig).yAxisMax,
            },
            x: {
              label: (config as ScatterConfig).xAxisLabel,
              //color: "#2222cc",
              tickCount: (config as ScatterConfig).xAxisTickCount,
              tickPrecision: (config as ScatterConfig).xAxisTickPrecision,
              min: (config as ScatterConfig).xAxisMin,
              max: (config as ScatterConfig).xAxisMax,
            },
          }}
          series={{
            getElementGroup: (p) => p.pointLabel,
            getSeriesName: (p) => p.seriesLabel,
            getSeriesColor: (_, seriesIndex) =>
              seriesColors?.[seriesIndex] ?? "",
            location: (config as ScatterConfig).legendPosition,
          }}
          guides={(config as ScatterConfig).guides.map((g) => ({
            name: g.label,
            yValue: g.value,
            color: g.color,
            style: g.style,
          }))}
          bands={(config as ScatterConfig).areaBands.map((a) => ({
            name: a.label,
            yValueFrom: a.min,
            yValueTo: a.max,
            color: a.color,
          }))}
        />
      )}
      {chartType === "BoxAndWhisker" && (
        <BoxAndWhiskerChart
          data={seriesData}
          getPointValue={(p) => p.pointValue}
          showGrid={(config as BoxAndWhiskerConfig).showGrid}
          showLatestValue
          whiskerType={(config as BoxAndWhiskerConfig).whiskerType}
          axis={{
            y: {
              label:
                (config as BoxAndWhiskerConfig).yAxisLabel ||
                (config as BoxAndWhiskerConfig).measurable.name,
            },
            x: {
              label: (config as BoxAndWhiskerConfig).xAxisLabel || "Sites",
            },
          }}
          series={{
            getSeriesName: (p) => p.seriesLabel,
            getSeriesColor: (_, seriesIndex) =>
              seriesColors?.[seriesIndex] ?? "",
          }}
          guides={(config as BoxAndWhiskerConfig).guides.map((g) => ({
            name: g.label,
            yValue: g.value,
            color: g.color,
            style: g.style,
          }))}
          bands={(config as BoxAndWhiskerConfig).areaBands.map((a) => ({
            name: a.label,
            yValueFrom: a.min,
            yValueTo: a.max,
            color: a.color,
          }))}
          styles={{
            barColor: (config as BoxAndWhiskerConfig).barColor,
            barStrokeColor: (config as BoxAndWhiskerConfig).barStrokeColor,
            outlierTopColor: (config as BoxAndWhiskerConfig).outlierTopColor,
            latestValueColor: (config as BoxAndWhiskerConfig).latestValueColor,
            outlierBottomColor: (config as BoxAndWhiskerConfig)
              .outlierBottomColor,
          }}
        />
      )}
      {chartType === "Schoeller" && (
        <SchoellerChart
          data={seriesData}
          getPointValue={(p) => p.pointValue}
          getPointLabel={(p) => p.pointMeasurable.name}
          showGrid
          axis={{
            y: {
              label: (config as SchoellerConfig).yAxisLabel,
              min: (config as SchoellerConfig).yAxisMin,
              max: (config as SchoellerConfig).yAxisMax,
            },
            x: {
              label: (config as SchoellerConfig).xAxisLabel,
            },
          }}
          series={{
            getSeriesName: (p) => p.seriesLabel,
            getSeriesColor: (_, seriesIndex) =>
              seriesColors?.[seriesIndex] ?? "",
            location: (config as SchoellerConfig).legendPosition,
          }}
        />
      )}
      {chartType === "Piper" && (
        <PiperChart
          data={seriesData}
          showGrid
          elements={{
            cations: {
              a: (config as PiperConfig).cationsAElements.map((e) => e.name),
              b: (config as PiperConfig).cationsBElements.map((e) => e.name),
              c: (config as PiperConfig).cationsCElements.map((e) => e.name),
            },
            anions: {
              a: (config as PiperConfig).anionsAElements.map((e) => e.name),
              b: (config as PiperConfig).anionsBElements.map((e) => e.name),
              c: (config as PiperConfig).anionsCElements.map((e) => e.name),
            },
          }}
          getElementValue={(p, e) =>
            p.filter((p) => p.pointMeasurable.name === e)[0]?.pointValue ?? 0
          }
          series={{
            getElementGroup: (p) => p.pointLabel,
            getSeriesName: (p) => p.seriesLabel,
            getSeriesColor: (_, seriesIndex) =>
              seriesColors?.[seriesIndex] ?? "",
            location: (config as PiperConfig).legendPosition,
          }}
          axis={{
            cationsLabels: {
              a: (config as PiperConfig).cationsAAxisLabel,
              b: (config as PiperConfig).cationsBAxisLabel,
              c: (config as PiperConfig).cationsCAxisLabel,
            },
            anionsLabels: {
              a: (config as PiperConfig).anionsAAxisLabel,
              b: (config as PiperConfig).anionsBAxisLabel,
              c: (config as PiperConfig).anionsCAxisLabel,
            },
          }}
          styles={{
            anionsBackgroundColor: (config as PiperConfig).anionsColor,
            cationsBackgroundColor: (config as PiperConfig).cationsColor,
            matrixBackgroundColor: (config as PiperConfig).projectionColor,
          }}
        />
      )}
      {chartType === "Durov" && (
        <DurovChart
          data={seriesData}
          showGrid
          elements={{
            cations: {
              a: (config as DurovConfig).cationsAElements.map((e) => e.name),
              b: (config as DurovConfig).cationsBElements.map((e) => e.name),
              c: (config as DurovConfig).cationsCElements.map((e) => e.name),
            },
            anions: {
              a: (config as DurovConfig).anionsAElements.map((e) => e.name),
              b: (config as DurovConfig).anionsBElements.map((e) => e.name),
              c: (config as DurovConfig).anionsCElements.map((e) => e.name),
            },
            rightPlot: (config as DurovConfig).rightPlotMeasurable.name,
            bottomPlot: (config as DurovConfig).bottomPlotMeasurable.name,
          }}
          getElementValue={(p, e) =>
            p.filter((p) => p.pointMeasurable.name === e)[0]?.pointValue ?? 0
          }
          series={{
            getElementGroup: (p) => p.pointLabel,
            getSeriesName: (p) => p.seriesLabel,
            getSeriesColor: (_, seriesIndex) =>
              seriesColors?.[seriesIndex] ?? "",
            location: (config as PiperConfig).legendPosition,
          }}
          axis={{
            cationsLabels: {
              a: (config as DurovConfig).cationsAAxisLabel,
              b: (config as DurovConfig).cationsBAxisLabel,
              c: (config as DurovConfig).cationsCAxisLabel,
            },
            anionsLabels: {
              a: (config as DurovConfig).anionsAAxisLabel,
              b: (config as DurovConfig).anionsBAxisLabel,
              c: (config as DurovConfig).anionsCAxisLabel,
            },
            rightPlot: {
              x: {
                label: (config as DurovConfig).rightPlotAxisLabel,
                min: (config as DurovConfig).rightPlotAxisMin,
                max: (config as DurovConfig).rightPlotAxisMax,
              },
            },
            bottomPlot: {
              y: {
                label: (config as DurovConfig).bottomPlotAxisLabel,
                min: (config as DurovConfig).bottomPlotAxisMin,
                max: (config as DurovConfig).bottomPlotAxisMax,
              },
            },
          }}
          styles={{
            anionsBackgroundColor: (config as DurovConfig).anionsColor,
            cationsBackgroundColor: (config as DurovConfig).cationsColor,
            matrixBackgroundColor: (config as DurovConfig).projectionColor,
            bottomPlotBackgroundColor: (config as DurovConfig).bottomPlotColor,
            rightPlotBackgroundColor: (config as DurovConfig).rightPlotColor,
          }}
        />
      )}
      {chartType === "Stiff" && (
        <StiffChart
          data={seriesData}
          elements={{
            cations: (config as StiffConfig).cationElements.map((e) => [
              e.name,
            ]),
            anions: (config as StiffConfig).anionElements.map((e) => [e.name]),
          }}
          getElementValue={(p, e) =>
            p.filter((p) => p.pointMeasurable.name === e)[0]?.pointValue ?? 0
          }
          series={{
            getSeriesTitle: (p) => {
              switch ((config as StiffConfig).diagramLabel) {
                case "MeasurementTimestamp":
                  return p.measurement.date.toString();
                case "FormName":
                  return p.form.name;
                default:
                  return p.site.name;
              }
            },
            getSeriesSubtitle: (p) => {
              switch ((config as StiffConfig).diagramSubLabel) {
                case "MeasurementTimestamp":
                  return p.measurement.date.toString();
                case "FormName":
                  return p.form.name;
                default:
                  return p.site.name;
              }
            },
            unitLabel: (config as StiffConfig).xAxisUnitLabel,
          }}
          styles={{
            polygonColor: (config as StiffConfig).polygonColor,
          }}
        />
      )}
    </div>
  );
}

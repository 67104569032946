import React, { Suspense, lazy, useCallback } from "react";
import { Route, Routes } from "react-router-dom";
import { NavigableComponent } from "./interfaces";
import { Error404, HomePage, LoginPage, ProfilePage } from "./pages";
import { routes } from "./_config";
import { LoadingIndicator } from "./components";

const MapViewPage = lazy(
  async () => import("./pages/map-view-page/MapViewPage")
);
const SettingConfigPage = lazy(
  () => import("./pages/settings-page/SettingConfigPage")
);
const SettingsPage = lazy(() => import("./pages/settings-page/SettingsPage"));
const SettingItemPage = lazy(
  () => import("./pages/settings-page/SettingItemPage")
);
const SitesItemPage = lazy(() => import("./pages/sites-page/SitesItemPage"));
const SitesLibraryPage = lazy(
  () => import("./pages/sites-page/SitesLibraryPage")
);
const MeasurementsLibraryPage = lazy(
  () => import("./pages/measurements-page/MeasurementsLibraryPage")
);
const MeasurementsItemPage = lazy(
  () => import("./pages/measurements-page/MeasurementsItemPage")
);
const ChartsLibraryPage = lazy(
  () => import("./pages/chart-builder-page/ChartsLibraryPage")
);
const ChartsItemPage = lazy(
  () => import("./pages/chart-builder-page/ChartsItemPage")
);
const SitesExportPage = lazy(
  () => import("./pages/sites-page/SitesExportPage")
);
const SitesImportPage = lazy(
  () => import("./pages/sites-page/SitesImportPage")
);
const SitesImportTemplatePage = lazy(
  () => import("./pages/sites-page/SitesImportTemplatePage")
);
const ReportBuilderPage = lazy(
  () => import("./pages/report-builder-page/ReportBuilderPage")
);
const MeasurementsExportPage = lazy(
  () => import("./pages/measurements-page/MeasurementsExportPage")
);
const MeasurementsImportPage = lazy(
  () => import("./pages/measurements-page/MeasurementsImportPage")
);
const MeasurementsImportTemplatePage = lazy(
  () => import("./pages/measurements-page/MeasurementsImportTemplatePage")
);

export default function AppRoutes({
  setBreadcrumbs: _setBreadcrumbs,
  setToolbarConfig: _setToolbarConfig,
}: NavigableComponent) {
  const setBreadcrumbs = useCallback(_setBreadcrumbs, [_setBreadcrumbs]);
  const setToolbarConfig = useCallback(_setToolbarConfig, [_setToolbarConfig]);

  const routeComponentProps: NavigableComponent = {
    setBreadcrumbs,
    setToolbarConfig,
  };

  return (
    <Suspense
      fallback={
        <div className="h-app-bundle-loader">
          <LoadingIndicator />
        </div>
      }
    >
      <Routes>
        <Route
          path={routes.home.raw}
          element={<HomePage {...routeComponentProps} />}
        />
        <Route path={routes.login.raw} element={<LoginPage />} />
        <Route path={routes.loginVerification.raw} element={<LoginPage />} />
        <Route
          path={routes.profile.raw}
          element={<ProfilePage {...routeComponentProps} />}
        />
        <Route
          path={routes.settings.raw}
          element={<SettingsPage {...routeComponentProps} />}
        >
          <Route
            index
            element={<SettingConfigPage index {...routeComponentProps} />}
          />
          <Route
            path={routes.settingCategory.raw}
            element={<SettingConfigPage {...routeComponentProps} />}
          >
            <Route
              index
              element={<SettingItemPage index {...routeComponentProps} />}
            />
            <Route
              path={routes.settingItem.raw}
              element={<SettingItemPage {...routeComponentProps} />}
            />
          </Route>
        </Route>
        <Route
          path={routes.siteExport.raw}
          element={<SitesExportPage {...routeComponentProps} />}
        />
        <Route
          path={routes.siteImport.raw}
          element={<SitesImportPage {...routeComponentProps} />}
        />
        <Route
          path={routes.siteImportTemplate.raw}
          element={<SitesImportTemplatePage {...routeComponentProps} />}
        />
        <Route
          path={routes.sites.raw}
          element={<SitesLibraryPage {...routeComponentProps} />}
        >
          <Route
            index
            element={<SitesItemPage index {...routeComponentProps} />}
          />
          <Route
            path={routes.siteItem.raw}
            element={<SitesItemPage {...routeComponentProps} />}
          />
        </Route>
        <Route
          path={routes.measurementsExport.raw}
          element={<MeasurementsExportPage {...routeComponentProps} />}
        />
        <Route
          path={routes.measurementsImport.raw}
          element={<MeasurementsImportPage {...routeComponentProps} />}
        />
        <Route
          path={routes.measurementsImportTemplate.raw}
          element={<MeasurementsImportTemplatePage {...routeComponentProps} />}
        />
        <Route
          path={routes.measurements.raw}
          element={<MeasurementsLibraryPage {...routeComponentProps} />}
        >
          <Route
            index
            element={<MeasurementsItemPage index {...routeComponentProps} />}
          />
          <Route
            path={routes.measurementsItem.raw}
            element={<MeasurementsItemPage {...routeComponentProps} />}
          />
        </Route>
        <Route
          path={routes.map.raw}
          element={<MapViewPage {...routeComponentProps} />}
        />
        <Route
          path={routes.charts.raw}
          element={<ChartsLibraryPage {...routeComponentProps} />}
        >
          <Route
            index
            element={<ChartsItemPage index {...routeComponentProps} />}
          />
          <Route
            path={routes.chartItem.raw}
            element={<ChartsItemPage {...routeComponentProps} />}
          />
        </Route>
        <Route
          path={routes.reportBuilder.raw}
          element={<ReportBuilderPage {...routeComponentProps} />}
        />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </Suspense>
  );
}

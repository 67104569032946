import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useStateReducer } from "../../hooks";
import { ChartTemplateFull, NavigableComponent } from "../../interfaces";
import { chartTemplatesService } from "../../services";
import { FaHandPointer } from "react-icons/fa";
import { getQueryParameter } from "../../utilities";
import { routes } from "../../_config";
import { LoadingIndicator, Scrollable } from "../../components";
import EditChartTemplate from "./components/EditChartTemplate";
import ViewChartTemplate from "./components/ViewChartTemplate";
import "./_styles.scss";

interface ChartTemplatesItemPageProps {
  index?: boolean;
  onLoad: () => void;
}
interface State {
  chartTemplate?: ChartTemplateFull | null;
  isSaveDisabled?: boolean;
  loading?: boolean;
}

export default function ChartTemplatesItemPage({
  setBreadcrumbs,
  setToolbarConfig,
  index,
  onLoad,
}: NavigableComponent & ChartTemplatesItemPageProps) {
  const { settingId } = useParams();
  const [state, setState] = useStateReducer<State>({ isSaveDisabled: true });
  const { chartTemplate } = state;
  const loading = chartTemplate ? false : true;
  const location = useLocation();
  const mode = getQueryParameter(location.search, "mode");

  useEffect(() => {
    const load = async () => {
      const _chartTemplate = await chartTemplatesService.getSingle({
        id: settingId ?? "",
      });

      setState({ chartTemplate: _chartTemplate, loading: false });

      onLoad();

      setBreadcrumbs(
        index
          ? [
              { text: "Settings", to: routes.settings.go() },
              { text: "Chart Templates" },
            ]
          : [
              { text: "Settings", to: routes.settings.go() },
              { text: "Chart Templates", to: routes.chartTemplates.raw },
              { text: _chartTemplate.name },
            ]
      );
    };

    setState({ chartTemplate: null, isSaveDisabled: true, loading: true });
    load();
  }, [settingId, mode, setBreadcrumbs, onLoad, index, setState]);

  let content = <></>;

  if (index)
    content = (
      <>
        <FaHandPointer /> Select a chart template on the left.
      </>
    );
  else if (!chartTemplate || settingId !== chartTemplate.id || loading)
    content = <LoadingIndicator />;
  else
    content = (
      <Scrollable>
        {mode === "edit" ? (
          <EditChartTemplate
            setToolbarConfig={setToolbarConfig}
            setBreadcrumbs={setBreadcrumbs}
            chartTemplate={chartTemplate}
            onLoad={onLoad}
          />
        ) : (
          <ViewChartTemplate
            setToolbarConfig={setToolbarConfig}
            setBreadcrumbs={setBreadcrumbs}
            chartTemplate={chartTemplate}
          />
        )}
      </Scrollable>
    );

  return content;
}

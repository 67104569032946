import Constants from "../Constants";

export default function calculateRotatedLabelHeight(
  labelWidth: number,
  rotationAngle: number
) {
  const { xAxisLabelHeight, xAxisLabelMarginTop } = Constants;
  const radians = (rotationAngle * Math.PI) / 180;

  return (
    labelWidth * Math.sin(radians) + xAxisLabelHeight + xAxisLabelMarginTop
  );
}

import { GetSingleArgs } from "../interfaces";
import ReferenceSummary from "../interfaces/ReferenceSummary";
import { asyncify } from "../utilities";

interface CoordinateMethodsService {
  getAllCoordinateMethods(): Promise<ReferenceSummary[]>;
  getSingleCoordinateMethod(args: GetSingleArgs): Promise<ReferenceSummary>;
}

let _CACHE: ReferenceSummary[] = [
  {
    id: "NoInformation",
    name: "No Information"
  },
  {
    id: "HandheldGps",
    name: "Global Positioning System (handheld) (S)"
  },
  {
    id: "Surveyed",
    name: "Levelled or surveyed (L)"
  },
  {
    id: "Interpolated",
    name: "Interpolated from map (M)"
  },
  {
    id: "DifferentialGps",
    name: "Differential GPS with base station (D)"
  },
  {
    id: "DistrictAddress",
    name: "District and farm name and number (F)"
  }
];

const service: CoordinateMethodsService = {
  async getAllCoordinateMethods() {
    return await asyncify(() => _CACHE, 0);
  },

  async getSingleCoordinateMethod({ id }) {
    return (await service.getAllCoordinateMethods()).filter((s) => s.id === id)[0];
  },
};

export default service;

import Constants from "../Constants";
import { Band, Guide } from "../interfaces";
import measureTextWidth from "./measureTextWidth";

function isGuide(guide: object): guide is Guide {
  return (guide as Guide).yValue !== undefined;
}

function isBand(band: object): band is Band {
  return (band as Band).yValueFrom !== undefined;
}

export default function calculateBandAndGuideRows(
  guides: Guide[],
  bands: Band[],
  canvas?: HTMLCanvasElement
) {
  const { plotWidth, seriesRectangleSize, seriesMargin, seriesHeight } =
    Constants;
  const localCanvas = canvas ? undefined : document.createElement("canvas");
  const combined: (Guide | Band)[] = [...bands, ...guides];
  const labels = combined.map((d, i) => {
    const label = d.name;
    const textWidth = measureTextWidth(label, localCanvas ?? canvas);

    return {
      bandData: isBand(d) ? (d as Band) : undefined,
      guideData: isGuide(d) ? (d as Guide) : undefined,
      label,
      textWidth,
      rectangleSize: seriesRectangleSize,
      marginLeft: 10,
      marginRight: 10,
      totalWidth: textWidth + seriesRectangleSize + 20,
      row: -1,
      rowWidth: 0,
    };
  });

  localCanvas?.remove();

  let labelRows = 1;
  let currentRowWidth = 0;
  let lastSliceIndex = -1;

  for (let i = 0; i < labels.length; i++) {
    const label = labels[i];
    const nextLabel = i < labels.length - 1 ? labels[i + 1] : undefined;

    currentRowWidth += label.totalWidth;
    label.row = labelRows;

    if (
      nextLabel &&
      currentRowWidth + nextLabel?.totalWidth > plotWidth - seriesMargin * 2
    ) {
      for (let s = lastSliceIndex + 1; s <= i; s++)
        labels[s].rowWidth = currentRowWidth;

      lastSliceIndex = i;
      currentRowWidth = 0;
      labelRows++;
    }
  }

  for (let s = lastSliceIndex + 1; s < labels.length; s++)
    labels[s].rowWidth = currentRowWidth;

  return {
    rowCount: labelRows,
    labelData: labels,
    totalHeight: labels.length ? labelRows * seriesHeight : 0,
  };
}

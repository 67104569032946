import React from "react";
import { faLock, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import Helmet from "react-helmet";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { AppLogo, Button } from "../../components";
import { TextField } from "../../components/fields";
import { useAuthentication, useStateReducer } from "../../hooks";
import { classNameBuilder } from "../../utilities";
import { global, routes } from "../../_config";
import "./_styles.scss";
import { hideAlert, showAlert } from "../../components/alert/Alert";
import AlertTypeEnum from "../../enums/AlertTypeEnum";
import securityService from "../../services/securityService";

interface State {
  username?: string;
  password?: string;
  submitting?: boolean;
  error?: boolean;
  loading?: boolean;
  errorHighlight?: boolean;
  verificationResult?: string;
  showOTP?: boolean;
  otp?: string;
}

export default function LoginPage() {
  const navigate = useNavigate();
  const { jwtToken } = useParams();
  const [state, setState] = useStateReducer<State>({
    username: "",
    password: "",
    submitting: false,
    error: false,
    loading: true,
    errorHighlight: false,
    verificationResult: "",
    showOTP: false,
    otp: "",
  });
  const auth = useAuthentication();

  const handleChange = (prop: string, value: any) =>
    setState({ [prop]: value, errorHighlight: false });

  const handleSignIn = async () => {
    setState({ submitting: true });

    try {
      const res = await auth?.signIn(
        state.username ?? "",
        state.password ?? ""
      );

      if (res?.loginSuccess && res?.otpRequired)
        setState({ submitting: false, showOTP: true });
      else if (res?.loginSuccess && !res?.otpRequired) {
        setState({ submitting: false, showOTP: false });
        navigate(routes.home.go(), { replace: true });
      } else {
        setState({
          error: true,
          errorHighlight: true,
          password: "",
          submitting: false,
        });
        setTimeout(() => setState({ error: false }), 1000);
      }
    } catch (error: string | any) {
      if (error.toString().includes("User account requires verification"))
        handleVerificationRequest();
      else {
        setState({
          error: true,
          errorHighlight: true,
          password: "",
          submitting: false,
        });
        setTimeout(() => setState({ error: false }), 1000);
      }

      setState({ submitting: false });
    }
  };

  const handleOTPValidation = async () => {
    setState({ submitting: true });
    try {
      const userId = localStorage.getItem("userId");
      const res = await auth?.otpValidation(userId ?? "", state.otp ?? "");

      if (res) {
        setState({ submitting: false, showOTP: false });
        navigate(routes.home.go(), { replace: true });
      } else {
        setState({
          error: true,
          errorHighlight: true,
          password: "",
          submitting: false,
        });
        setTimeout(() => setState({ error: false }), 1000);
      }
    } catch (error: string | any) {
      setState({
        error: true,
        errorHighlight: true,
        password: "",
        submitting: false,
      });
      setTimeout(() => setState({ error: false }), 1000);

      setState({ submitting: false });
    }
  };

  const handleVerificationRequest = () => {
    showAlert({
      content: "Your account requires email verification.",
      options: {
        actions: [
          {
            text: "Request verification email",
            onClick: handleVerificationEmail,
            primary: true,
          },
        ],
        type: AlertTypeEnum.Info,
      },
    });
    return false;
  };

  const handleVerificationEmail = async () => {
    hideAlert();
    setState({ submitting: true });

    try {
      const username = state.username;
      const res = await securityService.verificationEmail(username ?? "");

      if (res) handleEmailAlert();
    } catch (error: string | any) {
      setState({
        error: true,
        errorHighlight: true,
        password: "",
        submitting: false,
      });
      setTimeout(() => setState({ error: false }), 1000);

      setState({ submitting: false });
    }
  };

  const handleOkClick = () => {
    hideAlert();
    setState({
      submitting: false,
      verificationResult: "",
      error: false,
      errorHighlight: false,
    });
  };

  const handleEmailAlert = () => {
    showAlert({
      content:
        "A verification email has been sent to your email address. Please click on the link in the email to verify your account and login.",
      options: {
        actions: [{ text: "Ok", onClick: handleOkClick, primary: true }],
        type: AlertTypeEnum.Info,
      },
    });
    return false;
  };

  const checkForSubmit = (e: any) => {
    if (e.key === "Enter") {
      handleSignIn();
      setState({ submitting: true });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setState({ loading: false });
    }, 1100);
  }, [setState]);

  useEffect(() => {
    const fetchData = async () => {
      if (jwtToken) {
        try {
          const res = await securityService.accountVerify(jwtToken ?? "");

          if (res) {
            setState({
              submitting: false,
              verificationResult:
                "Your account has been verified. Please login.",
            });
          }
        } catch (error: any) {
          if (error.toString().includes("The token has expired.")) {
            setState({
              verificationResult:
                "Token Expired: Please login to request a new token.",
            });
            setState({
              error: true,
              errorHighlight: true,
              submitting: false,
            });
          } else if (error.toString().includes("Invalid token specified")) {
            setState({
              verificationResult:
                "Invalid Token: Please login to request a new token.",
            });
            setState({
              error: true,
              errorHighlight: true,
              submitting: false,
            });
          } else {
            setState({
              verificationResult: "An error has occurred. Please try again.",
            });
            setState({
              error: true,
              errorHighlight: true,
              submitting: false,
            });
          }
        }
      }
    };

    fetchData();
  }, [jwtToken, setState]);

  if (auth?.user) return <Navigate to="/" replace />;

  return (
    <>
      <Helmet>
        <title>Sign In - {global.applicationName}</title>
      </Helmet>
      <div
        className={classNameBuilder(
          "h-app-login",
          state.loading ? "in" : "",
          state.error ? "error" : "",
          state.errorHighlight ? "error-highlight" : ""
        )}
      >
        <AppLogo />

        {state.showOTP ? (
          <div>
            <div className="credentials">
              <p style={{ textAlign: "left", fontWeight: "bold" }}>
                Please check your email for the OTP
              </p>
              <TextField
                focus={!state.otp}
                startAdornment={<FontAwesomeIcon icon={faUser} />}
                placeholder="OTP"
                value={state.otp}
                onChange={(e: any) => handleChange("otp", e.target.value)}
              />
            </div>
            <Button
              text="Submit"
              primary
              raised
              onClick={handleOTPValidation}
              working={state.submitting}
              disabled={!state.otp}
            />
          </div>
        ) : (
          <div>
            <div className="credentials">
              <p
                className={classNameBuilder(
                  "message",
                  state.error ? "error" : ""
                )}
              >
                {state.verificationResult}
              </p>
              <TextField
                focus={!state.username}
                startAdornment={<FontAwesomeIcon icon={faUser} />}
                placeholder="Username or email address"
                value={state.username}
                onChange={(e: any) => handleChange("username", e.target.value)}
                onKeyDown={checkForSubmit}
              />
              <TextField
                focus={state.error}
                type="password"
                placeholder="Password"
                startAdornment={<FontAwesomeIcon icon={faLock} />}
                value={state.password}
                onChange={(e: any) => handleChange("password", e.target.value)}
                onKeyDown={checkForSubmit}
              />
            </div>
            <Button
              text="Sign In"
              primary
              raised
              onClick={handleSignIn}
              working={state.submitting}
              disabled={!state.username || !state.password}
            />
          </div>
        )}
      </div>
    </>
  );
}
